import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../service/LanguageService';

const MessageDisplayModalComponent = (props) => {
  const [severityClass, setseverityClass] = useState("pi pi-check text-green-500");

  useEffect(() => {
    if(props?.severity === 'warning') {
      setseverityClass("pi pi-exclamation-triangle text-orange-500")
    } else if(props?.severity === 'error') {
      setseverityClass("pi pi-times-circle text-red-500")
    }

  }, [props]);

  const dialogFooter = (
    <>
      <Button label={translatedMessage("generic.close")} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
    </>
  );

  return (
    <Dialog
      visible={props.visible}
      style={{ width: '600px' }}
      header={translatedMessage(props.dialogTitle)}
      modal
      footer={dialogFooter}
      onHide={() => props.closeDialog()}
    >
      <div className="flex align-items-center">
        <i className={"mr-3 ".concat(severityClass)} style={{ fontSize: '3rem' }} />
        <div className='w-full text-align-justify align-items-center'>
          <div className="mb-3">{translatedMessage(props.dialogInfo)}</div>
        </div>
      </div>
    </Dialog>

  );
};

export default MessageDisplayModalComponent;
