import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';

const ApplicationDataTableUtils = {

  statusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-application-${_status.toLowerCase()}`}>{translatedMessage("ApplicationStatus." + _status)}</div>
    )
  },

  statusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      // placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  }

};

export default ApplicationDataTableUtils;