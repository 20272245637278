import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import useAuth from '../../hooks/useAuth';
import ContestationComponent from './ContestationComponent';
import ContestationDialog from './ContestationDialog';
import SecurityService from '../../service/SecurityService';
import { Message } from 'primereact/message';

const Contestations = (props) => {
    const [contestationRequest, setContestationRequest] = useState(null);
    const [contestationResponse, setContestationResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isResponse, setIsResponse] = useState(false);
    const [viewEditDialog, setViewEditDialog] = useState(false);
    const [canCreateContestationResponse, setCanCreateContestationResponse] = useState(false);
    const [canSeeContestationResponse, setCanSeeContestationResponse] = useState(false);

    const toast = useRef(null);
    const { auth } = useAuth();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        const checkCanCreate = async (contestationId) => {
            setCanCreateContestationResponse(await SecurityService.check('CAN_RESPOND_TO_CONTESTATION', contestationId));
        };

        const checkCanSee = async (contestationId) => {
            setCanSeeContestationResponse(await SecurityService.check('CAN_SEE_CONTESTATION_RESPONSE', contestationId));
        };

        const getContestations = async () => {
            await applicationService.getContestationsByResult(props?.application.id, props?.result.id)
                .then((_contestations) => {
                    let _contestationRequest = _contestations.find((contestation) => 'REQUEST' === contestation.type)
                    setContestationRequest(_contestationRequest)
                    setContestationResponse(_contestations.find((contestation) => 'RESPONSE' === contestation.type))

                    if (_contestationRequest) {
                        checkCanCreate(_contestationRequest?.id)
                        checkCanSee(_contestationRequest?.id)
                    }

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        setIsLoading(true);
        getContestations();
    }, [props?.application.id, applicationService, props?.result.id]);

    const showEditContestationRequestDialog = () => {
        setViewEditDialog(true)
        setIsResponse(false)
    };

    const showEditContestationResponseDialog = () => {
        setViewEditDialog(true)
        setIsResponse(true)
    };

    const updateContestations = (savedContestation) => {
        if ('REQUEST' === savedContestation.type) {
            setContestationRequest(savedContestation);
        }
        if ('RESPONSE' === savedContestation.type) {
            setContestationResponse(savedContestation);
        }
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className='grid'>
                    <div className='col-12'>
                        <h5 className='mb-1'>{translatedMessage('application.contestation.request')}</h5>

                        {props?.application?.user.id === auth.user.id &&
                            <Message 
                                severity="warn" 
                                text={translatedMessage("application.contestation.request.info") + " " + translatedMessage('application.contestation.request.docs.info')} 
                                className='mb-3'
                            />
                        }

                        {!contestationRequest && (
                            <div className='flex align-items-center'>
                                {props?.result.canBeContested
                                    ? <span className='mr-2'>{translatedMessage('application.contestation.none')}</span>
                                    : <span className='mr-2'>{translatedMessage('application.contestation.not_available')}</span>
                                }
                                <Button
                                    label={translatedMessage('application.contestation.request.create')}
                                    icon="pi pi-plus"
                                    className="p-button-text pcn-button-slim"
                                    onClick={showEditContestationRequestDialog}
                                    visible={props?.application?.user.id === auth.user.id && props?.result.canBeContested && !props.readOnly}
                                />
                            </div>
                        )}
                        {contestationRequest && (
                            (contestationRequest.sent || props?.application?.user.id === auth.user.id)
                                ? <ContestationComponent contestation={contestationRequest} type="REQUEST" readOnly={props.readOnly} resultPublishDate={props?.application?.competition?.resultsPublishedOn}/>
                                : <span className='mr-2'>{translatedMessage('application.contestation.none')}</span>
                        )}
                    </div>

                    {contestationRequest && contestationRequest.sent && (
                        <div className='col-12'>
                            <h5 className='mb-1'>{translatedMessage('application.contestation.response')}</h5>
                            {!contestationResponse && (
                                <div className='flex align-items-center'>
                                    <Button
                                        label={translatedMessage('application.contestation.response.create')}
                                        icon="pi pi-plus"
                                        className="p-button-text pcn-button-slim"
                                        onClick={showEditContestationResponseDialog}
                                        visible={canCreateContestationResponse && !props.readOnly && contestationRequest.sent}
                                    />
                                </div>
                            )}
                            {contestationResponse &&
                                (contestationResponse.sent || canCreateContestationResponse || canSeeContestationResponse) && (
                                    <ContestationComponent contestation={contestationResponse} type="RESPONSE" readOnly={props.readOnly} />
                                )}
                        </div>
                    )}
                </div>

                <ContestationDialog
                    contestation={isResponse ? contestationResponse : contestationRequest}
                    result={{ id: props?.result.id }}
                    type={isResponse ? "RESPONSE" : "REQUEST"}
                    visible={viewEditDialog}
                    onHide={() => setViewEditDialog(false)}
                    afterSave={updateContestations}
                />
            </>
        );
    }
};

export default Contestations;
