import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormFieldService } from '../../service/FormFieldService';
import { translatedMessage } from '../../service/LanguageService';
import useAuth from '../../hooks/useAuth';

import FormEditDialog from './FormEditDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import DeleteModalComponent from '../../components/DeleteModalComponent';
import FormViewDialog from '../../components/form/FormViewDialog';

const FormList = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedFormFields, setSelectedFormFields] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const formFieldService = useMemo(() => new FormFieldService(), []);

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);

        const getForms = async () => {
            await formFieldService.getFormList()
                .then((forms) => {
                    setForms(forms);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getForms();
    }, [formFieldService]);

    const updateList = async (savedForm, isNew) => {
        let updatedForms = DataTableUtils.updateTableList(forms, savedForm, isNew)

        setForms(updatedForms);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedForm(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (form) => {
        setSelectedForm(form);
        setEditDialogVisible(true);
    };

    const showFormFieldsPage = (form) => {
        navigate(`/form/${form.id}/field`);
    };

    const showViewDialog = (form) => {
        let _form = { ...form };
        setSelectedForm(_form);
        formFieldService.getFormFieldList(_form.id)
            .then((fields) => {
                setSelectedFormFields(fields);
                setViewDialogVisible(true);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const showDeleteDialog = (form) => {
        setSelectedForm(form);
        setDeleteDialogVisible(true);
    };

    const deleteForm = () => {
        formFieldService.deleteForm(selectedForm.id)
            .then(() => {
                let _forms = forms.filter(item => item.id !== selectedForm.id)
                setForms(_forms)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedForm(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('form.newForm')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={translatedMessage('generic.export')} icon="pi pi-download" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        disabled={rowData.id < 1000} tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => showFormFieldsPage(rowData)}
                        tooltip={translatedMessage("form.field.fieldsForForm")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}>
                        <FontAwesomeIcon icon='fa-solid fa-folder-tree' />
                    </Button>
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)}
                        disabled={rowData.id < 1000} tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{translatedMessage('form.forms')}</h3>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={forms}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="stack"
                            >
                                <Column
                                    field="name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable headerStyle={{ width: '70%', minWidth: '10rem' }}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            <FormEditDialog
                                value={selectedForm}
                                visible={editDialogVisible}
                                visibleSetter={setEditDialogVisible}
                                afterSave={updateList}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedForm ? selectedForm.name : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteForm()}
                            />

                            <FormViewDialog
                                formFields={selectedFormFields}
                                visible={viewDialogVisible}
                                closeDialog={() => { setViewDialogVisible(false) }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default FormList;
