import React, { useState, useEffect, useRef, useMemo} from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { FormFieldService } from '../../service/FormFieldService';

const emptyForm = {
    id: null,
    name: ''
};

const EditFormDialog = (props) => {

    const [form, setForm] = useState(emptyForm);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const toast = useRef(null);

    const formFieldService = useMemo(() => new FormFieldService(), []);

    useEffect(() => {
        setForm(props.value ? props.value : emptyForm);
        setIsNew(props.value ? false : true);
    }, [props.value]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveForm = (data) => {
        formFieldService.saveForm(data)
        .then(savedForm => {
            if (typeof props.afterSave === 'function') {
                props.afterSave(savedForm, isNew);
            }
            toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })                    
            hideDialog();
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: form,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.name.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveForm(data);
            setForm(emptyForm);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="form-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                visible={dialogVisible} 
                style={{ width: '450px' }} 
                header={translatedMessage('form.details')} 
                modal 
                className="p-fluid" 
                footer={formDialogFooter} 
                onHide={hideDialog}
            >
                <form id="form-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })}/>
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                {translatedMessage("generic.nameRo")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('name')}
                </form>
            </Dialog>
        </>
    );
};

export default EditFormDialog;
