import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import DOMPurify from 'dompurify';
import { translatedMessage } from '../../service/LanguageService';
import ApiService from '../../service/ApiService';
import GeneralUtils from '../../utilities/GeneralUtils';
import LanguageService from '../../service/LanguageService';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import EnumService from '../../service/EnumService';

const RecruitmentPlanEdit = (props) => {
    let emptyRecruitmentPlan = {
        id: null,
        name: '',
        description: '',
        status: null,
        file: {
            id: '',
            name: '',
            uuid: ''
        }
    };

    const [recruitmentPlan, setRecruitmentPlan] = useState(emptyRecruitmentPlan);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [statusValues, setStatusValues] = useState([]);

    const toast = useRef(null);

    useEffect(() => {
        const getEnumValues = async () => {
            const response = await EnumService.getEnumByName("RecruitmentPlanStatus");
            setStatusValues(response)
        }

        getEnumValues();
    }, []);

    useEffect(() => {
        setRecruitmentPlan(props.value);
    }, [props.value]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideRecruitmentPlanDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setRecruitmentPlan(emptyRecruitmentPlan);
        formik.resetForm();
    };

    const saveRecruitmentPlan = (planData) => {
        async function savePlan(plan) {
            return ApiService.getAuthenticatedInstance()
                .post('/recruitment', plan)
                .then((response) => response.data);
        }

        return savePlan(planData).then(
            (response) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave();
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.save.success'), life: 3000 });
            },
            (error) => {
                let errorMessage = error && error.response && error.response.data && error.response.data.message;
                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 7000 });
            }
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: recruitmentPlan,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.name.required');
            }

            return errors;
        },
        onSubmit: (data) => {
            saveRecruitmentPlan(data);
            hideRecruitmentPlanDialog();
            setRecruitmentPlan(emptyRecruitmentPlan);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const recruitmentPlanDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideRecruitmentPlanDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-check" className="p-button-text" form="recruitmentPlan-form" type="submit" />
        </>
    );

    const setDescriptionValue = (description) => {
        formik.setFieldValue('description', description);
    }

    return (
        <>
            <Toast ref={toast} />

            <Dialog
                visible={dialogVisible}
                header={translatedMessage('recruitmentPlan.details')}
                modal
                resizable={false}
                className="pcn-dialog p-fluid"
                footer={recruitmentPlanDialogFooter}
                onHide={hideRecruitmentPlanDialog}
            >
                <form id="recruitmentPlan-form" onSubmit={formik.handleSubmit}>
                    <div className="field">
                        <label htmlFor="name">{translatedMessage('generic.nameRo')} *</label>
                        <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })}
                            disabled={recruitmentPlan.id && recruitmentPlan.status !== 'DRAFT'} />
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field pcn-sun-editor w-100">
                        <label htmlFor="description">{translatedMessage('generic.description')}</label>
                        {(!recruitmentPlan.id || recruitmentPlan.status === 'DRAFT') &&
                            <SunEditor
                                id="description" name="description"
                                lang={LanguageService.getCurrentLanguage}
                                height="250px"
                                width='auto'
                                defaultValue={formik.values.description}
                                placeholder={""}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                    charCounter: ApiService.getIsProduction() ? true : false,
                                    maxCharCount: ApiService.getIsProduction() ? 5000 : null
                                }}
                                onChange={setDescriptionValue}
                            />
                        }
                        {recruitmentPlan.id && recruitmentPlan.status !== 'DRAFT' &&
                            <div className="p-inputtext pcn-html-editor-view-container mb-3">
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formik.values.description) }} />
                            </div>
                        }
                        {recruitmentPlan.id && recruitmentPlan.status !== 'DRAFT' && <InputTextarea id="description" name="description" value={formik.values.description} onChange={formik.handleChange} rows={3} cols={20}
                            disabled={formik.values.status === 'PUBLISHED'} hidden />}
                        {getFormErrorMessage('description')}
                    </div>
                    {formik.values.id && (
                        <div className="field">
                            <label htmlFor="status">{translatedMessage('generic.status')}</label>
                            <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={statusValues}></Dropdown>
                            {getFormErrorMessage('status')}
                        </div>
                    )}
                </form>
            </Dialog>
        </>
    );
};

export default RecruitmentPlanEdit;
