import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { translatedMessage } from '../service/LanguageService';

const ProcessingDataModalComponent = (props) => {

  return (
    <Dialog
      visible={props.visible}
      style={{ width: '450px' }}
      header={translatedMessage(props.dialogTitle)}
      modal
      closable={false}
    >
      <div className="flex align-items-center">
        <div className='w-full text-align-left align-items-center'>
          <div className="mb-3">{translatedMessage(props.dialogInfo)}</div>
          <ProgressBar mode="indeterminate" style={{ height: '10px' }}></ProgressBar>
        </div>
      </div>
    </Dialog>

  );
};

export default ProcessingDataModalComponent;
