import ApiService from './ApiService';
import GeneralUtils from '../utilities/GeneralUtils';

export class ApplicationService {
    async getApplications(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/competition/${competitionId}/applications`)
                .then((response) => {
                    response.data.map((item) => {
                        item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getMyApplicationsList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/application/my')
                .then((response) => {
                    response.data.map((item) => {
                        item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplication(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}`)
                .then((response) => {
                    response.data.registrationDate = response.data.registrationDate ? new Date(Date.parse(response.data.registrationDate)) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationLight(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/light`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationForEdit(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/for-edit`)
                .then((response) => {
                    response.data.registrationDate = response.data.registrationDate ? new Date(Date.parse(response.data.registrationDate)) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationFormFieldList(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/formFields`)
                .then((response) => {
                    response.data.map((item) => {
                        item.registrationDate = item.registrationDate ? new Date(Date.parse(item.registrationDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async updateApplicationFormFieldResponse(applicationFormFieldId, value) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .put('/application/formFieldResponse', { id: applicationFormFieldId, responseValue: value })
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async applyToCompetition(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/${competitionId}/register`)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationTestCenter(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/testCenter`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveChoosenTestCenter(applicationId, competitionTestCenterRoomId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/application/${applicationId}/testCenter/${competitionTestCenterRoomId}`)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationTestSessionChoice(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/testSessionChoice`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveApplicationTestSessionChoice(applicationId, chosenTestSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/application/${applicationId}/testSessionChoice/${chosenTestSessionId}`)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getClarifications(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/clarifications`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveClarification(clarification) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post('application/clarification', clarification)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCommitteeContestations(applicationId, committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/contestations/${committeeId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getContestationsByResult(applicationId, resultId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/result/${resultId}/contestation`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getAllContestations(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/contestations`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveContestation(contestation) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post('application/contestation', contestation)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationResults(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/results`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationResultById(applicationId, resultId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/result/${resultId}`)
                .then((response) => {
                    response.data.createdOn = response.data.createdOn ? new Date(Date.parse(response.data.createdOn )) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveRejectionReason(result) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/result/rejection-reason`, result)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveCommentOnApplicationResult(result) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/result/comment`, result)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCompetitionResults(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/results`, criteria)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCompetitionPreliminaryTestResults(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/preliminary-test-results`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async updateApplicationStatus(applicationId, status) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .put('/application/status', { id: applicationId, status: status })
                .then((response) => {
                    response.data.registrationDate = response.data.registrationDate ? new Date(Date.parse(response.data.registrationDate)) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationEducation(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/education`)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        item.id = item.profileEducationId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveApplicationEducation(applicationId, educationList) {
        let profileEducationIds = []
        educationList.forEach(item => {
            profileEducationIds.push(item.profileEducationId)
        })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/education`, profileEducationIds)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        item.id = item.profileEducationId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getApplicationExperience(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/experience`)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        item.id = item.profileWorkExperienceId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveApplicationExperience(applicationId, experienceList) {
        let profileExpereinceIds = []
        experienceList.forEach(item => {
            profileExpereinceIds.push(item.profileWorkExperienceId)
        })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/experience`, profileExpereinceIds)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        item.id = item.profileWorkExperienceId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getApplicationLanguage(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/language`)
                .then(response => {
                    response.data.map(item => {
                        item.id = item.profileLanguageId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveApplicationLanguage(applicationId, languageList) {
        let profileLanguageIds = []
        languageList.forEach(item => {
            profileLanguageIds.push(item.profileLanguageId)
        })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/language`, profileLanguageIds)
                .then(response => {
                    response.data.map(item => {
                        item.id = item.profileLanguageId
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveMyApplication(application, hasValidation = false) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/my/${hasValidation}`, application)
                .then((response) => {
                    response.data.application.registrationDate = response.data.application.registrationDate ? new Date(Date.parse(response.data.application.registrationDate)) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data) {
                        if(error.response.data.errorCodes) {
                            reject( { hasValidationError: true, errorCodes: error.response.data.errorCodes, application: error.response.data.application } )
                        } else if (error.response.data.message) {
                            errorMessage = error.response.data.message;
                        }                        
                    }
                    reject( { hasValidationError: false, errorMessage: errorMessage } );
                });
        });
    }

    async getApplicationTests(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/tests`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getApplicationTestSession(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/testSession`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async assignApplicationToTestCenter(applicationId, testCenterId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/assignToTestCenter/${testCenterId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async assignApplicationToTestSession(applicationId, testSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/assignToSession/${testSessionId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async verifyAccessCode(applicationTestSessionId, accessCode, applicationTestId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/applicationTestSession/${applicationTestSessionId}/verify/`, { accessCode: accessCode, id: applicationTestId })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async downloadApplicationScale(applicationTestId, fileName) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/applicationTest/${applicationTestId}/scale`, { responseType: 'blob' })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName + ".pdf");
                    link.click();
                    setTimeout(() => window.URL.revokeObjectURL(url), 0);
                    resolve()
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getSelectedAnswers(_applicationTest) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/test/${_applicationTest.id}/answers`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getCorrectionScale(_applicationTest) {
        return new Promise((resolve, reject) => {
            if (_applicationTest?.competitionTest?.test?.provider === "DIGITEST") {
                this.downloadApplicationScale(_applicationTest?.id, _applicationTest?.competitionTest?.test?.name)
                    .catch((error) => {
                       reject(error)
                    });
            }
        })
    }

    async getApplicationAccessibility(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/accessibility`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveApplicationAccessibility(accessibility) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post('application/accessibility/save', accessibility)
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveApplicationTestResult(applicationTest) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`applicationTest`, applicationTest)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async updateApplicationResultScore(resultId, score) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/application/result/score', { id: resultId, score: score })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }
    
    async updateApplicationResult(data) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/application/result/update', data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

}
