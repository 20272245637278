import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import DOMPurify from 'dompurify';
import { ProgressSpinner } from 'primereact/progressspinner';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { UserService } from '../../service/UserService';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import StorageFolderFileList from '../file/StorageFolderFileList';
import StorageFileUpload from '../file/StorageFileUpload';
import ApiService from '../../service/ApiService';

const defaulNewValue = {
    id: null,
    description: '',
    version: 0,
    folderId: null    
}

const AccessibilityComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [accessibility, setAccessibility] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)
        let _accessibility = props?.accessibility ? props.accessibility : defaulNewValue

        setAccessibility(_accessibility)
        setIsLoading(false)
    }, [props]);

    const formik = useFormik({
        initialValues: accessibility ? accessibility : defaulNewValue,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!GeneralUtils.nullIfEmptyHTMLEditor(data.description)) {
                errors.description = translatedMessage("form.error.itKnowledge.required");;
            }

            return errors;
        },
        onSubmit: async (data) => {
            await userService.saveAccessibility(data)
                .then((_accessibility) => {
                    setAccessibility(_accessibility)
                    setShowEditDialog(false)

                    if (typeof props.afterSave === 'function') {
                        props.afterSave(_accessibility);
                    }

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const onEditorChange = (content) => {
        let _accessibility = accessibility
        _accessibility.description = content
        setAccessibility(_accessibility)
        formik.setFieldValue("description", content)
    }

    const isFormFieldValid = (name) => {
        return !!(formik.touched[name] && formik.errors[name])
    };
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left mt-1">{formik.errors[name]}</small>;
    };

    const editDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => setShowEditDialog(false)} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="accessibility-form" type="submit" />
        </>
    );

    const filesDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>

                <div className="grid">
                    <div className="col-12">
                        <div className="input-panel flex flex-column mb-5">
                            {!props?.isForApplication &&
                                <label htmlFor="description" className='mb-2'>{translatedMessage('profile.accessibility.info')}</label>
                            }

                            {(!props?.isForApplication || (props?.isForApplication && props?.updateFields)) &&
                                <div className="mb-3">
                                    <Button className="pcn-button-slim p-button-text" onClick={() => setShowEditDialog(true)}>
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon={'fa-solid '.concat(accessibility?.id ? 'fa-pencil' : 'fa-plus')} className="mr-1 " />
                                            <span>{translatedMessage(accessibility?.id ? 'generic.edit' : 'generic.add')}</span>
                                        </div>
                                    </Button>
                                    {accessibility?.folderId &&
                                        <>
                                            <Button className="pcn-button-slim p-button-text" onClick={() => setShowUploadDialog(true)}>
                                                <div className='flex align-items-center'>
                                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" className="mr-1 " />
                                                    <span>{translatedMessage('generic.file.upload')}</span>
                                                </div>
                                            </Button>

                                            <Button className="pcn-button-slim p-button-text" onClick={() => setShowFilesDialog(true)}>
                                                <div className='flex align-items-center'>
                                                    <FontAwesomeIcon icon='fa-regular fa-file' className="mr-1 " />
                                                    <span>{translatedMessage('generic.files')}</span>
                                                </div>
                                            </Button>
                                        </>
                                    }
                                </div>
                            }

                            {accessibility?.id &&
                                <div className="p-inputtext pcn-html-editor-view-container mb-3">
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(accessibility?.description) }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <Dialog
                    visible={showEditDialog}
                    header={translatedMessage('profile.accessibility')}
                    modal
                    resizable={false}
                    className="pcn-dialog p-fluid"
                    footer={editDialogFooter}
                    onHide={() => setShowEditDialog(false)}
                >
                    <form id="accessibility-form" onSubmit={formik.handleSubmit}>
                        <div className="input-panel flex flex-column mb-5">
                            <SunEditor
                                id="description" name="description"
                                lang={LanguageService.getCurrentLanguage}
                                height="350px"
                                width='100%'
                                setContents={formik.values.description}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                    charCounter: ApiService.getIsProduction() ? true : false,
                                    maxCharCount: ApiService.getIsProduction() ? 5000 : null
                                }}
                                autoFocus={true}
                                onChange={(e) => onEditorChange(e)}
                            />
                            {getFormErrorMessage('description')}
                        </div>
                    </form>
                </Dialog>


                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={filesDialogFooter}
                >
                    <StorageFolderFileList
                        folderId={accessibility?.folderId}
                        folderName={translatedMessage("profile.accessibility").toString().toLowerCase()}
                        readOnly={!props?.updateFields}
                    />
                </Dialog>

                <StorageFileUpload
                    entityId={accessibility?.id}
                    folderId={accessibility?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={true}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                />
            </>
        );
    }
};

export default AccessibilityComponent;
