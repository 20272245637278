import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';

const defaulNewValue = {
    id: null,
    organizationName: '',
    position: '',
    startDate: '',
    endDate: '',
    // domainId: '',
    // domain: { label: '', value: '' },
    version: 0
}

const WorkExperienceEditDialogComponent = (props) => {
    const [experience, setExperience] = useState(defaulNewValue);
    const [isNew, setIsNew] = useState(false);
    // const [domainTypes, setDomainTypes] = useState([]);
    // const [filteredDomainTypes, setFilteredDomainTypes] = useState([]);

    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        let _experience = props.experience ? props.experience : defaulNewValue

        // const getDomains = async () => {
        //     if (_experience.id) {
        //         let _domain = props?.domainTypes.filter(item => item.value === _experience.domainId)
        //         _experience.domain = _domain[0] ? _domain[0] : _domain.domain
        //     }

        //     setDomainTypes(props?.domainTypes)
        // }

        const computeExperience = async () => {
            setExperience(_experience);
            setIsNew(_experience.id ? false : true);
        }

        // getDomains()
        computeExperience()
    }, [props]);

    // Form
    const formik = useFormik({
        initialValues: experience,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.organizationName || !data.organizationName.trim()) {
                errors.organizationName = translatedMessage("form.error.workExperience.institution.required");
            }

            if (!data.position || !data.position.trim()) {
                errors.position = translatedMessage("form.error.workExperience.position.required");
            }

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.workExperience.startDate.required");
            }

            if (data.endDate && data.startDate > data.endDate) {
                errors.endDate = translatedMessage("form.error.workExperience.endDate.toEarly");
            }

            // if (!data.domainId && !data.domain.value) {
            //     errors.domainId = translatedMessage("form.error.workExperience.domain.required");
            // }

            return errors;
        },
        onSubmit: (data) => {
            // data.domainId = data.domain && data.domain.value ? data.domain.value : data.domainId

            userService.saveWorkExperience(data)
                .then(savedExpereince => {
                    savedExpereince.applicationWorkExperienceId = data.applicationWorkExperienceId
                    savedExpereince.applicationId = data.applicationId
                    savedExpereince.profileWorkExperienceId = data.profileWorkExperienceId

                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedExpereince, isNew);
                    }

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                    handleDialogClose()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    // const searchItems = (event) => {
    //     let query = event.query;
    //     let _filteredItems = [];

    //     console.log(domainTypes)

    //     for (let i = 0; i < domainTypes.length; i++) {
    //         let item = domainTypes[i];
    //         if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
    //             _filteredItems.push(item);
    //         }
    //     }

    //     setFilteredDomainTypes(_filteredItems);
    // }

    const dialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => handleDialogClose()} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" form="experience-form" type="submit" />
        </>
    );

    const handleDialogClose = () => {
        props.onClose()
        formik.resetForm()
    }


    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                closable={true}
                onHide={handleDialogClose}
                style={{ width: '450px' }}
                header={translatedMessage("profile.workExperience.dialog.title")}
                modal
                className="p-fluid"
                footer={dialogFooter}
            >
                <form id="experience-form" onSubmit={formik.handleSubmit}>
                    <div className="input-panel flex flex-column mb-5">
                        <div className="field p-inputgroup mb-2">
                            <span className="p-float-label">
                                <InputText type="text" name="organizationName" id="organizationName"
                                    value={formik.values.organizationName} onChange={formik.handleChange} autoFocus
                                    className={classNames({ 'p-invalid': isFormFieldValid('organizationName') })} />
                                <label htmlFor="organizationName" className={classNames({ 'p-error': isFormFieldValid('organizationName') })}>
                                    {translatedMessage("profile.workExperience.organizationName")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('organizationName')}

                        {/* <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <AutoComplete value={formik.values.domain} suggestions={filteredDomainTypes} completeMethod={searchItems}
                                    virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown id="domainId" name="domainId"
                                    onChange={(e) => formik.setFieldValue("domain", e.value)}
                                    className={classNames({ 'p-invalid': isFormFieldValid('domainId') })} />

                                <label htmlFor="domainId" className={classNames({ 'p-error': isFormFieldValid('domainId') })}>
                                    {translatedMessage("profile.workExperience.domain")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('domainId')} */}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <InputText type="text" name="position" id="position"
                                    value={formik.values.position} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('position') })} />
                                <label htmlFor="position" className={classNames({ 'p-error': isFormFieldValid('position') })}>
                                    {translatedMessage("profile.workExperience.position")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('position')}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Calendar id="basic" value={formik.values.startDate} onChange={(e) => formik.setFieldValue("startDate", e.value)} dateFormat="dd-mm-yy"
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                    {translatedMessage("profile.workExperience.startDate")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('startDate')}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Calendar id="basic" value={formik.values.endDate} onChange={(e) => formik.setFieldValue("endDate", e.value)} dateFormat="dd-mm-yy"
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                    {translatedMessage("profile.workExperience.endDate")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('endDate')}
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default WorkExperienceEditDialogComponent;
