import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import ApplicationResults from '../../../components/application/ApplicationResults';
import StorageFolderFileList from '../../../components/file/StorageFolderFileList';
import ApplicationForm from '../../../components/form/ApplicationForm';
import SecurityService from '../../../service/SecurityService';
import ApplicationEducation from './ApplicationEducation';
import ApplicationWorkExperience from './ApplicationWorkExperience';
import ApplicationITKnowledge from './ApplicationITKnowledge';
import ApplicationLanguage from './ApplicationLanguage';
import ErrorMessageDisplayComponent from '../../../components/ErrorMessageDisplayComponent';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import DescriptionDisplayModalComponent from '../../../components/DescriptionDisplayModalComponent';
import CompetitionUtils from '../../../utilities/CompetitionUtils';
import GeneralUtils from '../../../utilities/GeneralUtils';
import { DataTable } from 'primereact/datatable';
import DataTableUtils from '../../../utilities/DataTableUtils';
import { Column } from 'primereact/column';

const allSections = [
    { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye' },
    { label: translatedMessage('profile.education'), code: 'EDUCATION', icon: 'fa-solid fa-user-graduate' },
    { label: translatedMessage('profile.workExperience'), code: 'EXPERIENCE', icon: 'fa-solid fa-building-columns' },
    { label: translatedMessage('profile.itKnowledge'), code: 'IT', icon: 'fa-solid fa-computer' },
    { label: translatedMessage('profile.language'), code: 'LANGUAGE', icon: 'fa-solid fa-globe' },
    { label: translatedMessage('application.form'), code: 'FORM', icon: 'fa-regular fa-file-lines' },
    { label: translatedMessage('application.files'), code: 'FILE', icon: 'fa-regular fa-file' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

let sections = [...allSections];

const ApplicationPage = () => {
    const [application, setApplication] = useState(null);
    const [applicationFormFields, setApplicationFormFields] = useState([]);
    const [applicationResults, setApplicationResults] = useState([]);
    const [contestations, setContestations] = useState([]);
    const [canEdit, setCanEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [section, setSection] = useState(sections[0]);
    const [accessibility, setAccessibility] = useState(null);
    const [showAccesibilityInfoDialog, setShowAccesibilityInfoDialog] = useState(false);
    const [showAccesibilityFilesDialog, setShowAccesibilityFilesDialog] = useState(false);
    const [isJobCompetition, setIsJobCompetition] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    const { applicationIdParam } = useParams();

    const navigate = useNavigate();
    const dt = useRef(null);

    useEffect(() => {
        setIsLoading(true);

        const getApplication = async () => {
            await applicationService.getApplication(applicationIdParam)
                .then(async (applicationData) => {
                    setApplication(applicationData);
                    if (CompetitionUtils.isJobCompetition(applicationData.competition.type)) {
                        setIsJobCompetition(true)
                    }
                    setIsLoading(false);

                    await getFormFields()
                    await getApplicationResults();
                    await getContestations();
                    if (applicationData.accessibilityNeed) {
                        await getApplicationAccessibility()
                    }
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getFormFields = async () => {
            await applicationService.getApplicationFormFieldList(applicationIdParam)
                .then((_fields) => {
                    setApplicationFormFields(_fields);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationResults = async () => {
            await applicationService
                .getApplicationResults(applicationIdParam)
                .then((_applicationResult) => {
                    setApplicationResults(_applicationResult);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getContestations = async () => {
            await applicationService
                .getAllContestations(applicationIdParam)
                .then((_contestations) => {
                    setContestations(_contestations);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationAccessibility = async () => {
            await applicationService
                .getApplicationAccessibility(applicationIdParam)
                .then((_accessibility) => {
                    setAccessibility(_accessibility);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const checkCanEdit = async () => {
            setCanEdit(await SecurityService.check('CAN_EDIT_APPLICATION', applicationIdParam));
        };

        checkCanEdit();
        getApplication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, applicationIdParam]);


    const onCreateContestation = (savedContestation) => {
        setSection(sections.find((section) => section.code === 'CONTESTATION'));
    };

    const navigationTemlate = (option) => {
        return (
            <>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </>
        );
    };

    const handleSectionChange = (value) => {
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/competition-evaluation/${application.competition.id}`);
            } else {
                setSection(value);
            }
        }
    };

    const getJobDetails = (data) => {
        return (
            <>
                {data.jobClass && <div><span className="font-bold">{translatedMessage("job.jobClass") + ": "}</span>{data.jobClass}</div>}
                {data.level && <div><span className="font-bold">{translatedMessage("job.level") + ": "}</span>{data.level}</div>}
                {data.departmentType && <div><span className="font-bold">{translatedMessage("job.departmentType") + ": "}</span>{data.departmentType}</div>}
                {data.departmentName && <div><span className="font-bold">{translatedMessage("job.departmentName") + ": "}</span>{data.departmentName}</div>}
            </>
        );
    };

    const getJobLocation = (data) => {
        return (
            <>
                {data.institution?.name && <div>{data.institution.name}</div>}
                {data.institution?.locality && <div><span className="font-bold">{translatedMessage("generic.locality") + ": "}</span>{data.institution.locality}</div>}
                {data.institution?.county && <div><span className="font-bold">{translatedMessage("generic.county") + ": "}</span>{data.institution.county}</div>}
            </>
        );
    };

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.firstName} {application?.lastName}</h3>
                            <div className="mb-5">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemlate} className="pcn-selectbutton-slim" />
                            </div>

                            {section.code === 'DETAIL' &&
                                <div className="w-full grid pcn-data-view-panel">
                                    <div className="col-12">
                                        <h5 className="mb-1 font-weight-normal">{translatedMessage('application.details')}</h5>
                                    </div>
                                    {!isJobCompetition && application?.positionName &&
                                        <div className="col-12 md:col-6">
                                            <div className="filed-label">{translatedMessage('application.publicPosition')}</div>
                                            <div className="filed-value">
                                                {application?.positionName}
                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                                        <div className="filed-value">
                                            <div className={`inline-block status status-application-${application?.status.toString().toLowerCase()}`}>
                                                {translatedMessage('ApplicationStatus.' + application?.status)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('profile.uid')}</div>
                                        <div className="filed-value">
                                            <div className="filed-value">{application?.user?.uid}</div>
                                        </div>
                                    </div>

                                    {CompetitionUtils.isJobCompetition(application?.competition.type) && application.jobList.length > 0 &&
                                        <div className='col-12 mb-3'>
                                            <div className='filed-label mt-3 mb-2'>{translatedMessage('application.job.paragraphTitle')}</div>
                                            <DataTable
                                                ref={dt}
                                                value={application.jobList}
                                                dataKey="id"
                                                paginator
                                                rows={DataTableUtils.defalRowsPerPage()}
                                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                                className="datatable-responsive pcn-datatable"
                                                responsiveLayout="scroll"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                            >
                                                <Column
                                                    field="position"
                                                    header={translatedMessage('job.position')}
                                                    sortable
                                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                    filter
                                                    showFilterMenu={false}
                                                />
                                                <Column
                                                    field="jobClass"
                                                    header={translatedMessage('generic.details')}
                                                    sortable
                                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                    body={getJobDetails}
                                                    filter
                                                    showFilterMenu={false}
                                                />
                                                <Column
                                                    field="institution.name"
                                                    header={translatedMessage('job.location')}
                                                    sortable
                                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                    body={getJobLocation}
                                                    filter
                                                    showFilterMenu={false}
                                                />
                                            </DataTable>
                                        </div>
                                    }

                                    {/* Accessibility */}
                                    <div className="col-12 mt-3">
                                        <h5 className="my-1 font-weight-normal">{translatedMessage('profile.accessibility')}</h5>
                                        {!application?.accessibilityNeed &&
                                            <div className='w-full text-align-left mt-2'>{translatedMessage("application.accessibility.no.text")} </div>
                                        }
                                        {application?.accessibilityNeed &&
                                            <>
                                                <Button className="pcn-button-slim p-button-text" onClick={() => setShowAccesibilityInfoDialog(true)}>
                                                    <div className='flex align-items-center'>
                                                        <FontAwesomeIcon icon='fa-regular fa-eye' className="mr-1 " />
                                                        <span>{translatedMessage('generic.details')}</span>
                                                    </div>
                                                </Button>

                                                <Button className="pcn-button-slim p-button-text" onClick={() => setShowAccesibilityFilesDialog(true)}>
                                                    <div className='flex align-items-center'>
                                                        <FontAwesomeIcon icon='fa-regular fa-file' className="mr-1 " />
                                                        <span>{translatedMessage('generic.files')}</span>
                                                    </div>
                                                </Button>
                                            </>
                                        }
                                    </div>
                                    {application?.accessibilityNeed &&
                                        <>
                                            <div className='col-12 md:col-6'>
                                                <div className="flex align-items-center">
                                                    <Checkbox
                                                        inputId="visualAccessibility"
                                                        name="adaptationOption"
                                                        disabled
                                                        checked={accessibility?.visualAccessibility}
                                                    />
                                                    <label htmlFor="visualAccessibility" className="ml-2">{translatedMessage("application.accessibility.visualAccessibility")}</label>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="flex align-items-center">
                                                    <Checkbox
                                                        inputId="auditoryAccessibility"
                                                        name="adaptationOption"
                                                        checked={accessibility?.auditoryAccessibility}
                                                        disabled
                                                    />
                                                    <label htmlFor="auditoryAccessibility" className="ml-2">{translatedMessage("application.accessibility.auditoryAccessibility")}</label>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="flex align-items-center">
                                                    <Checkbox
                                                        inputId="locomotorAccessibility"
                                                        name="adaptationOption"
                                                        checked={accessibility?.locomotorAccessibility}
                                                        disabled
                                                    />
                                                    <label htmlFor="locomotorAccessibility" className="ml-2">{translatedMessage("application.accessibility.locomotorAccessibility")}</label>
                                                </div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className="flex align-items-center">
                                                    <Checkbox
                                                        inputId="otherAccessibility"
                                                        name="adaptationOption"
                                                        checked={accessibility?.otherAccessibility}
                                                        disabled
                                                    />
                                                    <label htmlFor="otherAccessibility" className="ml-2">{translatedMessage("application.accessibility.otherAccessibility")}</label>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(accessibility?.adaptationDescription) }} />
                                            </div>
                                        </>
                                    }

                                    {/* Competition */}
                                    <div className="col-12 mt-4">
                                        <h5 className="mb-1 font-weight-normal">{translatedMessage('competition.details')}</h5>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('generic.nameRo')}</div>
                                        <div className="filed-value">{application?.competition?.name}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                                        <div className="filed-value">
                                            <div className={`inline-block status status-competition-${application?.competition?.status.toString().toLowerCase()}`}>
                                                {translatedMessage('CompetitionStatus.' + application?.competition?.status)}
                                            </div>
                                        </div>
                                    </div>
                                    {CompetitionUtils.isNationalCompetition(application?.competition.type) &&
                                        <>
                                            <div className="col-12 md:col-6">
                                                <div className="filed-label">{translatedMessage('competition.positionCategory')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionCategory." + application?.competition.positionCategory)}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="filed-label">{translatedMessage('competition.positionGrade')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionGrade." + application?.competition.positionGrade)}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="filed-label">{translatedMessage('competition.positionType')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionType." + application?.competition.positionType)}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.clarificationInterval')}</div>
                                        <div className="filed-value">
                                            {application?.competition?.clarificationInterval} {translatedMessage('generic.days')}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.contestationInterval')}</div>
                                        <div className="filed-value">
                                            {application?.competition?.contestationInterval} {translatedMessage('generic.days')}
                                        </div>
                                    </div>
                                    {CompetitionUtils.isJobCompetition(application?.competition.type) &&
                                        <>
                                            <div className='col-12 md:col-6'>
                                                <div className='filed-label'>{translatedMessage('competition.writtenTestDate')}</div>
                                                <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition.writtenTestDate)}</div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className='filed-label'>{translatedMessage('competition.interviewTestDate')}</div>
                                                <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition.interviewTestDate)}</div>
                                            </div>

                                            {application?.competition?.supplementaryTest1Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest1Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest1Date)}</div>
                                                    </div>
                                                </>
                                            }

                                            {application?.competition?.supplementaryTest2Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest2Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest2Date)}</div>
                                                    </div>
                                                </>
                                            }
                                            {application?.competition?.supplementaryTest3Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest3Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest3Date)}</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                        <div className='filed-value'>
                                            {application
                                                ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(application?.competition?.description) }} />
                                                : "-"
                                            }
                                        </div>
                                    </div>

                                    {CompetitionUtils.isJobCompetition(application?.competition?.type) && application?.competition?.bibliography &&
                                        <div className='col-12'>
                                            <div className='filed-label'>{translatedMessage('competition.bibliography')}</div>
                                            <div className='filed-value'>
                                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(application.competition.bibliography) }} />
                                            </div>
                                        </div>
                                    }

                                    {applicationResults.length > 0 &&
                                        <div className="col-12 lg:col-6 mt-3">
                                            <h5>{translatedMessage('competition.results')}</h5>
                                            <ApplicationResults
                                                results={applicationResults}
                                                contestations={contestations}
                                                onCreateContestation={onCreateContestation}
                                                viewLink={`/competition-evaluation/${application?.competition.id}/application-contestation/${applicationIdParam}/result/`}
                                                testLink={`/competition-evaluation/${application?.competition.id}/application-test/${applicationIdParam}`}
                                                isJobCompetition={isJobCompetition}
                                            />
                                        </div>
                                    }
                                </div>
                            }

                            {section.code === 'EDUCATION' && <ApplicationEducation application={application} formFields={applicationFormFields} updateFields={canEdit} hideFillInExpanation={true} />}

                            {section.code === 'EXPERIENCE' && <ApplicationWorkExperience application={application} formFields={applicationFormFields} updateFields={canEdit} hideFillInExpanation={true} />}

                            {section.code === 'IT' && <ApplicationITKnowledge application={application} formFields={applicationFormFields} updateFields={canEdit} />}

                            {section.code === 'LANGUAGE' && <ApplicationLanguage application={application} formFields={applicationFormFields} updateFields={canEdit} hideFillInExpanation={true} />}

                            {section.code === 'FORM' && <ApplicationForm application={application} updateFields={canEdit} />}

                            {section.code === 'FILE' &&
                                <StorageFolderFileList
                                    applicationId={application?.id}
                                    folderId={application?.folder.id}
                                    folderName={application?.competition.name}
                                    showUploadButton={true}
                                    pageTitle={translatedMessage('application.files')}
                                    readOnly={!canEdit}
                                    evaluationApplicationDocs={true}
                                />
                            }
                        </div>
                    </div>
                </div>

                <Dialog
                    visible={showAccesibilityFilesDialog}
                    closable={true}
                    onHide={() => setShowAccesibilityFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={() => {
                        return (
                            <Button
                                label={translatedMessage("generic.cancel")}
                                icon="pi pi-times"
                                className="p-button-text"
                                onClick={() => setShowAccesibilityFilesDialog(false)} />
                        )
                    }
                    }
                >
                    <StorageFolderFileList
                        folderId={accessibility?.folderId}
                        folderName={translatedMessage("profile.accessibility").toString().toLowerCase()}
                        readOnly={true}
                    />
                </Dialog>

                <DescriptionDisplayModalComponent
                    dialogTitle={"profile.accessibility"}
                    dialogInfo={accessibility?.description}
                    visible={showAccesibilityInfoDialog}
                    closeDialog={() => setShowAccesibilityInfoDialog(false)}
                />
            </>
        );
    }
};

export default ApplicationPage;
