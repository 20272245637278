import React from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../service/LanguageService';

const ErrorDialog = (props) => {

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );

    return (
        <>
            <Dialog
                visible={props.visible}
                header={translatedMessage("generic.error")}
                modal
                onHide={() => props.closeDialog()}
                footer={formDialogFooter}
                className="p-fluid"
                style={{ width: '450px' }}
            >
                {props.message}
                {props.hasLink && props.link && props.linkInfo && props.linkText &&
                    <div className='mt-3'>
                        {props.linkInfo + " "}
                        <Link to={props.link}><span className='font-bold underline'>{props.linkText}</span></Link>
                    </div>
                }
            </Dialog>
        </>
    );
};

export default ErrorDialog;
