import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';
import CommitteeMemberList from './CommitteeMemberList';
import StorageFolderFileList from '../file/StorageFolderFileList';
import CommitteeEditDialog from './CommitteeEditDialogComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralUtils from '../../utilities/GeneralUtils';
import useAuth from '../../hooks/useAuth';
import CommitteeUsedRoles from './CommitteeUsedRoles';
import { EvaluationService } from '../../service/EvaluationService';

const emptyCommittee = {
    id: null,
    name: '',
    type: '',
    startDate: '',
    endDate: '',
    competitionId: null,
    competitionName: '',
    folderId: null
};

const sections = [
    { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye' },
    { label: translatedMessage('menu.competition.committee.members'), code: 'MEMBER', icon: 'fa-solid fa-users' },
    { label: translatedMessage('generic.files'), code: 'FILE', icon: 'fa-regular fa-file' },
    { label: translatedMessage('committee.evaluations'), code: 'EVALUATIONS', icon: 'fa-solid fa-file-pen' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

const CommitteePageComponent = (props) => {
    const [committee, setCommittee] = useState(emptyCommittee);
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [section, setSection] = useState(sections[0]);

    const toast = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        setIsLoading(true);

        if (searchParams.get('section')) {
            setSection(sections.find((section) => section.code === searchParams.get('section')));
        }


        setCommittee(props?.committee);
        setCanEdit(props?.canEdit ? true : false)

        const getCommitteeRoles = async () => {
            await evaluationService.getCommitteeRoles(props?.committee.id)
                .then((_roles) => {
                    setRoles(_roles);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        if (section.code === 'DETAIL') {
            getCommitteeRoles()
        } else {
            setIsLoading(false)
        }

    }, [evaluationService, props, searchParams, section]);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const navigationTemplate = (option) => {
        return (
            <>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </>
        );
    };

    const handleSectionChange = (value) => {
        if (value) {
            if (value.code === 'BACK') {
                navigate(-1);
            } else if (value.code === 'EVALUATIONS') {
                let lazyParams = GeneralUtils.initApplicationEvaluationLazyParams()
                lazyParams.filters.committeeName.value = committee?.name
                GeneralUtils.processSessionFilterValues('ApplicationEvaluationList_'+committee?.competitionId, lazyParams, true, null);

                navigate(`/competition-evaluation/${committee?.competitionId}`);
            } else {
                setSection(value)
            }
        }
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{committee.name}</h3>
                            <div className="mb-5">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemplate} className="pcn-selectbutton-slim" />
                            </div>

                            {section.code === 'DETAIL' && (
                                <div className="w-full grid pcn-data-view-panel">
                                    <div className="col-12">
                                        <h5 className="mb-1 font-weight-normal">{translatedMessage('committee.details')}</h5>
                                        {canEdit &&
                                            <Button className="pcn-button-slim p-button-text" onClick={() => setEditDialogVisible(true)} visible={hasPermission('COMPETITION_EDIT')}>
                                                <div className="flex align-items-center">
                                                    <FontAwesomeIcon icon="fa-solid fa-pencil" className="mr-1 " />
                                                    <span>{translatedMessage('generic.edit')}</span>
                                                </div>
                                            </Button>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.type')}</div>
                                        <div className="filed-value">{translatedMessage('EvaluationCommitteeType.' + committee.type)}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('competition.competition')}</div>
                                        <div className="filed-value">{committee.competitionName}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                                        <div className="filed-value"><span className={`status status-${(committee.active ? 'ACTIVE' : 'INACTIVE').toString().toLowerCase()}`}>{translatedMessage('committee.' + (committee.active ? 'ACTIVE' : 'INACTIVE'))}</span></div>
                                    </div>
                                    <div className="col-6">
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.startDate')}</div>
                                        <div className="filed-value">{GeneralUtils.formatDate(committee.startDate)}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.endDate')}</div>
                                        <div className="filed-value">{GeneralUtils.formatDate(committee.endDate)}</div>
                                    </div>
                                    <div className="col-12">
                                        <div className="filed-label">{translatedMessage('generic.description')}</div>
                                        <div className="filed-value">{committee.description}</div>
                                    </div>
                                    {(roles || committee?.addedRoles) &&
                                        <div className="col-12">
                                            <div className="filed-label">{translatedMessage('committee.member.roles')}</div>
                                            <div className="filed-value"><CommitteeUsedRoles roles={roles ? roles : committee?.addedRoles} /></div>
                                        </div>
                                    }
                                </div>
                            )}

                            {section.code === 'MEMBER' && 
                                <CommitteeMemberList 
                                    committeeId={committee.id} 
                                    canEdit={canEdit} 
                                    competitionName={committee.competitionName} 
                                    competitionId={committee.competitionId}
                                />
                            }

                            {section.code === 'FILE' &&
                                <StorageFolderFileList
                                    folderId={committee.folderId}
                                    folderName={committee.name}
                                    showUploadButton={hasPermission('EVALUATION_CREATE')}
                                    pageTitle={translatedMessage('generic.files')}
                                    readOnly={false}
                                />
                            }

                            {canEdit &&
                                <CommitteeEditDialog
                                    value={committee}
                                    visible={editDialogVisible}
                                    afterSave={(savedCommittee) => setCommittee(savedCommittee)}
                                    visibleSetter={() => setEditDialogVisible(false)}
                                    competition={{ id: committee?.competitionId, name: committee?.competitionName }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default CommitteePageComponent;
