import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import DOMPurify from 'dompurify';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { Button } from 'primereact/button';
import SecurityService from '../../service/SecurityService';
import StorageFolderFileList from '../file/StorageFolderFileList';
import ContestationDialog from './ContestationDialog';
import GeneralUtils from '../../utilities/GeneralUtils';


const ContestationComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [contestation, setContestation] = useState({ application: {} });
    const [canEdit, setCanEdit] = useState(false);
    const [editContestationDialogVisible, setEditContestationDialogVisible] = useState(false);

    const toast = useRef(null);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true);
        setContestation(props.contestation);
        checkCanEdit(props.contestation?.id);
        setIsLoading(false);
    }, [props.contestation]);

    const checkCanEdit = async (contestationId) => {
        contestationId && setCanEdit(await SecurityService.check('CAN_EDIT_CONTESTATION', contestationId));
    };

    const afterDialogContestationSave = (savedContestation) => {
        setContestation(savedContestation);
        checkCanEdit(savedContestation.id);
    };

    const saveContestation = (data) => {
        applicationService
            .saveContestation(data)
            .then((savedContestation) => {
                setContestation(savedContestation);
                checkCanEdit(savedContestation.id);
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedContestation);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const confirmSendContestation = () => {
        confirmDialog({
            message: translatedMessage('application.send.contestation.request.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => sendContestation()
        });
    };

    const sendContestation = () => {
        let _contestation = { ...contestation };
        _contestation.sent = true;
        saveContestation(_contestation);
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        let sentDesc = translatedMessage('application.' + contestation.type.toLowerCase() + '.sent')
        if('REQUEST' === contestation.type && contestation.lastModifiedOn) {
            sentDesc = sentDesc + " (" + GeneralUtils.formatDateTime(contestation.lastModifiedOn) + ")"
        }
        return (
            <>
                <Toast ref={toast} />
                {canEdit && !contestation.sent && 'REQUEST' === contestation.type &&
                    <ConfirmDialog />
                }
                <div className="grid mb-3">
                    <div className="col-12 md:col-6">
                        <div className='w-full text-align-left'>
                            <div className="filed-label">{translatedMessage('generic.status')}</div>
                            <div className="filed-value flex align-items-center">
                                <span className={`mr-2 pill contestation-status-${contestation.sent ? 'sent' : 'not-sent'}`}>
                                    {contestation.sent
                                        ? sentDesc
                                        : translatedMessage('application.' + contestation.type.toLowerCase() + '.not.sent')}
                                </span>
                                <Button
                                    label={translatedMessage('generic.send')}
                                    icon="pi pi-send"
                                    className="p-button-text pcn-button-slim"
                                    onClick={confirmSendContestation}
                                    visible={canEdit && !contestation.sent && 'REQUEST' === contestation.type && !props.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                    {'REQUEST' === contestation.type && props?.resultPublishDate &&
                        <div className="col-12 md:col-6">
                            <div className="filed-label">{translatedMessage('application.result.publishDate')}</div>
                            <div className="filed-value">
                                {GeneralUtils.formatDateTime(props.resultPublishDate)}
                            </div>
                        </div>
                    }
                </div>
                {contestation && contestation.id && (
                    <div className="w-full grid pcn-data-view-panel">
                        <div className="col-12">
                            <div className="filed-label flex align-items-center">
                                <span className='mr-2'>{translatedMessage('application.contestation.detail')}</span>
                                <Button
                                    label={translatedMessage('generic.edit')}
                                    icon="pi pi-pencil"
                                    className="p-button-text pcn-button-slim"
                                    onClick={() => setEditContestationDialogVisible(true)}
                                    visible={canEdit && !props.readOnly}
                                />
                            </div>
                            <div className="field p-inputgroup mb-2">
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contestation.detail) }} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="filed-label">{translatedMessage('generic.files')}</div>
                            <StorageFolderFileList folderId={contestation.folder.id} folderName={contestation.folder.name} showUploadButton={true} readOnly={!canEdit || props.readOnly} />
                        </div>
                    </div>
                )}
                {!(contestation && contestation.id) && <>{translatedMessage('generic.doesNotExist')}</>}

                <ContestationDialog
                    contestation={contestation}
                    visible={editContestationDialogVisible}
                    onHide={() => setEditContestationDialogVisible(false)}
                    afterSave={afterDialogContestationSave}
                    type={contestation?.type}
                />
            </>
        );
    }
};

export default ContestationComponent;
