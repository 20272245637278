import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { ProgressSpinner } from 'primereact/progressspinner';

const FieldEvaluationDropdownComponent = (props) => {
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [applicationFieldEvaluationId, setApplicationFieldEvaluationId] = useState(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    const toast = useRef(null);

    useEffect(() => {
        setIsLoading(true)

        if (props?.applicationFieldEvaluation) {
            setStatus(props.applicationFieldEvaluation.status);
            setApplicationFieldEvaluationId(props.applicationFieldEvaluation.id);
            setIsLoading(false)
        }
    }, [props]);

    const saveFieldEvaluation = (_status) => {
        if (applicationFieldEvaluationId) {
            evaluationService.updateApplicationFieldEvaluation(applicationFieldEvaluationId, _status)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success'), life: 3000 });
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(applicationFieldEvaluationId, null, _status);
                    }
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }
    };

    const fieldStatusTemplate = (option) => {
        return (
            <div>
                <span className={`status status-field-evaluation-${option?.value?.toString().toLowerCase()}`}>{translatedMessage('FieldEvaluationStatus.' + option?.value)}</span>
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Inplace
                    closable
                    disabled={!props?.canBeEdited}
                    className="pcn-inplace"
                >
                    <InplaceDisplay>
                        <div className='flex align-items-center'>
                            <span className={`status status-field-evaluation-${status.toString().toLowerCase()}`}>
                                {translatedMessage('FieldEvaluationStatus.' + status)}
                            </span>
                            {props?.canBeEdited &&
                                <i className="pi pi-pencil ml-2" style={{ fontSize: "18px" }} />
                            }
                        </div>
                    </InplaceDisplay>
                    <InplaceContent>
                        <Dropdown
                            value={status}
                            options={props?.statusOptions}
                            onChange={(e) => saveFieldEvaluation(e.value)}
                            itemTemplate={fieldStatusTemplate}
                            valueTemplate={fieldStatusTemplate}
                        />
                    </InplaceContent>
                </Inplace>
            </>
        );
    }
};

export default FieldEvaluationDropdownComponent;
