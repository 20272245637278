import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';
import { CompetitionService } from '../../../service/CompetitionService';
import GeneralUtils from '../../../utilities/GeneralUtils';

const emptyTestSession = {
    id: null,
    startDate: '',
    endDate: ''
};

const TestSessionEditDialog = (props) => {
    const [testSession, setTestSession] = useState(emptyTestSession);
    const [competitionTestCenterRooms, setCompetitionTestCenterRooms] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const toast = useRef(null);

    const testService = useMemo(() => new TestService(), []);
    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        const getCompetitionTestCenterRooms = async () => {
            await competitionService.getTestCenterRoomList(props.competitionId, 'ACTIVE')
                .then((ctc) => {
                    setCompetitionTestCenterRooms(ctc);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetitionTestCenterRooms();
    }, [competitionService, props.competitionId]);

    useEffect(() => {
        setTestSession(props.value ? props.value : emptyTestSession);
        setIsNew(props.value ? false : true)
    }, [props.value]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);    

    const hideDialog = () => {
        props.onCancel();
        formik.resetForm();
    };

    const formik = useFormik({            
        enableReinitialize: true,
        initialValues: testSession,
        validate: (data) => {
            let errors = {};

            if (data.competitionTestCenterRoom && !data.competitionTestCenterRoom.id) {
                errors.competitionTestCenterRoom = translatedMessage('form.error.competitionTestCenterRoom.required');
            }
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.startDate.required');
            }
            if (!data.endDate) {
                errors.endDate = translatedMessage('form.error.endDate.required');
            }
            if (data.endDate && data.startDate >= data.endDate) {
                errors.endDate = translatedMessage("form.error.endDate.toEarly");
            }            
            return errors;
        },
        onSubmit: (data) => {
            testService.saveTestSession(data)
                .then((savedTestSession) => {
                    props.onSave(savedTestSession, isNew);
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                    hideDialog();
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const roomOptionTemplate = (option) => {
        return (
            <>
                {option && option.testCenterRoom && (
                    <div>
                        {option.testCenterRoom.name} ({option.testCenterRoom.testCenter.name}, {option.testCenterRoom.testCenter.city}, {option.testCenterRoom.testCenter.county.labelRo})
                    </div>
                )}
                {!option && <div>{translatedMessage('testSession.selectRoom')}</div>}
            </>
        );
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="testSession-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} style={{ width: '450px' }} header={translatedMessage('testSession.details')} modal className="pcn-dialog p-fluid" footer={testCenterDialogFooter} onHide={hideDialog}>
                <form id="testSession-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown
                                id="competitionTestCenterRoom.id"
                                name="competitionTestCenterRoom.id"
                                value={formik.values.competitionTestCenterRoom && formik.values.competitionTestCenterRoom.id}
                                onChange={formik.handleChange}
                                options={competitionTestCenterRooms}
                                className={classNames({ 'p-invalid': isFormFieldValid('room') })}
                                optionValue="id"
                                optionLabel="testCenterRoom.name"
                                autoFocus
                                valueTemplate={roomOptionTemplate}
                                itemTemplate={roomOptionTemplate}
                                disabled={formik.values.id && true}
                            />
                            <label htmlFor="competitionTestCenterRoom.id" className={classNames({ 'p-error': isFormFieldValid('room') })}>
                                {translatedMessage('competition.testCenterRoom.name')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('room')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Calendar
                                id="startDate"
                                name="startDate"
                                value={formik.values.startDate}
                                onChange={formik.handleChange}
                                dateFormat="dd-mm-yy"
                                showTime
                                hourFormat="24"
                                stepMinute={10}
                                onShow={() => {if(!formik.values.startDate){formik.setFieldValue("startDate", GeneralUtils.roundDateTimeHour(0))}}}
                                className={classNames({ 'p-invalid': isFormFieldValid('startDate') })}
                            />
                            <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                {translatedMessage('generic.startDate')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('startDate')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Calendar 
                                id="endDate" 
                                name="endDate" 
                                value={formik.values.endDate} 
                                onChange={formik.handleChange} 
                                dateFormat="dd-mm-yy" 
                                showTime hourFormat="24" 
                                stepMinute={10}
                                onShow={() => {if(!formik.values.endDate){formik.setFieldValue("endDate", GeneralUtils.roundDateTimeHour(1))}}}
                                className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} 
                            />
                            <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                {translatedMessage('generic.endDate')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('endDate')}
                </form>
            </Dialog>
        </>
    );
};

export default TestSessionEditDialog;
