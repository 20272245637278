import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import GeneralUtils from '../../utilities/GeneralUtils';
import ApiService from '../../service/ApiService';

const emptyContestation = {
    id: null,
    entityId: null,
    entityName: '',
    detail: '',
    type: '',
    folder: {
        id: null
    }
};

const ContestationDialog = (props) => {
    const [contestation, setContestation] = useState({});

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        if (props.contestation) {
            setContestation(props.contestation);
        } else {
            emptyContestation.applicationResult = props.result;
            emptyContestation.type = props.type;
            setContestation(emptyContestation);
        }        
    }, [props]);

    const hideDialog = () => {
        if (typeof props.onHide === 'function') {
            props.onHide();
        }
        formik.resetForm();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: contestation,
        validate: (data) => {
            let errors = {};

            if (!data.detail) {
                errors.detail = translatedMessage('form.error.detail.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveContestation(data);
            hideDialog();
            setContestation({});
            formik.resetForm();
        }
    });

    const saveContestation = (data) => {
        applicationService.saveContestation(data)
            .then((savedContestation) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedContestation);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const setDescriptionValue = (detail) => {
        formik.setFieldValue('detail', detail);
    }    

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                header={'REQUEST' === props.type ? translatedMessage('application.contestation.request') : translatedMessage('application.contestation.response')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                <div className="field pcn-sun-editor w-100 mb-0">
                        <label htmlFor="description" className='font-bold'>{translatedMessage('application.contestation.detail')} *</label>
                        <SunEditor
                            id="description" name="description"
                            lang={LanguageService.getCurrentLanguage}
                            height="300px"
                            width='auto'
                            defaultValue={formik.values.detail}
                            placeholder={""}
                            setOptions={{
                                buttonList: GeneralUtils.getSunEditorButtons(),
                                font: GeneralUtils.getSunEditorFonts(),
                                defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                charCounter: ApiService.getIsProduction() ? true : false,
                                maxCharCount: ApiService.getIsProduction() ? 10000 : null
                            }}
                            onChange={setDescriptionValue}
                        />
                    </div>                    
                    {getFormErrorMessage('detail')}
                </form>
            </Dialog>
        </>
    );
};

export default ContestationDialog;
