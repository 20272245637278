import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { ApplicationService } from '../../service/ApplicationService';
import { EvaluationService } from '../../service/EvaluationService';
import { translatedMessage } from '../../service/LanguageService';
import FormDisplay from './FormDisplay';

const ApplicationForm = (props) => {
    const [populated, setPopulated] = useState(false);
    const [applicationFormFields, setApplicationFormFields] = useState({});

    const applicationService = useMemo(() => new ApplicationService(), []);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    const toast = useRef(null);

    useEffect(() => {
        const getFormFields = async (applicationId) => {
            applicationService
                .getApplicationFormFieldList(applicationId)
                .then((_fields) => {
                    setApplicationFormFields(_fields);
                    setPopulated(true);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        if (!populated) {
            getFormFields(props.application.id);
        }
    }, [applicationService, evaluationService, populated, props]);

    const onFieldValueChange = (applicationFormFieldId, code, value) => {
        if (applicationFormFieldId) {
            let aff = applicationFormFields.find((aff) => aff.id === applicationFormFieldId);
            if (aff && aff.formField.field.code === code) {
                aff.responseValue = value;
            }
            applicationService
                .updateApplicationFormFieldResponse(applicationFormFieldId, value)
                .then((response) => {
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success'), life: 3000 });
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }
    };

    return (
        <>
            {' '}
            <Toast ref={toast} />
            {!populated && <ProgressSpinner />}
            {populated && (
                <Card>
                    <FormDisplay
                        formFields={applicationFormFields}
                        onFieldValueChange={onFieldValueChange}
                        updateFields={props.updateFields}
                        isForAplicant={true}
                    />
                </Card>
            )}
        </>
    );
};

export default ApplicationForm;
