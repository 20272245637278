import React, { useState, useEffect } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { translatedMessage } from '../../service/LanguageService';

const FieldDisplay = (props) => {
    const [field, setField] = useState({});
    const [fieldValue, setFieldValue] = useState(null);
    const [applicationFormFieldId, setApplicationFormFieldId] = useState(null);

    useEffect(() => {
        if (props.field) {
            if (props.field.formField) {
                // ApplicationFormFieldDto
                setField(props.field.formField.field);
                setFieldValue(props.field.responseValue);
                setApplicationFormFieldId(props.field.id);
            } else if (props.field.field) {
                // FormFieldDto
                setField(props.field.field);
                setFieldValue(props.field.requestedValue);
            } else {
                // FieldDto
                setField(props.field);
            }
        }
    }, [props]);

    const onChange = (code, value, event) => {
        setFieldValue(value);
        if (typeof props.onFieldValueChange === 'function') {
            props.onFieldValueChange(applicationFormFieldId, code, value);
        }
    };

    return (
        <div className={props?.className}>
            {field.type === 'BOOLEAN' && (
                <>
                    {field.preDescription}
                    <div className="grid my-3">
                        <div className="col-12 sm:col-6">
                            <div className="flex justify-content-start sm:justify-content-center align-items-center">
                                <div>
                                    <RadioButton id={`${field.code}_TRUE`} name={field.code} value="TRUE" checked={fieldValue === 'TRUE'} onChange={(e) => onChange(e.target.name, e.value, e)} disabled={!props.updateField} />
                                </div>
                                <div className="ml-2">{field.label}</div>
                            </div>
                        </div>
                        <div className="col-12 sm:col-6">
                            <div className="flex justify-content-start sm:justify-content-center align-items-center">
                                <div>
                                    <RadioButton id={`${field.code}_FALSE`} name={field.code} value="FALSE" checked={fieldValue === 'FALSE'} onChange={(e) => onChange(e.target.name, e.value, e)} disabled={!props.updateField} />
                                </div>
                                <div className="ml-2">{field.label2}</div>
                            </div>
                        </div>
                    </div>
                    {field.postDescription}
                </>
            )}

            {field.type !== 'BOOLEAN' && (
                <div className="my-3">
                    <div className="font-bold text-lg mb-1">{field.label}</div>
                    {field.preDescription}

                    {fieldValue && field.type === 'STRING' &&
                        <div className='mt-2'>
                            {applicationFormFieldId &&
                                <InputText id={field.code} name={field.code} value={fieldValue} onChange={(e) => onChange(e.target.id, e.target.value, e)} disabled={!props.updateField} />
                            }

                            {!applicationFormFieldId &&
                                <div className='mt-1'>
                                    <span className='font-bold'>{translatedMessage("form.field.requestedValue.description")}</span>{" " + fieldValue}
                                </div>
                            }
                        </div>
                    }

                    {fieldValue && field.type === 'NUMBER' &&
                        <div className='mt-2'>
                            {applicationFormFieldId &&
                                <InputNumber inputId={field.code} name={field.code} value={fieldValue} onChange={(e) => onChange(e.originalEvent.target.id, e.value, e)} disabled={!props.updateField} />
                            }

                            {!applicationFormFieldId &&
                                <div className='mt-1'>
                                    <span className='font-bold'>{translatedMessage("form.field.requestedValue.description")}</span>{" " + fieldValue}
                                </div>
                            }
                        </div>
                    }

                    {fieldValue && field.type === 'CUSTOM' &&
                        <div className='mt-2'>
                            {applicationFormFieldId &&
                                <InputText id={field.code} name={field.code} value={fieldValue} onChange={(e) => onChange(e.target.id, e.target.value, e)} disabled={!props.updateField} />
                            }

                            {!applicationFormFieldId &&
                                <div className='mt-1'>
                                    <span className='font-bold'>{translatedMessage("form.field.requestedValue.description")}</span>{" " + fieldValue}
                                </div>
                            }
                        </div>
                    }

                    {!fieldValue && props?.field?.requestedItemId && field.type === 'CUSTOM' &&
                        <div className='mt-2'>
                            {applicationFormFieldId &&
                                <InputText id={field.code} name={field.code} value={fieldValue} onChange={(e) => onChange(e.target.id, e.target.value, e)} disabled={!props.updateField} />
                            }

                            {!applicationFormFieldId &&
                                <div className='mt-1'>
                                    <span className='font-bold mr-1'>{translatedMessage("form.field.requestedValue.description")}</span>{props.field.entityLookupLabel ? props.field.entityLookupLabel : "-"}
                                </div>
                            }
                        </div>
                    }
                </div>
            )}
        </div>

    );
};

export default FieldDisplay;
