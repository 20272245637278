import { SplitButton } from 'primereact/splitbutton';
import React from 'react';
import ExportServices from '../service/ExportServices';

import { translatedMessage } from '../service/LanguageService';

const ExportButtonComponent = (props) => {

  const items = [
    {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      command: () => { handleExport("excel") }
    },
    {
      label: 'CSV',
      icon: 'pi pi-file',
      command: () => { handleExport("csv") }
    }
  ];

  const handleExport = async (type) => {
    let exportData = await props?.getExportData()
    ExportServices.export(type, exportData, props?.fileName, props?.header, props?.sortOrderHeader)
  }

  const onExportClick = (event) => {
    document.getElementsByClassName(props?.menuButtonClassName ? props?.menuButtonClassName : "pcn-export-dropdown")[0].click();
  }

  return (
    <SplitButton
      label={props?.label ? props?.label : translatedMessage("generic.export")}
      icon="pi pi-download"
      className={"pcn-splitbutton ".concat(props?.class ? props.class : "")}
      model={items}
      onClick={onExportClick}
      menuButtonClassName={props?.menuButtonClassName ? props?.menuButtonClassName : "pcn-export-dropdown"}
    />

  );
};

export default ExportButtonComponent;
