import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';

const resultOptions = [
    { label: translatedMessage('ApplicationStatus.ACCEPTED'), code: 'ACCEPTED' },
    { label: translatedMessage('ApplicationStatus.REJECTED'), code: 'REJECTED' },
    { label: translatedMessage('ApplicationStatus.CANCELED'), code: 'CANCELED' },
    //    ,{ label: translatedMessage('ApplicationStatus.IN_VALIDATION'), code: 'IN_VALIDATION' }
];

const ApplicationResultDialog = (props) => {
    const [result, setResult] = useState('IN_VALIDATION');
    const [dialogVisible, setDialogVisible] = useState(false);

    const toast = useRef(null);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setResult(props.application && props.application.status);
        setDialogVisible(props.visible);
    }, [props.application, props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        setResult('IN_VALIDATION');
    };

    const updateResult = () => {
        applicationService
            .updateApplicationStatus(props.application.id, result)
            .then((savedApplication) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedApplication);
                    hideDialog();
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" onClick={updateResult} />
        </>
    );

    const resultTemplate = (option) => {
        return (
            <div>
                <span className={`status status-application-${option?.code?.toString().toLowerCase()}`}>{translatedMessage('ApplicationStatus.' + option?.code)}</span>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} style={{ width: '450px' }} header={translatedMessage('application.result')} modal className="pcn-dialog p-fluid" footer={formDialogFooter} onHide={hideDialog}>
                <div className="w-full grid pcn-data-view-panel">
                    <div className="col-12">
                        <div className="filed-label">{translatedMessage('competition.competition')}</div>
                        <div className="filed-value">{props.application && props.application.competition.name}</div>
                    </div>
                    <div className="col-12">
                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                        <div className="filed-value">{translatedMessage(`CompetitionStatus.${props.application && props.application.competition.status}`)}</div>
                    </div>
                    <div className="col-12">
                        <div className="filed-label">{translatedMessage('application.applicant')}</div>
                        <div className="filed-value">
                            {props.application && props.application.user.fullName} ({props.application && props.application.user.username})
                        </div>
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="field p-inputgroup mt-3 mb-2">
                    <span className="p-float-label">
                        <Dropdown id="result" name="result" optionValue="code" optionLabel="label" value={result} options={resultOptions} onChange={(e) => setResult(e.value)} itemTemplate={resultTemplate} valueTemplate={resultTemplate} />
                        <label htmlFor="result">{translatedMessage('application.result')}</label>
                    </span>
                </div>
            </Dialog>
        </>
    );
};

export default ApplicationResultDialog;
