import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';

const DeleteDialog = (props) => {
    const deleteDialogFooter = (
        <>
            <Button label={translatedMessage('generic.no')} icon="pi pi-times" className="p-button-text" onClick={props.onCancel} />
            <Button label={translatedMessage('generic.yes')} icon="pi pi-check" className="p-button-text" onClick={props.onDelete} />
        </>
    );

    return (
        <Dialog
            visible={props.visible}
            style={{ width: '450px' }}
            header={translatedMessage('generic.delete.confirmation.title')}
            modal
            footer={deleteDialogFooter}
            onHide={props.onCancel}
            className="pcn-dialog p-fluid"
        >
            <div className="flex align-items-center">
                <i className="pi pi-exclamation-triangle mr-3 text-red-500" style={{ fontSize: '3rem' }} />
                <div className='w-full text-align-left align-items-center'>
                <div className="mb-2">{translatedMessage("generic.delete.confirmation.message")}</div>
                {props.recordDisplayName &&
                    <div className="font-bold text-lg">{props.recordDisplayName}</div>
                }
                </div>
            </div>
        </Dialog>
    );
};

export default DeleteDialog;
