import { createContext, useEffect, useState } from "react";

const NavContext = createContext({});

export const NavProvider = ({ children }) => {
    const [navProps, setNavProps] = useState([]);

    useEffect(() => {
        setNavProps(localStorage.getItem('_pcn_navProps') ? JSON.parse(localStorage.getItem('_pcn_navProps')) : [])
    }, [])

    const setNavPropsValue = (type, id, label, hasUrl = true, hasChildUrl = false) => {
        try {
            let _navProps = localStorage.getItem('_pcn_navProps') ? JSON.parse(localStorage.getItem('_pcn_navProps')) : []
            let selectedItem = _navProps? _navProps.filter(item => item.id === id && item.type === type) : []
            if (selectedItem.length === 0) {                
                _navProps.push({ type: type, id: id, label: label, hasUrl, hasChildUrl })
            } else {
                _navProps.map(item => {
                    if(item.id === id && item.type === type) {
                        item.label = label
                    }

                    return item
                })
            }

            setNavProps(_navProps)
            localStorage.setItem('_pcn_navProps', JSON.stringify(_navProps));
        } catch (error) {
            console.log(error);
        }
    };

    const getNavPropsValue = (type, id) => {
        try {
            let _navProps = navProps // JSON.parse(localStorage.getItem('_pcn_navProps'))
            return _navProps && _navProps.length > 0 ? _navProps.filter(item => item.id === id && item.type === type) : []
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <NavContext.Provider value={{ getNavPropsValue, setNavPropsValue }}>
            {children}
        </NavContext.Provider>
    )
}

export default NavContext;