import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
import ITKnowledgeComponent from '../../components/profile/ITKnowledgeComponent';

const MyITKnowledge = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [itKnowledge, setItKnowledge] = useState('on load value');
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getData = async () => {
            await userService.getItKnowledge()
                .then(response => {
                    setItKnowledge(response)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        getData();
    }, [userService]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full" >
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-left'>
                                <h5>{translatedMessage("profile.itKnowledge")}</h5>
                            </div>

                            <ITKnowledgeComponent itKnowledge={itKnowledge} updateFields={true} afterSave={(value) => setItKnowledge(value)}/>                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyITKnowledge;
