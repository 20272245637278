import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { AutoComplete } from 'primereact/autocomplete';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import ChooseMemberDialog from './ChooseMemberDialog';

const emptyMember = {
    id: null,
    user: {
        id: null
    },
    evaluationCommitteeId: null,
    isPresident: false,
    isSecretary: false
};

const CommitteeMemberEditDialog = (props) => {
    const [member, setMember] = useState(emptyMember);
    const [isNew, setIsNew] = useState(false);
    // const [filteredUsers, setFilteredUsers] = useState(false);
    const [allowSecretary, setAllowSecretary] = useState(true);

    const toast = useRef(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        let _member = { ...emptyMember };
        setIsNew(true);
        if (props.value.id) {
            _member = { ...props.value };
            setIsNew(false);
        }
        _member.evaluationCommitteeId = { ...props.committeeId };
        setNameAndUsername(_member.user);
        setMember(_member);
        setAllowSecretary(props.allowSecretary)
    }, [props, props.value]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: member,
        validate: (data) => {
            let errors = {};
            
            if (data.user && !data.user.id) {
                errors.user = translatedMessage('form.error.name.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveMember(data);
            hideMemberDialog();
            setMember(props.emptyMember);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleRoleChange = (event) => {
        if( event.value === 'President' ) {
            formik.setFieldValue('isPresident', true);
            formik.setFieldValue('isSecretary', false);
        }
        if( event.value === 'Secretary' ) {
            formik.setFieldValue('isPresident', false);
            formik.setFieldValue('isSecretary', true);
        }
        if( event.value === 'SimpleMember' ) {
            formik.setFieldValue('isPresident', false);
            formik.setFieldValue('isSecretary', false);
        }
    }

    const hideMemberDialog = () => {
        formik.resetForm();
        if (typeof props.onCancel === 'function') {
            props.onCancel();
        }
    };

    const saveMember = (data) => {
        let _member = { ...data };
        _member.evaluationCommitteeId = props.committeeId;
        evaluationService.saveCommitteeMember(_member)
            .then((savedMember) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedMember, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    // const searchUser = (searchText) => {
    //     evaluationService.searchPossibleMembers(props.committeeId, searchText)
    //         .then((users) => {
    //             users.map((user) => {
    //                 setNameAndUsername(user);
    //                 return user;
    //             });
    //             setFilteredUsers(users);
    //         })
    //         .catch((error) => {
    //             toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
    //         });
    // };

    const setChoosenMember = (member) => {
        let user = member.user
        setNameAndUsername(user);
        formik.setFieldValue("user", member.user)
        formik.setFieldValue("isPresident", false)
        formik.setFieldValue("isSecretary", false)
        formik.setFieldValue("isSimpleMember", true)
        setAllowSecretary(member.canBeSecretary && !props.hasSecretary)
    }

    // const searchItems = (event) => {
    //     let query = event.query;
    //     searchUser(query);
    // };

    const setNameAndUsername = (user) => {
        user.id ? (user.nameAndUsername = user.fullName + ' (' + user.username + ')') : (user.nameAndUsername = '');
    };

    const committeeDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideMemberDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-check" className="p-button-text" form="member-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                visible={props.visible} 
                style={{ width: '450px' }} 
                header={translatedMessage('committee.member.details')} 
                modal 
                className="p-fluid" 
                footer={committeeDialogFooter} 
                onHide={hideMemberDialog}
            >
                <form id="member-form" onSubmit={formik.handleSubmit}>
                    <div className="field p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete
                                id="user"
                                name="user"
                                value={formik.values && formik.values.user}
                                suggestions={null}
                                readOnly={true}
                                field="nameAndUsername"
                                onChange={formik.handleChange}
                                aria-label="Members"
                                minLength={3}
                                maxLength={20}
                                //disabled={!isNew}
                            />
                            
                            <ChooseMemberDialog onChooseMember={setChoosenMember} committeeId={props.committeeId}/>

                            <label htmlFor="user" className={classNames({ 'p-error': isFormFieldValid('user') })}>
                                {translatedMessage('generic.name')} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('user')}


                    <div className="field-radiobutton">
                        <RadioButton inputId="isPresident" name="role" value="President"  onChange={handleRoleChange} checked={formik.values && formik.values.isPresident} disabled={!props.allowPresident} />
                        <label htmlFor="isPresident">{translatedMessage('committee.member.isPresident')}</label>
                    </div>
                    <div className="field-radiobutton">
                        <RadioButton inputId="isSecretary" name="role" value="Secretary" onChange={handleRoleChange} checked={formik.values && formik.values.isSecretary} disabled={!allowSecretary}/>
                        <label htmlFor="isSecretary">{translatedMessage('committee.member.isSecretary')}</label>
                    </div>
                    <div className="field-radiobutton">
                        <RadioButton inputId="isSimpleMember" name="role" value="SimpleMember" onChange={handleRoleChange} checked={formik.values && !formik.values.isPresident && !formik.values.isSecretary}/>
                        <label htmlFor="isSimpleMember">{translatedMessage('committee.member.isSimpleMember')}</label>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CommitteeMemberEditDialog;
