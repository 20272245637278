import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { CompetitionService } from '../../../service/CompetitionService';
import { TestService } from '../../../service/TestService';
import { translatedMessage } from '../../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SecurityService from '../../../service/SecurityService';
import GeneralUtils from '../../../utilities/GeneralUtils';
import DataTableUtils from '../../../utilities/DataTableUtils';
import ApplicationTestCenterDialog from './ApplicationTestCenterDialog';
import useAuth from '../../../hooks/useAuth';
import ApplicationTestSessionDialog from './ApplicationTestSessionDialog';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import DataTableExportUtils from '../../../utilities/DataTableExportUtils';

const sections = [
    { label: translatedMessage('competition.testSessions'), code: 'TEST_SESSION', icon: 'fa-regular fa-calendar', url: '/test-session' },
    { label: translatedMessage('testSession.candidatesAssignedToTestSessions'), code: 'APPLICATIONS', icon: 'fa-regular fa-user', url: '/application-test-sessions' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

const ApplicationTestSessionList = () => {
    const [applicationsData, setApplicationsData] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const [applicationData, setApplicationData] = useState({});
    const [competition, setCompetition] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [isDistributing, setIsDistributing] = useState(false);
    const [applicationTestCenterDialogVisible, setApplicationTestCenterDialogVisible] = useState(false);
    const [applicationTestSessionDialogVisible, setApplicationTestSessionDialogVisible] = useState(false);
    const [section, setSection] = useState(sections[1]);
    const [canSendTestSessionNotifications, setCanSendTestSessionNotifications] = useState(false);
    const [isInOrganizationCommittee, setIsInOrganizationCommittee] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const propToColumnMap = {
        'applicationTestSesssion.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.name': 'assigned_test_center_name',
        'application.fullName': 'full_name'
    };

    const competitionService = useMemo(() => new CompetitionService(), []);
    const testService = useMemo(() => new TestService(), []);

    const navigate = useNavigate();
    const { auth } = useAuth();

    let { competitionIdParam } = useParams();

    const [loading, setLoading] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            competitionId: { value: competitionIdParam, matchMode: FilterMatchMode.EQUALS },
            testCenterId: { value: '', matchMode: FilterMatchMode.EQUALS },
            testCenterRoomId: { value: '', matchMode: FilterMatchMode.EQUALS },
            startDate: { value: '', matchMode: FilterMatchMode.EQUALS },
            fullName: { value: '', matchMode: FilterMatchMode.CONTAINS }
        }
    });

    useEffect(() => {
        const getCompetition = async () => {
            await competitionService
                .getCompetition(competitionIdParam)
                .then((_comp) => {
                    setCompetition(_comp);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetition();
        checkIsInOrganizationCommittee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionIdParam, competitionService, testService]);

    useEffect(() => {
        lazyLoadApplicationTestSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const lazyLoadApplicationTestSessions = () => {
        setLoading(true);
        loadApplicationTestSessions();
    };

    const setCriteria = () => {
        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'application_id';
        criteria.competitionId = competitionIdParam;
        criteria.testCenterId = lazyParams.filters.testCenterId.value;
        criteria.testCenterRoomId = lazyParams.filters.testCenterRoomId.value;
        criteria.fullName = lazyParams.filters.fullName.value;

        return criteria
    }

    const loadApplicationTestSessions = async () => {
        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService
            .getApplicationsTestSessionsAndTestCenters(criteria)
            .then((data) => {
                setApplicationsData(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
                setIsLoading(false);
                checkCanSendNotifications();
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            competitionService.getApplicationsTestSessionsAndTestCenters(criteria)
                .then((data) => {
                    let exportData = data.items.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item;
                        exportItem.firstName = item.application.firstName;
                        exportItem.lastName = item.application.lastName;
                        exportItem.county = item.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.county.label;
                        exportItem.city = item.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.city;
                        exportItem.center = item.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.name;
                        exportItem.room = item.testSession?.competitionTestCenterRoom.testCenterRoom.name;
                        exportItem.capacity = item.testSession?.competitionTestCenterRoom.testCenterRoom.capacity;
                        exportItem.startDate = GeneralUtils.formatDateTime(item.testSession?.startDate);
                        exportItem.endDate = GeneralUtils.formatDateTime(item.testSession?.endDate);
                        delete exportItem.application
                        delete exportItem.chosenTestCenter
                        delete exportItem.testSession
                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    };

    const checkCanSendNotifications = async () => {
        setCanSendTestSessionNotifications(await SecurityService.check('CAN_SEND_TEST_SESSION_NOTIFICATIONS', competitionIdParam));
    };

    const checkIsInOrganizationCommittee = async () => {
        setIsInOrganizationCommittee(await SecurityService.check('IS_IN_PRELIMINARY_TEST_ORGANIZATION_COMMITTEE', competitionIdParam));
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const navigationTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        );
    };

    const handleSectionChange = (value) => {
        let url = null;
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/competition-evaluation`);
            } else {
                if (value && value.url && value.url !== '#') {
                    url = `/competition-evaluation/${competition.id}${value.url}`;
                    navigate(url);
                } else {
                    setSection(value);
                }
            }
        }
    };

    const distributeCandidates = async () => {
        setIsDistributing(true);
        await competitionService
            .distributeCandidates(competitionIdParam)
            .then((count) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.testSession.distributedCandidates', count) });
                lazyLoadApplicationTestSessions();
                setIsDistributing(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                setIsDistributing(false);
            });
    };

    const confirmFinishTestSessionsDistribution = async () => {
        confirmDialog({
            message: translatedMessage('competition.testSession.finishDistributionToTestSessions.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => finishTestSessionsDistribution()
        });
    };

    const finishTestSessionsDistribution = async () => {
        setIsDistributing(true);
        await competitionService
            .finishTestSessionsDistribution(competitionIdParam)
            .then((_competition) => {
                setCompetition(_competition);
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.testSession.finishedDistributionToTestSessions') });
                checkCanSendNotifications();
                setIsDistributing(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                setIsDistributing(false);
            });
    };

    const chooseTestCenter = async (_applicationData) => {
        setApplicationData(_applicationData);
        setApplicationTestCenterDialogVisible(true);
    };

    const chooseTestSession = async (_applicationData) => {
        setApplicationData(_applicationData);
        setApplicationTestSessionDialogVisible(true);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('EVALUATION_CREATE') && isInOrganizationCommittee && (
                        <Button
                            label={translatedMessage('testSession.distributeCandidates')}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={distributeCandidates}
                            loading={isDistributing}
                            disabled={competition.testSessionsDistributionFinished}
                        />
                    )}
                </div>
                <div className="my-2">
                    {isInOrganizationCommittee && (
                        <Button
                            label={translatedMessage('testSession.sendNotifications')}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={confirmFinishTestSessionsDistribution}
                            loading={isDistributing}
                            disabled={!canSendTestSessionNotifications}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={DataTableExportUtils.userTestSessionListTableHeader()}
                sortOrderHeader={DataTableExportUtils.userTestSessionListSortOrderHeader()}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.testSessions"))}
            />
        );
    };

    const testSessionBodyTemplate = (_applicationTestSessionData) => {
        return (
            <div>
                {_applicationTestSessionData.testSession && (
                    <>
                        {_applicationTestSessionData.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.name} - {_applicationTestSessionData.testSession?.competitionTestCenterRoom.testCenterRoom.name}
                        <div className="mt-1">
                            {_applicationTestSessionData.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.city + ' ('}
                            {_applicationTestSessionData.testSession?.competitionTestCenterRoom.testCenterRoom.testCenter.county.labelRo + ')'}
                        </div>
                        <div className="mt-1">{GeneralUtils.formatDateTime(_applicationTestSessionData.testSession?.startDate) + ' - ' + GeneralUtils.formatDateTime(_applicationTestSessionData.testSession?.endDate)}</div>
                    </>
                )}
            </div>
        );
    };

    const actionBodyTemplate = (applicationData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('EVALUATION_CREATE') && isInOrganizationCommittee && (
                    <>
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => chooseTestCenter(applicationData)}
                            disabled={isDistributing || competition.testSessionsDistributionFinished}
                            tooltip={translatedMessage('generic.edit')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        />

                        <Button
                            icon="pi pi-calendar-plus"
                            className="p-button-rounded p-button-info m-1"
                            onClick={() => chooseTestSession(applicationData)}
                            disabled={isDistributing || !(hasPermission('EVALUATION_VIEW_ALL') && applicationData.canEdit)}
                            tooltip={translatedMessage('testSession.editAssign')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        />
                    </>
                )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    // Render
    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition.name}</h3>
                            <div className="mb-3">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemplate} className="pcn-selectbutton-slim" disabled={isDistributing} />
                            </div>
                            <div>
                                <h5 className="mb-1 font-weight-normal">{translatedMessage('testSession.candidatesAssignedToTestSessions')}</h5>
                            </div>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>
                            <DataTable
                                ref={dt}
                                value={applicationsData}
                                dataKey="id"
                                loading={loading}
                                lazy
                                paginator
                                totalRecords={totalRecords}
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                sortField={lazyParams.sortField}
                                sortOrder={lazyParams.sortOrder}
                                onPage={onPage}
                                onSort={onSort}
                                onFilter={onFilter}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column field="application.fullName" header={translatedMessage('generic.name')} sortable headerStyle={{ width: '40%', minWidth: '10rem' }} filter filterField="fullName" showFilterMenu={false} />
                                <Column
                                    field="applicationTestSesssion.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.name"
                                    header={translatedMessage('testSession.testSession')}
                                    sortable
                                    body={testSessionBodyTemplate}
                                    headerStyle={{ width: '40%', minWidth: '25rem' }}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>

                    <ApplicationTestCenterDialog
                        visible={applicationTestCenterDialogVisible}
                        onSave={lazyLoadApplicationTestSessions}
                        onCancel={() => {
                            setApplicationTestCenterDialogVisible(false);
                        }}
                        applicationData={applicationData}
                    />

                    <ApplicationTestSessionDialog
                        visible={applicationTestSessionDialogVisible}
                        onSave={lazyLoadApplicationTestSessions}
                        onCancel={() => {
                            setApplicationTestSessionDialogVisible(false);
                        }}
                        applicationData={applicationData}
                    />
                </div>
            </>
        );
    }
};

export default ApplicationTestSessionList;
