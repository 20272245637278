import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';

const ApplicationValidationErrorModalComponent = (props) => {

  const dialogDeleteFooter = (
    <>
      <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
    </>
  );

  return (
    <Dialog
      visible={props.visible}
      header={translatedMessage("application.validation.title")}
      modal
      footer={dialogDeleteFooter}
      className="pcn-dialog p-fluid"
      onHide={() => props.closeDialog()}
    >
      <div className="flex align-items-center">
        <i className="pi pi-exclamation-triangle mr-3 text-red-500" style={{ fontSize: '3rem' }} />
        <div className='w-full text-align-left align-items-center'>
          <div className="mb-2 font-bold">{translatedMessage("application.validation.info")}</div>
          <ul>
            {
              props.errors.map((item, index) =>
                <li key={index} className='mb-2'>{translatedMessage(item)}</li>
              )
            }
          </ul>
        </div>
      </div>
    </Dialog>

  );
};

export default ApplicationValidationErrorModalComponent;
