import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import MenuService from './service/MenuService';
import LayoutService from './service/LayoutService';
import ApiService from './service/ApiService';

import PersistLogin from './components/security/PersistLogin';
import RequireAuth from './components/security/RequireAuth';

import useMenu from './hooks/useMenu';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';

import Users from './pages/security/Users';
import Roles from './pages/security/Roles';
import NotFound from './pages/NotFound';

import MyProfile from './pages/profile/MyProfile';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import './assets/css/pcn.css'

import { addLocale, locale } from 'primereact/api';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Unauthorized from './pages/Unauthorized';
import MyEducation from './pages/profile/MyEducation';
import MyWorkExperience from './pages/profile/MyWorkExperience';
import RecruitmentPlanList from './pages/recruitment/RecruitmentPlanList';
import CompetitionList from './pages/competition/CompetitionList';
import CompetitionView from './pages/competition/CompetitionView';
import CompetitionTestCenterRoomList from './pages/competition/testCenter/CompetitionTestCenterRoomList';
import CompetitionTestList from './pages/competition/test/CompetitionTestList';
import TestSessionList from './pages/competition/testSession/TestSessionList';
import TestSessionView from './pages/competition/testSession/TestSessionView';
import ApplicationTestSessionList from './pages/competition/testSession/ApplicationTestSessionList';
import ResultList from './pages/competition/application/ResultList';
import CommitteePage from './pages/evaluation/CommitteePage';
import EvaluationPage from './pages/evaluation/EvaluationPage';
import FormList from './pages/form/FormList';
import FieldList from './pages/form/field/FieldList';
import FormFieldList from './pages/form/formField/FormFieldList';
import MyLanguage from './pages/profile/MyLanguage';
// import SignatureDemo from './pages/SignatureDemo';
import ChangePassword from './pages/profile/ChangePassword';
import TestList from './pages/test/TestList';
import TestCenterList from './pages/test/testCenter/TestCenterList';
import TestCenterRoomList from './pages/test/testCenterRoom/TestCenterRoomList';
import MyFile from './pages/profile/MyFile';
import HomePage from './pages/HomePage';
import CommitteeList from './pages/evaluation/CommitteeList';
import CompetitionForm from './pages/competition/form/CompetitionForm';
import GeneralUtils from './utilities/GeneralUtils';
import LanguageService from './service/LanguageService';
import MyApplicationPage from './pages/competition/application/MyApplicationPage';
import MyITKnowledge from './pages/profile/MyITKnowledge';
import MySettings from './pages/profile/MySettings';
import AvailableCompetitionList from './pages/competition/AvailableCompetitionList';
import MyApplicationList from './pages/competition/application/MyApplicationList';
import CompetitionDetails from './pages/competition/CompetitionDetails';
import MyApplicationContestationPage from './pages/competition/application/MyApplicationContestationPage';
import MyApplicationClarificationPage from './pages/competition/application/MyApplicationClarificationPage';
import MyApplicationTestsPage from './pages/competition/application/MyApplicationTestsPage';
import ApplicationPage from './pages/competition/application/ApplicationPage';
import EvaluationCompetitionList from './pages/competition/EvaluationCompetitionList';
import EvaluationCompetitionPage from './pages/competition/EvaluationCompetitionPage';
import EvaluationClarificationPage from './pages/evaluation/EvaluationClarificationPage';
import EvaluationContestationPage from './pages/evaluation/EvaluationContestationPage';
import EvaluationListForAplication from './pages/evaluation/EvaluationListForAplication';
import EvaluationViewPage from './pages/evaluation/EvaluationViewPage';
import MyNotifications from './pages/MyNotifications';
import MyApplicationContestationList from './pages/competition/application/MyApplicationContestationList';
import EvaluationContestationList from './pages/evaluation/EvaluationContestationList';
import RecruitmentPlanPublishedList from './pages/recruitment/RecruitmentPlanPublishedList';
import CompetitionClarificationList from './pages/competition/CompetitionClarificationList';
import CompetitionContestationList from './pages/competition/CompetitionContestationList';
import EvaluationCommitteeList from './pages/competition/EvaluationCommitteeList';
import EvaluationCommitteePage from './pages/competition/EvaluationCommitteePage';
import MyCommittee from './pages/evaluation/MyCommittee';
import EvaluationSecretaryPage from './pages/evaluation/EvaluationSecretaryPage';
import MyAccessibility from './pages/profile/MyAccessibility';
import TestDemoList from './pages/test/TestDemoList';
import EvaluationTestingPage from './pages/evaluation/EvaluationTestingPage';
import EvaluationTestingViewPage from './pages/evaluation/EvaluationTestingViewPage';
import ApplicationTestsPage from './pages/competition/application/ApplicationTestsPage';
import FinalReportCompetitionList from './pages/competition/FinalReportCompetitionList';
import EvaluationJobSecretaryPage from './pages/evaluation/EvaluationPhaseResultPage';
import TestVerificationScaleList from './pages/test/testVerificationScale/TestVerificationScaleList';
import ProfileView from './pages/profile/ProfileView';
import ProfileEducation from './pages/profile/ProfileEducation';
import ProfileWorkExperience from './pages/profile/ProfileWorkExperience';
import ProfileITKnowledge from './pages/profile/ProfileITKnowledge';
import ProfileLanguage from './pages/profile/ProfileLanguage';
import ProfileFile from './pages/profile/ProfileFile';

const topbarThemeColor = 'bluegrey'

export const RTLContext = React.createContext();

const App = () => {
    const [menuMode, setMenuMode] = useState('static');
    const [inlineMenuPosition, setInlineMenuPosition] = useState('bottom');
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [colorMode, setColorMode] = useState('light');
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState(LayoutService.getDefaulltInputStyle());
    const [isRTL, setRTL] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState(LayoutService.getDefaultMenuTheme());
    const [topbarTheme, setTopbarTheme] = useState(topbarThemeColor);
    const [theme, setTheme] = useState('indigo');
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const copyTooltipRef = useRef();
    let currentInlineMenuKey = useRef(null);
    const location = useLocation();

    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const menu = useMenu();

    const routes = MenuService.getRoutes();

    library.add(fas, far, fab);

    ApiService.setRedirectUrl(useLocation().pathname);

    addLocale('ro', GeneralUtils.getCalendarRO());
    locale(LanguageService.getCurrentLanguage());

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (menuMode === 'overlay') {
            hideOverlayMenu();
        }
        if (menuMode === 'static') {
            setDesktopMenuActive(true);
        }
    }, [menuMode]);

    useEffect(() => {
        onColorModeChange(colorMode);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuThemeChange = (theme) => {
        setMenuTheme(theme);
    };

    const onTopbarThemeChange = (theme) => {
        setTopbarTheme(theme);
    };

    useEffect(() => {
        const appLogoLink = document.getElementById('app-logo');

        if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
            appLogoLink.src = 'assets/layout/images/logo-dark.png';
        } else {
            appLogoLink.src = 'assets/layout/images/logo.png';
        }
    }, [topbarTheme]);

    const onThemeChange = (theme) => {
        setTheme(theme);
        const themeLink = document.getElementById('theme-css');
        const themeHref = 'assets/theme/' + theme + '/theme-' + colorMode + '.css';
        replaceLink(themeLink, themeHref);
    };

    const onColorModeChange = (mode) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === 'dark') {
                setInputStyle('outlined');
            } else {
                setInputStyle('outlined');
            }
        }

        if (mode === 'dark') {
            setMenuTheme('dark');
            setTopbarTheme('dark');
        } else {
            setMenuTheme(LayoutService.getDefaultMenuTheme());
            setTopbarTheme(topbarThemeColor);
        }

        const layoutLink = document.getElementById('layout-css');
        const layoutHref = 'assets/layout/css/layout-' + mode + '.css';
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById('theme-css');
        const urlTokens = themeLink.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
        const newURL = urlTokens.join('/');

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });
    };

    const replaceLink = (linkElement, href, callback) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                const _linkElement = document.getElementById(id);
                _linkElement && _linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onInlineMenuPositionChange = (mode) => {
        setInlineMenuPosition(mode);
    };

    const onMenuModeChange = (mode) => {
        setMenuMode(mode);
    };

    const onRTLChange = () => {
        setRTL((prevState) => !prevState);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
        else setMobileMenuActive((prevState) => !prevState);

        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else {
            setActiveTopbarItem(event.item);
        }

        event.originalEvent.preventDefault();
    };

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === 'overlay' || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState);
    };

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== 'input') {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === 'overlay' || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    // const onInlineMenuClick = (e, key) => {
    //     let menuKeys = { ...inlineMenuActive };
    //     if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
    //         menuKeys[currentInlineMenuKey.current] = false;
    //     }

    //     menuKeys[key] = !menuKeys[key];
    //     setInlineMenuActive(menuKeys);
    //     currentInlineMenuKey.current = key;
    //     inlineMenuClick = true;
    // };

    const layoutContainerClassName = classNames('layout-wrapper ', 'layout-menu-' + menuTheme + ' layout-topbar-' + topbarTheme, {
        'layout-menu-static': menuMode === 'static',
        'layout-menu-overlay': menuMode === 'overlay',
        'layout-menu-slim': menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': isRTL
    });

    return (
        <RTLContext.Provider value={isRTL}>
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar
                    horizontal={isHorizontal()}
                    activeTopbarItem={activeTopbarItem}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarItemClick={onTopbarItemClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick}
                    mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive}
                    onSearch={onSearch}
                />

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                    </div>
                </div>

                <div className="layout-main">
                    <AppBreadcrumb routes={routes} />

                    <div className="layout-content">
                        <Routes>
                            <Route element={<PersistLogin />}>
                                <Route path="/home" element={<HomePage />} />

                                {/* generic - all users */}
                                <Route path="/notifications" element={<MyNotifications />} />

                                {/* My Profile menu */}
                                <Route path="/my-profile" element={<MyProfile />} />
                                <Route path="/my-education" element={<MyEducation />} />
                                <Route path="/my-work-experience" element={<MyWorkExperience />} />
                                <Route path="/my-it-knowledge" element={<MyITKnowledge />} />
                                <Route path="/my-language-skill" element={<MyLanguage />} />
                                <Route path="/my-accessibility" element={<MyAccessibility />} />
                                <Route path="/my-files" element={<MyFile />} />
                                <Route path="/change-password" element={<ChangePassword />} />
                                <Route path="/my-settings" element={<MySettings />} />

                                {/* Competition menu - candidates */}
                                <Route path="/active-competition" element={<AvailableCompetitionList />} />
                                <Route path="/application" element={<MyApplicationList />} />
                                <Route path="/active-competition/:competitionIdParam" element={<CompetitionDetails />} />
                                <Route path="/application/:applicationIdParam" element={<MyApplicationPage />} />
                                <Route path="/application/:applicationIdParam/contestation" element={<MyApplicationContestationList />} />
                                <Route path="/application/:applicationIdParam/contestation/result/:resultIdParam" element={<MyApplicationContestationPage />} />
                                <Route path="/application/:applicationIdParam/clarification" element={<MyApplicationClarificationPage />} />
                                <Route path="/application/:applicationIdParam/tests" element={<MyApplicationTestsPage />} />
                                <Route path="/test-demo" element={<TestDemoList />} />

                                {/* Administration menu */}
                                <Route element={<RequireAuth allowedPermissions={["USER_LIST"]} />}>
                                    <Route path="/administration/users" element={<Users />} />
                                    <Route path="/administration/users/:userIdParam" element={<ProfileView />} />
                                    <Route path="/administration/users/:userIdParam/education" element={<ProfileEducation />} />
                                    <Route path="/administration/users/:userIdParam/workExperience" element={<ProfileWorkExperience />} />
                                    <Route path="/administration/users/:userIdParam/itKnowledge" element={<ProfileITKnowledge />} />
                                    <Route path="/administration/users/:userIdParam/language" element={<ProfileLanguage />} />
                                    <Route path="/administration/users/:userIdParam/file" element={<ProfileFile />} />
                                </Route>
                                <Route element={<RequireAuth allowedPermissions={["ROLE_LIST"]} />}>
                                    <Route path="/administration/roles" element={<Roles />} />
                                </Route>

                                {/* Recruitment menu */}
                                <Route element={<RequireAuth allowedPermissions={["RECRUITMENT_LIST"]} />}>
                                    <Route path="/recruitment" element={<RecruitmentPlanList />} />
                                </Route>
                                <Route path="/recruitment-plans" element={<RecruitmentPlanPublishedList />} />

                                {/* Competition menu - admin */}
                                <Route element={<RequireAuth allowedPermissions={["COMPETITION_LIST"]} />}>
                                    <Route path="/competition" element={<CompetitionList />} />
                                    <Route path="/competition/:competitionIdParam" element={<CompetitionView />} />
                                    <Route path="/competition/:competitionIdParam/committee" element={<CommitteeList />} />
                                    <Route path="/competition/:competitionIdParam/committee/:committeeIdParam" element={<CommitteePage />} />
                                    <Route path="/competition/:competitionIdParam/form/:formIdParam" element={<CompetitionForm />} />
                                    <Route path="/competition/:competitionIdParam/test-center" element={<CompetitionTestCenterRoomList />} />
                                    <Route path="/competition/:competitionIdParam/test" element={<CompetitionTestList />} />
                                </Route>

                                {/* Competition menu - evaluation */}
                                <Route element={<RequireAuth allowedPermissions={["EVALUATION_LIST"]} />}>
                                    <Route path="/competition-evaluation" element={<EvaluationCompetitionList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam" element={<EvaluationCompetitionPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/committees" element={<EvaluationCommitteeList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/committees/:committeeIdParam" element={<EvaluationCommitteePage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/clarifications" element={<CompetitionClarificationList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/contestations" element={<CompetitionContestationList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/results" element={<ResultList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-view/:applicationIdParam" element={<ApplicationPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/evaluations/:applicationIdParam/evaluation-view/:evaluationIdParam" element={<EvaluationViewPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/evaluations/:applicationIdParam/evaluation-testing-view/:evaluationIdParam" element={<EvaluationTestingViewPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/evaluation-edit/:evaluationIdParam" element={<EvaluationPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/evaluation-testing-edit/:evaluationIdParam" element={<EvaluationTestingPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-edit/:applicationIdParam" element={<EvaluationSecretaryPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-clarification/:applicationIdParam" element={<EvaluationClarificationPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-contestation/:applicationIdParam" element={<EvaluationContestationList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-contestation/:applicationIdParam/result/:resultIdParam" element={<EvaluationContestationPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/evaluations/:applicationIdParam" element={<EvaluationListForAplication />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/test-session" element={<TestSessionList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/test-session/:testSessionIdParam" element={<TestSessionView />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-test-sessions" element={<ApplicationTestSessionList />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/application-test/:applicationIdParam" element={<ApplicationTestsPage />} />
                                    <Route path="/competition-evaluation/:competitionIdParam/phase-result" element={<EvaluationJobSecretaryPage />} />
                                    <Route path="/my-committee" element={<MyCommittee />} />
                                </Route>

                                {/* Final report menu */}
                                <Route path="/final-report" element={<FinalReportCompetitionList />} />

                                {/* Tests menu - admin */}
                                <Route element={<RequireAuth allowedPermissions={["TEST_LIST"]} />}>
                                    <Route path="/test" element={<TestList />} />
                                    <Route path="/test-center" element={<TestCenterList />} />
                                    <Route path="/test-center/:testCenterIdParam/test-center-room" element={<TestCenterRoomList />} />
                                </Route>

                                {/* Tests menu - candidates */}
                                <Route path="/test-demo" element={<TestDemoList />} />
                                <Route path="/test-scale" element={<TestVerificationScaleList />} />

                                {/* Form menu - admin */}
                                <Route element={<RequireAuth allowedPermissions={["FORM_LIST"]} />}>
                                    <Route path="/form" element={<FormList />} />
                                    <Route path="/field" element={<FieldList />} />
                                    <Route path="/form/:formIdParam/field" element={<FormFieldList />} />
                                </Route>

                                <Route path="/unauthorized" element={<Unauthorized />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </div>

                    <AppFooter colorMode={colorMode} />
                </div>

                <AppConfig
                    inputStyle={inputStyle}
                    onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple}
                    onRippleEffect={onRipple}
                    menuMode={menuMode}
                    onMenuModeChange={onMenuModeChange}
                    inlineMenuPosition={inlineMenuPosition}
                    onInlineMenuPositionChange={onInlineMenuPositionChange}
                    colorMode={colorMode}
                    onColorModeChange={onColorModeChange}
                    menuTheme={menuTheme}
                    onMenuThemeChange={onMenuThemeChange}
                    topbarTheme={topbarTheme}
                    onTopbarThemeChange={onTopbarThemeChange}
                    theme={theme}
                    onThemeChange={onThemeChange}
                    isRTL={isRTL}
                    onRTLChange={onRTLChange}
                />

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} />

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
            </div>
        </RTLContext.Provider>
    );
};

export default App;
