import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';

import { FormFieldService } from '../../../service/FormFieldService';
import { translatedMessage } from '../../../service/LanguageService';
import EnumService from '../../../service/EnumService';
import EditFieldDialog from './EditFieldDialog';
import DataTableUtils from '../../../utilities/DataTableUtils';
import useAuth from '../../../hooks/useAuth';
import ViewFieldDialog from '../../../components/form/ViewFieldDialog';
import DeleteModalComponent from '../../../components/DeleteModalComponent';

const FieldList = () => {
    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState(null);
    const [fieldTypeOptions, setFieldTypeOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);

    const formFieldService = useMemo(() => new FormFieldService(), []);

    const { auth } = useAuth();

    useEffect(() => {
        setIsLoading(true)

        const getFieldTypes = async () => {
            setFieldTypeOptions(await EnumService.getEnumByName('FieldType'));
        };

        const getFields = async () => {
            await formFieldService.getFieldList()
                .then((fields) => {
                    setFields(fields);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getFieldTypes();
        getFields();
    }, [formFieldService]);

    const updateList = async (savedField, isNew) => {
        let updatedFields = DataTableUtils.updateTableList(fields, savedField, isNew)

        setFields(updatedFields);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedField(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (field) => {
        setSelectedField(field);
        setEditDialogVisible(true);
    };

    const showViewDialog = (field) => {
        setSelectedField(field);
        setViewDialogVisible(true);
    };

    const showDeleteDialog = (field) => {
        setSelectedField(field);
        setDeleteDialogVisible(true);
    };

    const hideViewDialog = () => {
        setViewDialogVisible(false);
        setSelectedField(null);
    };


    const deleteField = () => {
        formFieldService
            .deleteField(selectedField.id)
            .then(() => {
                let _fields = fields.filter(item => item.id !== selectedField.id)
                setFields(_fields)                
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedField(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('field.newField')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={translatedMessage('generic.export')} icon="pi pi-download" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        disabled={rowData.default} tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)}
                        disabled={rowData.default} tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    const typeBodyTemplate = (rowData) => {
        return <>{translatedMessage('FieldType.' + rowData.type)}</>;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{translatedMessage('field.fields')}</h3>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={fields}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="stack"
                            >
                                <Column
                                    field="code"
                                    header={translatedMessage('generic.code')}
                                    sortable
                                    headerStyle={{ width: '35%', minWidth: '10rem' }}
                                />
                                {/* <Column
                                    field="label"
                                    header={translatedMessage('generic.label')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                /> */}
                                <Column
                                    field="type"
                                    header={translatedMessage('generic.type')}
                                    sortable
                                    body={typeBodyTemplate}
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            <EditFieldDialog
                                value={selectedField}
                                visible={editDialogVisible}
                                afterSave={updateList}
                                visibleSetter={setEditDialogVisible}
                                fieldTypeOptions={fieldTypeOptions}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedField ? selectedField.label : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteField()}
                            />

                            <ViewFieldDialog
                                visible={viewDialogVisible}
                                field={selectedField}
                                closeDialog={hideViewDialog} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default FieldList;
