import ApiService from "./ApiService";
import GeneralUtils from '../utilities/GeneralUtils';

export class TestService {

    // test

    async getTestList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/test/list')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestListForCompetition(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/test/list`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getDemoTestList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/test/demo/list')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTest(testId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/test/${testId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveTest(test) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/test', test)
                .then(response => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteTest(testId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/test/${testId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // test center

    async getTestCenterList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/testCenter/list')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestCenter(testCenterId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/testCenter/${testCenterId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveTestCenter(testCenter) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/testCenter', testCenter)
                .then(response => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteTestCenter(testCenterId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/testCenter/${testCenterId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // test center room

    async getTestCenterRoomList(testCenterId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/testCenterRoom/list?testCenterId=${testCenterId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestCenterRoom(testCenterRoomId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/testCenterRoom/${testCenterRoomId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveTestCenterRoom(testCenterRoom) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/testCenterRoom', testCenterRoom)
                .then(response => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteTestCenterRoom(testCenterRoomId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/testCenterRoom/${testCenterRoomId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // test sessions    
    async getTestSessions(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/testSessions`, criteria)
                .then(response => {
                    response.data.items.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = new Date(Date.parse(item.endDate));
                        return item;
                    })
                    response.data.criteria.startDate = new Date(Date.parse(response.data.criteria.startDate));
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // test sessions    
    async getTestSessionsAvailableToChoseFrom(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/testSessionsAvailableToChoseFrom`, criteria)
                .then(response => {
                    response.data.items.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = new Date(Date.parse(item.endDate));
                        return item;
                    })
                    response.data.criteria.startDate = new Date(Date.parse(response.data.criteria.startDate));
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    async getTestSession(testSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/testSession/${testSessionId}`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.endDate = new Date(Date.parse(response.data.endDate));
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveTestSession(testSession) {
        testSession.startDate = GeneralUtils.resetSecondsAndMiliseconds(testSession?.startDate)
        testSession.endDate = GeneralUtils.resetSecondsAndMiliseconds(testSession?.endDate)
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/testSession', testSession)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.endDate = new Date(Date.parse(response.data.endDate));
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteTestSession(testSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/testSession/${testSessionId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async runDemoTest(testId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/test/${testId}/assessment`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestVerificationScaleList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/test/verification-scale/list')
                .then(response => {                    
                    resolve({data: response.data.testVerificationScale, canEdit: response.data.canEdit})
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveTestVerificationScale(verificationScale) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/test/verification-scale/edit', verificationScale)
                .then(response => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteTestVerificationScale(id) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/test/verification-scale/delete/${id}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }  
}