import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { UserService } from '../../service/UserService';
import useNavProps from '../../hooks/useNavProps';
import { useParams } from 'react-router-dom';
import ProfileToolbar from './ProfileToolbar';

const ProfileFile = () => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const toast = useRef(null);
    const { userIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true);
        const getProfile = async () => {
            await userService.getUserProfile(userIdParam)
                .then(async response => {
                    setUser(response)
                    setNavPropsValue('users', response.id, response.fullName)

                    setIsLoading(false);
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        getProfile()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, userIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">
                        <ProfileToolbar user={user} code="FILE" />

                        <StorageFolderFileList
                            folderId={user?.profile?.folderId}
                            folderName={user?.fullName}
                            showUploadButton={true}
                            pageTitle={translatedMessage("generic.files")}                        
                            singleFile={false}
                            addUsageOnUpload={true}
                            readOnly={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default ProfileFile;
