import React, { useState, useEffect } from 'react';
import MyApplications from '../components/application/MyApplications';
import MyAvailableCompetitions from '../components/application/MyAvailableCompetitions';
import MyCommitteesComponent from '../components/committee/MyCommitteesComponent';
import useAuth from '../hooks/useAuth';

const HomePage = () => {
    const [reload, setReload] = useState(new Date());

    const { auth } = useAuth();

    useEffect(() => { }, []);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="grid dashboard">
                <div className="col-12 xl:col-6">
                    <div className="card height-100">
                        <MyApplications reload={reload} />
                    </div>
                </div>
                <div className="col-12 xl:col-6">
                    <div className="card height-100">
                        <MyAvailableCompetitions triggerReload={() => setReload(new Date())} />
                    </div>
                </div>
                {hasPermission("IS_EVALUATOR") &&
                    <div className="col-12 lg:col-12">
                        <div className="card height-100">
                            <MyCommitteesComponent showOnlyActive={true} showFilter={false}/>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default HomePage;
