import React, { useState, useEffect, useMemo, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { CompetitionService } from '../../service/CompetitionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';

const MoveCompetitionStatusForward = (props) => {
    const [competition, setCompetition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setCompetition(props.competition);
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionService]);

    const moveCompetitionToNextStatus = () => {
        setIsLoading(true);
        competitionService
            .moveToNextStatus(competition.id)
            .then((_competition) => {
                setCompetition(_competition);
                toast.current.show({ severity: 'success', summary: 'Concursul a fost trecut in etapa urmatoare. Noul status este ' + _competition.status + '. Refresh page!', life: 5000 });
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
                setErrorMessage(translatedMessage(error));
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                setIsLoading(false);
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="card h-full w-full" style={{ border: '1px solid red' }}>
                <div className="w-full grid pcn-data-view-panel">
                    <div className="col-12 font-bold" style={{ color: 'red' }}>
                        Aceasta functionalitate este aici doar pentru testare!
                    </div>
                    <div className="col-6">
                        <div className="mb-2">
                            {translatedMessage('generic.status')}: <span className={`pill status status-competition-${competition?.status.toLowerCase()}`}> {translatedMessage('CompetitionStatus.' + competition?.status)}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        {isLoading && (
                            <div className="w-full flex align-items-center">
                                <div>Va rugam asteptati finalizarea procesului. Aceasta actiune poate dura si cateva minute.</div>
                                <ProgressSpinner />
                            </div>
                        )}
                        {isError && (
                            <div className="w-full flex align-items-center">
                                <ErrorMessageDisplayComponent message={errorMessage} />
                            </div>
                        )}
                        {!isLoading && !isError && (
                            <div>
                                <Button onClick={moveCompetitionToNextStatus}>
                                    <div className="flex align-items-center">
                                        <FontAwesomeIcon icon="fa-solid fa-forward-step" className="mr-1 " />
                                        <span>{translatedMessage(' !!! Trecerea concursului la statusul urmator !!! ')}</span>
                                    </div>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MoveCompetitionStatusForward;
