import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';
import { AutoComplete } from 'primereact/autocomplete';

const emptyRecord = {
    id: null,
    test: { id: null, name: '' },
    status: '',
    folderId: null
};

const TestVerificationScaleEditDialog = (props) => {

    const [verificationScale, setVerificationScale] = useState(emptyRecord);
    const [isNew, setIsNew] = useState(false);
    const [filteredFields, setFilteredFields] = useState(null);

    const toast = useRef(null);

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        let _vs = emptyRecord
        if (props.value) {
            _vs = props.value
            _vs.test = { id: props.value.testId, name: props.value.testName }
        }

        if (props.visible) {
            setVerificationScale(_vs)
            setIsNew(props.value ? false : true)
        }
    }, [props, testService]);

    const hideDialog = () => {
        if (typeof props.closeDialog === 'function') {
            props.closeDialog();
        }
        formik.resetForm();
    };

    const saveTest = (data) => {
        let _verificationScale = { id: data.id, testId: data.test?.id, status: data.status }
        testService.saveTestVerificationScale(_verificationScale)
            .then(savedTest => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedTest, isNew);
                }

                toast?.current?.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                hideDialog();
            })
            .catch(error => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: verificationScale,
        validate: (data) => {
            let errors = {};

            if (!data.test || !data.test.id) {
                errors.test = translatedMessage('form.error.test.required');
            }

            if (!data.status) {
                errors.status = translatedMessage('form.error.status.required');
            }

            return errors;
        },
        onSubmit: (data) => {
            saveTest(data);
            setVerificationScale(emptyRecord);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const testDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="test-form" type="submit" />
        </>
    );

    const searchField = (event) => {
        let _filteredItems;
        if (!event.query.trim().length) {
            _filteredItems = [...props.availableTests];
        } else {
            _filteredItems = props.availableTests.filter((item) => {
                return item.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 ||
                    item.template.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
            });
        }

        setFilteredFields(_filteredItems);
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                style={{ width: '450px' }}
                header={translatedMessage('testVerificationScale')}
                modal
                className="p-fluid"
                footer={testDialogFooter}
                onHide={hideDialog}
            >
                <form id="test-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <AutoComplete
                                id="test"
                                name="test"
                                value={formik.values && formik.values.test}
                                suggestions={filteredFields}
                                completeMethod={searchField}
                                field="name"
                                dropdown
                                forceSelection
                                itemTemplate={e => e.name}
                                onChange={formik.handleChange}
                                aria-label="Tests"
                                dropdownAriaLabel="Select field"
                                scrollHeight="300px"
                                showEmptyMessage={true}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                autoFocus
                                className={classNames({ 'p-invalid': isFormFieldValid('test') })}
                            />

                            <label htmlFor="test" className={classNames({ 'p-error': isFormFieldValid('test') })}>
                                {translatedMessage('test.name')} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('test')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={props.statuses}></Dropdown>
                            <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') })}>
                                {translatedMessage("generic.status")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('status')}
                </form>
            </Dialog>
        </>
    );
};

export default TestVerificationScaleEditDialog;
