import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';

const emptyTestCenterRoom = {
    id: null,
    name: '',
    testCenter: {
        id: null,
        name: ''
    },
    capacity: '',
    details: '',
    status: ''
};

const TestCenterRoomEditDialog = (props) => {
    const [testCenterRoom, setTestCenterRoom] = useState(emptyTestCenterRoom);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const toast = useRef(null);

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        let _testCenterRoom = { ...props.testCenterRoom ? props.testCenterRoom : emptyTestCenterRoom };
        _testCenterRoom.testCenter = props.testCenter;
        setTestCenterRoom(_testCenterRoom);
        setIsNew(props.testCenterRoom ? false : true);
    }, [props.testCenter, props.testCenterRoom]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveTestCenterRoom = (data) => {
        testService
            .saveTestCenterRoom(data)
            .then((savedRoom) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedRoom, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
                let _testCenterRoom = { ...emptyTestCenterRoom };
                _testCenterRoom.testCenter = props.testCenter;
                setTestCenterRoom(_testCenterRoom);
                formik.resetForm();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: testCenterRoom,
        validate: (data) => {
            let errors = {};

            if (!data.name ) {
                errors.name = translatedMessage('form.error.name.required');
            }
            if (!data.capacity ) {
                errors.capacity = translatedMessage('form.error.capacity.required');
            }
            if (!data.details ) {
                errors.details = translatedMessage('form.error.details.required');
            }
            if (!data.status ) {
                errors.status = translatedMessage('form.error.status.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveTestCenterRoom(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="testCenterRoom-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                visible={dialogVisible} 
                style={{ width: '450px' }} 
                header={translatedMessage('testCenter.room.details')} 
                modal 
                className="p-fluid" 
                footer={testCenterDialogFooter} 
                onHide={hideDialog}
            >
                <form id="testCenterRoom-form" onSubmit={formik.handleSubmit}>

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="testCenter" name="testCenter" value={formik.values.testCenter.name} disabled />
                            <label htmlFor="testCenter">
                                {translatedMessage('testCenter.room.testCenter')}*
                            </label>
                        </span>
                    </div>

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus
                                className={classNames({ 'p-invalid': isFormFieldValid('requestedValue') })} />
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                {translatedMessage('generic.nameRo')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('name')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputNumber id="capacity" name="capacity" value={formik.values.capacity} onValueChange={formik.handleChange} 
                                className={classNames({ 'p-invalid': isFormFieldValid('capacity') })} />
                            <label htmlFor="capacity" className={classNames({ 'p-error': isFormFieldValid('capacity') })}>
                                {translatedMessage('testCenter.room.capacity')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('capacity')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="details" name="details" value={formik.values.details} onChange={formik.handleChange} 
                                className={classNames({ 'p-invalid': isFormFieldValid('details') })} />
                            <label htmlFor="details" className={classNames({ 'p-error': isFormFieldValid('details') })}>
                                {translatedMessage('testCenter.room.detailsLabel')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('details')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={props.statusOptions}></Dropdown>
                            <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') })}>
                                {translatedMessage("generic.status")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('status')}
                </form>
            </Dialog>
        </>
    );
};

export default TestCenterRoomEditDialog;
