import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import { translatedMessage } from '../../../service/LanguageService';
import { FormFieldService } from '../../../service/FormFieldService';

const emptyField = {
    id: null,
    code: '',
    label: '',
    label2: '',
    type: '',
    preDescription: '',
    postDescription: ''
};

const EditFieldDialog = (props) => {

    const [field, setField] = useState(emptyField);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [isLabel2Visible, setIsLabel2Visible] = useState(false);

    const toast = useRef(null);

    const formFieldService = useMemo(() => new FormFieldService(), []);

    useEffect(() => {
        setField(props.value ? props.value : emptyField);
        setIsLabel2Visible(props.value ? (props.value.type === 'BOOLEAN' ? true : false) : false)
        setIsNew(props.value ? false : true);
    }, [props]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveField = (data) => {
        formFieldService.saveField(data)
            .then(savedField => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedField, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                hideDialog();
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: field,
        validate: (data) => {
            let errors = {};

            if (!data.code) {
                errors.code = translatedMessage('form.error.code.required');
            }
            if (!data.type) {
                errors.type = translatedMessage('form.error.type.required');
            }
            if (!data.label) {
                errors.label = translatedMessage('form.error.label.required');
            }          
            if (data.type === 'BOOLEAN' && !data.label2) {
                errors.label2 = translatedMessage('form.error.label.required');
            }
            if (!data.preDescription) {
                errors.preDescription = translatedMessage('form.error.form.preDescription.required');
            }   
            if (data.type === 'BOOLEAN' && !data.postDescription) {
                errors.postDescription = translatedMessage('form.error.form.postDescription.required');
            }           
            return errors;
        },
        onSubmit: (data) => {
            saveField(data);
            setField(emptyField);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleTypeChange = (data) => {
        formik.setFieldValue("type", data.value)
        if (data.value === 'BOOLEAN') {
            setIsLabel2Visible(true);
        } else {
            setIsLabel2Visible(false);
        }
    }

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} header={translatedMessage('field.details')} modal className="pcn-dialog p-fluid" footer={formDialogFooter} onHide={hideDialog}>
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="code" name="code" value={formik.values.code} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('code') })} />
                            <label htmlFor="code" className={classNames({ 'p-error': isFormFieldValid('code') })}>
                                {translatedMessage("generic.code")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('code')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="type" name="type" value={formik.values.type} onChange={handleTypeChange} options={props.fieldTypeOptions} 
                               className={classNames({ 'p-invalid': isFormFieldValid('type') })} />
                            <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                {translatedMessage("generic.type")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('type')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="label" name="label" value={formik.values.label} onChange={formik.handleChange} 
                                className={classNames({ 'p-invalid': isFormFieldValid('label') })} />
                            <label htmlFor="label" className={classNames({ 'p-error': isFormFieldValid('label') })}>
                                {formik.values.type !== 'BOOLEAN' ? translatedMessage("generic.label") : translatedMessage("boolean.label.yes")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('label')}

                    {isLabel2Visible &&
                        <div className="form p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <InputText type="text" id="label2" name="label2" value={formik.values.label2} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('label2') })} />
                                <label htmlFor="label2" className={classNames({ 'p-error': isFormFieldValid('label2') })}>
                                    {translatedMessage("boolean.label.no")}*
                                </label>
                            </span>
                        </div>
                    }
                    {getFormErrorMessage('label2')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputTextarea rows={5} cols={30} id="preDescription" name="preDescription" value={formik.values.preDescription || ''} onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('preDescription') })} />
                            <label htmlFor="preDescription" className={classNames({ 'p-error': isFormFieldValid('preDescription') })}>
                                {translatedMessage("field.preDescription" )}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('preDescription')}

                    {formik.values.type === 'BOOLEAN' &&
                        <div className="form p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <InputTextarea rows={5} cols={30} id="postDescription" name="postDescription" value={formik.values.postDescription || ''} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('postDescription') })} />
                                <label htmlFor="postDescription" className={classNames({ 'p-error': isFormFieldValid('postDescription') })}>
                                    {translatedMessage("field.postDescription")}*
                                </label>
                            </span>
                        </div>
                    }
                    {getFormErrorMessage('postDescription')}
                </form>
            </Dialog>
        </>
    );
};

export default EditFieldDialog;
