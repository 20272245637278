import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';

const EvaluationDataTableUtils = {

  statusBodyTemplate(_status) {
    return ( _status &&
      <div className={`inline-block status status-evaluation-${_status.toLowerCase()}`}>{translatedMessage("EvaluationStatus." + _status)}</div>
    )
  },

  statusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  },

  committeeTypeTemplate(_committeeType) {
    return <div>{translatedMessage('EvaluationCommitteeType.' + _committeeType)}</div>
  },

  committeeTypeFilterTemplate(options, committeeTypes) {
    return <MultiSelect
      value={options.value}
      options={committeeTypes}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.type')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
    />;
  },

  committeeStatusBodyTemplate(_committeeStatus) {
    return (
      <span className={`status status-${(_committeeStatus ? 'ACTIVE' : 'INACTIVE').toString().toLowerCase()}`}>
        {translatedMessage('committee.' + (_committeeStatus ? 'ACTIVE' : 'INACTIVE'))}
      </span>
    )
  },

  committeeStatusFilterTemplate(options) {
    let committeeStatuses = [
      { label: translatedMessage('committee.ACTIVE'), value: true },
      { label: translatedMessage('committee.INACTIVE'), value: false },
    ]

    return <MultiSelect
      value={options.value}
      options={committeeStatuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.committeeStatusBodyTemplate(e.value)}
    />;
  },

};

export default EvaluationDataTableUtils;