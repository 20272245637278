import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../../service/LanguageService';
import EducationListComponent from '../../../components/profile/EducationListComponent';
import { ApplicationService } from '../../../service/ApplicationService';
import { Message } from 'primereact/message';

import useAuth from '../../../hooks/useAuth';
import FieldEvaluationDropdownComponent from '../../../components/evaluation/FieldEvaluationDropdownComponent';

const ApplicationEducation = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [educationList, setEducationList] = useState(null);
    const [pageTitle, setPageTitle] = useState(translatedMessage("profile.education.title"));
    const [pageSubTitle, setPageSubTitle] = useState(null);
    const [educationRequirement, setEducationRequirement] = useState('');
    const [fieldEvaluation, setFieldEvaluation] = useState(null);

    const toast = useRef(null);
    const { auth } = useAuth();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getEducation = async () => {
            await applicationService.getApplicationEducation(props?.application.id)
                .then(educationRespone => {
                    setEducationList(educationRespone)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        const getEducationRequirement = () => {
            if (props?.formFields && props?.formFields.length > 0) {
                props.formFields.forEach(item => {
                    if (item.formField.field.code === 'studiu') {
                        setPageTitle(item.formField.field.label)
                        setPageSubTitle(item.formField.field.preDescription)
                        setEducationRequirement(item.formField.entityLookupLabel)
                    }
                })
            }
        }

        const getEvaluation = () => {
            if (props?.isForEvaluation && props?.evaluation?.fieldsEvaluation) {
                props?.evaluation?.fieldsEvaluation.forEach(item => {
                    if (item.applicationFormField.formField.field.code === 'studiu') {
                        setFieldEvaluation(item)
                    }
                })
            }
            setIsLoading(false)
        }

        getEducation()
        getEducationRequirement()
        getEvaluation()
    }, [applicationService, props]);

    const onFieldEvaluationChange = (applicationFieldEvaluationId, comment, status) => {
        let _fieldEvaluation = fieldEvaluation
        _fieldEvaluation.status = status
        _fieldEvaluation.comment = comment

        setFieldEvaluation({ ..._fieldEvaluation })
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        let canBeEdited = props?.isCommitteeActive && fieldEvaluation?.evaluation?.evaluationCommitteeUser?.user?.id === auth.user.id && !props?.viewOnly

        return (
            <>
                <Toast ref={toast} />
                <div className='w-full text-align-left mb-0'>
                    <h5 className='mb-1'>{pageTitle}</h5>
                    {pageSubTitle &&
                        <div className='mb-1 font-italic'>{pageSubTitle}</div>
                    }
                    {!props?.hideFillInExpanation &&
                        <>
                            <div>
                                <Message severity="warn" text={translatedMessage("application.education.info") + " " +
                                    translatedMessage("application.education.documents.info")} />
                                {/* <div className='mt-1'>{translatedMessage("application.education.info2")}</div> */}
                            </div>
                            {props?.application?.competition?.positionCategory === 'INALTI_FUNCTIONARI_PUBLICI' &&
                                <div className='mt-1'>
                                    {translatedMessage("application.high.education.documents.title")}
                                    <ul className='my-1'>
                                        <li>{translatedMessage("application.high.education.documents.info1")}</li>
                                        <li>{translatedMessage("application.high.education.documents.info2")}</li>
                                    </ul>
                                </div>
                            }
                        </>
                    }

                    <div className='mt-2'>
                        <span className='font-bold'>{translatedMessage("form.field.requestedValue.description") + " "}</span>
                        {educationRequirement ? educationRequirement : "-"}
                    </div>

                    {props?.isForEvaluation &&
                        <>
                            <h5 className='mb-1'>{translatedMessage("evaluation.evaluation")}</h5>
                            <div className="w-full grid pcn-data-view-panel">
                                <div className='col-12 pb-0 p-fluid'>
                                    <div className="filed-label">{translatedMessage('generic.status')}</div>
                                    <FieldEvaluationDropdownComponent
                                        applicationFieldEvaluation={fieldEvaluation}
                                        afterSave={onFieldEvaluationChange}
                                        statusOptions={props?.statusOptions}
                                        canBeEdited={canBeEdited}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </div>

                <EducationListComponent
                    education={educationList}
                    isForApplication={true}
                    application={props?.application}
                    onListUpdate={(list) => setEducationList(list)}
                    updateFields={props?.updateFields}
                />
            </>
        );
    }
};

export default ApplicationEducation;
