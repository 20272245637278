const CompetitionUtils = {

  isNationalCompetition(_type) {
    return _type === 'NATIONAL_DEBUTANT' || _type === 'NATIONAL_HIGH'
  },


  isJobCompetition(_type) {
    return _type === 'JOB_HIGH' || _type === 'JOB_DEBUTANT'
  },

  isDebutantCompetition(_type) {
    return _type === 'NATIONAL_DEBUTANT' || _type === 'JOB_DEBUTANT'
  }, 
  
  isHighCompetition(_type) {
    return _type === 'NATIONAL_HIGH' || _type === 'JOB_HIGH'
  }, 

};

export default CompetitionUtils;