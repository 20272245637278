import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';

const InstitutionDataTableUtils = {

  statusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-institution-${_status.toLowerCase()}`}>{translatedMessage("InstitutionStatus." + _status)}</div>
    )
  },

  statusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  },

  typeBodyTemplate(_type) {
    return (
      <div>{_type ? _type.label : '-'}</div>
    )
  },

  typeFilterTemplate(options, types) {
    return <MultiSelect
      value={options.value}
      options={types}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.type')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.typeBodyTemplate(e)}
    />
  },

  countyBodyTemplate(_county) {
    return (
      <div>{_county ? _county.label : '-'}</div>
    )
  },

  countyRowFilterTemplate(options, counties) {
    return <MultiSelect
      value={options.value}
      options={counties}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('testCenter.selectCounty')}
      className="p-column-filter"
      maxSelectedLabels={1}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.countyBodyTemplate(e)}
    />;
  }

};

export default InstitutionDataTableUtils;