import React from 'react';
import { Button } from 'primereact/button';
import ApiService from '../../service/ApiService';
import { translatedMessage } from '../../service/LanguageService';

const StorageFileDownloadTableButton = (props) => {

    async function downloadFile(fileUuid, fileName) {
        try {
            const response = await ApiService.getAuthenticatedInstance().get('/storage/file/' + fileUuid, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Button icon="pi pi-download" className="p-button-rounded p-button-info m-1" disabled={!props.fileUuid}
                onClick={() => downloadFile(props.fileUuid, props.fileName)}
                tooltip={translatedMessage("generic.file.download")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
        </>
    );
};

export default StorageFileDownloadTableButton;
