import { translatedMessage } from "../service/LanguageService";
import CompetitionUtils from "./CompetitionUtils";
import DOMPurify from 'dompurify';

const DataTableExportUtils = {

  resultListSortOrderHeader(competition, tests = []) {
    let eligibilityCols = ["fullName", "username", "county", "locality", "uid", "registrationNumber", "applicationStatus",
      "eligibility", "eligibilityRejectionReason", "eligibilityContestation", "eligibilityContestationRejectionReason", "eligibilityFinal"]

    if (competition.type !== 'JOB_DEBUTANT' && competition.type !== 'JOB_HIGH') {
      eligibilityCols.push("preliminaryTest")

      tests.forEach((test, index) => {
        eligibilityCols.push('test' + index)
      })

      eligibilityCols.push('preliminaryTestScore')

      eligibilityCols.push("preliminaryTestContestation")

      tests.forEach((test, index) => {
        eligibilityCols.push('test_contest' + index)
      })

      eligibilityCols.push("preliminaryTestContestationScore")

      eligibilityCols = eligibilityCols.concat(["preliminaryTestContestationRejectionReason", "preliminaryTestFinal",
        "advancedTest", "advancedTestComment", "advancedTestContestation", "advancedTestContestationRejectionReason", "advancedTestFinal",
        "competitionFinal", "competition"
      ])
    } else {
      eligibilityCols = eligibilityCols.concat(
        competition?.supplementaryTest1Name ? ["jobSupplementaryTest1", "jobSupplementaryTest1Contestation", "jobSupplementaryTest1Final"] : []
      ).concat(
        competition?.supplementaryTest2Name ? ["jobSupplementaryTest2", "jobSupplementaryTest2Contestation", "jobSupplementaryTest2Final"] : []
      ).concat(
        competition?.supplementaryTest3Name ? ["jobSupplementaryTest3", "jobSupplementaryTest3Contestation", "jobSupplementaryTest3Final"] : []
      ).concat([
        "jobWrittenTest", "jobWrittenTestScore",
        "jobWrittenTestContestation", "jobWrittenTestContestationScore",
        "jobWrittenTestFinal", "jobWrittenTestFinalScore",
        "jobInterview", "jobInterviewScore",
        "jobInterviewContestation", "jobInterviewContestationScore",
        "jobInterviewFinal", "jobInterviewFinalScore",
        "competitionFinal", "competitionFinalScore",
        "competition"
      ])
    }

    return eligibilityCols
  },

  resultListTableHeader(competition, tests = []) {
    let eligibilityCols = [
      translatedMessage("generic.name"), translatedMessage("generic.email"), translatedMessage("generic.county"), translatedMessage("generic.locality"), translatedMessage("profile.uid"),
      translatedMessage("application.registrationNumber"), translatedMessage("application.status"),
      translatedMessage('ResultType.ELIGIBILITY'), translatedMessage('application.result.rejectionReason'), translatedMessage('ResultType.ELIGIBILITY_CONTESTATION'),
      translatedMessage('application.result.rejectionReason'), translatedMessage('ResultType.ELIGIBILITY_FINAL')]

    if (competition.type !== 'JOB_DEBUTANT' && competition.type !== 'JOB_HIGH') {
      eligibilityCols.push(translatedMessage('ResultType.PRELIMINARY_TEST'))

      tests.forEach((test) => eligibilityCols.push(translatedMessage("preliminaryTest.result.export.title") + " " + test.name))

      eligibilityCols.push(translatedMessage('test.preliminaryTest.overallScore'))

      eligibilityCols.push(translatedMessage('ResultType.PRELIMINARY_TEST_CONTESTATION'))

      tests.forEach((test) => eligibilityCols.push(translatedMessage("preliminaryTest.result.contestation.export.title") + " " + test.name))

      eligibilityCols.push(translatedMessage('test.preliminaryTestContestation.overallScore'))

      eligibilityCols = eligibilityCols.concat([
        translatedMessage('application.result.rejectionReason'), translatedMessage('ResultType.PRELIMINARY_TEST_FINAL'),
        translatedMessage('ResultType.ADVANCED_TEST'), translatedMessage('application.result.comment'), translatedMessage('ResultType.ADVANCED_TEST_CONTESTATION'),
        translatedMessage('application.result.rejectionReason'), translatedMessage('ResultType.ADVANCED_TEST_FINAL'),
        translatedMessage('ResultType.COMPETITION_FINAL'),
        translatedMessage("competition.competition")
      ])
    } else {
      eligibilityCols = eligibilityCols.concat(
        competition?.supplementaryTest1Name ? [translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1_CONTESTATION'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1_FINAL')] : []
      ).concat(
        competition?.supplementaryTest2Name ? [translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2_CONTESTATION'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2_FINAL')] : []
      ).concat(
        competition?.supplementaryTest3Name ? [translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3_CONTESTATION'), translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3_FINAL')] : []
      ).concat([
        translatedMessage('ResultType.JOB_WRITTEN_TEST'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.JOB_WRITTEN_TEST_CONTESTATION'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.JOB_WRITTEN_TEST_FINAL'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.JOB_INTERVIEW'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.JOB_INTERVIEW_CONTESTATION'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.JOB_INTERVIEW_FINAL'), translatedMessage('application.result.score'),
        translatedMessage('ResultType.COMPETITION_FINAL'), translatedMessage('application.result.score'),
        translatedMessage("competition.competition")
      ])
    }

    return eligibilityCols
  },

  computeResultExportData(exportData, competition, tests = []) {
    exportData.map(item => {
      let exportItem = item
      exportItem.eligibility = item.eligibility ? translatedMessage("Result." + item.eligibility) : ""
      exportItem.eligibilityContestation = item.eligibilityContestation ? translatedMessage("Result." + item.eligibilityContestation) : ""
      exportItem.eligibilityFinal = item.eligibilityFinal ? translatedMessage("Result." + item.eligibilityFinal) : ""
      exportItem.preliminaryTest = item.preliminaryTest ? translatedMessage("Result." + item.preliminaryTest) : ""
      exportItem.preliminaryTestContestation = item.preliminaryTestContestation ? translatedMessage("Result." + item.preliminaryTestContestation) : ""
      exportItem.preliminaryTestFinal = item.preliminaryTestFinal ? translatedMessage("Result." + item.preliminaryTestFinal) : ""
      exportItem.advancedTest = item.advancedTest ? translatedMessage("Result." + item.advancedTest) : ""
      exportItem.advancedTestContestation = item.advancedTestContestation ? translatedMessage("Result." + item.advancedTestContestation) : ""
      exportItem.advancedTestFinal = item.advancedTestFinal ? translatedMessage("Result." + item.advancedTestFinal) : ""
      exportItem.jobSupplementaryTest1 = item.jobSupplementaryTest1 ? translatedMessage("Result." + item.jobSupplementaryTest1) : ""
      exportItem.jobSupplementaryTest1Contestation = item.jobSupplementaryTest1Contestation ? translatedMessage("Result." + item.jobSupplementaryTest1Contestation) : ""
      exportItem.jobSupplementaryTest1Final = item.jobSupplementaryTest1Final ? translatedMessage("Result." + item.jobSupplementaryTest1Final) : ""
      exportItem.jobSupplementaryTest2 = item.jobSupplementaryTest2 ? translatedMessage("Result." + item.jobSupplementaryTest2) : ""
      exportItem.jobSupplementaryTest2Contestation = item.jobSupplementaryTest2Contestation ? translatedMessage("Result." + item.jobSupplementaryTest2Contestation) : ""
      exportItem.jobSupplementaryTest2Final = item.jobSupplementaryTest2Final ? translatedMessage("Result." + item.jobSupplementaryTest2Final) : ""
      exportItem.jobSupplementaryTest3 = item.jobSupplementaryTest3 ? translatedMessage("Result." + item.jobSupplementaryTest3) : ""
      exportItem.jobSupplementaryTest3Contestation = item.jobSupplementaryTest3Contestation ? translatedMessage("Result." + item.jobSupplementaryTest3Contestation) : ""
      exportItem.jobSupplementaryTest3Final = item.jobSupplementaryTest3Final ? translatedMessage("Result." + item.jobSupplementaryTest3Final) : ""
      exportItem.jobWrittenTest = item.jobWrittenTest ? translatedMessage("Result." + item.jobWrittenTest) : ""
      exportItem.jobWrittenTestScore = item.jobWrittenTestScore
      exportItem.jobWrittenTestContestation = item.jobWrittenTestContestation ? translatedMessage("Result." + item.jobWrittenTestContestation) : ""
      exportItem.jobWrittenTestContestationScore = item.jobWrittenTestContestationScore
      exportItem.jobWrittenTestFinal = item.jobWrittenTestFinal ? translatedMessage("Result." + item.jobWrittenTestFinal) : ""
      exportItem.jobWrittenTestFinalScore = item.jobWrittenTestFinalScore
      exportItem.jobInterview = item.jobInterview ? translatedMessage("Result." + item.jobInterview) : ""
      exportItem.jobInterviewScore = item.jobInterviewScore
      exportItem.jobInterviewContestation = item.jobInterviewContestation ? translatedMessage("Result." + item.jobInterviewContestation) : ""
      exportItem.jobInterviewContestationScore = item.jobInterviewContestationScore
      exportItem.jobInterviewFinal = item.jobInterviewFinal ? translatedMessage("Result." + item.jobInterviewFinal) : ""
      exportItem.jobInterviewFinalScore = item.jobInterviewFinalScore
      exportItem.competitionFinal = item.competitionFinal ? translatedMessage("Result." + item.competitionFinal) : ""
      exportItem.competitionFinalScore = item.competitionFinalScore
      exportItem.applicationStatus = item.applicationStatus ? translatedMessage("ApplicationStatus." + item.applicationStatus) : ""
      exportItem.competition = competition.name

      exportItem.eligibilityRejectionReason = DOMPurify.sanitize(item.eligibilityRejectionReason, { ALLOWED_TAGS: [] })
      exportItem.eligibilityContestationRejectionReason = DOMPurify.sanitize(item.eligibilityContestationRejectionReason, { ALLOWED_TAGS: [] })
      exportItem.preliminaryTestContestationRejectionReason = DOMPurify.sanitize(item.preliminaryTestContestationRejectionReason, { ALLOWED_TAGS: [] })
      exportItem.advancedTestComment = DOMPurify.sanitize(item.advancedTestComment, { ALLOWED_TAGS: [] })
      exportItem.advancedTestContestationRejectionReason = DOMPurify.sanitize(item.advancedTestContestationRejectionReason, { ALLOWED_TAGS: [] })

      delete exportItem.applicationId
      if (CompetitionUtils.isJobCompetition(competition?.type)) {
        delete exportItem.preliminaryTest
        delete exportItem.preliminaryTestContestation
        delete exportItem.preliminaryTestFinal
        delete exportItem.advancedTest
        delete exportItem.advancedTestContestation
        delete exportItem.advancedTestFinal
        delete exportItem.eligibilityRejectionReason
        delete exportItem.eligibilityContestationRejectionReason
        delete exportItem.preliminaryTestContestationRejectionReason
        delete exportItem.advancedTestComment
        delete exportItem.advancedTestContestationRejectionReason
        if (!competition?.supplementaryTest1Name) {
          delete exportItem.jobSupplementaryTest1
          delete exportItem.jobSupplementaryTest1Contestation
          delete exportItem.jobSupplementaryTest1Final
        }
        if (!competition?.supplementaryTest2Name) {
          delete exportItem.jobSupplementaryTest2
          delete exportItem.jobSupplementaryTest2Contestation
          delete exportItem.jobSupplementaryTest2Final
        }
        if (!competition?.supplementaryTest3Name) {
          delete exportItem.jobSupplementaryTest3
          delete exportItem.jobSupplementaryTest3Contestation
          delete exportItem.jobSupplementaryTest3Final
        }
      } else {
        tests.forEach((test, index) => {
          let _testResult = item.preliminaryTestResults.filter(t => t.testId === test.id)[0]

          let _result = _testResult?.result
          let _resultContest = _testResult?.resultAfterContestation

          if (_testResult?.testType === "QUIZ") {
            _result = _testResult?.quizResult ? translatedMessage("Result." + _testResult?.quizResult) : null
            _resultContest = _testResult?.quizResultAfterContestation ? translatedMessage("Result." + _testResult?.quizResultAfterContestation) : null
          }

          exportItem['test' + index] = _result !== null ? _result : null
          exportItem['test_contest' + index] = _resultContest !== null ? _resultContest : null
        })

        delete exportItem.jobSupplementaryTest1
        delete exportItem.jobSupplementaryTest1Contestation
        delete exportItem.jobSupplementaryTest1Final
        delete exportItem.jobSupplementaryTest2
        delete exportItem.jobSupplementaryTest2Contestation
        delete exportItem.jobSupplementaryTest2Final
        delete exportItem.jobSupplementaryTest3
        delete exportItem.jobSupplementaryTest3Contestation
        delete exportItem.jobSupplementaryTest3Final
        delete exportItem.jobWrittenTest
        delete exportItem.jobWrittenTestScore
        delete exportItem.jobWrittenTestContestation
        delete exportItem.jobWrittenTestContestationScore
        delete exportItem.jobWrittenTestFinal
        delete exportItem.jobWrittenTestFinalScore
        delete exportItem.jobInterview
        delete exportItem.jobInterviewScore
        delete exportItem.jobInterviewContestation
        delete exportItem.jobInterviewContestationScore
        delete exportItem.jobInterviewFinal
        delete exportItem.jobInterviewFinalScore
        delete exportItem.competitionFinalScore
      }

      return exportItem;
    })

    return exportData;
  },

  testSessionListSortOrderHeader() {
    return [
      "region", "county", "city", "center", "room", "capacity", "usedCapacity", "startDate", "endDate"
    ]
  },

  testSessionListTableHeader() {
    return [
      translatedMessage("generic.region"),
      translatedMessage("generic.county"),
      translatedMessage("generic.city"),
      translatedMessage("testCenter.testCenter"),
      translatedMessage('competition.testCenterRoom.name'),
      translatedMessage('testCenter.room.capacity'),
      translatedMessage('testCenter.room.capacity.used'),
      translatedMessage('generic.startDate'),
      translatedMessage('generic.endDate'),
    ]
  },

  userTestSessionListSortOrderHeader() {
    return [
      "firstName", "lastName", "county", "city", "center", "room", "capacity", "startDate", "endDate"
    ]
  },

  userTestSessionListTableHeader() {
    return [
      translatedMessage("generic.firstName"),
      translatedMessage("generic.lastName"),
      translatedMessage("generic.county"),
      translatedMessage("generic.city"),
      translatedMessage("testCenter.testCenter"),
      translatedMessage('competition.testCenterRoom.name'),
      translatedMessage('testCenter.room.capacity'),
      translatedMessage('generic.startDate'),
      translatedMessage('generic.endDate'),
    ]
  },

  sessionUserListSortOrderHeader() {
    return [
      "user", "presence", "center", "room", "startDate", "endDate", "city", "county", "region"
    ]
  },

  sessionUserListTableHeader() {
    return [
      translatedMessage("generic.name"),
      translatedMessage("testSession.presence"),
      translatedMessage("testCenter.testCenter"),
      translatedMessage('competition.testCenterRoom.name'),
      translatedMessage('generic.startDate'),
      translatedMessage('generic.endDate'),
      translatedMessage("generic.city"),
      translatedMessage("generic.county"),
      translatedMessage("generic.region")
    ]
  },

  competitionListSortOrderHeader() {
    return [
      "name", "status", "positionCategory", "positionGrade", "positionType", "institution", "startDate", "submitEndDate", "clarificationInterval", "contestationInterval",
      "overallTestsPassingScore", "writtenTestDate", "interviewTestDate", 
      "supplementaryTest1Name", "supplementaryTest1Date", "supplementaryTest2Name", "supplementaryTest2Date", "supplementaryTest3Name", "supplementaryTest3Date"
    ]
  },

  competitionListTableHeader() {
    return [
      translatedMessage('competition.competition'),
      translatedMessage('generic.status'),
      translatedMessage('competition.positionCategory'),
      translatedMessage('competition.positionGrade'),
      translatedMessage('competition.positionType'),
      translatedMessage('competition.institution'),
      translatedMessage('competition.startDate'),
      translatedMessage('competition.submitEndDate'),
      translatedMessage('competition.clarificationInterval'),
      translatedMessage('competition.contestationInterval'),
      translatedMessage('competition.overallTestsPassingScore'),
      translatedMessage('competition.writtenTestDate'),
      translatedMessage('competition.interviewTestDate'),
      translatedMessage('competition.supplementaryTest1Name'),
      translatedMessage('competition.supplementaryTest1Date'),
      translatedMessage('competition.supplementaryTest2Name'),
      translatedMessage('competition.supplementaryTest2Date'),
      translatedMessage('competition.supplementaryTest3Name'),
      translatedMessage('competition.supplementaryTest3Date')
    ]
  },

  applicationEvaluationListSortOrderHeader(_maxComments = 0) {
    let cols = ["fullName", "username", "uid", "registrationNumber", "applicationStatus", "evaluationStatus", "rejectionReason"]

    if (_maxComments === 0) {
      cols.push("evaluationComment")
    } else {
      for (let i = 0; i < _maxComments; i++) {
        cols.push("evaluationComment_" + i)
      }
    }

    cols = cols.concat(["evaluationCommitteeName", "evaluationCommitteeType", "accessibility", "competition", "competitionStatus"])

    return cols
  },

  applicationEvaluationListTableHeader(_maxComments = 0) {
    let cols = [translatedMessage("generic.name"), translatedMessage("generic.email"), translatedMessage("profile.uid"), translatedMessage("application.registrationNumber"),
    translatedMessage("generic.status"), translatedMessage("evaluation.myEvaluationStatus"), translatedMessage("application.result.rejectionReason")]

    if (_maxComments === 0) {
      cols.push(translatedMessage("evaluation.comment"))
    } else {
      for (let i = 1; i <= _maxComments; i++) {
        cols.push(translatedMessage("evaluation.comment") + " " + i)
      }
    }

    cols = cols.concat([translatedMessage("committee.committee"), translatedMessage("committee.type"), translatedMessage("profile.accessibility"),
    translatedMessage("competition.competition"), translatedMessage("competition.status"),])

    return cols
  }

};

export default DataTableExportUtils;