import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';
import GeneralUtils from '../../utilities/GeneralUtils';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import ExportButtonComponent from '../../components/ExportButtonComponent';

const sortOrderHeader = [
    "fullName",
    "requestSent",
    "responseSent",
    "competition"
]

const tableHeader = [
    translatedMessage("application.candidate"),
    translatedMessage("application.clarification.request.status"),
    translatedMessage("application.clarification.response.status"),
    translatedMessage("competition.competition")
]

const CompetitionClarificationList = () => {
    const [competition, setCompetition] = useState([]);
    const [clarifications, setClarifications] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('generic.error.get.data');

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    const { competitionIdParam } = useParams();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            competitionId: { value: competitionIdParam, matchMode: FilterMatchMode.EQUALS },
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            requestSent: { value: null, matchMode: FilterMatchMode.EQUALS },
            responseSent: { value: null, matchMode: FilterMatchMode.EQUALS },
            requestDetail: { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    });

    const propToColumnMap = {
        fullName: "first_name || ' ' || last_name",
        requestSent: 'request_sent',
        responseSent: 'response_sent',
        requestDetail: 'request_detail'
    };

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam).then((response) => {
                setCompetition(response);
            });
        };

        getCompetition();
    }, [competitionService, competitionIdParam]);

    useEffect(() => {
        lazyLoadClarifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const lazyLoadClarifications = () => {
        setTableLoading(true);
        loadClarifications();
    };

    const setCriteria = () => {
        let criteria = {};        
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'application_id';
        criteria.competitionId = competitionIdParam;
        criteria.fullName = lazyParams.filters.fullName.value;
        criteria.requestSent = lazyParams.filters.requestSent.value;
        criteria.responseSent = lazyParams.filters.responseSent.value;
        criteria.requestDetail = lazyParams.filters.requestDetail.value;

        return criteria
    }

    const loadClarifications = async () => {
        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService.getClarifications(criteria)
            .then((data) => {
                setClarifications(data.items);
                setTotalRecords(data.totalCount);
                setTableLoading(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setError(true);
                setErrorMessage(error);
                setIsLoading(false);
                // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const viewApplication = (rowData) => {
        navigate(`/competition-evaluation/${competition.id}/application-clarification/${rowData.applicationId}`);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    const leftToolbarTemplate = () => {
        return <></>;
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            competitionService.getClarifications(criteria)
                .then((data) => {
                    let exportData = data.items.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.requestSent = item.requestSent ? translatedMessage('application.request.sent') : translatedMessage('application.request.not.sent')
                        exportItem.responseSent = item.responseSent ? translatedMessage('application.request.sent') : translatedMessage('application.request.not.sent')
                        exportItem.competition = competition.name
                        delete exportItem.applicationId
                        delete exportItem.competitionId
                        delete exportItem.firstName
                        delete exportItem.lastName
                        delete exportItem.requestDetail
                        delete exportItem.requestType
                        delete exportItem.responseDetail
                        delete exportItem.responseType
                        delete exportItem.userId

                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }
    
    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage('application.clarification.requests') + "_" + competition?.name)}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-primary m-1"
                    onClick={() => viewApplication(rowData)}
                    visible={hasPermission('EVALUATION_VIEW')}
                    tooltip={translatedMessage('application.view')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                />
            </div>
        );
    };

    const clarificationDetailBodyTemplate = (rowData) => {
        let cleanContent = GeneralUtils.getHTMLSubstringWithEllipsis(rowData.requestDetail, 200);
        return <span dangerouslySetInnerHTML={{ __html: cleanContent }} />;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition?.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation`)}>
                                    <div className="flex align-items-center">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="mb-4">
                                <h5 className="mb-1 font-weight-normal">{translatedMessage('application.clarification.requests')}</h5>
                                <div className="flex align-items-center">
                                    <div className="font-bold mr-1">{translatedMessage('competition.status') + ':'}</div>
                                    <div className={`inline-block status status-competition-${competition?.status.toString().toLowerCase()}`}>{translatedMessage('CompetitionStatus.' + competition?.status)}</div>
                                </div>
                            </div>
                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={clarifications}
                                dataKey="id"
                                loading={tableLoading}
                                lazy
                                paginator
                                totalRecords={totalRecords}
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                sortField={lazyParams.sortField}
                                sortOrder={lazyParams.sortOrder}
                                onPage={onPage}
                                onSort={onSort}
                                onFilter={onFilter}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column field="fullName" header={translatedMessage('generic.name')} sortable headerStyle={{ width: '25%', minWidth: '10rem' }} filter showFilterMenu={false} />
                                <Column
                                    field="requestSent"
                                    header={translatedMessage('application.clarification.request.status')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    body={(e) => CompetitionDataTableUtils.clarificationRequestSentBodyTemplate(e.requestSent)}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.clarificationRequestSentFilterTemplate(e)}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="responseSent"
                                    header={translatedMessage('application.clarification.response.status')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    body={(e) => CompetitionDataTableUtils.clarificationResponseSentBodyTemplate(e.responseSent)}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.clarificationResponseSentFilterTemplate(e)}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="requestDetail"
                                    header={translatedMessage('application.clarification.request')}
                                    sortable
                                    headerStyle={{ width: '45%', minWidth: '10rem' }}
                                    body={clarificationDetailBodyTemplate}
                                    filter
                                    showFilterMenu={false}
                                />

                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default CompetitionClarificationList;
