import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../hooks/useAuth';
import { EvaluationService } from '../../service/EvaluationService';
import useNavProps from '../../hooks/useNavProps';
import EnumService from '../../service/EnumService';
import EvaluationDataTableUtils from '../../utilities/EvaluationDataTableUtils';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import GeneralUtils from '../../utilities/GeneralUtils';

const sortOrderHeader = [
        "evaluatorName", 
        "evaluatorRole", 
        "status", 
        "comment",
        "evaluationCommitteeName", 
        "evaluationCommitteeType", 
        "aplicantName",        
        "applicationStatus",
        "competition", 
        "competitionStatus"]

const tableHeader = [
    translatedMessage("evaluation.evaluator"),
    translatedMessage("committee.member.role"),
    translatedMessage("evaluation.evaluationStatus"),
    translatedMessage("evaluation.comment"),
    translatedMessage("committee.committee"),
    translatedMessage("committee.type"),
    translatedMessage("application.candidate"),
    translatedMessage("application.status"),
    translatedMessage("competition.competition"),
    translatedMessage("competition.status"),    
]

const EvaluationListForAplication = () => {
    const [evaluations, setEvaluations] = useState([]);
    const [application, setApplication] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [evaluationStatuses, setEvaluationStatuses] = useState([]);
    const [committeeTypes, setCommitteeTypes] = useState([]);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");

    const [filters] = useState({
        'evaluationCommitteeUser.user.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'evaluationCommitteeUser.evaluationCommittee.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.IN },
        'evaluationCommitteeUser.evaluationCommittee.type': { value: null, matchMode: FilterMatchMode.IN },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    const { setNavPropsValue } = useNavProps();

    const evaluationService = useMemo(() => new EvaluationService(), []);

    const { competitionIdParam, applicationIdParam } = useParams();

    useEffect(() => {
        const getEvaluationStatuses = async () => {
            await EnumService.getEnumByName('EvaluationStatus').then((statuses) => setEvaluationStatuses(statuses));
        };

        const getCommitteeTypes = async (competitionId) => {
            await EnumService.getEnumByNameAndParam('EvaluationCommitteeType', competitionId).then((types) => setCommitteeTypes(types));
        };        

        const getEvaluations = async () => {
            await evaluationService.getEvaluationsByApplication(applicationIdParam)
                .then((response) => {
                    setEvaluations(response);
                    if (response.length > 0) {
                        getCommitteeTypes(response[0].application?.competition?.id)
                        setApplication(response[0].application)
                        getEvaluationStatuses()                        
                        setNavPropsValue('evaluations', response[0].application.id, response[0].application.firstName + " " + response[0].application.lastName, true, true)
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        getEvaluations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluationService, applicationIdParam]);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const viewEvaluation = (rowData) => {
        if (rowData.evaluationCommitteeUser?.evaluationCommittee?.type === "PRELIMINARY_TEST_CONTESTATION" || 
            rowData.evaluationCommitteeUser?.evaluationCommittee?.type === "ADVANCED_TEST" || 
            rowData.evaluationCommitteeUser?.evaluationCommittee?.type === "ADVANCED_TEST_CONTESTATION") {            

            navigate(`/competition-evaluation/${competitionIdParam}/evaluations/${application?.id}/evaluation-testing-view/${rowData.id}`);
        } else {
            navigate(`/competition-evaluation/${competitionIdParam}/evaluations/${application?.id}/evaluation-view/${rowData.id}`);
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <></>
        );
    };

    const committeeMemberRoleTemplate = (evaluator) => {
        return (
            evaluator.isPresident 
                ? translatedMessage('committee.member.isPresident') 
                : evaluator.isSecretary ? translatedMessage('committee.member.isSecretary') : translatedMessage('committee.member.isSimpleMember'));
    }

    const handleExport = () => {
        let exportData = evaluations.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item       
            exportItem.evaluatorName = item.evaluationCommitteeUser?.user?.fullName
            exportItem.evaluatorRole = committeeMemberRoleTemplate(item.evaluationCommitteeUser)
            exportItem.status = item.status ? translatedMessage("EvaluationStatus." + item.status) : ""
            exportItem.evaluationCommitteeName = item.evaluationCommitteeUser?.evaluationCommittee?.name
            exportItem.evaluationCommitteeType = item.evaluationCommitteeUser?.evaluationCommittee?.type 
                ? translatedMessage("EvaluationCommitteeType." + item.evaluationCommitteeUser?.evaluationCommittee?.type) : ""
            exportItem.aplicantName = item.application?.fullName
            exportItem.applicationStatus = item.application?.status ? translatedMessage("ApplicationStatus." + item.application?.status) : ""            
            exportItem.competitionStatus = application?.competition?.status ? translatedMessage("CompetitionStatus." + application?.competition?.status) : ""
            exportItem.competition = application?.competition?.name
            delete exportItem.application
            delete exportItem.evaluationCommitteeUser

            return item;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage('committee.evaluations') + "_" + application?.firstName + "_" + application?.lastName)}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1"
                    onClick={() => viewEvaluation(rowData)} visible={hasPermission('EVALUATION_VIEW')}
                    tooltip={translatedMessage("evaluation.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
            </div >
        );
    };   
    
    const committeeTypeTemplate = (_committeeType) => {
        return <div>{committeeTypes?.find((type) => type.value === _committeeType)?.label}</div>;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-0">
                                {translatedMessage('committee.evaluations')} {translatedMessage('generic.for')} {application?.firstName} {application?.lastName}
                            </h3>
                            <div className="mb-1">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation/${competitionIdParam}`)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>
                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={evaluations}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['evaluationCommitteeUser.user.fullName', 'status', 'evaluationCommitteeUser.evaluationCommittee.name', 'evaluationCommitteeUser.evaluationCommittee.type']}
                            >
                                <Column
                                    field="evaluationCommitteeUser.user.fullName"
                                    header={translatedMessage('evaluation.evaluator')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    header={translatedMessage('evaluation.evaluationStatus')}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '10rem' }}
                                    body={(e) => EvaluationDataTableUtils.statusBodyTemplate(e.status)} 
                                    filter
                                    filterElement={(e) => EvaluationDataTableUtils.statusFilterTemplate(e, evaluationStatuses)}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="evaluationCommitteeUser.evaluationCommittee.name"
                                    header={translatedMessage('committee.committee')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="evaluationCommitteeUser.evaluationCommittee.type"
                                    header={translatedMessage('generic.type')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                    body={(e) => committeeTypeTemplate(e.evaluationCommitteeUser.evaluationCommittee.type)} 
                                    filter
                                    filterElement={(e) => EvaluationDataTableUtils.committeeTypeFilterTemplate(e, committeeTypes)}
                                    showFilterMenu={false}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationListForAplication;
