import ApiService from "./ApiService";
import GeneralUtils from "../utilities/GeneralUtils";

let currentUser = null;

export class UserService {

    async getUsersOld(criteria) {
        return ApiService.getAuthenticatedInstance().post('/users', criteria)
            .then((res) => {
                return res.data
            });
    }

    async getUsers(criteria) {
        return new Promise((resolve, reject) => {
            return ApiService.getAuthenticatedInstance()
                .post('/users', criteria)
                .then((response) => {
                    response.data.items.map((item) => {
                        item.profileJobTitle = item.profileJobTitle ? item.profileJobTitle : '';
                        item.profileInstitutionName = item.profileInstitutionName ? item.profileInstitutionName : '';
                        return item;
                    });
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    console.log(error)
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async registerUserByAdmin(user) {
        return ApiService.getAuthenticatedInstance().post('/user/register/admin',
            { username: user.username, firstName: user.firstName, lastName: user.lastName })
            .then((res) => res.data);
    }

    async updateUser(user) {
        return ApiService.getAuthenticatedInstance().patch('/user/' + user.id,
            { 
                username: user.username, 
                firstName: user.firstName, 
                lastName: user.lastName, 
                profileInstitutionId: user.profileInstitutionId, 
                profileJobTitle: user.profileJobTitle,
                accountLockedTemporary: user.accountLockedTemporary
            })
            .then((res) => res.data);
    }

    async searchUser(searchText) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/users/search/${searchText}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveProfile(user) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/save', {
                firstName: user.firstName,
                lastName: user.lastName,
                pin: user.pin,
                idCardType: user.idCardType,
                idCardSeries: user.idCardSeries,
                idCardNumber: user.idCardNumber,
                phone: user.phone,
                language: user.language,
                address: user.address,
                localityId: user.localityId,
                countyId: user.countyId,
                acceptMarketing: user.acceptMarketing,
                acceptNotification: user.acceptNotification,
                //acceptNotificationJobHigh: user.acceptNotificationJobHigh,
                //acceptNotificationJobDebutant: user.acceptNotificationJobDebutant,
                acceptNotificationJobExecutivePublicFunctions: user.acceptNotificationJobExecutivePublicFunctions,
                acceptNotificationJobPublicManagementPositions: user.acceptNotificationJobPublicManagementPositions,
                acceptNotificationJobHighCivilServants: user.acceptNotificationJobHighCivilServants,
                publicServant: user.publicServant
            })
                .then(userResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(userResponse.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    } 
    
    async saveProfileSettings(user) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/save-settings', {
                firstName: user.firstName,
                lastName: user.lastName,                
                language: user.language,
                acceptMarketing: user.acceptMarketing,
                acceptNotification: user.acceptNotification,
                //acceptNotificationJobHigh: user.acceptNotificationJobHigh,
                //acceptNotificationJobDebutant: user.acceptNotificationJobDebutant,
                acceptNotificationJobExecutivePublicFunctions: user.acceptNotificationJobExecutivePublicFunctions,
                acceptNotificationJobPublicManagementPositions: user.acceptNotificationJobPublicManagementPositions,
                acceptNotificationJobHighCivilServants: user.acceptNotificationJobHighCivilServants,
                institutions: user.institutions ? user.institutions : []
            })
                .then(userResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(userResponse.data))
                })
                .catch(error => {
                    let errorMessage = "profile.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }     

    async changePassword(passwordData) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/user/change-password', passwordData)
                .then(() => {
                    resolve() 
                })
                .catch(error => {
                    let errorMessage = "error.generic_500";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async initCurrentUser() {
        if (!getCurrentUser()) {
            let response = await ApiService.getAuthenticatedInstance().get('/profile/my');
            // console.log("my profile: ", response.data)

            //replace null fields with empty
            const userProfile = GeneralUtils.emptyInNullForObjectFields(response.data);

            setCurrentUser(userProfile);
            return userProfile;
        } else {
            return getCurrentUser();
        }
    }

    async getEducation() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/education')
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveEducation(education) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/education/save', education)
                .then(educationResponse => {
                    educationResponse.data.startDate = new Date(Date.parse(educationResponse.data.startDate));
                    educationResponse.data.endDate = educationResponse.data.endDate ? new Date(Date.parse(educationResponse.data.endDate)) : '';                    
                    resolve(GeneralUtils.emptyInNullForObjectFields(educationResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteEducation(educationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/education/delete/${educationId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async getWorkExperience() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/experience')
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveWorkExperience(experience) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/experience/save', experience)
                .then(experienceResponse => {
                    experienceResponse.data.startDate = new Date(Date.parse(experienceResponse.data.startDate));
                    experienceResponse.data.endDate = experienceResponse.data.endDate ? new Date(Date.parse(experienceResponse.data.endDate)) : '';                    
                    resolve(GeneralUtils.emptyInNullForObjectFields(experienceResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteWorkExperience(experienceId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/experience/delete/${experienceId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    } 
    
    async getLanguageSkill() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/language')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveLanguageSkill(language) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/profile/my/language/save', language)
                .then(languageResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(languageResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteLanguageSkill(languageId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/language/delete/${languageId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    
    
    async getItKnowledge() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/it-knowledge')
                .then(response => resolve(response.data ? response.data : '')  )
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async saveItKnowledge(itKnowledge) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/it-knowledge/save`, {"itKnowledge": itKnowledge})
                .then(response => resolve(response.data ? response.data : '') )
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getAccessibility() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/profile/my/accessibility')
                .then(response => resolve(response.data))
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async saveAccessibility(accessibility) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/profile/my/accessibility/save`, accessibility)
                .then(response => resolve(response.data) )
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async getInstitutions() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/institutions')
                .then(response => { resolve(response.data) })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getInstitutionsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/institution/list`, criteria)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    checkUserForCompetitionRegister(user) {
        let canRegister = true
        
        if(!user?.pin || !user?.idCardType || !user?.idCardNumber || !user?.idCardSeries || !user?.phone || !user?.countyId || !user?.localityId || !user?.address) {
            canRegister = false
        }

        return canRegister
    }

    async getUserProfile(userId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/user/${userId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getUserProfileEducation(userId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/user/${userId}/education`)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getUserProfileWorkExperience(userId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/user/${userId}/experience`)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getUserProfileItKnowledge(userId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/user/${userId}/it-knowledge`)
                .then(response => resolve(response.data ? response.data : '')  )
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getUserProfileLanguageSkill(userId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/profile/user/${userId}/language`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}

export function getCurrentUser() { return currentUser }

export function setCurrentUser(user) { currentUser = user }

export function hasPermission(permissionName) {
    if (currentUser && currentUser.permissions) {
        return currentUser.permissions.indexOf(permissionName) > -1;
    } else {
        return false;
    }
};

export function hasRole(roleName) {
    if (currentUser && currentUser.roles) {
        return currentUser.roles.indexOf(roleName) > -1;
    } else {
        return false;
    }
};