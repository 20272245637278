import axios from 'axios'
import { Base64 } from 'js-base64';
import GeneralUtils from '../utilities/GeneralUtils';

let token;
let authenticatedInstance = null;

// DEV API
//const baseURL = 'http://localhost:8080/rest';

// STAGE 2 API
//const baseURL = 'https://platforma-concurs.anfp.gov.ro:8443/rest';

// STAGE v3 & v4 API
//const baseURL = 'https://platforma-concurs-national.anfp.gov.ro:8443/rest';

// PRODUCTION v2 API
//const baseURL = 'https://platforma-concurs.anfp.gov.ro/rest';

// PRODUCTION v3 & v4 API
const baseURL = 'https://platforma-concurs-national.anfp.gov.ro/rest';

// DEV CAPTCHA
//const captchaKey = '6LfgwaMZAAAAABb8GbDskgQAOZat24YkMZyc1yVr';
// STAGE & PRODUCTION CAPTCHA
const captchaKey = '6LdFEAslAAAAAOSg5mOe4-Tn7TCF2uBPTsQJ6dGQ';

// DEV & STAGE MoveCompetitionStatusForward
//const isProduction = false
// PRODUCTION MoveCompetitionStatusForward
const isProduction = true

// DEV/STAGE: false | PROD: true
const isRegisterEnabled = true

const dateTransformer = data => {
  if (data instanceof Date) {
    return GeneralUtils.formatDateTimeForServer(data)
  }
  if (Array.isArray(data)) {
    return data.map(val => dateTransformer(val))
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]))
  }
  return data
}

const ApiService = {
  getAuthenticatedInstance: () => authenticatedInstance,

  instance: axios.create({ baseURL }),

  loginInstance: (username, password) => axios.create({
    baseURL,
    transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
    headers: {
      "Authorization": `Basic ` + Base64.encode(username + ":" + password)
    },
  }),

  async initToken() {
    token = localStorage.getItem('_t');
    authenticatedInstance = axios.create({
      baseURL,
      transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
    return !!token;
  },

  async persistToken(newToken) {
    token = newToken;
    localStorage.setItem('_t', token);
    authenticatedInstance = axios.create({
      baseURL,
      transformRequest: [dateTransformer].concat(axios.defaults.transformRequest),
      headers: {
        "Authorization": `Bearer ${token}`
      },
    });
  },

  getToken: () => token,

  getBaseUrl: () => baseURL,

  getRedirectUrl: () => {
    return localStorage.getItem('_pcn_redirect');
  },

  setRedirectUrl: (path) => {
    if (typeof path !== 'undefined' && path !== null && path.length > 0 && path !== '/') {
      localStorage.setItem('_pcn_redirect', path);
    }
  },

  getSafe(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  },

  getCaptchaSiteKey() {
    return captchaKey
  },

  getIsProduction() {
    return isProduction
  },

  getIsRegisterEnabled() {
    return isRegisterEnabled
  },

};

export default ApiService;
