import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import { useNavigate, useParams } from 'react-router-dom';

import CommitteeListComponent from '../../components/committee/CommitteeListComponent';
import useNavProps from '../../hooks/useNavProps';

const EvaluationCommitteeList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [competition, setCompetition] = useState(null);

    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();
    const toast = useRef(null);
    const { competitionIdParam } = useParams();
    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setIsLoading(true);

        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then((competitionData) => {
                    setCompetition(competitionData);
                    setIsLoading(false);

                    setNavPropsValue('competition-evaluation', competitionData.id, competitionData.name)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionService, competitionIdParam]);


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition?.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation`)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <CommitteeListComponent competition={competition} viewCommitteeLink={`/competition-evaluation/${competition.id}/committees`} canEdit={false}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationCommitteeList;
