import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useNavProps from '../../hooks/useNavProps';
import ApplicationEvaluationList from './application/ApplicationEvaluationList';
import { CompetitionService } from '../../service/CompetitionService';

const EvaluationCompetitionPage = () => {
    const [competition, setCompetition] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    const { competitionIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        
        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then((competitionData) => {
                    setCompetition(competitionData)
                    setIsLoading(false)

                    setNavPropsValue('competition-evaluation', competitionData.id, competitionData.name)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };


        getCompetition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionService, competitionIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition.name}</h3>
                            <div className="mb-3">
                            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation`)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>                                
                            </div>                            

                            <ApplicationEvaluationList competition={competition} />

                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationCompetitionPage;
