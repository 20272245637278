import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';
import { CompetitionService } from '../../service/CompetitionService';
import { ApplicationService } from '../../service/ApplicationService';
import GeneralUtils from '../../utilities/GeneralUtils';
import useAuth from '../../hooks/useAuth';
import { UserService } from '../../service/UserService';
import ErrorDialog from '../ErrorDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';

const MyAvailableCompetitions = (props) => {
    const [competitions, setCompetitions] = useState([]);
    const [viewErrorDialog, setViewErrorDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const { auth } = useAuth();
    const toast = useRef(null);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
        sortField: 'id',
        sortOrder: 1
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        name: 'name',
        status: 'status',
        type: 'type',
        positionCategory: 'position_category',
        startDate: 'start_date',
        submitEndDate: 'submit_end_date'
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const competitionService = useMemo(() => new CompetitionService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);
    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        loadCompetitions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('MyAvailableCompetitions', lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id';

        return criteria;
    };

    const loadCompetitions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService
            .getMyAvailableCompetitionListByCriteria(criteria)
            .then((data) => {
                setCompetitions(data.competitions);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const viewCompetition = (competition) => {
        navigate(`/active-competition/${competition.id}`);
    };

    const applyToCompetition = (competition) => {
        if (userService.checkUserForCompetitionRegister(auth.user)) {
            confirmDialog({
                message: translatedMessage('competition.apply.confirm') + ' "' + competition.name + '" ?',
                header: translatedMessage('generic.confirm'),
                acceptClassName: 'p-button-text',
                acceptIcon: 'pi pi-check',
                rejectClassName: 'p-button-text',
                rejectIcon: 'pi pi-times',
                className: 'pcn-confirm-dialog no-icon',
                accept: () => acceptFunc(competition.id, competition.name)
            });
        } else {
            setViewErrorDialog(true);
        }
    };

    const acceptFunc = (competitionId, competitionName) => {
        applicationService
            .applyToCompetition(competitionId)
            .then((_application) => {
                toast.current.show({ severity: 'success', detail: translatedMessage('competition.apply.confirmation') + ' "' + competitionName + '".', life: 3000 });
                loadCompetitions();
                triggerReloadOtherComponents();
                navigate(`/application/${_application.id}`);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const triggerReloadOtherComponents = () => {
        if (typeof props.triggerReload === 'function') {
            props.triggerReload();
        }
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.submitEndDate)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => viewCompetition(rowData)} tooltip={translatedMessage('generic.view')} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {rowData.canRegister && (
                    <Button
                        icon="pi pi-file-edit"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => applyToCompetition(rowData)}
                        tooltip={translatedMessage('competition.apply.register')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="card-header">
                <h5>{translatedMessage('homePage.availableCompetitions')}</h5>
            </div>
            <DataTable
                value={competitions}
                dataKey="id"
                paginator
                lazy
                totalRecords={totalRecords}
                first={lazyParams.first}
                rows={lazyParams.rows}
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                rowsPerPageOptions={5}
                className="datatable-responsive"
                responsiveLayout="stack"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                loading={isLoading}
            >
                <Column field="name" header={translatedMessage('competition.competition')} sortable headerStyle={{ width: '40%', minWidth: '8rem' }} />
                <Column
                        field="positionCategory"
                        header={translatedMessage('competition.positionCategory')}
                        sortable
                        body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.positionCategory)}
                        headerStyle={{ width: '25%', minWidth: '8rem' }}
                    />
                <Column
                    field="startDate"
                    header={translatedMessage('competition.submitInterval')}
                    sortable
                    body={dateBodyTemplate}
                    headerStyle={{ width: '8%', minWidth: '8rem' }}
                />
                <Column body={actionBodyTemplate}></Column>
            </DataTable>

            <ErrorDialog
                visible={viewErrorDialog}
                closeDialog={() => setViewErrorDialog(false)}
                message={translatedMessage('profile.error.competition.notComplete')}
                hasLink={true}
                link={'/my-profile'}
                linkInfo={translatedMessage('profile.goTo.text')}
                linkText={translatedMessage('generic.here')}
            />
        </>
    );
};

export default MyAvailableCompetitions;
