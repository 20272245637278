import React from 'react';
import { translatedMessage } from '../service/LanguageService';

const AddressViewComponent = (props) => {
    // console.log("props: ", props)
    return (
        <>
            {props?.entity?.address && (
                props?.oneLine
                    ?
                    <div className='w-full'>
                        {
                            (props.entity.address ? props.entity.address : "-") +
                            ", " + (props.entity.locality ? props.entity.locality?.label + ", " : "") +
                            translatedMessage("generic.county") + " " + props.entity.county.label
                        }
                    </div>

                    :
                    <div className='w-full'>
                        <div>{props.entity.address ? props.entity.address : "-"}</div>
                        <div className='mt-1'>{(props.entity.locality ? props.entity.locality?.label + ", " : "") + translatedMessage("generic.county") + " " + props.entity.county.label}</div>
                    </div>
            )}
            {!props?.entity?.address && "-"}
        </>
    );
};

export default AddressViewComponent;
