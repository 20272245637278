import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TestService } from '../../../service/TestService';
import { translatedMessage } from '../../../service/LanguageService';
import EnumService from '../../../service/EnumService';
import DeleteModalComponent from '../../../components/DeleteModalComponent';
import DataTableUtils from '../../../utilities/DataTableUtils';
import useAuth from '../../../hooks/useAuth';

import TestCenterEditDialog from './TestCenterEditDialog';
import TestCenterViewDialog from './TestCenterViewDialog';
import TestCenterDataTableUtils from '../../../utilities/TestCenterDataTableUtils';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import GeneralUtils from '../../../utilities/GeneralUtils';

const sortOrderHeader = ["name", "region", "county", "city", "address", "phone", "email", "capacity", "status"]

const tableHeader = [
    translatedMessage("competition.testCenter.name"),
    translatedMessage("generic.region"),
    translatedMessage("generic.county"),
    translatedMessage("generic.city"),
    translatedMessage("generic.address"),
    translatedMessage("generic.phone"),
    translatedMessage("generic.email"),
    translatedMessage("testCenter.capacity"),
    translatedMessage("generic.status"),
]

const TestCenterList = () => {
    const [testCenters, setTestCenters] = useState([]);
    const [selectedTestCenter, setSelectedTestCenter] = useState(null);

    const [regionOptions, setRegionOptions] = useState([]);
    const [countyOptions, setCountyOptions] = useState([]);
    const [filteredCountyOptions, setFilteredCountyOptions] = useState([]);
    const [testCenterStatusOptions, setTestCenterStatusOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'region.id': { value: null, matchMode: FilterMatchMode.IN },
        'county.id': { value: null, matchMode: FilterMatchMode.IN },
        'city': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'capacity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();
    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        setIsLoading(true)
        const getOptions = async () => {
            setRegionOptions(await EnumService.getEntityLookupByField("NOM:REGION"));
            setCountyOptions(await EnumService.getEntityLookupWithParentIdByField("NOM:COUNTY"));
            setFilteredCountyOptions(await EnumService.getEntityLookupWithParentIdByField("NOM:COUNTY"));
            setTestCenterStatusOptions(await EnumService.getEnumByName('TestCenterStatus'));
        };

        const getTestCenters = async () => {
            await testService.getTestCenterList()
                .then((testCenters) => {
                    setTestCenters(testCenters);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getOptions();
        getTestCenters();
    }, [testService]);

    // const updateCountyOptions = (regionIds) => {
    //     let newOptions = countyOptions.filter(county => regionIds.findIndex(regId => regId===county.relatedId) !== -1);
    //     setFilteredCountyOptions(newOptions);
    // }

    const updateList = async (savedTestCenter, isNew) => {
        let updatedTestCenters = DataTableUtils.updateTableList(testCenters, savedTestCenter, isNew)

        setTestCenters(updatedTestCenters);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedTestCenter(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (testCenter) => {
        setSelectedTestCenter(testCenter);
        setEditDialogVisible(true);
    };

    const showTestCenterRoomsPage = (testCenter) => {
        navigate(`/test-center/${testCenter.id}/test-center-room`);
    };

    const showViewDialog = (testCenter) => {
        setSelectedTestCenter(testCenter);
        setViewDialogVisible(true);
    };

    const showDeleteDialog = (testCenter) => {
        setSelectedTestCenter(testCenter);
        setDeleteDialogVisible(true);
    };

    const deleteTestCenter = () => {
        testService.deleteTestCenter(selectedTestCenter.id)
            .then(() => {
                let _testCenter = testCenters.filter(item => item.id !== selectedTestCenter.id)
                setTestCenters(_testCenter)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedTestCenter(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('testCenter.newTestCenter')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let exportData = testCenters.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.region = item.region.label
            exportItem.county = item.county.label
            exportItem.status = item.status ? translatedMessage('TestCenterStatus.' + item.status) : ""

            return exportItem;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.testCenters"))}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        let disableDelete = rowData.status === 'ACTIVE' ? true : false;
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        disabled={rowData.id < 1000} tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button className="p-button-rounded p-button-info p-button-icon-only m-1" onClick={() => showTestCenterRoomsPage(rowData)}
                        tooltip={translatedMessage("testCenter.room.roomsInTestCenter")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}>
                        <FontAwesomeIcon icon='fa-solid fa-folder-tree' />
                    </Button>
                }
                {hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)}
                        disabled={disableDelete} tooltip={translatedMessage(disableDelete ? "error.test_center.not_inactive" : "generic.delete")}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    // const regionRowFilterTemplate = (options) => {
    //     return <MultiSelect 
    //                 value={options.value} 
    //                 options={regionOptions} 
    //                 onChange={(e) => {updateCountyOptions(e.value); options.filterApplyCallback(e.value);}} 
    //                 placeholder={translatedMessage('testCenter.selectRegion')}
    //                 className="p-column-filter" 
    //                 maxSelectedLabels={1} 
    //                 selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}                    
    //             />;
    // }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{translatedMessage('testCenter.testCenters')}</h3>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={testCenters}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['name', 'region.id', 'county.id', 'city', 'capacity', 'status']}
                            >
                                <Column field="name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '8rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                {/* <Column
                                    field="region.label"
                                    filterField="region.id"
                                    header={translatedMessage('generic.region')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={regionRowFilterTemplate}
                                /> */}
                                <Column
                                    field="county.label"
                                    filterField="county.id"
                                    header={translatedMessage('generic.county')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '5rem' }}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={(e) => TestCenterDataTableUtils.countyRowFilterTemplate(e, filteredCountyOptions)}
                                />
                                <Column
                                    field="city"
                                    header={translatedMessage('generic.city')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '8rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="capacity"
                                    header={translatedMessage('testCenter.capacity')}
                                    sortable
                                    headerStyle={{ width: '5%', minWidth: '8rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    filterField="status"
                                    header={translatedMessage('generic.status')}
                                    headerStyle={{ width: '5%', minWidth: '5rem' }}
                                    sortable
                                    body={(e) => TestCenterDataTableUtils.statusBodyTemplate(e.status)}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={(e) => TestCenterDataTableUtils.statusFilterTemplate(e, testCenterStatusOptions)}
                                />
                                <Column body={actionBodyTemplate} />
                            </DataTable>

                            <TestCenterEditDialog
                                value={selectedTestCenter}
                                visible={editDialogVisible}
                                visibleSetter={setEditDialogVisible}
                                afterSave={updateList}
                                regionOptions={regionOptions}
                                countyOptions={countyOptions}
                                testCenterStatusOptions={testCenterStatusOptions}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedTestCenter ? selectedTestCenter.name : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteTestCenter()}
                            />

                            <TestCenterViewDialog
                                testCenter={selectedTestCenter}
                                visible={viewDialogVisible}
                                closeDialog={() => { setViewDialogVisible(false) }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestCenterList;
