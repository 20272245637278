import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
import AccessibilityComponent from '../../components/profile/AccessibilityComponent';

const MyAccessibility = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [accessibility, setAccessibility] = useState(null);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getData = async () => {
            await userService.getAccessibility()
                .then(response => {
                    setAccessibility(response ? response : null)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        getData();
    }, [userService]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full" >
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-center mb-5'>
                                <h5>{translatedMessage("profile.accessibility")}</h5>
                            </div>

                            <AccessibilityComponent accessibility={accessibility} updateFields={true} afterSave={(value) => setAccessibility(value)}/>                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyAccessibility;
