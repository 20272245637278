import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import AddressViewComponent from '../../components/AddressViewComponent';
import GeneralUtils from '../../utilities/GeneralUtils';
import ProfileToolbar from './ProfileToolbar';

const ProfileView = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const [user, setUser] = useState();
    const toast = useRef(null);
    const { userIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const computeProfile = async () => {
            await userService.getUserProfile(userIdParam)
                .then(response => {
                    // console.log(response)
                    setUser(response)
                    setNavPropsValue('users', response.id, response.fullName)

                    setIsLoading(false)
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        computeProfile()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, userIdParam]);

    if (isLoading) {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className='w-full flex align-items-center'>
                    <ProgressSpinner />
                </div>
            </>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <ProfileToolbar user={user} code="DETAIL" />

                            <div className='w-full grid pcn-data-view-panel'>
                                <div className='col-12'>
                                    <h5 className='mb-1 font-weight-normal'>{translatedMessage("user.details")}</h5>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('generic.firstName')}</div>
                                    <div className="filed-value">{user?.firstName}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('generic.lastName')}</div>
                                    <div className="filed-value">{user?.lastName}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('register.username')}</div>
                                    <div className="filed-value">{user?.username}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('profile.uid')}</div>
                                    <div className="filed-value">{GeneralUtils.dashIfNullForObject(user?.uid)}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('profile.pin')}</div>
                                    <div className="filed-value">{GeneralUtils.dashIfNullForObject(user?.profile?.pin)}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('profile.idCard')}</div>
                                    <div className="filed-value">{`${translatedMessage("IdCardType." + user?.profile?.idCardType)}: ${user?.profile?.idCardSeries} | ${user?.profile?.idCardNumber} `}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('profile.phone')}</div>
                                    <div className="filed-value">{GeneralUtils.dashIfNullForObject(user?.profile?.phone)}</div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('generic.address')}</div>
                                    <div className="filed-value">
                                        <AddressViewComponent entity={user?.profile} oneLine={true} />
                                    </div>
                                </div>

                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('profile.publicServant')}</div>
                                    <div className="filed-value">{GeneralUtils.yesNoConverter(user?.profile?.publicServant)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
};

export default ProfileView;
