import React, { useState, useEffect, useRef } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import useAuth from '../../hooks/useAuth';

import { Toast } from 'primereact/toast';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';

const MyFile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const [profile, setProfile] = useState();
    const { auth, } = useAuth();
    const toast = useRef(null);

    useEffect(() => {
        setIsLoading(true);
        setProfile(auth.user);
        setIsLoading(false);
    }, [auth]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <div className="grid">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card">
                        <StorageFolderFileList
                            folderId={profile.folderId}
                            folderName={profile.firstName}
                            showUploadButton={true}
                            pageTitle={translatedMessage("profile.files")}
                            pageSubTitle={translatedMessage('profile.files.info') + 
                                (profile?.publicServant ? " " + translatedMessage("profile.publicServant.file.info") : "")}
                            singleFile={false}
                            addUsageOnUpload={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default MyFile;
