import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { EvaluationService } from '../../service/EvaluationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';
import useAuth from '../../hooks/useAuth';
import ApplicationEducation from '../competition/application/ApplicationEducation';
import ApplicationWorkExperience from '../competition/application/ApplicationWorkExperience';
import ApplicationITKnowledge from '../competition/application/ApplicationITKnowledge';
import ApplicationLanguage from '../competition/application/ApplicationLanguage';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import EvaluationFormPage from './EvaluationFormPage';

const allSections = [
    { label: translatedMessage('evaluation.evaluation'), code: 'GENERAL_EVALUATION', icon: 'fa-solid fa-user' },
    { label: translatedMessage('profile.education'), code: 'EDUCATION', icon: 'fa-solid fa-user-graduate' },
    { label: translatedMessage('profile.workExperience'), code: 'EXPERIENCE', icon: 'fa-solid fa-building-columns' },
    { label: translatedMessage('profile.itKnowledge'), code: 'IT', icon: 'fa-solid fa-computer' },
    { label: translatedMessage('profile.language'), code: 'LANGUAGE', icon: 'fa-solid fa-globe' },
    { label: translatedMessage('application.form'), code: 'FORM_EVALUATION', icon: 'fa-regular fa-file-lines' },
    { label: translatedMessage('application.files'), code: 'FILES', icon: 'fa-regular fa-file' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

let sections = [...allSections];

const EvaluationViewPage = () => {
    const [committee, setCommittee] = useState({});
    const [applicationFormFields, setApplicationFormFields] = useState([]);
    const [application, setApplication] = useState(null);
    const [evaluation, setEvaluation] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");
    const [section, setSection] = useState(sections[0]);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    const { competitionIdParam, evaluationIdParam } = useParams();

    const [searchParams] = useSearchParams();
    const { auth } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)

        if (searchParams.get('section')) {
            setSection(sections.find((section) => section.code === searchParams.get('section')));
        }

        const filterSections = (committee) => {
            if (committee.type !== 'COMPETITION' && committee.type !== 'ELIGIBILITY_CONTESTATION') {
                sections = allSections.filter((_section) => _section.code !== 'CLARIFICATION' && _section.code !== 'FORM_EVALUATION' && _section.code !== 'FILES');
            }
        };

        const getEvaluation = async () => {
            await evaluationService.getEvaluationById(evaluationIdParam)
                .then(async (_evaluation) => {
                    _evaluation.fieldsEvaluation = await evaluationService.getApplicationFieldEvaluationsList(_evaluation.id)
                    setEvaluation(_evaluation)
                    setApplication(_evaluation.application)

                    getFormFields(_evaluation?.application?.id)

                    setCommittee(_evaluation.evaluationCommitteeUser.evaluationCommittee);
                    filterSections(_evaluation.evaluationCommitteeUser.evaluationCommittee);

                    setIsLoading(false)
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getFormFields = async (applicationId) => {
            applicationService.getApplicationFormFieldList(applicationId)
                .then((_fields) => {
                    setApplicationFormFields(_fields);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        if (section.code === 'GENERAL_EVALUATION') {
            getEvaluation()
        } else {
            setIsLoading(false)
        }
    }, [applicationService, evaluationService, evaluationIdParam, searchParams, section, auth.user.id]);

    const navigationTemplate = (option) => {
        return (
            <>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </>
        );
    };

    const handleSectionChange = (value) => {
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/competition-evaluation/${competitionIdParam}/evaluations/${application?.id}`);
            } else {
                setSection(value);
            }
        }
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">
                                {translatedMessage('evaluation.evaluation')} {translatedMessage('generic.for')} {application?.firstName} {application?.lastName}
                            </h3>
                            <div className="mb-5">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemplate} className="pcn-selectbutton-slim" />
                            </div>

                            {section.code === 'GENERAL_EVALUATION' && (
                                <div className="w-full grid pcn-data-view-panel">
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('evaluation.evaluator')}</div>
                                        <div className="filed-value">
                                            {evaluation.evaluationCommitteeUser.user.fullName}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.competition')}</div>
                                        <div className="filed-value">{application?.competition?.name}</div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('evaluation.committee')}</div>
                                        <div className="filed-value">{committee.name}</div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('generic.type')}</div>
                                        <div className="filed-value">{translatedMessage('EvaluationCommitteeType.' + committee.type)}</div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <h5 className='mb-1'>{translatedMessage("evaluation.evaluation")}</h5>
                                        <div className="w-full grid pcn-data-view-panel">
                                            <div className='col-12 pb-0 mb-2'>
                                                <div className="filed-label">{translatedMessage('generic.status')}</div>
                                                <span className={`status status-evaluation-${evaluation?.status.toString().toLowerCase()}`} >
                                                    {translatedMessage('EvaluationStatus.' + evaluation?.status)}
                                                </span>
                                            </div>
                                            <div className='col-12 pb-0'>
                                                <div className="filed-label">{translatedMessage('evaluation.comment')}</div>
                                                <div className='filed-value text-justify'>{evaluation?.comment || '-'}</div>
                                            </div>                                            
                                        </div>
                                    </div>                                    
                                </div>
                            )}

                            {section.code === 'EDUCATION' &&
                                <ApplicationEducation
                                    application={application}
                                    formFields={applicationFormFields}
                                    evaluation={evaluation}
                                    isForEvaluation={true}
                                    hideFillInExpanation={true}
                                    isCommitteeActive={committee.active}
                                    viewOnly={true}
                                />
                            }

                            {section.code === 'EXPERIENCE' &&
                                <ApplicationWorkExperience
                                    application={application}
                                    formFields={applicationFormFields}
                                    evaluation={evaluation}
                                    isForEvaluation={true}
                                    hideFillInExpanation={true}
                                    isCommitteeActive={committee.active}
                                    viewOnly={true}
                                />
                            }

                            {section.code === 'IT' &&
                                <ApplicationITKnowledge
                                    application={application}
                                    formFields={applicationFormFields}
                                    evaluation={evaluation}
                                    isForEvaluation={true}
                                    isCommitteeActive={committee.active}
                                    viewOnly={true}
                                />
                            }

                            {section.code === 'LANGUAGE' &&
                                <ApplicationLanguage
                                    application={application}
                                    formFields={applicationFormFields}
                                    evaluation={evaluation}
                                    isForEvaluation={true}
                                    hideFillInExpanation={true}
                                    isCommitteeActive={committee.active}
                                    viewOnly={true}
                                />
                            }

                            {section.code === 'FORM_EVALUATION' && (
                                <EvaluationFormPage
                                    formFields={applicationFormFields}
                                    evaluation={evaluation}
                                    isCommitteeActive={committee.active}
                                    viewOnly={true}
                                />
                            )}

                            {section.code === 'FILES' && 
                                <StorageFolderFileList  
                                    applicationId={application?.id} 
                                    folderId={application.folder.id} 
                                    folderName={application.competition.name} 
                                    showUploadButton={false} 
                                    pageTitle={translatedMessage('application.files')} 
                                    readOnly={true} 
                                    evaluationApplicationDocs={true}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationViewPage;
