import { useEffect, useState } from 'react';
import useAuth from "./useAuth";
import { translatedMessage } from "../service/LanguageService";

const useMenu = () => {
    const { auth } = useAuth();
    const [administrationMenu, setAdministrationMenu] = useState({});
    const [recruitmentMenu, setRecruitmentMenu] = useState({});
    const [competitionMenu, setCompetitionMenu] = useState({});
    const [finalReportMenu, setFinalReportMenu] = useState({});
    const [testMenu, setTestMenu] = useState({});
    const [formMenu, setFormMenu] = useState({});


    useEffect(() => {
        const hasPermission = (permissionName) => {
            if (auth && auth.user && auth.user.permissions) {
                return auth.user.permissions.indexOf(permissionName) > -1;
            } else {
                return false;
            }
        };

        // Administration menu
        let administrationItems = [];
        if (hasPermission('USER_LIST')) {
            administrationItems.push({ label: translatedMessage('menu.administration.users'), icon: 'fa-regular fa-user', to: '/administration/users' });
        }
        if (hasPermission('ROLE_LIST')) {
            administrationItems.push({ label: translatedMessage('menu.administration.roles'), icon: 'fa-solid fa-tags', to: '/administration/roles' });
        }
        setAdministrationMenu(hasPermission('USER_LIST') || hasPermission('ROLE_LIST') ? 
            {   label: translatedMessage('menu.administration'), 
                items: administrationItems 
            } : '')

        // Recruitment menu
        let recruitmentItems = [];
        if (hasPermission('RECRUITMENT_LIST')) {
            recruitmentItems.push({ label: translatedMessage('menu.recruitment'), icon: 'fa-regular fa-map', to: '/recruitment' });
        } else {
            recruitmentItems.push({ label: translatedMessage('menu.recruitment'), icon: 'fa-regular fa-map', to: '/recruitment-plans' });
        }
        setRecruitmentMenu(
        {   label: translatedMessage('recruitmentPlan.recruitmentPlan'), 
            items: recruitmentItems 
        });

        // Competition menu
        let competitionItems = [];
        if (hasPermission('COMPETITION_LIST')) {
            competitionItems.push({ label: translatedMessage('menu.competition.management'), icon: 'fa-solid fa-list-ol', to: '/competition' });
        }
        if (hasPermission('EVALUATION_LIST')) {
            competitionItems.push({ label: translatedMessage('menu.competition-evaluation'), icon: 'fa-solid fa-list-check', to: '/competition-evaluation' });
        }        
        if (hasPermission('IS_EVALUATOR')) {
            competitionItems.push({ label: translatedMessage('menu.my-committee'), icon: 'fa-solid fa-users', to: '/my-committee' });
        }         
        competitionItems.push({ label: translatedMessage('menu.active-competition'), icon: 'fa-solid fa-list', to: '/active-competition' })
        competitionItems.push({ label: translatedMessage('menu.application'), icon: 'fa-solid fa-user-edit', to: '/application' })        

        setCompetitionMenu(
            {   label: translatedMessage('competition.competition'), 
                items: competitionItems 
            } );        

        // Final report menu
        let finalReportItems = [];
        finalReportItems.push({ label: translatedMessage('menu.finalReport.competition'), icon: 'fa-regular fa-map', to: '/final-report' });
        setFinalReportMenu(
        {   label: translatedMessage('menu.finalReport'), 
            items: finalReportItems 
        });

        // Test menu
        let testItems = [];
        if (hasPermission('TEST_LIST')) {
            testItems.push({ label: translatedMessage('menu.test'), icon: 'fa-solid fa-list-check', to: '/test' });
            testItems.push({ label: translatedMessage('menu.test-center'), icon: 'fa-solid fa-building', to: '/test-center' });
            // testItems.push({ label: translatedMessage('menu.test-session'), icon: 'fa-regular fa-calendar', to: '/test-session' });
        }        
        testItems.push({ label: translatedMessage('menu.test-verification-scale'), icon: 'fa-solid fa-file-circle-check', to: '/test-scale' });
        testItems.push({ label: translatedMessage('menu.test-demo'), icon: 'fa-solid fa-check-double', to: '/test-demo' })

        setTestMenu(
            {   label: translatedMessage('test.testing'), 
                items: testItems 
            } );        

        // Form menu
        let formItems = [];
        if (hasPermission('FORM_LIST')) {
            formItems.push({ label: translatedMessage('menu.form'), icon: 'fa-regular fa-file-lines', to: '/form' });
            formItems.push({ label: translatedMessage('menu.field'), icon: 'fa-solid fa-grip-lines', to: '/field' });            
        }
        setFormMenu(hasPermission('FORM_LIST') ? 
            {   label: translatedMessage('form.form'), 
                items: formItems 
            } : '');
    }, [auth]);

    return [
        administrationMenu,
        recruitmentMenu,
        competitionMenu,
        finalReportMenu,
        testMenu,
        formMenu        
    ];
}

export default useMenu
