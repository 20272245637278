const LayoutService = {
  getDefaultMenuTheme: () => {
    // light | dark | bluegrey
    return 'light';
  },

  getDefaulltInputStyle: () => {
    // filled | outlined
    return 'outlined';
  }

};

export default LayoutService;