import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { CompetitionService } from '../../service/CompetitionService';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import GeneralUtils from '../../utilities/GeneralUtils';
import ApiService from '../../service/ApiService';
import CompetitionUtils from '../../utilities/CompetitionUtils';
import useAuth from '../../hooks/useAuth';
import EnumService from '../../service/EnumService';

let emptyCompetition = {
    id: null,
    name: '',
    description: '',
    bibliography: '',
    positionCategory: '',
    positionGrade: '',
    positionType: '',
    type: 'NATIONAL_DEBUTANT',
    startDate: '',
    submitEndDate: '',
    status: 'DRAFT',
    clarificationInterval: '3',
    contestationInterval: '1',
    supplementaryTest1Name: '',
    supplementaryTest1Date: '',
    supplementaryTest2Name: '',
    supplementaryTest2Date: '',
    supplementaryTest3Name: '',
    supplementaryTest3Date: '',
    writtenTestDate: '',
    interviewTestDate: '',
    folderId: null,
    institution: null
};

const CompetitionEditDialog = (props) => {
    const [competition, setCompetition] = useState(emptyCompetition);
    const [isNew, setIsNew] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [competitionTypes, setCompetitionTypes] = useState([]);
    const [positionCategories, setPositionCategories] = useState([]);
    const [positionGrades, setPositionGrades] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const [disableType, setDisableType] = useState(false);
    const [disableFields, setDisableFields] = useState(false);

    const toast = useRef(null);
    const { auth } = useAuth();
    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        let _competition = props.value ? props.value : emptyCompetition;
        setCompetition(_competition);
        setIsNew(props.value ? false : true);
        if (ApiService.getIsProduction()) {
            setStatuses(props?.statuses.filter(item => item.value === 'DRAFT' || item.value === 'ACTIVE' ||
                item.value === 'REGISTRATION_OPEN' || item.value === 'CANCELED' || item.value === 'FINISHED_NO_APPLICANTS'))
        } else {
            setStatuses(props?.statuses)
        }

        let _types = props?.types

        if (CompetitionUtils.isNationalCompetition(_competition.type)) {
            getPositionCategories();
            _competition.positionCategory && getPositionGrades(_competition.positionCategory);
            _competition.positionGrade && getPositionTypes(_competition.positionGrade);
            _competition.positionType && getCompetitionTypes(_competition.positionType);
        }

        if (props.value) {
            if (CompetitionUtils.isJobCompetition(props.value.type)) {
                setDisableType(true)
                setCompetitionTypes(_types)
                if (CompetitionUtils.isDebutantCompetition(props.value.type)) {
                    setDisableFields(true)
                }
            } else {
                setDisableType(false)
                setCompetitionTypes(_types.filter(item => item.value.includes("NATIONAL")))
            }
        } else {
            setDisableType(false)
            setCompetitionTypes(_types.filter(item => item.value.includes("NATIONAL")))
        }
    }, [props]);

    const getPositionCategories = async () => {
        setPositionCategories(await EnumService.getEnumByName('PositionCategory'));
    };

    const getPositionGrades = async (positionCategory) => {
        let _positionGrades = await EnumService.getEnumByNameAndParam('PositionGrade', positionCategory);
        setPositionGrades(_positionGrades);
        return _positionGrades;
    };

    const getPositionTypes = async (positionGrade) => {
        let _positionTypes = await EnumService.getEnumByNameAndParam('PositionType', positionGrade)
        setPositionTypes(_positionTypes);
        return _positionTypes;
    };

    const getCompetitionTypes = async (positionType) => {
        let _competitionTypes = await EnumService.getEnumByNameAndParam('CompetitionType', positionType);
        setCompetitionTypes(_competitionTypes);
        return _competitionTypes;
    };

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const saveCompetition = (data) => {
        competitionService.saveCompetition(data)
            .then((savedCompetition) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedCompetition, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: competition,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.nameRo.required');
            }
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.competition.startDate.required');
            }
            if (!data.submitEndDate) {
                errors.submitEndDate = translatedMessage('form.error.competition.submitEndDate.required');
            }
            if (!data.status) {
                errors.status = translatedMessage('form.error.competition.status.required');
            }
            if (!data.clarificationInterval && data.clarificationInterval !== 0) {
                errors.clarificationInterval = translatedMessage('form.error.competition.clarificationInterval.required');
            }
            if (!data.contestationInterval) {
                errors.contestationInterval = translatedMessage('form.error.competition.contestationInterval.required');
            }

            if (data.interviewTestDate && data.interviewTestDate < data.writtenTestDate) {
                errors.interviewTestDate = translatedMessage('form.error.competition.interviewTestDate.toEarly');
            }

            if (data.supplementaryTest1Name && !data.supplementaryTest1Date) {
                errors.supplementaryTest1Date = translatedMessage('form.error.competition.supplementaryTest1Date.required');
            }
            if (!data.supplementaryTest1Name && (data.supplementaryTest2Name || data.supplementaryTest3Name)) {
                errors.supplementaryTest1Name = translatedMessage('form.error.competition.supplementaryTest1.missing');
            }

            if (data.supplementaryTest2Name && !data.supplementaryTest2Date) {
                errors.supplementaryTest2Date = translatedMessage('form.error.competition.supplementaryTest2Date.required');
            }
            if (!data.supplementaryTest2Name && data.supplementaryTest3Name) {
                errors.supplementaryTest2Name = translatedMessage('form.error.competition.supplementaryTest2.missing');
            }

            if (data.supplementaryTest3Name && !data.supplementaryTest3Date) {
                errors.supplementaryTest3Date = translatedMessage('form.error.competition.supplementaryTest3Date.required');
            }

            if (data.supplementaryTest1Date && data.supplementaryTest2Date && data.supplementaryTest1Date > data.supplementaryTest2Date) {
                errors.supplementaryTest2Date = translatedMessage('form.error.competition.supplementaryTest2Date.toEarly');
            }
            if (data.supplementaryTest1Date && data.supplementaryTest3Date && data.supplementaryTest1Date > data.supplementaryTest3Date) {
                errors.supplementaryTest3Date = translatedMessage('form.error.competition.supplementaryTest3Date.toEarly');
            }
            if (data.supplementaryTest2Date && data.supplementaryTest3Date && data.supplementaryTest2Date > data.supplementaryTest3Date) {
                errors.supplementaryTest3Date = translatedMessage('form.error.competition.supplementaryTest3Date.toEarly');
            }

            if (CompetitionUtils.isJobCompetition(data.type) && !data.writtenTestDate) {
                errors.writtenTestDate = translatedMessage('form.error.competition.writtenTestDate.required');
            }

            if (CompetitionUtils.isNationalCompetition(formik.values.type)) {
                if (!data.positionCategory) {
                    errors.positionCategory = translatedMessage('form.error.competition.positionCategory.required');
                }
                if (!data.positionGrade) {
                    errors.positionGrade = translatedMessage('form.error.competition.positionGrade.required');
                }
                if (!data.positionType) {
                    errors.positionType = translatedMessage('form.error.competition.positionType.required');
                }
                if (!data.overallTestsPassingScore) {
                    errors.overallTestsPassingScore = translatedMessage('form.error.competition.overallTestsPassingScore.required');
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            saveCompetition(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const setDescriptionValue = (description) => {
        formik.setFieldValue('description', description);
    }

    const setBibliographyValue = (bibliography) => {
        formik.setFieldValue('bibliography', bibliography);
    }

    const handleTypeChange = (_type) => {
        formik.setFieldValue('type', _type.value);
    }

    const handlePositionCategoryChange = (_positionCategory) => {
        formik.setFieldValue('positionCategory', _positionCategory.value);
        getPositionGrades(_positionCategory.value)
            .then((_positionGrades) => {
                if (_positionGrades.length === 1) {
                    handlePositionGradeChange(_positionGrades[0])
                } else {
                    formik.setFieldValue('positionGrade', null);
                    setPositionTypes([]);
                    formik.setFieldValue('positionType', null);
                }
            })
    }

    const handlePositionGradeChange = (_positionGrade) => {
        formik.setFieldValue('positionGrade', _positionGrade.value);
        getPositionTypes(_positionGrade.value)
            .then((_positionTypes) => {
                if (_positionTypes.length === 1) {
                    handlePositionTypeChange(_positionTypes[0])
                } else {
                    formik.setFieldValue('positionType', null);
                }
            })
    }

    const handlePositionTypeChange = (_positionType) => {
        formik.setFieldValue('positionType', _positionType.value);
        getCompetitionTypes(_positionType.value)
            .then((_competitionTypes) => {
                handleTypeChange(_competitionTypes[0])
            })
    }

    const positionTemplate = (option) => {
        return option.label;
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                header={translatedMessage('competition.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>
                    <div className='grid'>
                        <div className='col-12'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputText
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        autoFocus
                                        className={classNames({ 'p-invalid': isFormFieldValid('name') })}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                    />
                                    <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                        {translatedMessage('generic.nameRo')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('name')}
                        </div>

                        {CompetitionUtils.isNationalCompetition(formik.values.type) && <>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="positionCategory"
                                            name="positionCategory"
                                            value={formik.values.positionCategory}
                                            onChange={handlePositionCategoryChange}
                                            options={positionCategories}
                                            disabled={disableType || (disableFields && !hasPermission('COMPETITION_EDIT'))}
                                            itemTemplate={positionTemplate}
                                            scrollHeight="400px"
                                            className={classNames({ 'p-invalid': isFormFieldValid('positionCategory') })}
                                        />
                                        <label htmlFor="positionCategory" className={classNames({ 'p-error': isFormFieldValid('positionCategory') })}>
                                            {translatedMessage('competition.positionCategory')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('positionCategory')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="positionGrade"
                                            name="positionGrade"
                                            value={formik.values.positionGrade}
                                            onChange={handlePositionGradeChange}
                                            options={positionGrades}
                                            disabled={disableType || (disableFields && !hasPermission('COMPETITION_EDIT'))}
                                            itemTemplate={positionTemplate}
                                            scrollHeight="400px"
                                            className={classNames({ 'p-invalid': isFormFieldValid('positionGrade') })}
                                        />
                                        <label htmlFor="positionGrade" className={classNames({ 'p-error': isFormFieldValid('positionGrade') })}>
                                            {translatedMessage('competition.positionGrade')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('positionGrade')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="positionType"
                                            name="positionType"
                                            value={formik.values.positionType}
                                            onChange={handlePositionTypeChange}
                                            options={positionTypes}
                                            disabled={disableType || (disableFields && !hasPermission('COMPETITION_EDIT'))}
                                            itemTemplate={positionTemplate}
                                            scrollHeight="400px"
                                            className={classNames({ 'p-invalid': isFormFieldValid('positionType') })}
                                        />
                                        <label htmlFor="positionType" className={classNames({ 'p-error': isFormFieldValid('positionType') })}>
                                            {translatedMessage('competition.positionType')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('positionType')}
                            </div>
                        </>
                        }

                        {CompetitionUtils.isJobCompetition(formik.values.type) &&
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="type"
                                            name="type"
                                            value={formik.values.type}
                                            onChange={handleTypeChange}
                                            options={competitionTypes}
                                            disabled={disableType || (disableFields && !hasPermission('COMPETITION_EDIT'))}
                                        />
                                        <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                            {translatedMessage('generic.type')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('type')}
                            </div>
                        }

                        <div className='col-12 lg:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange} options={statuses}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                    />
                                    <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') })}>
                                        {translatedMessage('generic.status')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('status')}
                        </div>

                        <div className='col-12 lg:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar
                                        id="startDate"
                                        name="startDate"
                                        value={formik.values.startDate}
                                        onChange={formik.handleChange}
                                        dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('startDate') })}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                    />
                                    <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                        {translatedMessage("competition.startDate")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('startDate')}
                        </div>

                        <div className='col-12 lg:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <Calendar
                                        id="submitEndDate"
                                        name="submitEndDate"
                                        value={formik.values.submitEndDate}
                                        onChange={formik.handleChange}
                                        dateFormat="dd-mm-yy"
                                        className={classNames({ 'p-invalid': isFormFieldValid('submitEndDate') })}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                    />
                                    <label htmlFor="submitEndDate" className={classNames({ 'p-error': isFormFieldValid('submitEndDate') })}>
                                        {translatedMessage("competition.submitEndDate")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('submitEndDate')}
                        </div>

                        <div className='col-12 lg:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputNumber
                                        id="clarificationInterval"
                                        name="clarificationInterval"
                                        value={formik.values.clarificationInterval}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('clarificationInterval') })}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        min={0}
                                    />
                                    <label htmlFor="clarificationInterval" className={classNames({ 'p-error': isFormFieldValid('clarificationInterval') })}>
                                        {translatedMessage('competition.clarificationInterval')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('clarificationInterval')}
                        </div>

                        <div className='col-12 lg:col-6'>
                            <div className="p-inputgroup">
                                <span className="p-float-label">
                                    <InputNumber
                                        id="contestationInterval"
                                        name="contestationInterval"
                                        value={formik.values.contestationInterval}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('contestationInterval') })}
                                        disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        min={0}
                                    />
                                    <label htmlFor="contestationInterval" className={classNames({ 'p-error': isFormFieldValid('contestationInterval') })}>
                                        {translatedMessage('competition.contestationInterval')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('contestationInterval')}
                        </div>


                        {CompetitionUtils.isNationalCompetition(formik.values.type) && <>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputNumber
                                            id="overallTestsPassingScore"
                                            name="overallTestsPassingScore"
                                            value={formik.values.overallTestsPassingScore}
                                            onValueChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('overallTestsPassingScore') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="overallTestsPassingScore" className={classNames({ 'p-error': isFormFieldValid('overallTestsPassingScore') })}>
                                            {translatedMessage('competition.overallTestsPassingScore')} *
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('overallTestsPassingScore')}
                            </div>
                        </>}

                        {CompetitionUtils.isJobCompetition(competition.type) && <>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar
                                            id="writtenTestDate"
                                            name="writtenTestDate"
                                            value={formik.values.writtenTestDate}
                                            onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy"
                                            showTime
                                            hourFormat="24"
                                            stepMinute={10}
                                            className={classNames({ 'p-invalid': isFormFieldValid('writtenTestDate') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="writtenTestDate" className={classNames({ 'p-error': isFormFieldValid('writtenTestDate') })}>
                                            {translatedMessage("competition.writtenTestDate")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('writtenTestDate')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar
                                            id="interviewTestDate"
                                            name="interviewTestDate"
                                            value={formik.values.interviewTestDate}
                                            onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy"
                                            showTime
                                            hourFormat="24"
                                            stepMinute={10}
                                            className={classNames({ 'p-invalid': isFormFieldValid('interviewTestDate') })}
                                        />
                                        <label htmlFor="interviewTestDate" className={classNames({ 'p-error': isFormFieldValid('interviewTestDate') })}>
                                            {translatedMessage("competition.interviewTestDate")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('interviewTestDate')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText
                                            type="text"
                                            id="supplementaryTest1Name"
                                            name="supplementaryTest1Name"
                                            value={formik.values.supplementaryTest1Name ? formik.values.supplementaryTest1Name : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest1Name') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest1Name" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest1Name') })}>
                                            {translatedMessage('competition.supplementaryTest1Name')}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest1Name')}
                            </div>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar
                                            id="supplementaryTest1Date"
                                            name="supplementaryTest1Date"
                                            value={formik.values.supplementaryTest1Date}
                                            onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy"
                                            showTime
                                            hourFormat="24"
                                            stepMinute={10}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest1Date') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest1Date" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest1Date') })}>
                                            {translatedMessage("competition.supplementaryTest1Date")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest1Date')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText
                                            type="text"
                                            id="supplementaryTest2Name"
                                            name="supplementaryTest2Name"
                                            value={formik.values.supplementaryTest2Name ? formik.values.supplementaryTest2Name : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest2Name') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest2Name" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest2Name') })}>
                                            {translatedMessage('competition.supplementaryTest2Name')}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest2Name')}
                            </div>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar
                                            id="supplementaryTest2Date"
                                            name="supplementaryTest2Date"
                                            value={formik.values.supplementaryTest2Date}
                                            onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy"
                                            showTime
                                            hourFormat="24"
                                            stepMinute={10}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest2Date') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest2Date" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest2Date') })}>
                                            {translatedMessage("competition.supplementaryTest2Date")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest2Date')}
                            </div>

                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <InputText
                                            type="text"
                                            id="supplementaryTest3Name"
                                            name="supplementaryTest3Name"
                                            value={formik.values.supplementaryTest3Name ? formik.values.supplementaryTest3Name : ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest3Name') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest3Name" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest3Name') })}>
                                            {translatedMessage('competition.supplementaryTest3Name')}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest3Name')}
                            </div>
                            <div className='col-12 lg:col-6'>
                                <div className="p-inputgroup">
                                    <span className="p-float-label">
                                        <Calendar
                                            id="supplementaryTest3Date"
                                            name="supplementaryTest3Date"
                                            value={formik.values.supplementaryTest3Date}
                                            onChange={formik.handleChange}
                                            dateFormat="dd-mm-yy"
                                            showTime
                                            hourFormat="24"
                                            stepMinute={10}
                                            className={classNames({ 'p-invalid': isFormFieldValid('supplementaryTest3Date') })}
                                            disabled={disableFields && !hasPermission('COMPETITION_EDIT')}
                                        />
                                        <label htmlFor="supplementaryTest3Date" className={classNames({ 'p-error': isFormFieldValid('supplementaryTest3Date') })}>
                                            {translatedMessage("competition.supplementaryTest3Date")}
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('supplementaryTest3Date')}
                            </div>
                        </>}

                        <div className='col-12 '>
                            <div className="field pcn-sun-editor w-100 mb-0">
                                <label htmlFor="description">{translatedMessage('generic.description')}</label>
                                <SunEditor
                                    id="description" name="description"
                                    lang={LanguageService.getCurrentLanguage}
                                    height="200px"
                                    width='auto'
                                    defaultValue={formik.values.description}
                                    placeholder={""}
                                    setOptions={{
                                        buttonList: GeneralUtils.getSunEditorButtons(),
                                        font: GeneralUtils.getSunEditorFonts(),
                                        defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                        charCounter: ApiService.getIsProduction() ? true : false,
                                        maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                    }}
                                    onChange={setDescriptionValue}
                                    disable={disableFields && !hasPermission('COMPETITION_EDIT')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>

                        {CompetitionUtils.isJobCompetition(competition.type) &&
                            <div className='col-12 '>
                                <div className="field pcn-sun-editor w-100 mb-0">
                                    <label htmlFor="bibliography">{translatedMessage('competition.bibliography')}</label>
                                    <SunEditor
                                        id="bibliography" name="bibliography"
                                        lang={LanguageService.getCurrentLanguage}
                                        height="200px"
                                        width='auto'
                                        defaultValue={formik.values.bibliography}
                                        placeholder={""}
                                        setOptions={{
                                            buttonList: GeneralUtils.getSunEditorButtons(),
                                            font: GeneralUtils.getSunEditorFonts(),
                                            defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                            charCounter: ApiService.getIsProduction() ? true : false,
                                            maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                        }}
                                        onChange={setBibliographyValue}
                                        disable={disableFields && !hasPermission('COMPETITION_EDIT')}
                                    />
                                    {getFormErrorMessage('bibliography')}
                                </div>
                            </div>
                        }
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CompetitionEditDialog;
