import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import DOMPurify from 'dompurify';
import { translatedMessage } from '../../service/LanguageService';
import { CompetitionService } from '../../service/CompetitionService';
import useNavProps from '../../hooks/useNavProps';
import GeneralUtils from '../../utilities/GeneralUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { ApplicationService } from '../../service/ApplicationService';
import useAuth from '../../hooks/useAuth';
import { UserService } from '../../service/UserService';
import ErrorDialog from '../../components/ErrorDialog';
import { FormFieldService } from '../../service/FormFieldService';
import FormViewDialog from '../../components/form/FormViewDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import CompetitionUtils from '../../utilities/CompetitionUtils';

const CompetitionDetails = () => {
    const [competition, setCompetition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [viewErrorDialog, setViewErrorDialog] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [viewFormDialog, setViewFormDialog] = useState(false);
    const [jobs, setJobs] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);
    const userService = useMemo(() => new UserService(), []);
    const formFieldService = useMemo(() => new FormFieldService(), []);

    const { competitionIdParam } = useParams();
    const navigate = useNavigate();
    const { auth } = useAuth();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getCompetition = async () => {
            await competitionService.getCompetitionDetails(competitionIdParam)
                .then((competitionData) => {
                    setCompetition(competitionData);

                    setNavPropsValue('active-competition', competitionData.id, competitionData.name)

                    if (CompetitionUtils.isJobCompetition(competitionData.type)) {
                        getJobs(competitionIdParam)
                    }

                    setIsLoading(false)

                })
                .catch((error) => {
                    setIsLoading(false)
                    setIsError(true)
                    setErrorMessage(error)
                });
        };

        const getJobs = async (competitionId) => {
            await competitionService.getCompetitionJobs(competitionId)
                .then((_jobs) => {
                    setJobs(_jobs);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetition();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionService, competitionIdParam]);

    const applyToCompetition = () => {
        if (userService.checkUserForCompetitionRegister(auth.user)) {
            confirmDialog({
                message: translatedMessage('competition.apply.confirm') + ' "' + competition.name + '" ?',
                header: translatedMessage('generic.confirm'),
                acceptClassName: 'p-button-text',
                acceptIcon: 'pi pi-check',
                rejectClassName: 'p-button-text',
                rejectIcon: 'pi pi-times',
                className: 'pcn-confirm-dialog no-icon',
                accept: () => acceptRegistration()
            });
        } else {
            setViewErrorDialog(true)
        }
    }

    const acceptRegistration = () => {
        applicationService.applyToCompetition(competition.id)
            .then((response) => {
                navigate(`/application/${response?.id}`)
                toast.current.show({ severity: 'success', detail: translatedMessage('competition.apply.confirmation') + ' "' + competition.name + '".' });
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    }

    const viewForm = () => {
        formFieldService.getFormFieldListByCompetition(competition.id)
            .then((fields) => {
                setFormFields(fields);
                setViewFormDialog(true);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const getJobDetails = (data) => {
        return (
            <>
                {data.jobClass && <div><span className="font-bold">{translatedMessage("job.jobClass") + ": "}</span>{data.jobClass}</div>}
                {data.level && <div><span className="font-bold">{translatedMessage("job.level") + ": "}</span>{data.level}</div>}
                {data.departmentType && <div><span className="font-bold">{translatedMessage("job.departmentType") + ": "}</span>{data.departmentType}</div>}
                {data.departmentName && <div><span className="font-bold">{translatedMessage("job.departmentName") + ": "}</span>{data.departmentName}</div>}
            </>
        );
    };

    const getJobLocation = (data) => {
        return (
            <>
                {data.institution?.name && <div>{data.institution.name}</div>}
                {data.institution?.locality && <div><span className="font-bold">{translatedMessage("generic.locality") + ": "}</span>{data.institution.locality}</div>}
                {data.institution?.county && <div><span className="font-bold">{translatedMessage("generic.county") + ": "}</span>{data.institution.county}</div>}
            </>
        );
    };

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else if (isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full grid pcn-data-view-panel'>
                                <div className='col-12'>
                                    <h3 className='mb-1 font-weight-normal'>{competition?.name}</h3>
                                    <div className='mb-5'>
                                        <Button className="pcn-button-slim p-button-text" onClick={viewForm}>
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-regular fa-file-text' className="mr-1 " />
                                                <span>{translatedMessage('competition.viewForm')}</span>
                                            </div>
                                        </Button>
                                        {competition.canRegister &&
                                            <Button className="pcn-button-slim p-button-text" onClick={applyToCompetition}>
                                                <div className='flex align-items-center'>
                                                    <FontAwesomeIcon icon='fa-solid fa-file-pen' className="mr-1 " />
                                                    <span>{translatedMessage('competition.apply.register')}</span>
                                                </div>
                                            </Button>
                                        }
                                        <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/active-competition`)}>
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                                <span>{translatedMessage('generic.backToList')}</span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('competition.institution')}</div>
                                    <div className='filed-value'>{competition.institution?.name ? competition.institution.name : "-"}</div>
                                </div>
                                {CompetitionUtils.isNationalCompetition(competition.type) &&
                                    <>
                                        <div className="col-12 md:col-6">
                                            <div className="filed-label">{translatedMessage('competition.positionCategory')}</div>
                                            <div className="filed-value">
                                                {translatedMessage("PositionCategory." + competition.positionCategory)}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <div className="filed-label">{translatedMessage('competition.positionGrade')}</div>
                                            <div className="filed-value">
                                                {translatedMessage("PositionGrade." + competition.positionGrade)}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="filed-label">{translatedMessage('competition.positionType')}</div>
                                            <div className="filed-value">
                                                {translatedMessage("PositionType." + competition.positionType)}
                                            </div>
                                        </div>
                                    </>
                                }
                                {CompetitionUtils.isJobCompetition(competition.type) && competition.positionCategory &&
                                    <>
                                        <div className="col-12">
                                            <div className="filed-label">{translatedMessage('competition.positionCategory')}</div>
                                            <div className="filed-value">
                                                {translatedMessage("PositionCategory." + competition.positionCategory)}
                                            </div>
                                        </div>                                        
                                    </>
                                }
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.startDate')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(competition?.startDate)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.submitEndDate')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(competition?.submitEndDate)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.clarificationInterval')}</div>
                                    <div className='filed-value'>{competition?.clarificationInterval} {translatedMessage("generic.days")}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.contestationInterval')}</div>
                                    <div className='filed-value'>{competition?.contestationInterval} {translatedMessage("generic.days")}</div>
                                </div>
                                {CompetitionUtils.isJobCompetition(competition.type) &&
                                    <>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.writtenTestDate')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.writtenTestDate)}</div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.interviewTestDate')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.interviewTestDate)}</div>
                                        </div>

                                        {competition.supplementaryTest1Name &&
                                            <>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Name')}</div>
                                                    <div className='filed-value'>{competition.supplementaryTest1Name}</div>
                                                </div>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Date')}</div>
                                                    <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest1Date)}</div>
                                                </div>
                                            </>
                                        }

                                        {competition.supplementaryTest2Name &&
                                            <>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Name')}</div>
                                                    <div className='filed-value'>{competition.supplementaryTest2Name}</div>
                                                </div>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Date')}</div>
                                                    <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest2Date)}</div>
                                                </div>
                                            </>
                                        }
                                        {competition.supplementaryTest3Name &&
                                            <>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Name')}</div>
                                                    <div className='filed-value'>{competition.supplementaryTest3Name}</div>
                                                </div>
                                                <div className='col-12 md:col-6'>
                                                    <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Date')}</div>
                                                    <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest3Date)}</div>
                                                </div>
                                            </>
                                        }

                                        {jobs.length > 0 &&
                                            <div className='col-12 mb-3'>
                                                <div className='filed-label mt-3 mb-2'>{translatedMessage('job.paragraphTitle')}</div>
                                                <DataTable
                                                    ref={dt}
                                                    value={jobs}
                                                    dataKey="id"
                                                    paginator
                                                    rows={DataTableUtils.defalRowsPerPage()}
                                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                                    className="datatable-responsive pcn-datatable"
                                                    responsiveLayout="scroll"
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                >
                                                    <Column
                                                        field="position"
                                                        header={translatedMessage('job.position')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="jobClass"
                                                        header={translatedMessage('generic.details')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobDetails}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="institution.name"
                                                        header={translatedMessage('job.location')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobLocation}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                </DataTable>
                                            </div>
                                        }
                                    </>

                                }
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                    <div className='filed-value'>
                                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(competition?.description) }} />
                                    </div>
                                </div>
                                {CompetitionUtils.isJobCompetition(competition.type) && competition?.bibliography &&
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('competition.bibliography')}</div>
                                        <div className='filed-value'>
                                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(competition.bibliography) }} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <FormViewDialog
                    formFields={formFields}
                    visible={viewFormDialog}
                    closeDialog={() => { setViewFormDialog(false) }}
                />

                <ErrorDialog
                    visible={viewErrorDialog}
                    closeDialog={() => setViewErrorDialog(false)}
                    message={translatedMessage("profile.error.competition.notComplete")}
                    hasLink={true}
                    link={"/my-profile"}
                    linkInfo={translatedMessage("profile.goTo.text")}
                    linkText={translatedMessage('generic.here')}
                />
            </>
        );
    }
};

export default CompetitionDetails;
