import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

const TestCenterDataTableUtils = {

  statusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-${_status.toLowerCase()}`}>{translatedMessage('TestCenterStatus.' +_status)}</div>
    )
  },

  statusFilterTemplate(options, statuses) {    
    return <Dropdown
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  },

  countyRowFilterTemplate(options, counties) {
    return <MultiSelect
      value={options.value}
      options={counties}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('testCenter.selectCounty')}
      className="p-column-filter"
      maxSelectedLabels={1}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      filter
    />;
  }

};

export default TestCenterDataTableUtils;