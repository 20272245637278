import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import FormFieldListComponent from '../../../components/form/FormFieldListComponent';
import { FormFieldService } from '../../../service/FormFieldService';
import { translatedMessage } from '../../../service/LanguageService';
import useNavProps from '../../../hooks/useNavProps';

const FormFieldList = () => {
    const [form, setForm] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { setNavPropsValue } = useNavProps();
    const toast = useRef(null);
    const navigate = useNavigate();

    let { formIdParam } = useParams();

    const formFieldService = useMemo(() => new FormFieldService(), []);

    useEffect(() => {
        setIsLoading(true);
        const getForm = async () => {
            await formFieldService.getForm(formIdParam)
                .then(form => {
                    setForm(form);
                    setIsLoading(false);

                    setNavPropsValue('form', form.id, form.name, false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFieldService, formIdParam]);

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{form?.name}</h3>
                            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/form`)}>
                                <div className='flex align-items-center'>
                                    <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                    <span>{translatedMessage('generic.backToList')}</span>
                                </div>
                            </Button>

                            <h5 className='mb-1 font-weight-normal'>{translatedMessage("field.fields")}</h5>

                            <FormFieldListComponent form={form} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default FormFieldList;
