import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import EducationListComponent from '../../components/profile/EducationListComponent';

const MyEducation = () => {
    const [educationList, setEducationList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getEducation = async () => {
            await userService.getEducation()
                .then(educationRespone => {
                    setEducationList(educationRespone)
                    setIsLoading(false)
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        getEducation()
    }, [userService]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-left'>
                                <h5>{translatedMessage("profile.education.title")}</h5>
                            </div>

                            <EducationListComponent 
                                education={educationList} 
                                onListUpdate={(list) => setEducationList(list)} 
                                hasExportButton={true} 
                                updateFields={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyEducation;
