import ApiService from "./ApiService";
import GeneralUtils from '../utilities/GeneralUtils';

export class FormFieldService {
    
    // form

    async getFormList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/form/list')
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getForm(formId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/form/${formId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveForm(form) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/form', form)
                .then(formResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(formResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteForm(formId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/form/${formId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // form field

    async getFormFieldList(formId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/form/${formId}/fields`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getFormFieldListByCompetition(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/form/fields`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async saveFormField(formField) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/form/field`, formField)
                .then(formResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(formResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async deleteFormField(formFieldId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/form/field/${formFieldId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    
    async updateFormFieldsOrder(formFields) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/form/fields`, formFields)
                .then(formResponse => {
                    formResponse.data.map(item => {
                        return GeneralUtils.emptyInNullForObjectFields(item)
                    })
                    resolve(formResponse.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // field

    async getFieldList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/field/list')
                .then(response => {
                    response.data.map(item => {
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getField(fieldId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/field/${fieldId}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveField(field) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/field', field)
                .then(formResponse => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(formResponse.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteField(fieldId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/field/${fieldId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}