import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Contestations from '../../components/application/Contestations';
import useNavProps from '../../hooks/useNavProps';

const EvaluationContestationPage = () => {
    const [application, setApplication] = useState(null);
    const [result, setResult] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    const { applicationIdParam,  resultIdParam } = useParams();

    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);

        const getApplicationResult = async () => {
            await applicationService.getApplicationResultById(applicationIdParam, resultIdParam)
                .then((resultData) => {
                    setResult(resultData);
                    setApplication(resultData.application);

                    setNavPropsValue('application', resultData.application.id, resultData.application.competition.name);
                    setNavPropsValue('result', resultIdParam, translatedMessage('ResultType.' + resultData.type))

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };        

        getApplicationResult()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, applicationIdParam])


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.competition.name + " - " + translatedMessage('ResultType.' + result.type)}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(-1)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="w-full pcn-data-view-panel">
                                <Contestations application={application} result={result}/>
                            </div>                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationContestationPage;
