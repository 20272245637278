import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
import ITKnowledgeComponent from '../../components/profile/ITKnowledgeComponent';
import useNavProps from '../../hooks/useNavProps';
import { useParams } from 'react-router-dom';
import ProfileToolbar from './ProfileToolbar';

const ProfileITKnowledge = () => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [itKnowledge, setItKnowledge] = useState('on load value');
    const toast = useRef(null);
    const { userIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getProfile = async () => {
            await userService.getUserProfile(userIdParam)
                .then(async response => {
                    setUser(response)
                    setNavPropsValue('users', response.id, response.fullName)

                    await getIT()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        const getIT = async () => {
            await userService.getUserProfileItKnowledge(userIdParam)
                .then(response => {
                    setItKnowledge(response)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        getProfile();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, userIdParam]);

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full" >
                    <div className="col-12">
                        <div className="card h-full">
                            <ProfileToolbar user={user} code="IT" />

                            <div className='w-full text-align-left'>
                                <h5>{translatedMessage("profile.itKnowledge")}</h5>
                            </div>

                            <ITKnowledgeComponent 
                                itKnowledge={itKnowledge} 
                                updateFields={false} 
                                afterSave={(value) => setItKnowledge(value)}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ProfileITKnowledge;
