import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import GeneralUtils from '../../utilities/GeneralUtils';
import { ProgressSpinner } from 'primereact/progressspinner';
import ApiService from '../../service/ApiService';

const newValue = {
    id: null,
    visualAccessibility: false,
    auditoryAccessibility: false,
    locomotorAccessibility: false,
    otherAccessibility: false,
    noAdaptation: true,
    adaptationDescription: '',
    applicationId: null,
    folder: {
        id: null
    }
};

const ApplicationAccessibilityDialog = (props) => {
    const [accessibility, setAccessibility] = useState(newValue);
    const [isLoading, setIsLoading] = useState(false);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getAccessibility = async () => {
            await applicationService.getApplicationAccessibility(props?.applicationId)
                .then(async (accesibilityData) => {
                    if (accesibilityData?.id) {
                        setAccessibility(accesibilityData)
                    } else {
                        let newItem = newValue
                        newItem.applicationId = props.applicationId;
                        setAccessibility(newItem);
                    }
                    setIsLoading(false)

                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getAccessibility()
    }, [applicationService, props?.visible, props.applicationId]);

    const hideDialog = (_accessibility = null, saveSuccess = false) => {
        if (typeof props.onHide === 'function') {
            props.onHide(_accessibility ? _accessibility : accessibility, saveSuccess);
        }
        setAccessibility(newValue)
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues: accessibility,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!GeneralUtils.nullIfEmptyHTMLEditor(data.adaptationDescription)) {
                errors.adaptationDescription = translatedMessage('form.error.accessibility.adaptationDescription.required');
            }

            if (!data.visualAccessibility && !data.auditoryAccessibility && !data.locomotorAccessibility && !data.otherAccessibility) {
                errors.adaptationOption = translatedMessage('form.error.accessibility.adaptationCheck.required');
            } else {
                data.noAdaptation = false
            }

            return errors;
        },
        onSubmit: async (data) => {
            await saveAccessibility(data);
        }
    });

    const saveAccessibility = async (data) => {
        await applicationService.saveApplicationAccessibility(data)
            .then((savedAccessibility) => {
                hideDialog(savedAccessibility, true)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            })
    };

    const isFormFieldValid = (name) => {
        let touched = formik.touched[name]
        if (name === "adaptationOption") {
            touched = formik.touched["visualAccessibility"] || formik.touched["auditoryAccessibility"] ||
                formik.touched["locomotorAccessibility"] || formik.touched["otherAccessibility"]
        }

        return !!(touched && formik.errors[name])
    };
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => hideDialog()} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const setDescriptionValue = (detail) => {
        formik.setFieldValue('adaptationDescription', detail);
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Dialog
                    visible={props.visible}
                    header={translatedMessage('application.accessibility.yes.text')}
                    modal
                    className="pcn-dialog p-fluid"
                    footer={formDialogFooter}
                    onHide={hideDialog}
                >
                    <form id="field-form" onSubmit={formik.handleSubmit}>
                        <div className='w-full font-bold mb-3'>{translatedMessage("application.accessibility.yes.text.info")}</div>
                        <div className="grid">
                            <div className='col-12 md:col-6'>
                                <div className="flex align-items-center">
                                    <Checkbox
                                        inputId="visualAccessibility"
                                        name="adaptationOption"
                                        value={formik.values.visualAccessibility}
                                        onChange={(e) => formik.setFieldValue("visualAccessibility", e.checked)}
                                        checked={formik.values.visualAccessibility} />
                                    <label htmlFor="visualAccessibility" className="ml-2">{translatedMessage("application.accessibility.visualAccessibility")}</label>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className="flex align-items-center">
                                    <Checkbox
                                        inputId="auditoryAccessibility"
                                        name="adaptationOption"
                                        value={formik.values.auditoryAccessibility}
                                        onChange={(e) => formik.setFieldValue("auditoryAccessibility", e.checked)}
                                        checked={formik.values.auditoryAccessibility} />
                                    <label htmlFor="auditoryAccessibility" className="ml-2">{translatedMessage("application.accessibility.auditoryAccessibility")}</label>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className="flex align-items-center">
                                    <Checkbox
                                        inputId="locomotorAccessibility"
                                        name="adaptationOption"
                                        value={formik.values.locomotorAccessibility}
                                        onChange={(e) => formik.setFieldValue("locomotorAccessibility", e.checked)}
                                        checked={formik.values.locomotorAccessibility} />
                                    <label htmlFor="locomotorAccessibility" className="ml-2">{translatedMessage("application.accessibility.locomotorAccessibility")}</label>
                                </div>
                            </div>
                            <div className='col-12 md:col-6'>
                                <div className="flex align-items-center">
                                    <Checkbox
                                        inputId="otherAccessibility"
                                        name="adaptationOption"
                                        value={formik.values.otherAccessibility}
                                        onChange={(e) => formik.setFieldValue("otherAccessibility", e.checked)}
                                        checked={formik.values.otherAccessibility} />
                                    <label htmlFor="otherAccessibility" className="ml-2">{translatedMessage("application.accessibility.otherAccessibility")}</label>
                                </div>
                            </div>
                        </div>
                        {getFormErrorMessage('adaptationOption')}

                        <div className="field pcn-sun-editor w-100 mb-0 mt-3">
                            <label htmlFor="description" className='font-bold'>{translatedMessage('application.accessibility.details')} *</label>
                            <SunEditor
                                id="adaptationDescription"
                                name="adaptationDescription"
                                lang={LanguageService.getCurrentLanguage}
                                height="300px"
                                width='auto'
                                setContents={formik.values.adaptationDescription}
                                placeholder={""}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                    charCounter: ApiService.getIsProduction() ? true : false,
                                    maxCharCount: ApiService.getIsProduction() ? 4000 : null
                                }}
                                onChange={setDescriptionValue}
                            />
                        </div>
                        {getFormErrorMessage('adaptationDescription')}
                    </form>
                </Dialog>
            </>
        );
    }
};

export default ApplicationAccessibilityDialog;
