import React from 'react';
import { translatedMessage } from '../service/LanguageService';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import DataTableUtils from '../utilities/DataTableUtils';

const TestSessionDataTableUtils = {
    testCenterBodyTemplate(_testCenter) {
        return (
            <div>
                {_testCenter?.name}
                <div className="mt-1">
                    {_testCenter?.city + ' ('}
                    {_testCenter?.county?.label + ')'}
                </div>
            </div>
        );
    },

    testCenterFilterTemplate(options, testCenters) {
        return (
            <Dropdown
                value={options.value}
                options={testCenters}
                onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                placeholder={translatedMessage('testCenter.testCenter')}
                className="p-column-filter"
                itemTemplate={(_testCenter) => this.testCenterBodyTemplate(_testCenter)}
                optionValue="id"
                optionLabel="name"
            />
        );
    },

    testCenterRoomBodyTemplate(_testCenterRoom) {
        return <div>{_testCenterRoom.name}</div>;
    },

    testCenterRoomFilterTemplate(options, testCenterRooms) {
        return (
            <Dropdown
                value={options.value}
                options={testCenterRooms}
                onChange={(e) => options.filterApplyCallback(e.value, options.index)}
                placeholder={translatedMessage('competition.testCenterRoom.name')}
                className="p-column-filter"
                itemTemplate={(_testCenterRoom) => this.testCenterRoomBodyTemplate(_testCenterRoom)}
                optionValue="id"
                optionLabel="name"
            />
        );
    },

    dateTimeBodyTemplate(_testSession) {
        return DataTableUtils.dateTimeIntervalTemplate(_testSession.startDate, _testSession.endDate);
    },

    testCenterStartDateFilterTemplate(options) {
        return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value, options.index)} dateFormat="dd.mm.yy" placeholder="Start date" mask="99.99.9999" />;
    }
};

export default TestSessionDataTableUtils;
