import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../service/LanguageService';
import StorageFolderFileList from './file/StorageFolderFileList';
import DOMPurify from 'dompurify';

const DescriptionDisplayModalComponent = (props) => {
    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );

    return (
        <Dialog visible={props.visible} className="pcn-dialog p-fluid" header={translatedMessage(props.dialogTitle)} modal footer={dialogFooter} onHide={() => props.closeDialog()}>
            <div className="w-full text-align-justify align-items-center">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.dialogInfo) }} className="mb-3" />
            </div>
            {props.folder && (
                <div className="w-full grid pcn-data-view-panel">
                    <div className="col-12">
                        <StorageFolderFileList folderId={props.folder.id} folderName={props.folder.name} showUploadButton={false} readOnly={true} />
                    </div>
                </div>
            )}
        </Dialog>
    );
};

export default DescriptionDisplayModalComponent;
