import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ApplicationService } from '../../service/ApplicationService';
import { EvaluationService } from '../../service/EvaluationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import ApplicationResultDialog from '../../components/application/ApplicationResultDialog';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import SunEditor from 'suneditor-react';
import GeneralUtils from '../../utilities/GeneralUtils';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';
import 'suneditor/dist/css/suneditor.min.css';
import ApplicationTestResultEditDialog from '../../components/application/ApplicationTestResultEditDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import DOMPurify from 'dompurify';
import ApplicationTestQuestionsDialog from '../../components/application/ApplicationTestQuestionsDialog';
import ApiService from '../../service/ApiService';
import ResultDataTableUtils from '../../utilities/ResultDataTableUtils';

const EvaluationSecretaryPage = () => {
    const [committee, setCommittee] = useState({});
    const [application, setApplication] = useState(null);
    const [result, setResult] = useState({});
    const [previousResult, setPreviousResult] = useState({});
    const [reload, setReload] = useState({});
    const [applicationTests, setApplicationTests] = useState(null);
    const [selectedTest, setSelectedTest] = useState(null);
    const [showTests, setshowTests] = useState(false);
    const [testQuestions, setTestQuestions] = useState([]);
    const [answersDialogVisible, setAnswersDialogVisible] = useState(false);

    const [editApplicationResultVisible, setEditApplicationResultVisible] = useState(false);
    const [editTestDialogVisible, setEditTestDialogVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");

    const toast = useRef(null);
    const dt = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    const { competitionIdParam, applicationIdParam } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)

        const getApplication = async () => {
            await applicationService.getApplicationForEdit(applicationIdParam)
                .then(async (_application) => {
                    setApplication(_application)
                    await getCommitteeByApplication(applicationIdParam)
                    setIsLoading(false)
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getCommitteeByApplication = async (applicationId) => {
            await evaluationService.getCommitteeByApplication(applicationId)
                .then(async (_committee) => {
                    setCommittee(_committee)
                    await getApplicationResult(_committee.id, applicationIdParam);
                    if (_committee.type === "PRELIMINARY_TEST_CONTESTATION") {
                        await getApplicationTests(applicationId)
                    }
                    if (_committee.type === "ADVANCED_TEST_CONTESTATION") {
                        await getApplicationResultByType(applicationId, "ADVANCED_TEST")
                    }
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationResult = async (committeeId, applicationId) => {
            await evaluationService.getCommitteeApplicationResult(committeeId, applicationId)
                .then((_result) => {
                    setResult(_result);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationResultByType = async (applicationId, resultType) => {
            await evaluationService.getApplicationResultByType(applicationId, resultType)
                .then((_result) => {
                    setPreviousResult(_result);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        

        getApplication()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, evaluationService, applicationIdParam, reload]);

    const getApplicationTests = async (applicationId) => {
        await applicationService.getApplicationTests(applicationId)
            .then((response) => {
                let _applicationTests = response.applicationTestList

                if (response.preliminaryTestScore) {
                    let _preliminaryTests = _applicationTests.filter(item => !item.competitionTest.advancedTest)
                    let _advancedTests = _applicationTests.filter(item => item.competitionTest.advancedTest)
                    _applicationTests = _preliminaryTests

                    _applicationTests.push({
                        competitionTest: {
                            test: { name: translatedMessage("test.preliminaryTest.overallScore") },
                            passingScore: response.preliminaryTestPassingScore
                        },
                        result: response.preliminaryTestScore,
                        resultAfterContestation: response.preliminaryTestContestationScore,
                        isOverAllScore: true
                    })

                    _advancedTests.forEach(item => _applicationTests.push(item))
                }

                setApplicationTests(_applicationTests);
                setshowTests(true)
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };


    const openApplicationResultDialog = () => {
        setEditApplicationResultVisible(true);
    };

    const afterUpdateApplicationStatus = async (savedApplication) => {
        setApplication(savedApplication);
        setReload({ ...reload });
    };

    const onRejectionReasonEditorChange = (content) => {
        let _result = result
        _result.rejectionReason = content

        setResult(_result)
        formik.setFieldValue("rejectionReason", content)
    };

    const onCommentEditorChange = (content) => {
        let _result = result;
        _result.comment = content;

        setResult(_result);
        formik2.setFieldValue("comment", content)
    };

    const isCompetitionInAdvancedTestsPhase = (competition) => {
        return competition?.status === 'ADVANCED_TEST' || competition?.status === 'ADVANCED_TEST_CONTESTATION_VALIDATION';
    };

    const isCompetitionInAdvancedTestsContestation = (competition) => {
        return competition?.status === 'ADVANCED_TEST_CONTESTATION_VALIDATION';
    };

    const formik = useFormik({
        initialValues: {
            rejectionReason: result?.rejectionReason
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.rejectionReason || !GeneralUtils.nullIfEmptyHTMLEditor(data.rejectionReason)) {
                errors.rejectionReason = translatedMessage("form.error.rejectionReason.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            applicationService.saveRejectionReason(result)
                .then((_result) => {
                    onRejectionReasonEditorChange(_result.rejectionReason)
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const formik2 = useFormik({
        initialValues: {
            comment: result.comment
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.comment || !GeneralUtils.nullIfEmptyHTMLEditor(data.comment)) {
                errors.comment = translatedMessage("form.error.comment.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            applicationService.saveCommentOnApplicationResult(result)
                .then((_result) => {
                    onCommentEditorChange(_result.comment)
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left mt-1">{formik.errors[name]}</small>;
    };

    const isFormFieldValidOnFormik2 = (name) => {
        return !!(formik2.touched[name] && formik2.errors[name]);
    }

    const getFormErrorMessageOnFormik2 = (name) => {
        return isFormFieldValidOnFormik2(name) && <small className="p-error text-align-left mt-1">{formik2.errors[name]}</small>;
    };

    const getSelectedAnswers = async (_applicationTest) => {
        await applicationService.getSelectedAnswers(_applicationTest)
            .then(_answers => {
                setTestQuestions(_answers)
                setSelectedTest(_applicationTest)
                setAnswersDialogVisible(true)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const getCorrectionScale = async (_applicationTest) => {
        await applicationService.getCorrectionScale(_applicationTest)
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const onTestEdit = (_applicationTest) => {
        setSelectedTest(_applicationTest)
        setEditTestDialogVisible(true)
    }

    const actionBodyTemplate = (_applicationTest) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {!_applicationTest.isOverAllScore && <>
                    <Button icon="pi pi-list" className="p-button-rounded p-button-info m-1"
                        onClick={() => getSelectedAnswers(_applicationTest)}
                        tooltip={translatedMessage("test.scale.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />

                    <Button icon="pi pi-download" className="p-button-rounded p-button-info m-1"
                        onClick={() => getCorrectionScale(_applicationTest)}
                        disabled={_applicationTest?.competitionTest?.test?.provider !== "DIGITEST" || !_applicationTest.hasFile}
                        tooltip={translatedMessage("test.scale.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />

                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1"
                        onClick={() => onTestEdit(_applicationTest)}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                </>}
            </div>
        );
    };

    const updateTestList = async (savedTest) => {        
        let updatedTests = DataTableUtils.updateTableList(applicationTests, savedTest, false)
        setApplicationTests(updatedTests);
        
        getApplicationTests(application?.id)
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">
                                {translatedMessage('evaluation.evaluation')} {translatedMessage('generic.for')} {application?.firstName} {application?.lastName}
                            </h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation/${competitionIdParam}`)}>
                                    <div className="flex align-items-center">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="w-full grid pcn-data-view-panel">
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('competition.competition')}</div>
                                    <div className="filed-value">{application?.competition?.name}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('evaluation.committee')}</div>
                                    <div className="filed-value">{committee.name}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('generic.type')}</div>
                                    <div className="filed-value">{committee.type ? translatedMessage('EvaluationCommitteeType.' + committee.type) : '-'}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('committee.member.role')}</div>
                                    <div className="filed-value">{translatedMessage('committee.member.isSecretary')}</div>
                                </div>

                                {!isCompetitionInAdvancedTestsPhase(application?.competition) && showTests &&
                                    <div className="col-12 mt-3 mb-3">
                                        <h5 className="mb-1">{translatedMessage('test.tests')}</h5>
                                        <DataTable
                                            ref={dt}
                                            value={applicationTests}
                                            dataKey="id"
                                            className="datatable-responsive pcn-datatable"
                                            responsiveLayout="stack"
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        >
                                            <Column
                                                field="competitionTest.testOrder"
                                                header={translatedMessage('test.order')}
                                                headerStyle={{ width: '5%', minWidth: '6rem' }}
                                                body={(e) => e.isOverAllScore ? "" : e.competitionTest.testOrder}
                                            />
                                            <Column
                                                field="competitionTest.test.name"
                                                header={translatedMessage('test.name')}
                                                headerStyle={{ width: '30%', minWidth: '10rem' }}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column
                                                field="competitionTest.passingScore"
                                                header={translatedMessage('test.passingScore')}
                                                headerStyle={{ width: '15%', minWidth: '10rem' }}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column
                                                field="result"
                                                header={translatedMessage('test.result')}
                                                headerStyle={{ width: '15%', minWidth: '10rem' }}
                                                body={(e) => ResultDataTableUtils.resultBodyTemplate(e)}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column
                                                field="resultAfterContestation"
                                                header={translatedMessage('test.resultAfterContestation')}
                                                headerStyle={{ width: '15%', minWidth: '10rem' }}
                                                body={(e) => ResultDataTableUtils.resultContestationBodyTemplate(e)}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column body={actionBodyTemplate}></Column>
                                        </DataTable>
                                    </div>
                                }

                                <div className="col-12">
                                    <h5 className="mb-1">{translatedMessage(committee.active ? 'evaluation.evaluationStatus' : 'evaluation.result')}</h5>
                                    <div className="filed-value flex align-items-center">
                                        {committee?.active ? (
                                            <span className={`status status-application-${application.status.toString().toLowerCase()}`}>
                                                {application?.status ? translatedMessage('ApplicationStatus.' + application.status) : '-'}
                                            </span>
                                        ) : (
                                            <span className={`status status-result-${result?.result?.toString().toLowerCase()}`}>
                                                {result?.result ? translatedMessage('Result.' + result?.result) : '-'}
                                            </span>
                                        )}
                                        {committee?.active && (
                                            <div className="ml-2">
                                                <Button label={translatedMessage('application.result.set')} icon="pi pi-pencil" className="p-button-text pcn-button-slim" onClick={openApplicationResultDialog} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!isCompetitionInAdvancedTestsPhase(application?.competition) && application.status === 'REJECTED' && (
                                    <>
                                        <div className="col-12">
                                            <div className="filed-label">{translatedMessage('application.result.rejectionReason')}</div>
                                            <form id="result-form" onSubmit={formik.handleSubmit}>
                                                <SunEditor
                                                    id="rejectionReason"
                                                    name="rejectionReason"
                                                    lang={LanguageService.getCurrentLanguage}
                                                    height="250px"
                                                    width="100%"
                                                    setContents={result?.rejectionReason}
                                                    setOptions={{
                                                        buttonList: GeneralUtils.getSunEditorButtons(),
                                                        font: GeneralUtils.getSunEditorFonts(),
                                                        defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                                        charCounter: ApiService.getIsProduction() ? true : false,
                                                        maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                                    }}
                                                    autoFocus={true}
                                                    onChange={(e) => onRejectionReasonEditorChange(e)}
                                                />
                                                {getFormErrorMessage('rejectionReason')}
                                            </form>

                                            <div className="w-full text-align-left mt-3">
                                                <Button className="px-3" label={translatedMessage('generic.save')} form="result-form" type="submit" />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {isCompetitionInAdvancedTestsPhase(application?.competition) && !isCompetitionInAdvancedTestsContestation(application?.competition) && (
                                    <>
                                        <div className="col-12">
                                            <h5 className="mb-1">{translatedMessage('test.advanced.result')}</h5>
                                            <div className="filed-label">{translatedMessage('generic.files')}</div>
                                            <StorageFolderFileList folderId={result?.folder?.id} folderName={result?.folder?.name} showUploadButton={true} hidePaginator={true} />
                                            <div className="mt-5 filed-label">{translatedMessage('application.result.comment')}</div>
                                            <form id="result-form" onSubmit={formik2.handleSubmit}>
                                                <SunEditor
                                                    id="comment"
                                                    name="comment"
                                                    lang={LanguageService.getCurrentLanguage}
                                                    height="250px"
                                                    width="100%"
                                                    setContents={result?.comment}
                                                    setOptions={{
                                                        buttonList: GeneralUtils.getSunEditorButtons(),
                                                        font: GeneralUtils.getSunEditorFonts(),
                                                        defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                                        charCounter: ApiService.getIsProduction() ? true : false,
                                                        maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                                    }}
                                                    autoFocus={true}
                                                    onChange={onCommentEditorChange}
                                                />
                                                {getFormErrorMessageOnFormik2('comment')}
                                            </form>

                                            <div className="w-full text-align-left mt-3">
                                                <Button className="px-3" label={translatedMessage('generic.save')} form="result-form" type="submit" />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {isCompetitionInAdvancedTestsContestation(application?.competition) && (
                                    <>
                                        {application.status === 'REJECTED' &&
                                            <div className="col-12">
                                                <div className="filed-label">{translatedMessage('application.result.rejectionReason')}</div>
                                                <form id="result-form" onSubmit={formik.handleSubmit}>
                                                    <SunEditor
                                                        id="rejectionReason"
                                                        name="rejectionReason"
                                                        lang={LanguageService.getCurrentLanguage}
                                                        height="250px"
                                                        width="100%"
                                                        setContents={result?.rejectionReason}
                                                        setOptions={{
                                                            buttonList: GeneralUtils.getSunEditorButtons(),
                                                            font: GeneralUtils.getSunEditorFonts(),
                                                            defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                                            charCounter: ApiService.getIsProduction() ? true : false,
                                                            maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                                        }}
                                                        autoFocus={true}
                                                        onChange={(e) => onRejectionReasonEditorChange(e)}
                                                    />
                                                    {getFormErrorMessage('rejectionReason')}
                                                </form>

                                                <div className="w-full text-align-left mt-3">
                                                    <Button className="px-3" label={translatedMessage('generic.save')} form="result-form" type="submit" />
                                                </div>
                                            </div>
                                        }

                                        <div className="col-12">
                                            <h5 className="mb-1">{translatedMessage('test.advanced.result')}</h5>
                                            <div className="filed-label">{translatedMessage('generic.files')}</div>
                                            <StorageFolderFileList
                                                folderId={previousResult?.folder?.id}
                                                folderName={previousResult?.folder?.name}
                                                showUploadButton={true}
                                                readOnly={true}
                                                hidePaginator={true} />

                                            <div className="mt-5 filed-label">{translatedMessage('application.result.comment')}</div>
                                            <div className='filed-value'>
                                                {previousResult?.comment
                                                    ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previousResult?.comment) }} />
                                                    : "-"
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {editApplicationResultVisible &&
                    <ApplicationResultDialog
                        visible={editApplicationResultVisible}
                        visibleSetter={setEditApplicationResultVisible}
                        application={application}
                        afterSave={afterUpdateApplicationStatus}
                    />
                }

                {editTestDialogVisible &&
                    <ApplicationTestResultEditDialog
                        visible={editTestDialogVisible}
                        visibleSetter={(value) => setEditTestDialogVisible(value)}
                        afterSave={updateTestList}
                        applicationTest={selectedTest}
                    />
                }

                {answersDialogVisible &&
                    <ApplicationTestQuestionsDialog
                        visible={answersDialogVisible}
                        questions={testQuestions}
                        applicationTest={selectedTest}
                        title={translatedMessage("test.scale.view")}
                        closeDialog={() => setAnswersDialogVisible(false)}
                    />
                }
            </>
        );
    }
};

export default EvaluationSecretaryPage;
