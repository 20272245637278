import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import LanguageListComponent from '../../components/profile/LanguageListComponent';
import { useParams } from 'react-router-dom';
import useNavProps from '../../hooks/useNavProps';
import ProfileToolbar from './ProfileToolbar';

const ProfileLanguage = () => {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [languageList, setLanguageList] = useState(null);
    const toast = useRef(null);
    const { userIdParam } = useParams();
    const { setNavPropsValue } = useNavProps();

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)
        const getProfile = async () => {
            await userService.getUserProfile(userIdParam)
                .then(async response => {
                    setUser(response)
                    setNavPropsValue('users', response.id, response.fullName)

                    await getLanguageList()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        const getLanguageList = async () => {
            await userService.getUserProfileLanguageSkill(userIdParam)
                .then(languageRespone => {
                    setLanguageList(languageRespone)
                    setIsLoading(false)
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        getProfile()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userService, userIdParam]);


    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <ProfileToolbar user={user} code="LANGUAGE" />

                            <div className='w-full text-align-left'>
                                <h5>{translatedMessage("profile.language")}</h5>
                            </div>

                            <LanguageListComponent 
                                language={languageList} 
                                onListUpdate={(list) => setLanguageList(list)} 
                                hasExportButton={true}
                                updateFields={true}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ProfileLanguage;
