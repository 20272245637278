import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { FilterMatchMode } from 'primereact/api';
import DOMPurify from 'dompurify';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import ApiService from "../../service/ApiService";
import RecruitmentPlanEdit from './RecruitmentPlanEdit';
import StorageFileUpload from '../../components/file/StorageFileUpload';
import DataTableUtils from '../../utilities/DataTableUtils';
import useAuth from "../../hooks/useAuth";
import StorageFileDownloadTableButton from '../../components/file/StorageFileDownloadTableButton';
import StorageFileViewTableButton from '../../components/file/StorageFileViewTableButton';
import DeleteModalComponent from '../../components/DeleteModalComponent';
import EnumService from '../../service/EnumService';
import RecruitmentDataTableUtils from '../../utilities/RecruitmentDataTableUtils';
import ExportButtonComponent from '../../components/ExportButtonComponent';

const tableHeader = [
    translatedMessage("generic.nameRo"),
    translatedMessage("generic.status"),
    translatedMessage("generic.lastUpdated.on")
]


const RecruitmentPlanList = () => {

    const emptyRecruitmentPlan = {
        id: null,
        name: '',
        description: '',
        status: null,
        folderId: null,
        fileUuid: null,
        fileName: ''
    };

    const [recruitmentPlans, setRecruitmentPlans] = useState([]);
    const [statusValues, setStatusValues] = useState([]);
    const [recruitmentPlan, setRecruitmentPlan] = useState(emptyRecruitmentPlan);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.IN }
    });

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const getEnumValues = async () => {
            const response = await EnumService.getEnumByName("RecruitmentPlanStatus");
            setStatusValues(response)
        }

        refreshRecruitmentPlans()
        getEnumValues()
    }, []);

    const refreshRecruitmentPlans = async () => {
        async function getAll() {
            return ApiService.getAuthenticatedInstance().get('/recruitment/list')
                .then((res) => res.data);
        }
        const rp = await getAll();
        return setRecruitmentPlans(rp);
    };

    const showNewRecruitmentPlanDialog = () => {
        setRecruitmentPlan(emptyRecruitmentPlan);
        setEditDialogVisible(true);
    };

    const showEditRecruitmentPlan = (recruitmentPlan) => {
        setRecruitmentPlan(emptyRecruitmentPlan)
        let rp = { ...recruitmentPlan };
        if (!rp.file) {
            rp.file = { id: '', name: '', uuid: '' };
        }
        setRecruitmentPlan(rp);
        setEditDialogVisible(true);
    };

    const showUploadRecruitmentPlan = (recruitmentPlan) => {
        setRecruitmentPlan(recruitmentPlan);
        setUploadDialogVisible(true);
    };

    const showViewDialog = (recruitmentPlan) => {
        let rp = { ...recruitmentPlan };
        setRecruitmentPlan(rp);
        setViewDialogVisible(true);
    };

    const hideViewDialog = () => {
        setViewDialogVisible(false);
    }

    const showDeleteRecruitmentPlanDialog = (recruitmentPlan) => {
        setRecruitmentPlan(recruitmentPlan);
        setDeleteDialogVisible(true);
    };

    const hideDeleteRecruitmentPlanDialog = () => {
        setDeleteDialogVisible(false);
    };

    const onFileUploadSuccess = (planId, response) => {
        recruitmentPlans.map(item => {
            if (item.id === planId) {
                item.fileUuid = response && response[0] && response[0].uuid
                item.fileName = response && response[0] && response[0].name
            }

            return item
        });
        setRecruitmentPlans(recruitmentPlans)
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const deleteRecruitmentPlan = () => {
        async function deletePlan() {
            return ApiService.getAuthenticatedInstance()
                .delete('/recruitment/' + recruitmentPlan.id)
                .then((res) => res.data);
        }
        return deletePlan()
            .then(
                () => {
                    refreshRecruitmentPlans();
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.delete.success'), life: 3000 });
                },
                (error) => {
                    console.log(error)
                    let errorMessage = error && error.response && error.response.data && error.response.data.message;
                    toast.current.show({ severity: 'error', summary: translatedMessage('generic.error'), detail: translatedMessage('generic.delete.error') + ' : ' + errorMessage, life: 3000 });
                }
            ).finally(() => {
                setDeleteDialogVisible(false);
                setRecruitmentPlan(emptyRecruitmentPlan);
            });
    };

    const viewRecruitmentPlanDialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={hideViewDialog} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('RECRUITMENT_CREATE') && <Button label={translatedMessage('recruitmentPlan.newPlan')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewRecruitmentPlanDialog} />}
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let exportData = recruitmentPlans.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.status = translatedMessage("RecruitmentPlanStatus." + item.status)
            exportItem.lastUpdatedOn = new Date(Date.parse(item.lastUpdatedOn))
            delete exportItem.folderId
            delete exportItem.fileUuid
            delete exportItem.fileName
            delete exportItem.description

            return item;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                fileName={translatedMessage("recruitmentPlan.recruitmentPlan")}                    
            />
        );
    };

    const lastUpdatedOnBodyTemplate = (rowData) => {
        return GeneralUtils.formatDate(rowData.lastUpdatedOn);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('RECRUITMENT_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('RECRUITMENT_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditRecruitmentPlan(rowData)}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('RECRUITMENT_EDIT') &&
                    <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => showUploadRecruitmentPlan(rowData)}
                        tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {<StorageFileViewTableButton fileUuid={rowData.fileUuid} fileName={rowData.fileName} />}
                {<StorageFileDownloadTableButton fileUuid={rowData.fileUuid} fileName={rowData.fileName} />}
                {hasPermission('RECRUITMENT_EDIT') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteRecruitmentPlanDialog(rowData)}
                        disabled={rowData.id < 1000} tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="grid h-full">
                <div className="col-12">
                    <div className="card h-full">
                        <h3 className='mb-0'>{translatedMessage('recruitmentPlan.recruitmentPlans')}</h3>

                        <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                        <DataTable
                            ref={dt}
                            value={recruitmentPlans}
                            dataKey="id"
                            paginator
                            rows={DataTableUtils.defalRowsPerPage()}
                            rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                            className="datatable-responsive pcn-datatable"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                            responsiveLayout="stack"
                            filterDisplay="row"
                            filters={filters}
                            globalFilterFields={['name', 'status']}
                        >
                            <Column
                                field="name"
                                header={translatedMessage('generic.nameRo')}
                                sortable
                                headerStyle={{ width: '30%', minWidth: '10rem' }}
                                filter
                                showFilterMenu={false}
                            ></Column>
                            <Column
                                field="status"
                                header={translatedMessage('generic.status')}
                                sortable
                                body={(e) => RecruitmentDataTableUtils.statusBodyTemplate(e.status)}
                                headerStyle={{ width: '15%', minWidth: '8rem' }}
                                filter
                                filterElement={(e) => RecruitmentDataTableUtils.statusFilterTemplate(e, statusValues)}
                                showFilterMenu={false}
                            ></Column>
                            <Column
                                field="lastUpdatedOn"
                                header={translatedMessage('generic.lastUpdated.on')}
                                sortable
                                body={lastUpdatedOnBodyTemplate}
                                headerStyle={{ width: '10%', minWidth: '8rem' }}
                            ></Column>
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>

                        {/* Edit dialog */}
                        <RecruitmentPlanEdit
                            value={recruitmentPlan}
                            visible={editDialogVisible}
                            afterSave={refreshRecruitmentPlans}
                            visibleSetter={setEditDialogVisible}
                        />

                        <StorageFileUpload
                            entityId={recruitmentPlan.id}
                            folderId={recruitmentPlan.folderId}
                            usageId={null}
                            deletePrevious={true}
                            multiple={false}
                            accept={["pdf", "image"]}
                            maxFileSize={26214400}
                            visible={uploadDialogVisible}
                            afterSave={refreshRecruitmentPlans}
                            visibleSetter={setUploadDialogVisible}
                            onUploadSuccess={onFileUploadSuccess}
                            onUploadError={onFileUploadError}
                        />

                        <DeleteModalComponent
                            visible={deleteDialogVisible}
                            item={recruitmentPlan.name}
                            closeDialog={hideDeleteRecruitmentPlanDialog}
                            deleteRecord={() => deleteRecruitmentPlan()}
                        />

                        {/* View dialog */}
                        <Dialog
                            visible={viewDialogVisible}
                            header={`${recruitmentPlan.name} (${translatedMessage("RecruitmentPlanStatus." + recruitmentPlan.status)})`}
                            modal
                            footer={viewRecruitmentPlanDialogFooter}
                            onHide={hideViewDialog}
                            className="pcn-dialog p-fluid"
                        >
                            <div className="p-inputtext pcn-html-editor-view-container mb-3">
                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recruitmentPlan?.description) }} />
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecruitmentPlanList;
