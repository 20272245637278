import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { translatedMessage } from '../../service/LanguageService';
import FormDisplay from './FormDisplay';

const FormViewDialog = (props) => {

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );


    return (
        <>
            <Dialog
                visible={props.visible}
                header={translatedMessage('form.details')}
                modal
                onHide={() => props.closeDialog()}
                className="pcn-dialog p-fluid"
                footer={dialogFooter}
            >
                <FormDisplay formFields={props.formFields} />
            </Dialog>
        </>
    );
};

export default FormViewDialog;
