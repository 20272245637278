import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompetitionService } from '../../service/CompetitionService';
import EnumService from '../../service/EnumService';
import { ApplicationService } from '../../service/ApplicationService';
import ApplicationDataTableUtils from '../../utilities/ApplicationDataTableUtils';
import ResultDataTableUtils from '../../utilities/ResultDataTableUtils';
import SecurityService from '../../service/SecurityService';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import GeneralUtils from '../../utilities/GeneralUtils';

const sortOrderHeader = [
    "fullName", "username", "uid", "registrationNumber", "resultType", "result", "score", "competitionStatus", "competition"
]

const tableHeader = [
    translatedMessage("generic.name"),
    translatedMessage("generic.email"),
    translatedMessage("profile.uid"),
    translatedMessage("application.registrationNumber"),
    translatedMessage("application.result.type"),
    translatedMessage("application.result"),
    translatedMessage("application.result.score"),
    translatedMessage("competition.status"),
    translatedMessage("competition.competition")
]

const EvaluationPhaseResultPage = () => {
    const [competition, setCompetition] = useState({});
    const [results, setResults] = useState([]);
    const [resultTypes, setResultTypes] = useState([]);
    const [selectedResultType, setSelectedResultType] = useState({ value: 'JOB_WRITTEN_TEST' });
    const [canEditResults, setCanEditResults] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true);
    const [error, setError] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const { competitionIdParam } = useParams();

    const applicationService = useMemo(() => new ApplicationService(), []);
    const competitionService = useMemo(() => new CompetitionService(), []);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 100,
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            applicationStatus: { value: null, matchMode: FilterMatchMode.IN },
            result: { value: null, matchMode: FilterMatchMode.EQUALS },
        }
    });

    const propToColumnMap = {
        'fullName': 'full_name',
        'applicationStatus': 'status',
        'result': 'result',
    };
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        setFirstLoading(true)
        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then(async (competitionData) => {
                    setCompetition(competitionData)
                    await getResultTypes(competitionData)
                    await checkCanEditResults()
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getResultTypes = async (_competition) => {
            let _types = await EnumService.getEnumByNameAndParam('ResultTypeJob', competitionIdParam)
            _types = _types.filter(item => !item.value.includes("ELIGIBILITY"))

            let _selectedResultType = selectedResultType
            if (_competition.resultType) {
                _selectedResultType = _types.filter(item => item.value === _competition.resultType)[0]
            } else if (_competition.status === "FINISHED") {
                _selectedResultType = { label: translatedMessage("ResultType.COMPETITION_FINAL"), value: 'COMPETITION_FINAL' }
            }

            setResultTypes(_types);
            setSelectedResultType(_selectedResultType)
            setFirstLoading(false)
        };

        const checkCanEditResults = async () => {
            setCanEditResults(await SecurityService.check('CAN_EDIT_COMPETITION_PHASE_RESULTS', competitionIdParam))
        }

        getCompetition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionIdParam]);

    useEffect(() => {
        if (!firstLoading) {
            loadApplicationResult();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams, firstLoading]);

    const setCriteria = (resultType) => {
        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || ('application_id');
        criteria.competitionId = competitionIdParam;

        if (resultType.value === competition.resultType) {
            criteria.applicationStatuses = lazyParams.filters.applicationStatus.value;
        } else {
            criteria.result = lazyParams.filters.result.value;
        }
        criteria.name = lazyParams.filters.fullName.value;
        criteria.resultType = resultType.value

        return criteria;
    }


    const loadApplicationResult = async (resultType = selectedResultType) => {
        setIsLoading(true)

        let criteria = setCriteria(resultType);
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService.getCompetitionResultByType(criteria)
            .then((data) => {
                setResults(data.results)
                setTotalRecords(data.totalCount)
                setIsLoading(false)
            })
            .catch((error) => {
                setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const statusDropdownTemplate = (option) => {
        return (
            <div className={`inline-block status status-application-${option.value.toString().toLowerCase()}`}>
                {translatedMessage('ApplicationStatus.' + option.value)}
            </div>
        )
    }

    const computeApplicationStatus = (_status) => {
        let options = [
            { label: translatedMessage('ApplicationStatus.ACCEPTED'), value: 'ACCEPTED' },
            { label: translatedMessage('ApplicationStatus.REJECTED'), value: 'REJECTED' },
            { label: translatedMessage('ApplicationStatus.ABSENT'), value: 'ABSENT' },
            { label: translatedMessage('ApplicationStatus.CANCELED'), value: 'CANCELED' }
        ];

        if (_status === 'IN_VALIDATION') {
            options.push({ label: translatedMessage('ApplicationStatus.IN_VALIDATION'), value: 'IN_VALIDATION' })
        }

        if (_status === 'CONTESTATION_VALIDATION') {
            options.push({ label: translatedMessage('ApplicationStatus.CONTESTATION_VALIDATION'), value: 'CONTESTATION_VALIDATION' })
        }

        return options
    }

    const handleStatusChange = (e) => {
        let options = computeApplicationStatus(e.rowData.applicationStatus)
        return (
            e.rowData.resultType === competition.resultType
                ? <Dropdown
                    value={e.rowData.applicationStatus}
                    options={options}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(option) => e.editorCallback(option.value)}
                    placeholder={translatedMessage('generic.status')}
                    itemTemplate={statusDropdownTemplate}
                    valueTemplate={statusDropdownTemplate}
                />
                : statusTemplate(e.rowData)
        );
    }

    const handleScoreChange = (options) => {
        return (
            options.rowData.resultType === competition.resultType && options.rowData.hasScore
                ? <InputNumber value={options.rowData.score} onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} />
                : scoreTemplate(options)
        );
    }

    const statusTemplate = (_data) => {
        if (_data.resultType === competition.resultType) {
            return (
                <div className={`inline-block status status-application-${_data.applicationStatus.toLowerCase()}`}>
                    {translatedMessage("ApplicationStatus." + _data.applicationStatus)}
                </div>
            );
        } else {
            return (
                <div className={`inline-block status status-result-${_data.result.toLowerCase()}`}>
                    {translatedMessage("Result." + _data.result)}
                </div>
            );
        }
    };

    const scoreTemplate = (_data) => {
        return _data.hasScore && _data.score ? _data.score : "-"
    };

    const leftToolbarTemplate = () => {
        return <></>
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("application.result.forPhase")
                    + "_" + translatedMessage("ResultType." + selectedResultType.value))}
            />
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria(selectedResultType)
        return new Promise((resolve, reject) => {
            competitionService.getCompetitionResultByType(criteria)
                .then((data) => {
                    let exportData = data.results.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.result = selectedResultType.value === competition.resultType
                            ? translatedMessage("ApplicationStatus." + item.applicationStatus)
                            : translatedMessage("Result." + item.result)

                        exportItem.resultType = translatedMessage("ResultType." + item.resultType)
                        exportItem.competitionStatus = translatedMessage("CompetitionStatus." + competition.status)
                        exportItem.competition = competition.name

                        delete exportItem.applicationId
                        delete exportItem.applicationStatus
                        delete exportItem.competitionId
                        delete exportItem.hasScore
                        delete exportItem.firstName
                        delete exportItem.lastName
                        delete exportItem.id
                        delete exportItem.rejectionReason
                        delete exportItem.resultId

                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const onRowEditComplete = (e) => {
        let { data, newData, index } = e;

        let minAcceptedScore = 60
        if (competition?.positionCategory === "FUNCTII_PUBLICE_DE_CONDUCERE" || competition?.positionCategory === "INALTI_FUNCTIONARI_PUBLICI") {
            minAcceptedScore = 70
        }

        let isValid = true
        let errorMessage = null

        if (data.hasScore && newData.applicationStatus === 'ACCEPTED' && (!newData.score || newData.score < minAcceptedScore)) {
            isValid = false
            errorMessage = "job.result.accepted.error"
        }
        if (data.hasScore && newData.applicationStatus === 'REJECTED' && (!newData.score || newData.score >= minAcceptedScore)) {
            isValid = false
            errorMessage = "job.result.rejected.error"
        }
        if ((newData.applicationStatus === 'IN_VALIDATION' ||
            newData.applicationStatus === 'CONTESTATION_VALIDATION' ||
            newData.applicationStatus === 'CANCELED' ||
            newData.applicationStatus === 'ABSENT') && newData.score && data.hasScore) {

            isValid = false
            errorMessage = "job.result.validation.error"
        }


        if (isValid) {
            applicationService.updateApplicationResult({
                id: data.resultId,
                application: { id: data.applicationId, status: newData.applicationStatus },
                score: newData.score
            })
                .then(() => {
                    let _results = [...results];
                    _results[index] = newData;

                    setResults(_results);

                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        } else {
            toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage, minAcceptedScore), life: 7000 })
        }

    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0
        setLazyParams(event)
    };

    const filterStatus = (e) => {
        if (selectedResultType.value === competition.resultType) {
            let options = [
                { label: translatedMessage('ApplicationStatus.ACCEPTED'), value: 'ACCEPTED' },
                { label: translatedMessage('ApplicationStatus.REJECTED'), value: 'REJECTED' },
                { label: translatedMessage('ApplicationStatus.ABSENT'), value: 'ABSENT' },
                { label: translatedMessage('ApplicationStatus.CANCELED'), value: 'CANCELED' },
                { label: translatedMessage('ApplicationStatus.IN_VALIDATION'), value: 'IN_VALIDATION' }
            ];

            if (competition.status.includes('CONTESTATION')) {
                options.push({ label: translatedMessage('ApplicationStatus.CONTESTATION_VALIDATION'), value: 'CONTESTATION_VALIDATION' })
            }

            return ApplicationDataTableUtils.statusFilterTemplate(e, options)
        } else {
            return ResultDataTableUtils.statusFilterTemplate(e)
        }
    }

    const onResultTypeChange = (_resultType) => {
        let _selectedType = resultTypes.filter(item => item.value === _resultType)
        if (_selectedType && _selectedType[0]) {
            setSelectedResultType(_selectedType[0])
            loadApplicationResult(_selectedType[0])
        }
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h3 className="mb-1">{competition.name}</h3>
                                <div className="mb-3">
                                    <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation`)}>
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                            <span>{translatedMessage('generic.backToList')}</span>
                                        </div>
                                    </Button>
                                </div>
                                <div className="mb-4">
                                    <div className="flex align-items-center">
                                        <div className="font-bold mr-1">{translatedMessage('competition.status') + ':'}</div>
                                        <div className={`inline-block status status-competition-${competition?.status?.toString().toLowerCase()}`}>{translatedMessage('CompetitionStatus.' + competition?.status)}</div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h5 className="mb-1 font-weight-normal">{translatedMessage('application.result.forPhase')}</h5>
                                    <Dropdown
                                        value={selectedResultType?.value}
                                        options={resultTypes}
                                        onChange={(e) => onResultTypeChange(e.value)}
                                        className="p-column-filter"
                                        itemTemplate={(_result) => {
                                            return <span className={`status status-result-${_result.value.toString().toLowerCase()}`}>{translatedMessage('ResultType.' + _result.value)}</span>;
                                        }}
                                    />
                                </div>


                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={results}
                                    dataKey="id"
                                    loading={isLoading}
                                    lazy
                                    paginator
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    responsiveLayout="scrool"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    onRowEditComplete={onRowEditComplete}
                                    editMode="row"
                                >
                                    <Column
                                        field="fullName"
                                        header={translatedMessage('generic.name')}
                                        headerStyle={{ width: '30%', minWidth: '10rem' }}
                                        sortable
                                        filter
                                        filterField="fullName"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field={selectedResultType.value === competition.resultType ? "applicationStatus" : "result"}
                                        header={translatedMessage('application.result')}
                                        headerStyle={{ width: '30%', minWidth: '10rem' }}
                                        body={statusTemplate}
                                        editor={(e) => handleStatusChange(e)}
                                        sortable
                                        filter
                                        filterField={selectedResultType.value === competition.resultType ? "applicationStatus" : "result"}
                                        filterElement={(e) => filterStatus(e)}
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="score"
                                        header={translatedMessage('application.result.score')}
                                        headerStyle={{ width: '30%', minWidth: '10rem' }}
                                        sortable
                                        body={scoreTemplate}
                                        editor={(e) => handleScoreChange(e)}
                                    />
                                    <Column
                                        rowEditor
                                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                                        bodyStyle={{ textAlign: 'center' }}
                                        hidden={selectedResultType.value !== competition.resultType || !canEditResults} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );

};

export default EvaluationPhaseResultPage;
