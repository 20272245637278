import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { translatedMessage } from '../../service/LanguageService';
import FieldDisplay from '../../components/form/FieldDisplay';
import DataTableUtils from '../../utilities/DataTableUtils';
import useAuth from '../../hooks/useAuth';
import { EvaluationService } from '../../service/EvaluationService';

const EvaluationFormPage = (props) => {
    const [evaluation, setEvaluation] = useState({});
    const [filteredFieldEvaluation, setFilteredFieldEvaluation] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getEvaluation = () => {
            setEvaluation(props?.evaluation)
            if (props?.evaluation.fieldsEvaluation) {
                let _formFieldsEvaluation = props?.evaluation?.fieldsEvaluation
                    .filter(item => item.applicationFormField.formField.field.type !== 'CUSTOM')

                setFilteredFieldEvaluation(_formFieldsEvaluation)
            }

            setIsLoading(false)
        }


        getEvaluation()
    }, [props]);

    const fieldTemplate = (rowData) => {
        return (
            <>
                <FieldDisplay field={rowData.applicationFormField} className="pcn-form-field-view" updateField={false} />
            </>
        );
    };

    const fieldStatusTemplate = (rowData) => {
        return (
            <div className={`cursor-pointer inline-block status status-field-evaluation-${rowData.status.toString().toLowerCase()}`}>
                <span className='mr-2'>{translatedMessage('FieldEvaluationStatus.' + rowData.status)}</span>
                {!props?.viewOnly && <i className="pi pi-pencil" />}
            </div>
        )
    }

    const statusDropdownTemplate = (option) => {
        return (
            <div className={`inline-block status status-field-evaluation-${option.value.toString().toLowerCase()}`}>
                {translatedMessage('FieldEvaluationStatus.' + option.value)}
            </div>
        )
    }

    const handlestatusChange = (rowData) => {
        return (
            <Dropdown 
                value={rowData.status} 
                options={props?.statusOptions} 
                optionLabel="label" 
                optionValue="value"
                onChange={(e) => saveFieldEvaluation(e.value, rowData.id)} 
                placeholder={translatedMessage('generic.status')}
                itemTemplate={statusDropdownTemplate}
                valueTemplate={statusDropdownTemplate}
                disabled={!evaluation?.evaluationCommitteeUser?.user?.id === auth.user.id || props?.viewOnly}
            />
        );
    }

    const saveFieldEvaluation = (_status, fieldId) => {
        if (fieldId) {
            evaluationService.updateApplicationFieldEvaluation(fieldId, _status)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success'), life: 3000 });
                    onFieldEvaluationChange(fieldId, _status)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }
    };

    const onFieldEvaluationChange = (fieldId, status, comment = null) => {
        let _evaluation = evaluation
        _evaluation.fieldsEvaluation.map(item => {
            if (item.id === fieldId) {
                item.status = status
                item.comment = comment
            }

            return item
        })

        let _formFieldsEvaluation = _evaluation.fieldsEvaluation.filter(item => item.applicationFormField.formField.field.type !== 'CUSTOM')

        setEvaluation(_evaluation)
        setFilteredFieldEvaluation(_formFieldsEvaluation)
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className='w-full text-align-left'>
                    <h5 className='mb-1'>{translatedMessage('application.form')}</h5>

                    <DataTable
                        ref={dt}
                        value={filteredFieldEvaluation}
                        dataKey="id"
                        paginator
                        rows={DataTableUtils.defalRowsPerPage()}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                        responsiveLayout="stack"
                        stripedRows
                        rowGroupMode="rowspan"
                        groupRowsBy="application.user.username"
                    >
                        <Column
                            field="applicationFormField.formField.field.code"
                            header={translatedMessage('evaluation.requirement')}
                            headerStyle={{ width: '70%', minWidth: '10rem' }}
                            body={fieldTemplate}
                        />
                        <Column
                            field="status"
                            header={translatedMessage('generic.status')}
                            headerStyle={{ width: '5%', minWidth: '10rem' }}
                            body={fieldStatusTemplate}
                            editor={evaluation?.evaluationCommitteeUser?.user?.id === auth.user.id && !props?.viewOnly ? (event) => handlestatusChange(event.rowData) : ''}
                        />
                    </DataTable>

                </div>
            </>
        );
    }
};

export default EvaluationFormPage;
