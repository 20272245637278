import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormFieldService } from '../../../service/FormFieldService';
import { translatedMessage } from '../../../service/LanguageService';
import { CompetitionService } from '../../../service/CompetitionService';
import CompetitionToolbar from '../CompetitionToolbar';
import FormFieldListComponent from '../../../components/form/FormFieldListComponent';
import SecurityService from '../../../service/SecurityService';

const CompetitionForm = () => {
    const [form, setForm] = useState({});
    const [competition, setCompetition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasEditPermission, setHasEditPermission] = useState(false);

    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);
    const formFieldService = useMemo(() => new FormFieldService(), []);

    const { competitionIdParam } = useParams();
    const { formIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true)

        const checkEditPermission = async () => {
            setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        };  

        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then(async (competitionData) => {
                    setCompetition(competitionData);
                    await getForm()
                    await checkEditPermission()
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getForm = async () => {
            await formFieldService.getForm(formIdParam)
                .then(form => {
                    setForm(form);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };        

        getCompetition()
    }, [competitionIdParam, competitionService, formFieldService, formIdParam]);
 

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <CompetitionToolbar competition={competition} code="FORM" />

                            <h5 className='mb-1 font-weight-normal'>{translatedMessage("form.field.form")}</h5>
                            
                            <FormFieldListComponent form={form} hasEditPermission={hasEditPermission}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default CompetitionForm;
