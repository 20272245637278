import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import DataTableUtils from './DataTableUtils';

const CompetitionDataTableUtils = {

  statusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-competition-${_status.toLowerCase()}`}>{translatedMessage("CompetitionStatus." + _status)}</div>
    )
  },

  statusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      selectedItemTemplate={DataTableUtils.filterSelectedItemTemplate(options.value, statuses, "generic.status")}
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  },

  typeBodyTemplate(_type) {
    return (
      <div className={`pill inline-block competition-type-${_type.toLowerCase()}`}>{translatedMessage("CompetitionType." + _type)}</div>
    )
  },

  newTypeBodyTemplate(_type) {
    let coputedType =  _type?.split("_")?.[0]
    return (
      <div className={`pill inline-block competition-type-${coputedType?.toLowerCase()}`}>{translatedMessage("CompetitionType." + coputedType)}</div>
    )
  },

  typeFilterTemplate(options, types) {
    return <MultiSelect
      value={options.value}
      options={types}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.type')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      selectedItemTemplate={DataTableUtils.filterSelectedItemTemplate(options.value, types, "generic.type")}
      itemTemplate={(e) => this.typeBodyTemplate(e.value)}
    />
  },

  newTypeFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('CompetitionType.NATIONAL'), value: 'NATIONAL' },
      { label: translatedMessage('CompetitionType.JOB'), value: 'JOB' },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.type')}
      className="p-column-filter"
      itemTemplate={(e) => this.newTypeBodyTemplate(e.value)}
    />
  },

  categoryBodyTemplate(_category) {
    return (
      _category 
        ? <div className={`pill inline-block competition-category-${_category.toLowerCase()}`}>{translatedMessage("PositionCategory." + _category)}</div>
        : <span>-</span>
    )
  },

  categoryFilterTemplate(options, categories) {
    return <MultiSelect
      value={options.value}
      options={categories}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('competition.positionCategory')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      selectedItemTemplate={DataTableUtils.filterSelectedItemTemplate(options.value, categories, "competition.positionCategory")}
      itemTemplate={(e) => this.categoryBodyTemplate(e.value)}
    />
  },

  clarificationRequestSentBodyTemplate(_sent) {
    return (
      <div className={`inline-block pill clarification-status-${_sent ? 'sent' : 'not-sent'}`}>
        {_sent
          ? translatedMessage('application.request.sent')
          : translatedMessage('application.request.not.sent')
        }
      </div>
    )
  },

  clarificationRequestSentFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('application.request.sent'), value: true },
      { label: translatedMessage('application.request.not.sent'), value: false },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      showClear
      itemTemplate={(e) => this.clarificationRequestSentBodyTemplate(e.value)}
    />
  },

  clarificationResponseSentBodyTemplate(_sent) {
    return (
      <div className={`inline-block pill clarification-status-${_sent ? 'sent' : 'not-sent'}`}>
        {_sent
          ? translatedMessage('application.response.sent')
          : translatedMessage('application.response.not.sent')
        }
      </div>
    )
  },

  clarificationResponseSentFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('application.response.sent'), value: true },
      { label: translatedMessage('application.response.not.sent'), value: false },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      showClear
      itemTemplate={(e) => this.clarificationResponseSentBodyTemplate(e.value)}
    />
  },

  contestationRequestSentBodyTemplate(_sent) {
    return (
      <div className={`inline-block pill contestation-status-${_sent ? 'sent' : 'not-sent'}`}>
        {_sent
          ? translatedMessage('application.request.sent')
          : translatedMessage('application.request.not.sent')
        }
      </div>
    )
  },

  contestationRequestSentFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('application.request.sent'), value: true },
      { label: translatedMessage('application.request.not.sent'), value: false },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      showClear
      itemTemplate={(e) => this.contestationRequestSentBodyTemplate(e.value)}
    />
  },

  contestationResponseSentBodyTemplate(_sent) {
    return (
      <div className={`inline-block pill contestation-status-${_sent === true ? 'sent' : (_sent === false ? 'not-sent' : 'not-filled')}`}>
        {_sent === true
          ? translatedMessage('application.response.sent')
          : (_sent === false
            ? translatedMessage('application.response.not.sent')
            : translatedMessage('application.response.not.filled')
          )
        }
      </div>
    )
  },

  contestationResponseSentFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('application.response.sent'), value: true },
      { label: translatedMessage('application.response.not.sent'), value: false },
      { label: translatedMessage('application.response.not.filled'), value: '-1' },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      showClear
      itemTemplate={(e) => this.contestationResponseSentBodyTemplate(e.value)}
    />
  },

  finalReportStatusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-${_status.toLowerCase()}`}>{translatedMessage("CompetitionFinalReportStatus." + _status)}</div>
    )
  },

  finalReportStatusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      itemTemplate={(e) => this.finalReportStatusBodyTemplate(e.value)}
    />
  },  
  
  institutionBodyTemplate(_institution) {
    return (
      <>
        <div className="pcn-multiselect-item" title={_institution.label}>
          {_institution.label}
        </div>
      </>
    )
  },

  institutionFilterTemplate(options, institutions) {
    return <MultiSelect
      value={options.value}
      options={institutions}
      optionLabel="label"
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('institution.institution')}
      panelClassName="pcn-multiselect-panel"
      maxSelectedLabels={1}
      showSelectAll={false}
      // selectionLimit={1}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      selectedItemTemplate={DataTableUtils.filterSelectedItemTemplate(options.value, institutions, "institution.institution")}
      itemTemplate={(e) => this.institutionBodyTemplate(e)}
      filter
    />
  },



};

export default CompetitionDataTableUtils;