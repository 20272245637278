import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { translatedMessage } from '../../service/LanguageService';

const ApplicationTestQuestionsDialog = (props) => {
    const [questions, setQuestions] = useState(null);
    const [applicationTest, setApplicationTest] = useState(null);

    const dt = useRef(null);

    useEffect(() => {
        setQuestions(props?.questions);
        setApplicationTest(props?.applicationTest);
    }, [props]);


    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );

    return (
        <>
            <Dialog
                visible={props?.visible}
                closable={true}
                header={applicationTest?.competitionTest?.test?.name + " - " + props?.title}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={() => props.closeDialog()}
            >
                <DataTable
                    ref={dt}
                    value={questions}
                    dataKey="id"
                    className="datatable-responsive pcn-datatable"
                    responsiveLayout="stack"
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                >
                    <Column
                        field="questionCode"
                        header={translatedMessage('test.question.id')}
                        headerStyle={{ width: '10%', minWidth: '10rem' }}
                    />
                    <Column
                        field="questionChosenAnswer"
                        header={translatedMessage('test.question.answer.selected')}
                        body={(e) => e.questionChosenAnswer ? e.questionChosenAnswer : "-"}
                        headerStyle={{ width: '50%', minWidth: '10rem' }}
                    />
                    <Column
                        field="questionCorrectAnswer"
                        header={translatedMessage('test.question.answer.correct')}
                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                        body={(e) => e.questionCorrectAnswer ? e.questionCorrectAnswer : "-"}
                        hidden={applicationTest?.result === null && applicationTest?.resultAfterContestation === null}
                    />
                    <Column
                        field="questionStatus"
                        header={translatedMessage('test.question.answer.isCorrect')}
                        body={(e) => translatedMessage('QuestionStatus.'.concat(e.questionStatus))}
                        headerStyle={{ width: '10%', minWidth: '10rem' }}
                        hidden={applicationTest?.result === null && applicationTest?.resultAfterContestation === null}
                    />
                </DataTable>

            </Dialog>
        </>
    );
};

export default ApplicationTestQuestionsDialog;
