import ApiService from "./ApiService";
import DownloadService from "./DownloadService";
import { translatedMessage } from "./LanguageService";

export class StorageService {

    async getFilesInFolder(folderId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/storage/${folderId}/files`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteFile(fileUuid) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/storage/file/${fileUuid}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    

    async downloadFilesInFolder(folderId, folderName) {
        return new Promise((resolve, reject) => {
            DownloadService.httpRequest(ApiService.getBaseUrl() + `/storage/folder/download/${folderId}`, 'get')
                .then(response => response.arrayBuffer())
                .then(blob => {
                    //Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', folderName.toLowerCase() + '.zip');

                    //Append to html page
                    document.body.appendChild(link);

                    //Force download
                    link.click();

                    //Clean up and remove the link                    
                    setTimeout(function () {
                        link.parentNode.removeChild(link);
                        URL.revokeObjectURL(url);
                    }, 3000);

                    resolve()
                })
                .catch(error => {
                    let errorMessage = translatedMessage("generic.error.get.data");
                    if (error && error.status && error.status === 404) {
                        errorMessage = translatedMessage("error.generic.not_found")
                    }
                    reject(errorMessage)
                })
        })
    }

    async downloadFilesForApplication(applicationId) {
        return new Promise((resolve, reject) => {
            DownloadService.httpRequest(ApiService.getBaseUrl() + `/application/files/download/${applicationId}`, 'get')
                .then(response => response.arrayBuffer())
                .then(blob => {
                    //Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "candidat_" + applicationId + '.zip');

                    //Append to html page
                    document.body.appendChild(link);

                    //Force download
                    link.click();

                    //Clean up and remove the link                    
                    setTimeout(function () {
                        link.parentNode.removeChild(link);
                        URL.revokeObjectURL(url);
                    }, 3000);

                    resolve()
                })
                .catch(error => {
                    let errorMessage = translatedMessage("generic.error.get.data");
                    if (error && error.status && error.status === 404) {
                        errorMessage = translatedMessage("error.generic.not_found")
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveFileUsage(fileId, usageId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/storage/file/${fileId}/usage/${usageId}`)
                .then( resolve() )
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
    
    async getFilesInApplication(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/files`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getUserFilesForApplication(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/application/${applicationId}/user-files`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async copyFilesToApplication(applicationId, fileList) {
        let fileIds = []
        fileList.forEach(item => {
            fileIds.push(item.id)
        })

        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/application/${applicationId}/copy-files`, fileIds)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }
}