import { translatedMessage } from "../service/LanguageService";
import { Dropdown } from 'primereact/dropdown';

const resultOptions = [
  { label: translatedMessage('Result.ACCEPTED'), value: 'ACCEPTED' },
  { label: translatedMessage('Result.REJECTED'), value: 'REJECTED' },
  { label: translatedMessage('Result.ABSENT'), value: 'ABSENT' },
  { label: translatedMessage('Result.CANCELED'), value: 'CANCELED' }
]

const ResultDataTableUtils = {

  statusBodyTemplate(_result) {
    return (
      <div className={`inline-block status status-result-${_result.toLowerCase()}`}>{translatedMessage("Result." + _result)}</div>
    )
  },

  statusFilterTemplate(options) {
    return <Dropdown
      value={options.value}
      options={resultOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      itemTemplate={(e) => this.statusBodyTemplate(e.value)}
    />
  },

  resultBodyTemplate(_applicationTest) {
    let _result = _applicationTest.testType === "QUIZ"
      ? _applicationTest.quizResult ? translatedMessage("Result." + _applicationTest.quizResult.toUpperCase()) : null
      : _applicationTest.result

    let _isRejected = _applicationTest.testType === "QUIZ"
      ? _applicationTest.quizResult?.toUpperCase() !== "ACCEPTED"
      : _applicationTest.result < _applicationTest.competitionTest.passingScore

    return (
      <span className={_isRejected ? 'text-red-500' : ''}>
        {_result !== null ? _result : translatedMessage("test.notTaken")}
      </span>
    )
  },

  resultContestationBodyTemplate(_applicationTest) {
    let _result = _applicationTest.testType === "QUIZ"
      ? _applicationTest.quizResultAfterContestation ? translatedMessage("Result." + _applicationTest.quizResultAfterContestation.toUpperCase()) : null
      : _applicationTest.resultAfterContestation

    let _isRejected = _applicationTest.testType === "QUIZ"
      ? _applicationTest.quizResultAfterContestation?.toUpperCase() !== "ACCEPTED"
      : _applicationTest.resultAfterContestation < _applicationTest.competitionTest.passingScore

    return (
      <span className={_isRejected ? 'text-red-500' : ''}>
        {_result}
      </span>
    )
  }

};

export default ResultDataTableUtils;