import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';
import FieldDisplay from './FieldDisplay';

const ViewFormFieldDialog = (props) => {

    const [dialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );

    const formField = props?.formField ? props.formField : null
    const field = formField?.field ? formField.field : null

    return (
        <>
            <Dialog
                visible={dialogVisible}
                header={translatedMessage('form.field.details')}
                modal
                onHide={() => props.closeDialog()}
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
            >
                <div className='w-full grid pcn-data-view-panel mx-0'>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.code')}</div>
                        <div className='filed-value'>{field?.code}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.type')}</div>
                        <div className='filed-value'>{translatedMessage('FieldType.' + field?.type)}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('form.field.requestedValue')}</div>
                        <div className='filed-value'>
                            {
                                formField?.requestedValue 
                                    ? field?.type === 'BOOLEAN' 
                                        ? translatedMessage("generic." + formField?.requestedValue.toLowerCase()) 
                                        : formField?.requestedValue 
                                    : formField?.requestedItemId
                                        ? formField?.entityLookupLabel
                                        : '-'
                            }
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('form.field.preview')}</div>
                        <FieldDisplay field={props?.formField} className="pcn-form-field-view"/>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ViewFormFieldDialog;
