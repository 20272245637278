import React, { useContext, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
// import { MegaMenu } from 'primereact/megamenu';
import { useNavigate } from 'react-router-dom';
// import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import { RTLContext } from './App';
import { translatedMessage } from './service/LanguageService';
import useLogout from "./hooks/useLogout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationBell from './components/notification/NotificationBell';
import CandidateGuide from './components/CandidateGuide';
import { getCurrentUser } from './service/UserService';

const AppTopbar = (props) => {
    const isRTL = useContext(RTLContext);
    const navigate = useNavigate();
    const logout = useLogout();

    // const topbarRef1 = useRef(null);
    // const topbarRef3 = useRef(null);
    const topbarRef4 = useRef(null);

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null);

    useEffect(() => {
        // Fixed for 6.1.0
        /*if (props.searchActive) {
            searchPanel.current.element.focus();
        }*/
    }, [props.searchActive]);

    // const onInputKeydown = (event) => {
    //     const key = event.which;

    //     //escape, tab and enter
    //     if (key === 27 || key === 9 || key === 13) {
    //         props.onSearch(false);
    //     }
    // };

    const handleLogOut = async () => {
        await logout();
    }

    const getCurrentUserName = () => {
        let user = getCurrentUser()
        return user && user.username
    }

    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/home')}>
                    <img id="app-logo" src="assets/layout/images/logo.png" alt="ultima-layout" style={{ height: '70px' }} />
                </button>
                <button type="button" className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick}></i>
                </button>
            </div>

            <div className={classNames('layout-topbar-right', { 'layout-topbar-mobile-active': props.mobileTopbarActive })}>
                <div className="layout-topbar-actions-left">
                    <div className='w-full text-center lg:text-left'>
                        <div><h4 className='mb-0'>Agenția Națională a Funcționarilor Publici</h4></div>
                        <div><h6 className='mb-2 md:mb-0'>Platforma Informatică de Concurs</h6></div>
                    </div>
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item notifications">
                            <CandidateGuide onTopbarItemClick={props.onTopbarItemClick} activeTopbarItem={props.activeTopbarItem} />
                        </li>
                        <li className="layout-topbar-item notifications">
                            <NotificationBell onTopbarItemClick={props.onTopbarItemClick} activeTopbarItem={props.activeTopbarItem} />
                        </li>
                        <li className="layout-topbar-item">
                            <Button className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link"
                                onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}
                                tooltip={getCurrentUserName()} tooltipOptions={{ showOnDisabled: true, position: 'left' }}>
                                {/* <img src="assets/demo/images/avatar/amyelsner.png" alt="avatar" style={{ width: '32px', height: '32px' }} /> */}
                                <i className="pi pi-user fs-large"></i>
                            </Button>

                            <CSSTransition nodeRef={topbarRef4} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul ref={topbarRef4} className="layout-topbar-action-panel shadow-6">
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-profile")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-user', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>{translatedMessage("profile.myProfile")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-education")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <FontAwesomeIcon icon={["fas", "user-graduate"]} className={classNames({ 'mr-2': !isRTL, 'ml-2': isRTL })} />
                                            <span>{translatedMessage("profile.education")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-work-experience")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <FontAwesomeIcon icon={["fas", "building-columns"]} className={classNames({ 'mr-2': !isRTL, 'ml-2': isRTL })} />
                                            <span>{translatedMessage("profile.workExperience")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-it-knowledge")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <FontAwesomeIcon icon={["fas", "computer"]} className={classNames({ 'mr-2': !isRTL, 'ml-2': isRTL })} />
                                            <span>{translatedMessage("profile.itKnowledge")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-language-skill")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <FontAwesomeIcon icon={["fas", "globe"]} className={classNames({ 'mr-2': !isRTL, 'ml-2': isRTL })} />
                                            <span>{translatedMessage("profile.language")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-accessibility")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <FontAwesomeIcon icon={["fas", "universal-access"]} className={classNames({ 'mr-2': !isRTL, 'ml-2': isRTL })} />
                                            <span>{translatedMessage("profile.accessibility")}</span>
                                        </button>
                                    </li>                                    
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-files")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-file', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>{translatedMessage("profile.files")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/change-password")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-lock', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>{translatedMessage("profile.changePassword")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => navigate("/my-settings")}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-cog', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>{translatedMessage("generic.settings")}</span>
                                        </button>
                                    </li>
                                    <li className="layout-topbar-action-item" onClick={() => handleLogOut()}>
                                        <button className="flex flex-row align-items-center p-link">
                                            <i className={classNames('pi pi-power-off', { 'mr-2': !isRTL, 'ml-2': isRTL })}></i>
                                            <span>{translatedMessage("generic.logout")}</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
