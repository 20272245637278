import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import CommitteeMemberEditDialog from '../../pages/evaluation/CommitteeMemberEditDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import DeleteModalComponent from '../DeleteModalComponent';
import ExportButtonComponent from '../ExportButtonComponent';
import GeneralUtils from '../../utilities/GeneralUtils';
import SecurityService from '../../service/SecurityService';

const tableHeader = [
    translatedMessage("generic.name"),
    translatedMessage("generic.email"),
    translatedMessage("generic.institution"),
    translatedMessage("committee.member.role"),
    translatedMessage("committee.committee"),
    translatedMessage("generic.type"),
    translatedMessage("competition.competition"),
]

const CommitteeMemberList = (props) => {
    const [members, setMembers] = useState([]);
    const [member, setMember] = useState([]);
    const [editMemberDialogVisible, setEditMemberDialogVisible] = useState(false);
    const [deleteMemberDialogVisible, setDeleteMemberDialogVisible] = useState(false);
    const [hasPresident, setHasPresident] = useState(false);
    const [hasSecretary, setHasSecretary] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [filters] = useState({
        'user.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'user.username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'user.institution': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        setIsLoading(true);

        const checkEditPermission = async () => {
            setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', props?.competitionId));
        };        

        const getMembers = async () => {
            await evaluationService.getCommitteeMemberList(props.committeeId)
                .then(async (members) => {
                    setMembers(members)
                    setCommitteeRoles(members)
                    setCanEdit(props?.canEdit ? true : false)
                    await checkEditPermission()
                    setIsLoading(false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getMembers();
    }, [evaluationService, props]);

    const updateList = async (savedMember, isNew) => {
        let updatedMembers = []
        if (!isNew) {
            members.forEach(item => {
                if (item.id === savedMember.id) {
                    updatedMembers.push(savedMember)
                } else {
                    updatedMembers.push(item)
                }
            })
        } else {
            updatedMembers = members.map(item => ({ ...item }))
            updatedMembers.push(savedMember)
        }

        setMembers(updatedMembers);
        setCommitteeRoles(updatedMembers)
    };

    const setCommitteeRoles = (committeeMembers) => {
        setHasPresident(false)
        setHasSecretary(false)
        committeeMembers.forEach(item => {
            if (item.isPresident) {
                setHasPresident(true)
            }
            if (item.isSecretary) {
                setHasSecretary(true)
            }
        })
    }

    const deleteCommitteeMember = async () => {
        await evaluationService.deleteCommitteeMember(member.id)
            .then(() => {
                let _members = members.filter(item => item.id !== member.id)
                setMembers(_members)
                setCommitteeRoles(_members)
                setDeleteMemberDialogVisible(false);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const showEditDialog = (rowData) => {
        setMember(rowData);
        setEditMemberDialogVisible(true);
    };

    const showDeleteDialog = (rowData) => {
        setMember(rowData);
        setDeleteMemberDialogVisible(true);
    };

    const showNewMemberDialog = () => {
        setMember({ allowedSecretary: true });
        setEditMemberDialogVisible(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasEditPermission &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasEditPermission &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        return <>
            {canEdit && hasEditPermission && 
                <Button label={translatedMessage('committee.newCommitteeMember')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewMemberDialog} />}
        </>;
    };

    const handleExport = () => {
        let exportData = members.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.name = item.user.fullName
            exportItem.username = item.user.username
            exportItem.institution = item.user.institution            
            exportItem.role = committeeMemberRoleTemplate(item)
            exportItem.committeeName = item.evaluationCommittee.name
            exportItem.type = translatedMessage("EvaluationCommitteeType." + item.evaluationCommittee.type)
            exportItem.competition = props?.competitionName
            delete exportItem.evaluationCommitteeId
            delete exportItem.isPresident
            delete exportItem.isSecretary
            delete exportItem.user
            delete exportItem.allowedSecretary
            delete exportItem.evaluationCommittee

            return exportItem;
        })

        return exportData
    }    

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("menu.competition.committee.members") + "_" + props?.competitionName)}
            />
        );
    };

    const committeeMemberRoleTemplate = (rowData) => {
        return (
            rowData.isPresident 
                ? translatedMessage('committee.member.isPresident') 
                : rowData.isSecretary ? translatedMessage('committee.member.isSecretary') : translatedMessage('committee.member.isSimpleMember'));
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid">
                    <div className='col-12'>
                        <h5 className='mb-1 font-weight-normal'>{translatedMessage("committee.members")}</h5>
                    </div>
                    <div className="col-12">
                        <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>
                        <DataTable
                            ref={dt}
                            value={members}
                            dataKey="id"
                            paginator
                            rows={DataTableUtils.defalRowsPerPage()}
                            rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                            className="datatable-responsive pcn-datatable"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                            responsiveLayout="stack"
                            filterDisplay="row"
                            filters={filters}
                            globalFilterFields={['user.fullName', 'user.username', 'user.institution']}
                        >
                            <Column
                                field="user.fullName"
                                header={translatedMessage('generic.name')}
                                sortable
                                headerStyle={{ width: '20%', minWidth: '10rem' }}
                                filter
                                showFilterMenu={false}
                            />
                            <Column
                                field="user.username"
                                header={translatedMessage('generic.email')}
                                sortable
                                headerStyle={{ width: '30%', minWidth: '10rem' }}
                                filter
                                showFilterMenu={false}
                            />
                            <Column
                                field="user.institution"
                                header={translatedMessage('generic.institution')}
                                sortable
                                headerStyle={{ width: '20%', minWidth: '10rem' }}
                                filter
                                showFilterMenu={false}
                            />
                            <Column
                                field="isPresident"
                                header={translatedMessage('committee.member.role')}
                                sortable
                                headerStyle={{ width: '5%', minWidth: '7rem' }}
                                body={committeeMemberRoleTemplate}
                            />
                            {canEdit &&
                                <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }} />
                            }
                        </DataTable>
                    </div>
                </div>

                <CommitteeMemberEditDialog
                    visible={editMemberDialogVisible}
                    value={member}
                    afterSave={updateList}
                    onCancel={() => { setEditMemberDialogVisible(false) }}
                    committeeId={props.committeeId}
                    allowPresident={member.isPresident || !hasPresident}
                    allowSecretary={(member.isSecretary || !hasSecretary) && member.allowedSecretary}
                    hasSecretary={hasSecretary}
                />

                <DeleteModalComponent
                    visible={deleteMemberDialogVisible}
                    item={member.user?.fullName ? member.user?.fullName : ''}
                    closeDialog={() => setDeleteMemberDialogVisible(false)}
                    deleteRecord={() => deleteCommitteeMember()}
                />
            </>
        );
    }
};

export default CommitteeMemberList;
