import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TestService } from '../../../service/TestService';
import { translatedMessage } from '../../../service/LanguageService';
import EnumService from '../../../service/EnumService';
import useAuth from '../../../hooks/useAuth';
import useNavProps from '../../../hooks/useNavProps';
import DataTableUtils from '../../../utilities/DataTableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteModalComponent from '../../../components/DeleteModalComponent';
import TestCenterRoomEditDialog from './TestCenterRoomEditDialog';
import TestCenterRoomViewDialog from './TestCenterRoomViewDialog';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import GeneralUtils from '../../../utilities/GeneralUtils';

const sortOrderHeader = ["name", "capacity", "details", "status", "testCenter"]

const tableHeader = [
    translatedMessage("competition.testCenterRoom.name"),
    translatedMessage("testCenter.capacity"),
    translatedMessage("generic.details"),
    translatedMessage("generic.status"),
    translatedMessage("competition.testCenter.name")
]

const TestCenterRoomList = () => {
    const [testCenter, setTestCenter] = useState({});
    const [testCenterRooms, setTestCenterRooms] = useState([]);
    const [selectedTestCenterRoom, setSelectedTestCenterRoom] = useState(null);
    const [statusOptions, setStatusOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const { auth } = useAuth();
    const { setNavPropsValue } = useNavProps();

    const testService = useMemo(() => new TestService(), []);

    let { testCenterIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true)
        const getOptions = async () => {
            setStatusOptions(await EnumService.getEnumByName('TestCenterStatus'));
        };

        const getTestCenter = async () => {
            testService.getTestCenter(testCenterIdParam)
                .then(async testCenter => {
                    setTestCenter(testCenter);
                    await getTestCenterRoomList()
                    
                    setNavPropsValue('test-center', testCenter.id, testCenter.name, false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getTestCenterRoomList = async () => {
            await testService.getTestCenterRoomList(testCenterIdParam)
                .then((rooms) => {
                    setTestCenterRooms(rooms);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getOptions();
        getTestCenter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testCenterIdParam, testService]);

    const updateList = async (savedRoom, isNew) => {
        let updatedRooms = DataTableUtils.updateTableList(testCenterRooms, savedRoom, isNew)

        setTestCenterRooms(updatedRooms);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedTestCenterRoom(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (room) => {
        setSelectedTestCenterRoom(null);
        let _room = { ...room };
        _room.testCenter = testCenter;
        setSelectedTestCenterRoom(_room);
        setEditDialogVisible(true);
    };

    const showViewDialog = (room) => {
        let _room = { ...room };
        setSelectedTestCenterRoom(_room);
        setViewDialogVisible(true);
    };

    const showDeleteDialog = (room) => {
        setSelectedTestCenterRoom(room);
        setDeleteDialogVisible(true);
    };

    const deleteRoom = () => {
        testService.deleteTestCenterRoom(selectedTestCenterRoom.id)
            .then(() => {
                let _rooms = testCenterRooms.filter(item => item.id !== selectedTestCenterRoom.id)
                setTestCenterRooms(_rooms)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedTestCenterRoom(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('testCenter.room.newTestCenterRoom')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let exportData = testCenterRooms.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.testCenter = item.testCenter.name
            exportItem.status = item.status ? translatedMessage('TestCenterStatus.' + item.status) : ""

            return exportItem;
        })

        return exportData
    } 

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                sortOrderHeader={sortOrderHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("testCenter.room.roomsInTestCenter") + "_" + testCenter?.name)}
            />
        );
    };

    const statusTemplate = (rowData) => {
        return (
            <span className={`status status-${rowData.status.toLowerCase()}`}>
                {translatedMessage('TestCenterStatus.' + rowData.status)}
            </span>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">

                            <h3 className='mb-1'>{testCenter.name}</h3>
                            <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/test-center`)}>
                                <div className='flex align-items-center'>
                                    <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                    <span>{translatedMessage('generic.backToList')}</span>
                                </div>
                            </Button>

                            <h5 className='mb-1 font-weight-normal'>{translatedMessage("testCenter.room.roomsInTestCenter")}</h5>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={testCenterRooms}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="stack"
                            >
                                <Column
                                    field="name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '30%', minWidth: '10rem' }}
                                />
                                <Column
                                    field="capacity"
                                    header={translatedMessage('testCenter.room.capacity')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                />
                                <Column
                                    field="details"
                                    header={translatedMessage('testCenter.room.detailsLabel')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                />
                                <Column
                                    field="status"
                                    header={translatedMessage('generic.status')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                    body={statusTemplate}
                                />
                                <Column body={actionBodyTemplate} />
                            </DataTable>

                            <TestCenterRoomEditDialog
                                testCenterRoom={selectedTestCenterRoom}
                                testCenter={testCenter}
                                visible={editDialogVisible}
                                afterSave={updateList}
                                visibleSetter={setEditDialogVisible}
                                statusOptions={statusOptions}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedTestCenterRoom ? selectedTestCenterRoom.name : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteRoom()}
                            />

                            <TestCenterRoomViewDialog
                                testCenterRoom={selectedTestCenterRoom}
                                visible={viewDialogVisible}
                                closeDialog={() => { setViewDialogVisible(false) }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestCenterRoomList;
