import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Link } from 'react-router-dom';

import { StorageService } from '../../service/StorageService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import StorageFileUpload from '../../components/file/StorageFileUpload';
import StorageFileDownloadTableButton from './StorageFileDownloadTableButton';
import StorageFileViewTableButton from './StorageFileViewTableButton';
import DeleteModalComponent from '../DeleteModalComponent';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { Message } from 'primereact/message';
import ApplicationFileListModalComponent from './ApplicationFileListModalComponent';

const StorageFolderFileList = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState(null);
    const [profileFiles, setProfileFiles] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showProfileFilesDialog, setShowProfileFilesDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    const storageService = useMemo(() => new StorageService(), []);

    const getLabelForValue = (value, array) => {
        const item = array.filter(x => x.value === value)
        return item[0] ? item[0].label : value
    }

    useEffect(() => {
        setIsLoading(true)

        const getFiles = async () => {
            let usageLookupResponse = await EnumService.getEntityLookupByField("NOM:FILE_USAGE");
            setUsageLookup(usageLookupResponse)

            await storageService.getFilesInFolder(props.folderId)
                .then(filesResponse => {
                    let appFiles = []
                    filesResponse.forEach(item => {
                        item.usageLabel = item.usage ? getLabelForValue(item.usage.id, usageLookupResponse) : null

                        appFiles.push(item)
                    })

                    setFileList(appFiles)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        const getApplicationFiles = async () => {
            let usageLookupResponse = await EnumService.getEntityLookupByField("NOM:FILE_USAGE");
            setUsageLookup(usageLookupResponse)

            await storageService.getFilesInApplication(props.applicationId)
                .then(filesResponse => {
                    let appFiles = []
                    filesResponse.forEach(item => {
                        item.usageLabel = item.usage ? getLabelForValue(item.usage.id, usageLookupResponse) : null
                        appFiles.push(item)
                    })

                    setFileList(appFiles)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        if (props.evaluationApplicationDocs && props.applicationId) {
            getApplicationFiles();
        } else if (props.userApplicationDocFolder && props.applicationId) {
            getUserFiles();
        } else if (props.folderId) {
            getFiles();
        } else {
            setIsLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, storageService]);

    const getUserFiles = async () => {
        let usageLookupResponse = await EnumService.getEntityLookupByField("NOM:FILE_USAGE");
        setUsageLookup(usageLookupResponse)

        await storageService.getUserFilesForApplication(props.applicationId)
            .then(filesResponse => {
                let appFiles = []
                let pFiles = []
                filesResponse.forEach(item => {
                    item.usageLabel = item.usage ? getLabelForValue(item.usage.id, usageLookupResponse) : null
                    if (item.usage?.code === 'NomFileUsageCI' || item.usage?.code === 'NomFileUsageNameChange' || item.usage?.code === 'NomFileUsagePublicServant') {
                        appFiles.push(item)
                    } else if (item.copyToFolder) {
                        appFiles.push(item)
                        pFiles.push(item)
                    } else {
                        pFiles.push(item)
                    }
                })

                setFileList(appFiles)
                setProfileFiles(pFiles)
                setIsLoading(false)
            })
            .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="mt-2 md:mt-0 mr-2">
                {props.showUploadButton &&
                    <Button label={translatedMessage("generic.files.upload")} icon="pi pi-plus" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                        onClick={() => handleUpload()} visible={!props.readOnly}
                    />
                }
                {props.updateFiles && props.userApplicationDocFolder &&
                    <Button label={translatedMessage("generic.add")} icon="pi pi-plus" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                        onClick={() => handleAdd()}
                    />
                }
                <Button label={translatedMessage("file.downloadAll")} icon="pi pi-download" className="p-button-primary mr-2 my-1 md:my-1 white-space-nowrap"
                    onClick={() => handleDownloadAll()} disabled={fileList && fileList.length === 0}
                />
            </div>

            <div className="mt-2 md:mt-0 p-input-icon-left flex justify-content-end">
                <div className="p-input-icon-left w-auto">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage("generic.search")}
                        className="my-1 md:my-1" />
                </div>
            </div>
        </div>
    );

    const doOnFilter = (data) => { }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {<StorageFileViewTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}
                {<StorageFileDownloadTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => handleDelete(rowData)}
                    tooltip={translatedMessage("generic.delete")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    visible={!props.readOnly} />
            </div>
        );
    };

    const usageBodyTemplate = (rowData) => {
        return (
            <div title={translatedMessage("file.usage.select")}>
                <span className='mr-2'>{rowData.usageLabel ? rowData.usageLabel : "-"}</span>
                {!props.readOnly && <i className="pi pi-pencil" />}
            </div>
        )
    }

    const sizeBodyTemplate = (rowData) => {
        let roundedSize = (rowData.size / (1024 * 1024)).toFixed(2)
        return roundedSize.toString().concat(" MB");
    }

    const dateBodyTemplate = (rowData) => {
        return GeneralUtils.formatDateTime(rowData.fileDate);;
    }

    const handleDownloadAll = () => {
        if (props.evaluationApplicationDocs && props.applicationId) {
            storageService.downloadFilesForApplication(props.applicationId)
                .catch((errorMessage) => toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 }));
        } else if (props.folderId) {
            storageService.downloadFilesInFolder(props.folderId, props.folderName.replaceAll(' ', '_').toLowerCase())
                .catch((errorMessage) => toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 }));
        }
    }

    const handleDelete = (data) => {
        setSelectedValue(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        storageService.deleteFile(selectedValue.uuid)
            .then(() => {
                let _fileList = fileList.filter(item => item.id !== selectedValue.id)
                setFileList(_fileList)
                setShowDeleteDialog(false);

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    const handleUpload = () => {
        setShowUploadDialog(true)
    };

    const handleAdd = () => {
        setShowProfileFilesDialog(true)
    };

    const onFileUploadSuccess = (id, response) => {
        let _fileList = fileList.map(item => ({ ...item }))
        if (response?.length > 0) {
            response.forEach(item => {
                item.usageLabel = item.usage?.label
                _fileList.push(item)
            })
        }

        setFileList(_fileList)
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleUsageChange = (rowData) => {
        return (
            <Dropdown value={rowData.usageLabel} options={usageLookup} optionLabel="label" optionValue="value"
                onChange={(e) => onUsageChange(e.value, rowData.id)} placeholder={translatedMessage('file.usage.select')}
                disabled={props.readOnly}
            />
        );
    }

    const onUsageChange = (usageId, fileId) => {
        storageService.saveFileUsage(fileId, usageId)
            .then(() => {
                let _fileList = fileList.map(item => ({ ...item }))
                _fileList.map(item => {
                    if (item.id === fileId) {
                        item.usageLabel = getLabelForValue(usageId, usageLookup)
                    }

                    return item
                })

                setFileList(_fileList)
            })
            .catch(errorMessage => {
                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 5000 });
            })
    }

    const afterProfileFilesSelected = (refresh = false) => {
        if (refresh) {
            getUserFiles()
        }
        setShowProfileFilesDialog(false)
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        let infoText = props.pageSubTitle ? props.pageSubTitle : null;
        if (props.pageInfo) {
            infoText += " " + props.pageInfo;
        }

        return (
            <>
                <Toast ref={toast} />

                {props.pageTitle &&
                    <div className='w-full text-align-left'>
                        <h5 className='mb-2'>{props.pageTitle}</h5>
                    </div>
                }
                {infoText &&
                    <Message severity="warn" text={infoText} />
                }
                {props.pageInfoLink && props.pageInfoLinkText &&
                    <div className='w-full text-align-left mt-2'>
                        {props.pageInfoLinkText + " "}
                        <Link to={props.pageInfoLink}>
                            <span className='font-bold underline'>{translatedMessage('generic.here').toString().toUpperCase() + "."}</span>
                        </Link>
                    </div>
                }
                {props.pageInfoAddFiles &&
                    <div className='w-full text-align-left mt-2'>
                        {props.pageInfoAddFiles}
                    </div>
                }
                <DataTable
                    ref={dt}
                    value={fileList}
                    dataKey="id"
                    paginator={props?.hidePaginator ? false : true}
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={props?.hidePaginator ? 25 : DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    globalFilter={globalFilter}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    header={header}
                    responsiveLayout="stack"
                    onFilter={doOnFilter}
                >
                    <Column
                        field="name"
                        header={translatedMessage("generic.nameRo")}
                        sortable
                        headerStyle={{ width: '40%', minWidth: '8rem' }}
                    ></Column>
                    <Column
                        field="usageLabel"
                        header={translatedMessage("file.usage")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        body={usageBodyTemplate}
                        editor={props.readOnly ? '' : (event) => handleUsageChange(event.rowData)}
                    ></Column>
                    <Column
                        field="size"
                        header={translatedMessage("file.size")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                        body={sizeBodyTemplate}
                    ></Column>
                    <Column
                        field="date"
                        header={translatedMessage("file.date")}
                        sortable
                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                        body={dateBodyTemplate}
                    ></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <StorageFileUpload
                    entityId={null}
                    folderId={props.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={props?.singleFile || props?.addUsageOnUpload ? false : true}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    addUsageOnUpload={props?.addUsageOnUpload}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedValue && selectedValue.name}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />

                {showProfileFilesDialog && props?.applicationId &&
                    <ApplicationFileListModalComponent
                        dialogTitle={"profile.files"}
                        visible={showProfileFilesDialog}
                        closeDialog={(refresh) => afterProfileFilesSelected(refresh)}
                        files={profileFiles}
                        applicationId={props?.applicationId}
                    />
                }
            </>
        );
    }
};

export default StorageFolderFileList;