import React, { useState, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { translatedMessage } from '../service/LanguageService';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import PasswordRequirement from '../components/PasswordRequirementComponent'
import ApiService from '../service/ApiService';
import FooterBeforeLogin from '../components/FooterBeforeLogin';

const ResetPassword = () => {
    const [changeSuccess, setChangeSuccess] = useState(false);
    let { username } = useParams();
    let { token } = useParams();

    const navigate = useNavigate();
    const toast = useRef(null);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.password) {
                errors.password = translatedMessage("form.error.password.required");
            }
            else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/i.test(data.password.replace(" ", ""))) {
                errors.password = translatedMessage("form.error.password.invalid");
            }

            if (!data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.required");
            }
            else if (data.password !== data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.noMatch");
            }

            return errors;
        },
        onSubmit: (data) => {
            ApiService.instance.post('/user/set-password', {
                username: username,
                password: data.password,
                resetPasswordToken: token
            })
                .then(() => {
                    setChangeSuccess(true)
                    toast.current.show({ severity: 'success', summary: translatedMessage("resetPassword.setPassword.success.message"), lifetime: 3000 });
                })
                .catch(error => {
                    // console.log(error)
                    let errorMessage = "register.error.generic";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    setChangeSuccess(false)
                    toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), lifetime: 7000 });
                });
        }
    });

    const handleToastHide = () => {
        if (changeSuccess) {
            navigate(`/login/${username}`);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const passwordFooter = <PasswordRequirement />;

    return (
        <div className="pages-body login-page flex flex-column before-login-pages">
            <div className='pcn-login-logos w-full flex justify-content-center py-1'></div>
            <Toast ref={toast} onHide={handleToastHide}></Toast>

            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <div className="pages-header px-4 py-2 mb-0" style={{ height: '89px' }}>
                        <img src="assets/layout/images/logo.png" alt="logo" />
                    </div>

                    <h5 className='mb-0 mt-1'>Agenția Națională a Funcționarilor Publici</h5>
                    <h4 className='mt-2 mb-1'>{translatedMessage("resetPassword.setPassword")}</h4>

                    <form id="reset-form" onSubmit={formik.handleSubmit}>
                        <div className="input-panel flex flex-column mb-4">
                            <div className="p-inputgroup mt-3 mb-0">
                                <span className="p-float-label">
                                    <Password type="password" name="password" id="new-password" autoComplete="new-password"
                                        value={formik.values.password} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('password') })}
                                        toggleMask footer={passwordFooter} />
                                    <label htmlFor="new-password" className={classNames({ 'p-error': isFormFieldValid('password') })}>
                                        {translatedMessage("generic.password")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('password')}

                            <div className="p-inputgroup mt-3 mb-0">
                                <span className="p-float-label">
                                    <InputText type="password" name="confirmPassword" id="confirmPassword"
                                        value={formik.values.confirmPassword} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} />
                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>
                                        {translatedMessage("generic.confirmPassword")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('confirmPassword')}
                        </div>
                    </form>

                    <Button className="login-button mb-3 px-3" label={translatedMessage("resetPassword.resetPassword")} form="reset-form" type="submit"></Button>

                    <div className="grid">
                        <div className="col text-align-left">
                            <Link to="/login">{translatedMessage('login.login')}</Link>
                        </div>
                        <div className="col text-align-right">
                            <Link to="/reset-password-request">{translatedMessage('login.forgotPassword')}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBeforeLogin />
        </div>
    );
};

export default ResetPassword;