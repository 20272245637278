import { Dropdown } from "primereact/dropdown";
import { translatedMessage } from "../service/LanguageService";
import GeneralUtils from "./GeneralUtils";

const DataTableUtils = {

  defalRowsPerPage() {
    return 10
  },

  rowsPerPageOptions() {
    return [5, 10, 25, 50, 100]
  },

  dateTemplate(date) {
    return (
      <span>
        <div style={{ display: 'block' }}>{GeneralUtils.formatDate(date)}</div>
      </span>
    )
  },

  dateIntervalTemplate(startDate, endDate) {
    return (
      <span>
        <div style={{ display: 'block', marginBottom: 5 }}>{GeneralUtils.formatDate(startDate)}</div>
        <div>{GeneralUtils.formatDate(endDate)}</div>
      </span>
    )
  },

  dateTimeIntervalTemplate(startDate, endDate) {
    return (
      <span>
        <div style={{ display: 'block', marginBottom: 5 }}>{GeneralUtils.formatDateTimeNoSeconds(startDate)}</div>
        <div>{GeneralUtils.formatDateTimeNoSeconds(endDate)}</div>
      </span>
    )
  },

  getEntityLookupBodyTemplate(id, list) {
    const entityLookup = list.filter(element => element.value === id);
    return entityLookup[0] ? entityLookup[0].label : '';
  },

  yesNoTemplate(value) {
    return (
      <span>
        <div style={{ display: 'block' }}>{value ? translatedMessage("generic.yes") : translatedMessage("generic.no")}</div>
      </span>
    )
  },

  updateTableList(list, savedData, isNew) {
    let _list = []
    if (!isNew) {
      list.forEach(item => {
        if (item.id === savedData.id) {
          _list.push(savedData)
        } else {
          _list.push(item)
        }
      })
    } else {
      _list = list.map(item => ({ ...item }))
      _list.push(savedData)
    }

    return _list;
  },

  yesNoFilterTemplate(options) {
    let dropdownOptions = [
      { label: translatedMessage('generic.yes'), value: true },
      { label: translatedMessage('generic.no'), value: false },
    ]

    return <Dropdown
      value={options.value}
      options={dropdownOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      className="p-column-filter"
      // placeholder={translatedMessage('profile.accessibility')}
      itemTemplate={(e) => this.yesNoTemplate(e.value)}
    />
  },

  filterSelectedItemTemplate(_ids, _list, _placeholderString) {
    if (_ids && _ids[0]) {
      let selectedItem = _list.filter(item => item.value === _ids[0])
      return (
        selectedItem && selectedItem[0]
          ? <span title={selectedItem[0].label}>{selectedItem[0].label}</span>
          : translatedMessage(_placeholderString)
      )
    } else {
      return (
        translatedMessage(_placeholderString)
      )
    }
  },

};

export default DataTableUtils;