import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { translatedMessage } from '../../service/LanguageService';
import { TestService } from '../../service/TestService';
import EnumService from '../../service/EnumService';

const emptyTest = {
    id: null,
    name: '',
    template: '',
    jobProfileId: '',
    minScore: '',
    maxScore: '',
    provider: '',
    demo: false,
    isLanguageTest: false,
    languageTest: ''
};

const TestEditDialog = (props) => {

    const [test, setTest] = useState(emptyTest);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [languages, setLanguages] = useState([]);

    const toast = useRef(null);

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        const getLanguageOptions = async () => {
            setLanguages(await EnumService.getEnumByName('LanguageTestOption'));
        }

        setTest(props.value ? props.value : emptyTest);
        setIsNew(props.value ? false : true);
        getLanguageOptions()
    }, [props.value]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveTest = (data) => {
        testService.saveTest(data)
            .then(savedTest => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedTest, isNew);
                }

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                hideDialog();
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: test,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.name.required');
            }
            if (!data.template) {
                errors.template = translatedMessage('form.error.template.required');
            }
            if (!data.provider) {
                errors.provider = translatedMessage('form.error.provider.required');
            } else if (data.provider === "GR8PI" && !data.jobProfileId) {
                errors.jobProfileId = translatedMessage('form.error.jobProfileId.required');
            }
            if (data.minScore === null) {
                errors.minScore = translatedMessage('form.error.minScore.required');
            }
            if (!data.maxScore) {
                errors.maxScore = translatedMessage('form.error.maxScore.required');
            }
            if (data.isLanguageTest && !data.languageTest) {
                errors.languageTest = translatedMessage('form.error.test.language.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            if (!data.isLanguageTest) {
                data.languageTest = null
            }

            saveTest(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const testDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="test-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogVisible}
                style={{ width: '450px' }}
                header={translatedMessage('test.details')}
                modal
                className="p-fluid"
                footer={testDialogFooter}
                onHide={hideDialog}
            >
                <form id="test-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                {translatedMessage("generic.nameRo")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('name')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="provider" name="provider" value={formik.values.provider} onChange={formik.handleChange} options={props.providers}></Dropdown>
                            <label htmlFor="provider" className={classNames({ 'p-error': isFormFieldValid('provider') })}>
                                {translatedMessage("test.provider")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('provider')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="template" name="template" value={formik.values.template} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('template') })} />
                            <label htmlFor="template" className={classNames({ 'p-error': isFormFieldValid('template') })}>
                                {translatedMessage("test.template")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('template')}

                    {formik.values.provider === "GR8PI" && (
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <InputText type="text" id="jobProfileId" name="jobProfileId" value={formik.values.jobProfileId} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('jobProfileId') })} />
                                    <label htmlFor="jobProfileId" className={classNames({ 'p-error': isFormFieldValid('jobProfileId') })}>
                                        {translatedMessage("test.jobProfile")} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('jobProfileId')}
                        </>
                    )}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputNumber id="minScore" name="minScore" value={formik.values.minScore} onValueChange={formik.handleChange} min={0} max={1000000}
                                className={classNames({ 'p-invalid': isFormFieldValid('minScore') })} useGrouping={false} maxFractionDigits={2} />
                            <label htmlFor="minScore" className={classNames({ 'p-error': isFormFieldValid('minScore') })}>
                                {translatedMessage("test.minScore")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('minScore')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputNumber id="maxScore" name="maxScore" value={formik.values.maxScore} onValueChange={formik.handleChange} min={0} max={1000000}
                                className={classNames({ 'p-invalid': isFormFieldValid('maxScore') })} useGrouping={false} maxFractionDigits={2} />
                            <label htmlFor="maxScore" className={classNames({ 'p-error': isFormFieldValid('maxScore') })}>
                                {translatedMessage("test.maxScore")} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('maxScore')}

                    <div className="field-checkbox text-left mt-3">
                        <Checkbox inputId="demo" name="demo" checked={formik.values.demo}
                            onChange={formik.handleChange} />
                        <label htmlFor="demo">{translatedMessage("test.demoTest")}</label>
                    </div>

                    <div className="field-checkbox text-left mt-3">
                        <Checkbox inputId="isLanguageTest" name="isLanguageTest" checked={formik.values.isLanguageTest}
                            onChange={formik.handleChange} />
                        <label htmlFor="isLanguageTest">{translatedMessage("test.isLanguageTest")}</label>
                    </div>

                    {formik.values.isLanguageTest && (
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <Dropdown id="languageTest" name="languageTest" value={formik.values.languageTest} onChange={formik.handleChange} options={languages}
                                        className={classNames({ 'p-invalid': isFormFieldValid('languageTest') })}
                                    />
                                    <label htmlFor="languageTest" className={classNames({ 'p-error': isFormFieldValid('languageTest') })}>
                                        {translatedMessage("test.language")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('languageTest')}
                        </>
                    )}
                </form>
            </Dialog>
        </>
    );
};

export default TestEditDialog;
