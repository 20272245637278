import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import DataTableUtils from '../../utilities/DataTableUtils';
import useAuth from '../../hooks/useAuth';
import EvaluationDataTableUtils from '../../utilities/EvaluationDataTableUtils';
import EnumService from '../../service/EnumService';

const MyCommitteesComponent = (props) => {
    const [committees, setCommittees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [committeeTypes, setCommitteeTypes] = useState([]);

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'type': { value: null, matchMode: FilterMatchMode.IN },
        'competitionName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'active': { value: null, matchMode: FilterMatchMode.IN }
    });

    const navigate = useNavigate();
    const { auth } = useAuth();
    const toast = useRef(null);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getCommitteeTypes = async () => {
            await EnumService.getEnumByName('EvaluationCommitteeType').then((types) => setCommitteeTypes(types));
        };

        const getMyCommittees = async () => {
            evaluationService.getMyCommitteesList(props?.showOnlyActive)
                .then((_committees) => {
                    setCommittees(_committees);
                    getCommitteeTypes()
                    setIsLoading(false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getMyCommittees();
    }, [evaluationService, props]);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const viewMyCommittee = (rowData) => {
        navigate(`/competition-evaluation/${rowData.competitionId}/committees/${rowData.id}`);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => viewMyCommittee(rowData)} visible={hasPermission('EVALUATION_VIEW')} />
            </div>
        );
    };
    
    const committeeTypeTemplate = (_committeeType, _competitionType) => {
        let committeeType = _committeeType;
        if (_competitionType === 'NATIONAL_DEBUTANT' && (_committeeType === 'PRELIMINARY_TEST_CONTESTATION' || _committeeType === 'PRELIMINARY_TEST_ORG')) {
            committeeType = _committeeType + '.' + _competitionType;
        }
        return <div>{translatedMessage('EvaluationCommitteeType.' + committeeType)}</div>;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="card-header">
                    <h5>{translatedMessage('homePage.myCommittees')}</h5>
                </div>
                <DataTable
                    value={committees}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive"
                    responsiveLayout="stack"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    filterDisplay={props?.showFilter ? 'row' : false}
                    filters={filters}
                    globalFilterFields={['name', 'type', 'competitionName', 'active']}
                >
                    <Column 
                        field="name" 
                        header={translatedMessage('generic.nameRo')} 
                        sortable 
                        headerStyle={{ width: '35%', minWidth: '10rem' }} 
                        filter
                        showFilterMenu={false}
                    />
                    <Column 
                        field="type" 
                        header={translatedMessage('generic.type')} 
                        sortable 
                        body={(e) => committeeTypeTemplate(e.type, e.competitionType)} 
                        headerStyle={{ width: '25%', minWidth: '10rem' }}                         
                        filter
                        filterElement={(e) => EvaluationDataTableUtils.committeeTypeFilterTemplate(e, committeeTypes)}
                        showFilterMenu={false}
                    />
                    <Column 
                        field="competitionName" 
                        header={translatedMessage('competition.competition')} 
                        sortable 
                        headerStyle={{ width: '20%', minWidth: '10rem' }} 
                        filter
                        showFilterMenu={false}
                    />
                    <Column 
                        field="active" 
                        header={translatedMessage('generic.status')} 
                        sortable 
                        body={(e) => EvaluationDataTableUtils.committeeStatusBodyTemplate(e.active)}
                        headerStyle={{ width: '5%', minWidth: '8rem' }} 
                        filter
                        filterElement={(e) => EvaluationDataTableUtils.committeeStatusFilterTemplate(e)}
                        showFilterMenu={false}
                    />
                    <Column body={actionBodyTemplate} />
                </DataTable>
            </>
        );
    }
};

export default MyCommitteesComponent;
