import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChooseTestSessionDialog from './ChooseTestSessionDialog';
import GeneralUtils from '../../../utilities/GeneralUtils';
import ErrorMessageDisplayComponent from '../../../components/ErrorMessageDisplayComponent';
import ApplicationTestQuestionsDialog from '../../../components/application/ApplicationTestQuestionsDialog';
import ResultDataTableUtils from '../../../utilities/ResultDataTableUtils';

const MyApplicationTestsPage = () => {
    const [application, setApplication] = useState(null);
    // const [applicationTestCenter, setApplicationTestCenter] = useState({});
    const [applicationTestSessionChoice, setApplicationTestSessionChoice] = useState({});
    const [applicationTestSession, setApplicationTestSession] = useState(null);
    const [applicationTests, setApplicationTests] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [accessCodeDialogVisible, setAccessCodeDialogVisible] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [applicationTest, setApplicationTest] = useState({});
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");
    const [hasResult, setHasResult] = useState(false);
    const [hasResultAC, setHasResultAC] = useState(false);
    const [testQuestions, setTestQuestions] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [answersDialogVisible, setAnswersDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const { applicationIdParam } = useParams();

    const navigate = useNavigate();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        const getApplication = async () => {
            await applicationService.getApplicationLight(applicationIdParam)
                .then(async (_applicationData) => {
                    setApplication(_applicationData);
                    // await getApplicationTestCenter();
                    await getApplicationTestSessionChoice();
                    await getApplicationTestSession();
                    await getApplicationTests();
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        // Enable if the candidate chooses the test center instead of the session
        // const getApplicationTestCenter = async () => {
        //     await applicationService.getApplicationTestCenter(applicationIdParam)
        //         .then((_applicationTestCenter) => {
        //             setApplicationTestCenter(_applicationTestCenter);
        //         })
        //         .catch((error) => {
        //             setError(true)
        //             setErrorMessage(error)
        //             setIsLoading(false)
        //             // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
        //         });
        // };

        const getApplicationTestSessionChoice = async () => {
            await applicationService.getApplicationTestSessionChoice(applicationIdParam)
                .then((_applicationTestSessionChoice) => {
                    setApplicationTestSessionChoice(_applicationTestSessionChoice);
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getApplicationTestSession = async () => {
            await applicationService.getApplicationTestSession(applicationIdParam)
                .then((_applicationTestSession) => {
                    setApplicationTestSession(_applicationTestSession);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationTests = async () => {
            if (applicationTestSession?.id) {
                await applicationService.getApplicationTests(applicationIdParam)
                    .then((response) => {
                        // console.log(response.applicationTestList)
                        let _applicationTests = response.applicationTestList

                        if (_applicationTests?.length > 0) {
                            _applicationTests.forEach(item => {
                                if (item.result !== null) {
                                    setHasResult(true)
                                }
                                if (item.resultAfterContestation !== null) {
                                    setHasResultAC(true)
                                }
                            })
                        }

                        if(response.preliminaryTestScore) {
                            let _preliminaryTests = _applicationTests.filter(item => !item.competitionTest.advancedTest)
                            let _advancedTests = _applicationTests.filter(item => item.competitionTest.advancedTest)
                            _applicationTests = _preliminaryTests

                            _applicationTests.push({ 
                                competitionTest: { 
                                    test: { name: translatedMessage("test.preliminaryTest.overallScore") },
                                    passingScore:  response.preliminaryTestPassingScore
                                },
                                result: response.preliminaryTestScore,
                                resultAfterContestation: response.preliminaryTestContestationScore,
                                isOverAllScore: true
                            })

                            _advancedTests.forEach(item => _applicationTests.push(item))                            
                        }

                        setApplicationTests(_applicationTests);

                        setIsLoading(false);
                    })
                    .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
            } else {
                setIsLoading(false)
            }
        };

        setIsLoading(true);
        getApplication();
    }, [applicationIdParam, applicationService, applicationTestSession?.id]);

    // const onSaveATCR = (atcr) => {
    //     setApplicationTestCenter(atcr);
    // };

    const onSaveApplicationTestSessionChoice = (atcr) => {
        setApplicationTestSessionChoice(atcr);
    };

    const handleTakeTest = (_applicationTest) => {
        setApplicationTest(_applicationTest);
        setAccessCodeDialogVisible(true);
    };

    const getSelectedAnswers = async (_applicationTest) => {
        await applicationService.getSelectedAnswers(_applicationTest)
            .then(_answers => {
                setTestQuestions(_answers)
                setSelectedTest(_applicationTest)
                setAnswersDialogVisible(true)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const actionBodyTemplate = (_applicationTest) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {applicationTestSession?.id && applicationTestSession?.application?.competition?.testSessionsDistributionFinished && 
                    !_applicationTest.isOverAllScore && (
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => handleTakeTest(_applicationTest)}
                        tooltip={translatedMessage('test.start')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={_applicationTest.finished || !_applicationTest.hasUrl || !_applicationTest.canBeStarted ||
                            application?.competition?.status !== 'PRELIMINARY_TEST'}
                    >
                        <FontAwesomeIcon icon="fas fa-play" />
                    </Button>
                )}
                {_applicationTest.hasAnswers && !_applicationTest.isOverAllScore &&
                    <Button icon="pi pi-list" className="p-button-rounded p-button-info m-1"
                        onClick={() => getSelectedAnswers(_applicationTest)}
                        tooltip={translatedMessage(!hasResult ? "test.answers.view" : "test.scale.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const hideDialog = () => {
        setAccessCodeDialogVisible(false);
        setAccessCode('');
    };

    const checkAccessCode = async () => {
        await applicationService
            .verifyAccessCode(applicationTestSession.id, accessCode, applicationTest.id)
            .then((_testUrl) => {
                if (_testUrl) {
                    window.open(_testUrl);
                    hideDialog();
                } else {
                    toast.current.show({ severity: 'error', summary: translatedMessage('test.accessCode.notValid'), life: 5000 });
                }
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('test.accessCode.verify')} icon="pi pi-save" className="p-button-text" onClick={checkAccessCode} />
        </>
    );

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.competition?.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(-1)}>
                                    <div className="flex align-items-center">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="w-full pcn-data-view-panel">
                                {(!applicationTestSession?.id ||
                                    (applicationTestSession?.id && !applicationTestSession?.application?.competition?.testSessionsDistributionFinished)) && (
                                        <>
                                            <h5 className="mb-1">{translatedMessage('application.choosenTestSession')}</h5>
                                            {/* Enable if the candidate chooses the test center instead of the session */}
                                            {/* <div className={applicationTestCenter?.id ? '' : 'flex align-items-center'}>
                                                {!applicationTestCenter?.id && (
                                                    <div className='mr-2'>{translatedMessage('application.choosenTestCenter.none')}</div>
                                                )}
                                                {applicationTestCenter?.id && (
                                                    <div>
                                                        <div className="filed-label">{applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name}</div>

                                                        <div>
                                                            {translatedMessage("generic.address") + ": " +
                                                                applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.address + ", " +
                                                                applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city + ", " +
                                                                applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.county?.label
                                                            }
                                                        </div>
                                                        <div>
                                                            {translatedMessage("generic.phone") + ": " + applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.phone}
                                                        </div>
                                                        <div>
                                                            {translatedMessage("generic.email") + ": " + applicationTestCenter?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.email}
                                                        </div>
                                                    </div>
                                                )}
                                                {!applicationTestSession?.id &&
                                                    <ChooseTestCenterDialog application={application} onSave={onSaveATCR} isEdit={applicationTestCenter?.id !== null} />
                                                }
                                            </div> */}

                                            <div className={applicationTestSessionChoice?.id ? '' : 'flex align-items-center'}>
                                                {!applicationTestSessionChoice?.id && (
                                                    <div className='mr-2'>{translatedMessage('application.choosenTestSession.none')}</div>
                                                )}
                                                {applicationTestSessionChoice?.id && (
                                                    <div>
                                                        <div>
                                                            {translatedMessage("generic.address") + ": " +
                                                                applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.county?.label + ", " +
                                                                applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city + ", " +
                                                                applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.address
                                                            }
                                                        </div>
                                                        <div>
                                                            {translatedMessage("competition.testCenter.name") + ": " + applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name}
                                                        </div>
                                                        <div>
                                                            {translatedMessage("competition.testCenterRoom.name") + ": " + applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.name}
                                                        </div>
                                                        <div>
                                                            {translatedMessage("competition.testSession.date") + ": "} <span className='bold'>{GeneralUtils.formatDate(applicationTestSessionChoice?.testSession?.startDate)} {GeneralUtils.formatTime(applicationTestSessionChoice?.testSession?.startDate)} - {GeneralUtils.formatTime(applicationTestSessionChoice?.testSession?.endDate)}</span>
                                                        </div>
                                                        <div>
                                                            {translatedMessage("generic.phone") + ": " + applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.phone}
                                                        </div>
                                                        <div>
                                                            {translatedMessage("generic.email") + ": " + applicationTestSessionChoice?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.email}
                                                        </div>
                                                    </div>
                                                )}
                                                {!applicationTestSession?.id && application.competition.testSessionsChooseEnabled &&
                                                    <ChooseTestSessionDialog application={application} onSave={onSaveApplicationTestSessionChoice} isEdit={applicationTestSessionChoice?.id !== null}
                                                        chosenTestSessionId={applicationTestSessionChoice?.testSession?.id} />
                                                }
                                            </div>
                                        </>
                                    )}
                                {applicationTestSession?.application?.competition?.testSessionsDistributionFinished && applicationTestSession?.id && (
                                    <>
                                        <h5 className="mb-1">{translatedMessage('testSession.testSession')}</h5>

                                        <div className="font-bold">
                                            {applicationTestSession?.testSession.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name + ", " +
                                                applicationTestSession?.testSession.competitionTestCenterRoom?.testCenterRoom?.name}
                                        </div>
                                        <div>
                                            <span>{translatedMessage("competition.testSession.date") + ": "}</span>
                                            {GeneralUtils.formatDate(applicationTestSession?.testSession?.startDate) + ", " +
                                                GeneralUtils.formatTime(applicationTestSession?.testSession?.startDate) + " - " +
                                                GeneralUtils.formatTime(applicationTestSession?.testSession?.endDate)}
                                        </div>
                                        <div>
                                            {translatedMessage("competition.testCenter.address") + ": " +
                                                applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.address + ", " +
                                                applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city + ", " +
                                                applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.county?.label
                                            }
                                        </div>
                                        <div>
                                            {translatedMessage("generic.phone") + ": " + applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.phone}
                                        </div>
                                        <div>
                                            {translatedMessage("generic.email") + ": " + applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.email}
                                        </div>
                                    </>
                                )}

                                <h5 className="mb-1">{translatedMessage('test.tests')}</h5>
                                <DataTable
                                    ref={dt}
                                    value={applicationTests}
                                    dataKey="id"
                                    className="datatable-responsive pcn-datatable"
                                    responsiveLayout="stack"
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                >
                                    <Column
                                        field="competitionTest.testOrder"
                                        header={translatedMessage('test.order')}
                                        headerStyle={{ width: '5%', minWidth: '6rem' }}
                                        body={(e) => e.isOverAllScore ? "" : e.competitionTest.testOrder}
                                    />
                                    <Column
                                        field="competitionTest.test.name"
                                        header={translatedMessage('test.name')}
                                        headerStyle={{ width: '30%', minWidth: '10rem' }}
                                        bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                    />
                                    <Column
                                        field="competitionTest.passingScore"
                                        header={translatedMessage('test.passingScore')}
                                        headerStyle={{ width: '15%', minWidth: '10rem' }}
                                        body={(e) => e.testType === "QUIZ" ? translatedMessage("Result.ACCEPTED") : e.competitionTest?.passingScore}
                                        bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                    />
                                    <Column
                                        field="result"
                                        header={translatedMessage('test.result')}
                                        headerStyle={{ width: '15%', minWidth: '10rem' }}
                                        hidden={!hasResult}
                                        body={(e) => ResultDataTableUtils.resultBodyTemplate(e)}
                                        bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                    />
                                    <Column
                                        field="resultAfterContestation"
                                        header={translatedMessage('test.resultAfterContestation')}
                                        headerStyle={{ width: '15%', minWidth: '10rem' }}
                                        hidden={!hasResultAC}
                                        body={(e) => ResultDataTableUtils.resultContestationBodyTemplate(e)}
                                        bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                    />
                                    <Column body={actionBodyTemplate}></Column>
                                </DataTable>

                                <Dialog
                                    visible={accessCodeDialogVisible}
                                    style={{ width: '450px' }}
                                    header={applicationTest?.competitionTest?.test?.name}
                                    modal
                                    className="pcn-dialog p-fluid"
                                    footer={testCenterDialogFooter}
                                    onHide={hideDialog}
                                >
                                    <>
                                        <span className="p-float-label">
                                            <InputText id="in" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} autoFocus />
                                            <label htmlFor="in">{translatedMessage('test.accessCode')}</label>
                                        </span>
                                    </>
                                </Dialog>

                                <ApplicationTestQuestionsDialog
                                    visible={answersDialogVisible}
                                    questions={testQuestions}
                                    applicationTest={selectedTest}
                                    title={hasResult ? translatedMessage("test.scale.view") : translatedMessage("test.answers.view")}
                                    closeDialog={() => setAnswersDialogVisible(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyApplicationTestsPage;
