import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CompetitionService } from '../../../service/CompetitionService';
import { TestService } from '../../../service/TestService';
import { translatedMessage } from '../../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiService from '../../../service/ApiService';
import GeneralUtils from '../../../utilities/GeneralUtils';
import DataTableUtils from '../../../utilities/DataTableUtils';
import useNavProps from '../../../hooks/useNavProps';
import ApplicationTestSessionPresenceDialog from './ApplicationTestSessionPresenceDialog';
import SecurityService from '../../../service/SecurityService';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import DataTableExportUtils from '../../../utilities/DataTableExportUtils';

const TestSessionView = () => {
    const [applicationTestSessions, setApplicationTestSessions] = useState(null);
    const [applicationTestSession, setApplicationTestSession] = useState({});
    const [competition, setCompetition] = useState({});
    const [testSession, setTestSession] = useState({});
    const [presenceDialogVisible, setPresenceDialogVisible] = useState(false);

    const [isInOrganizationCommittee, setIsInOrganizationCommittee] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);
    const testService = useMemo(() => new TestService(), []);

    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();

    const { competitionIdParam, testSessionIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        const getCompetition = async () => {
            await competitionService
                .getCompetition(competitionIdParam)
                .then(async (_comp) => {
                    setCompetition(_comp);
                    await getTestSession()
                    await getApplicationTestSessions()
                    setNavPropsValue('competition-evaluation', _comp.id, _comp.name);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getTestSession = async () => {
            await testService
                .getTestSession(testSessionIdParam)
                .then((_testSession) => {
                    setTestSession(_testSession);
                    setNavPropsValue(
                        'test-session',
                        _testSession.id,
                        _testSession.competitionTestCenterRoom.testCenterRoom.testCenter.name + ', ' + _testSession.competitionTestCenterRoom.testCenterRoom.name + ', ' + GeneralUtils.formatDateTime(_testSession.startDate)
                    );
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationTestSessions = async () => {
            await competitionService
                .getApplicationsTestSessions(competitionIdParam, testSessionIdParam)
                .then((_applicationTestSessions) => {
                    setApplicationTestSessions(_applicationTestSessions);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const checkIsInOrganizationCommittee = async () => {
            setIsInOrganizationCommittee(await SecurityService.check('IS_IN_PRELIMINARY_TEST_ORGANIZATION_COMMITTEE', competitionIdParam));
        };

        getCompetition();
        checkIsInOrganizationCommittee();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionIdParam, testSessionIdParam, competitionService, testService]);

    const handleExport = () => {
        let exportData = applicationTestSessions.map((item) => {
            let exportItem = {};
            exportItem.user = item.application.fullName;
            exportItem.presence = item.isPresent ? translatedMessage("generic.yes") : translatedMessage("generic.no");
            exportItem.region = item.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.region.label;
            exportItem.county = item.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.county.label;
            exportItem.city = item.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.city;
            exportItem.center = item.testSession.competitionTestCenterRoom.testCenterRoom.testCenter.name;
            exportItem.room = item.testSession.competitionTestCenterRoom.testCenterRoom.name;
            exportItem.startDate = GeneralUtils.formatDateTime(item.testSession.startDate);
            exportItem.endDate = GeneralUtils.formatDateTime(item.testSession.endDate);
            return exportItem;
        });

        return exportData;
    };

    async function downloadFile(backEndUrl, fileName) {
        try {
            const response = await ApiService.getAuthenticatedInstance().get(backEndUrl, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        } catch (e) {
            console.log(e);
        }
    }

    const handlePrintAll = async () => {
        downloadFile(`/testSession/${testSessionIdParam}/formulareConcursNational`, `formulare-inscriere-sesiune-${testSessionIdParam}.pdf`);
    };

    const handlePrint = async (_applicationTestSession) => {
        downloadFile(`/application/${_applicationTestSession.application.id}/nationalCompetitionForm`, `formular-inscriere-candidat-${_applicationTestSession.application.id}.pdf`);
    };

    const handleEditPresence = async (_applicationTestSession) => {
        setApplicationTestSession(_applicationTestSession);
        setPresenceDialogVisible(true);
    };

    const updatePresence = (_applicationTestSession) => {
        let _ats = applicationTestSessions.find((ats) => ats.id === _applicationTestSession.id);
        _ats.isPresent = _applicationTestSession.isPresent;
        _ats.isCanceled = _applicationTestSession.isCanceled;
        _ats.extendedTime = _applicationTestSession.extendedTime;
        _ats.hasAllTestUrlsGenerated = _applicationTestSession.hasAllTestUrlsGenerated;
        setApplicationTestSession([...applicationTestSessions]);
    };

    const generateTestUrls = async (_applicationTestSession) => {
        await competitionService
            .generateApplicationTestsUrls(_applicationTestSession.id)
            .then((_applicationTestSession) => {
                let _ats = applicationTestSessions.find((ats) => ats.id === _applicationTestSession.id)
                _ats.isPresent = _applicationTestSession.isPresent;
                _ats.hasAllTestUrlsGenerated = _applicationTestSession.hasAllTestUrlsGenerated;
                setApplicationTestSession([...applicationTestSessions]);
                toast.current.show({ severity: 'success', summary: translatedMessage('testSession.application.generateTestUrls.success') + '' });                
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    // Table
    const doOnFilter = (data) => {};

    const dataTableHeader = (
        <div className="flex justify-content-end">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search')} />
            </span>
        </div>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{isInOrganizationCommittee && <Button label={translatedMessage('testSession.printAllFiles')} icon="pi pi-print" className="p-button-primary mr-2" onClick={handlePrintAll} />}</div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={DataTableExportUtils.sessionUserListTableHeader()}
                sortOrderHeader={DataTableExportUtils.sessionUserListSortOrderHeader()}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("testSession.testSession"))}
            />
        );
    };

    const presentBodyTemplate = (applicationTestSession) => {
        let presenceStatus = applicationTestSession.isPresent ? 'present' : 'absent';
        return (
            <>
                <div className={`inline-block status status-applicationTestSession-${presenceStatus.toString().toLowerCase()}`}>{translatedMessage('testSession.' + presenceStatus)}</div>
            </>
        );
    };

    const canceledBodyTemplate = (applicationTestSession) => {
        let canceledStatus = applicationTestSession.isCanceled ? 'canceled' : 'notCanceled';
        return (
            <>
                <div className={`inline-block status status-applicationTestSession-${canceledStatus.toString().toLowerCase()}`}>{translatedMessage('testSession.' + canceledStatus)}</div>
            </>
        );
    };

    const actionBodyTemplate = (applicationTestSession) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {isInOrganizationCommittee && (
                    <Button
                        icon="pi pi-sync"
                        className="p-button-rounded p-button-info m-1"
                        onClick={() => generateTestUrls(applicationTestSession)}
                        tooltip={translatedMessage('testSession.application.generateTestUrls')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!applicationTestSession.isPresent || applicationTestSession.isCanceled || applicationTestSession.hasAllTestUrlsGenerated}
                    />
                )}
                {isInOrganizationCommittee && (
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-info m-1"
                        onClick={() => handleEditPresence(applicationTestSession)}
                        tooltip={translatedMessage('testSession.editPresence')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={competition.status !== 'PRELIMINARY_TEST'}
                    />
                )}
                {isInOrganizationCommittee && (
                    <Button
                        icon="pi pi-print"
                        className="p-button-rounded p-button-info m-1"
                        onClick={() => handlePrint(applicationTestSession)}
                        tooltip={translatedMessage('testSession.printForm')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
                {isInOrganizationCommittee && (
                    <Button icon="pi pi-list" className="p-button-rounded p-button-info m-1"
                        onClick={() => navigate(`/competition-evaluation/${competitionIdParam}/application-test/${applicationTestSession.application.id}`)}
                        tooltip={translatedMessage('test.tests')} tooltipOptions={{ showOnDisabled: true, position: 'top' }} 
                        disabled={!applicationTestSession.isPresent || applicationTestSession.isCanceled}
                    />
                )}
            </div>
        );
    };

    // Render
    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation/${competition.id}/test-session`)}>
                                    <div className="flex align-items-center">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="mb-4">
                                <h5 className="mb-1 font-weight-normal">
                                    {translatedMessage('testSession.assignedCandidates')} {testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city}, {testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name},{' '}
                                    {testSession?.competitionTestCenterRoom?.testCenterRoom?.name}, {GeneralUtils.formatDate(testSession?.startDate)}, {GeneralUtils.formatTime(testSession?.startDate)} - {GeneralUtils.formatTime(testSession?.endDate)}
                                </h5>
                            </div>
                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>
                            <DataTable
                                ref={dt}
                                value={applicationTestSessions}
                                dataKey="id"
                                loading={isLoading}
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={dataTableHeader}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                globalFilterFields={[
                                    'application.fullName'
                                ]}
                            >
                                <Column field="application.fullName" header={translatedMessage('generic.name')} sortable headerStyle={{ width: '40%', minWidth: '10rem' }} />
                                <Column field="isPresent" header={translatedMessage('testSession.presence')} body={presentBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }} />
                                <Column field="isCanceled" header={translatedMessage('testSession.status')} body={canceledBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }} />
                                <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }}></Column>
                            </DataTable>

                            <ApplicationTestSessionPresenceDialog
                                visible={presenceDialogVisible}
                                onSave={updatePresence}
                                onCancel={() => {
                                    setPresenceDialogVisible(false);
                                }}
                                applicationTestSession={applicationTestSession}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestSessionView;
