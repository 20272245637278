import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useNavProps from '../../hooks/useNavProps';

const EvaluationContestationList = () => {
    const [application, setApplication] = useState(null);
    const [applicationResults, setApplicationResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    const { applicationIdParam } = useParams();

    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();
    const dt = useRef(null);

    useEffect(() => {
        setIsLoading(true);

        const getApplication = async () => {
            await applicationService.getApplication(applicationIdParam)
                .then((applicationData) => {
                    setApplication(applicationData);
                    setNavPropsValue('application-contestation', applicationData.id, applicationData.firstName + " " + applicationData.lastName, true, true)
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };        

        const getApplicationResults = async () => {
            applicationService.getApplicationResults(applicationIdParam)
                .then((_applicationResult) => {

                    setApplicationResults(_applicationResult);
                    if(_applicationResult && _applicationResult.length > 0) {
                        setApplication(_applicationResult[0].application)

                        setNavPropsValue('application-contestation', _applicationResult[0].application.id, _applicationResult[0].application.firstName + " " + _applicationResult[0].application.lastName, true, true)
                    } else {
                        getApplication()
                    }
                    setIsLoading(false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getApplicationResults()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, applicationIdParam])

    const resultTypeTemplate = (_result) => {
        return <>{translatedMessage('ResultType.' + _result.type)}</>;
    };

    const resultTemplate = (_result) => {
        return <span className={`status status-result-${_result.result.toString().toLowerCase()}`}>{translatedMessage('Result.' + _result.result)}</span>;
    };

    const resultActionTemplate = (_result) => {
        return (
            <>
                <Button
                    icon="pi pi-file"
                    className="p-button-rounded p-button-primary m-1"
                    onClick={() => navigate(`/competition-evaluation/${application?.competition.id}/application-contestation/${application?.id}/result/${_result.id}`)}
                    tooltip={translatedMessage('application.contestation')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                />
            </>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.competition.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation/${application?.competition.id}`)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>

                            <div className="w-full grid pcn-data-view-panel">
                                <div className="col-12">
                                    <h5 className="my-1 font-weight-normal">{translatedMessage('application.results.contestation')}</h5>
                                </div>
                            </div>
                            <DataTable ref={dt} value={applicationResults} dataKey="id" className="datatable-responsive pcn-datatable" responsiveLayout="stack" emptyMessage={translatedMessage('generic.tableEmptyMessage')}>
                                <Column
                                    header={translatedMessage('application.result.type')}
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    body={resultTypeTemplate}
                                />
                                <Column
                                    header={translatedMessage('application.result')}
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    body={resultTemplate}
                                />
                                <Column
                                    header={translatedMessage('application.contestation')}
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    alignHeader='right'
                                    body={resultActionTemplate}
                                    bodyStyle={{ textAlign: 'right' }}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default EvaluationContestationList;
