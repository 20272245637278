import LanguageService, { translatedMessage } from '../service/LanguageService';
import DOMPurify from 'dompurify';

let dateFormatter = new Intl.DateTimeFormat(Intl.getCanonicalLocales(LanguageService.getCurrentLanguage()), {
  year: 'numeric', month: 'numeric', day: 'numeric',
});

let dateTimeFormatter = new Intl.DateTimeFormat(Intl.getCanonicalLocales(LanguageService.getCurrentLanguage()), {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric',
  hour12: false
});

let timeFormatter = new Intl.DateTimeFormat(Intl.getCanonicalLocales(LanguageService.getCurrentLanguage()), {
  hour: 'numeric', minute: 'numeric',
  hour12: false
});

let dateTimeNoSecondsFormatter = new Intl.DateTimeFormat(Intl.getCanonicalLocales(LanguageService.getCurrentLanguage()), {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric',
  hour12: false
});

const GeneralUtils = {

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  },

  formatDateTimeForServer(value) {
    //console.log(value);
    let date = value;
    if (value && typeof value === 'string') {
      date = new Date(Date.parse(value));
    }
    return date ? (
      [
        date.getFullYear(),
        GeneralUtils.padTo2Digits(date.getMonth() + 1),
        GeneralUtils.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        GeneralUtils.padTo2Digits(date.getHours()),
        GeneralUtils.padTo2Digits(date.getMinutes()),
        GeneralUtils.padTo2Digits(date.getSeconds()),
      ].join(':')
    ) : '-';
  },

  emptyInNullForObjectFields(object) {
    return Object.keys(object).reduce((acc, key) => { acc[key] = object[key] === null ? '' : object[key]; return acc; }, {})
  },

  nullIfEmptyHTMLEditor(value) {
    let response = value.trim();
    response = response.replaceAll('&nbsp;', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '').replaceAll('<br/>', '')
    response = response.replace(/[\u200B-\u200D\uFEFF]/g, '');

    return response && response.trim() !== '' ? response.trim() : null
  },

  formatDateDDMMYYY(value) {
    return value
      ? value.toLocaleDateString('ro-RO', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).replaceAll(".", "-")
      : '-';
  },

  formatDate(value) {
    //console.log(value);
    let date = value;
    if (value && typeof value === 'string') {
      date = new Date(Date.parse(value));
    }
    return date ? dateFormatter.format(date) : '-';
  },

  formatTime(value) {
    //console.log(value);
    let date = value;
    if (value && typeof value === 'string') {
      date = new Date(Date.parse(value));
    }
    return date ? timeFormatter.format(date) : '-';
  },

  formatDateTime(value) {
    //console.log(value);
    let date = value;
    if (value && typeof value === 'string') {
      date = new Date(Date.parse(value));
    }
    return date ? dateTimeFormatter.format(date) : '-';
  },

  formatDateTimeNoSeconds(value) {
    //console.log(value);
    let date = value;
    if (value && typeof value === 'string') {
      date = new Date(Date.parse(value));
    }
    return date ? dateTimeNoSecondsFormatter.format(date) : '-';
  },

  resetSecondsAndMiliseconds(value) {
    let date = value;
    if (date) {
      date = new Date(date.setMilliseconds(0))
      date = new Date(date.setSeconds(0))
    }
    return date;
  },

  roundDateTimeHour(addedHours) {
    let newDate = new Date()
    newDate.setHours(newDate.getHours() + 1 + addedHours)
    newDate.setMinutes(0)
    newDate.setSeconds(0)

    return newDate
  },

  getNumberSeparatedByDots: (x, decimalsNo) => {
    if (!x) {
      if (x !== 0) {
        return "N/A";
      }
    }

    if (x.toString().includes(".")) {
      let decimals = x.toString().split(".")[x.toString().split(".").length - 1].substring(0, decimalsNo);

      return x.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "," + decimals;
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",00";
  },

  getSubstringWithEllipsis: (content, charNumber) => {
    if (content.length - 3 <= charNumber) {
      return content
    } else {
      let _content = content.slice(0, charNumber).concat("...")
      return _content
    }
  },

  getHTMLSubstringWithEllipsis: (content, charNumber) => {
    if (content.length - 3 <= charNumber) {
      return DOMPurify.sanitize(content)
    } else {
      let _content = DOMPurify.sanitize(content.slice(0, charNumber)).concat("...")
      return _content
    }
  },

  // SunEditor
  getSunEditorButtons() {
    return [
      ['font', 'fontSize', 'formatBlock'],
      ['paragraphStyle', 'blockquote'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['fontColor', 'hiliteColor', 'textStyle'],
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'lineHeight'],
      ['removeFormat'],
      ['table', 'undo'],
      // ['showBlocks'],
      // ['link', 'redo']
      // '/',
    ];
  },

  getSunEditorDefaultStyle() {
    return "font-family: Roboto; font-size: 15px; color: #000000;";
  },

  getSunEditorFonts() {
    return ['Arial', 'Comic Sans MS', 'Courier New', 'Impact',
      'Georgia', 'Open Sans', 'Roboto', 'sans-serif', 'tahoma', 'Trebuchet MS', 'Verdana'];
  },

  // Calendar
  getCalendarRO() {
    return {
      "startsWith": "Începe cu",
      "contains": "Conține",
      "notContains": "Nu conține",
      "endsWith": "Se termină cu",
      "equals": "Este egal",
      "notEquals": "Nu este egal",
      "noFilter": "Fără filtru",
      "lt": "Mai mic decât",
      "lte": "Mai mic sau egal cu",
      "gt": "Mai mare decât",
      "gte": "Mai mare sau egal cu",
      "dateIs": "Data este",
      "dateIsNot": "Data nu este",
      "dateBefore": "Data este înainte",
      "dateAfter": "Data este după",
      "custom": "Personalizat",
      "clear": "Curăță",
      "apply": "Aplică",
      "matchAll": "Potrivește cu toate",
      "matchAny": "Potrivește cu orice",
      "addRule": "Adăugă o regulă",
      "removeRule": "Elimină regula",
      "accept": "Da",
      "reject": "Nu",
      "choose": "Alege",
      "upload": "Încarcă",
      "cancel": "Anulează",
      "dayNames": ["Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"],
      "dayNamesShort": ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"],
      "dayNamesMin": ["Du", "Lu", "Ma", "Mi", "Jo", "Vi", "Sâ"],
      "monthNames": ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"],
      "monthNamesShort": ["Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul", "Aug", "Sep", "Oct", "Noi", "Dec"],
      "today": "Astăzi",
      "weekHeader": "Săpt",
      "firstDayOfWeek": 1,
      "dateFormat": "dd.mm.yy",
      "weak": "Slabă",
      "medium": "Medie",
      "strong": "Puternică",
      "passwordPrompt": "Introduceți parola",
      "emptyFilterMessage": "Nu s-au găsite rezultate",
      "emptyMessage": "Nu există opțiuni disponibile"
    }
  },

  getFileTypeByCode(code) {
    if (code.toLowerCase() === 'pdf') {
      return ".pdf"
    } else if (code.toLowerCase() === 'image') {
      return "image/*"
    } else if (code.toLowerCase() === 'xlsx') {
      return ".xlsx"
    } else if (code.toLowerCase() === 'xls') {
      return ".xls"
    }
  },

  checkFileMimeByCode(codeList, fileType) {
    let isError = true
    codeList.forEach(item => {
      if (item.toLowerCase() === 'pdf' && fileType === "application/pdf") {
        isError = false
      }
      if (item.toLowerCase() === 'image' && fileType.substring(0, 6) === "image/") {
        isError = false
      }
      if (item.toLowerCase() === 'xlsx' && fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        isError = false
      }
      if (item.toLowerCase() === 'xls' && (fileType === "application/x-excel" || fileType === "application/vnd.ms-excel")) {
        isError = false
      }
    })

    return isError
  },

  replaceSpaces(value) {
    let response = value.trim();
    response = response.replaceAll(' ', '_')

    return response
  },

  processSessionFilterValues(filterId, filter, filterUpdatedFromSession, onUpdateFromSession) {
    let _filter_values_ = sessionStorage.getItem('_filter_values_') ? JSON.parse(sessionStorage.getItem('_filter_values_')) : {};
    let sessionFilter = _filter_values_[filterId];

    if (!filterUpdatedFromSession && sessionFilter) {
      //console.log('update filter values from session');
      filter.filters = sessionFilter.filters;
      filter.first = sessionFilter.first;
      filter.rows = sessionFilter.rows;
      filter.page = sessionFilter.page;
      filter.sortField = sessionFilter.sortField;
      filter.sortOrder = sessionFilter.sortOrder;
      if (typeof onUpdateFromSession === 'function') {
        onUpdateFromSession(true);
      }
    } else {
      //console.log('save filter values to session');
      _filter_values_[filterId] = filter;
      sessionStorage.setItem('_filter_values_', JSON.stringify(_filter_values_));
    }
  },

  initApplicationEvaluationLazyParams() {
    return {
      first: 0,
      rows: 100,
      page: 1,
      sortField: 'id',
      sortOrder: 1,
      filters: {
        fullName: { value: null },
        applicationStatus: { value: null },
        committeeName: { value: null },
        evaluationStatus: { value: null },
        accessibility: { value: null }
      }
    }
  },

  dashIfNullForObject(object) {
    return object ? object : '-'
  },

  yesNoConverter(value) {
    return value ? translatedMessage("generic.yes") : translatedMessage("generic.no")
  },
};

export default GeneralUtils;
