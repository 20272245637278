import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { FormFieldService } from '../../service/FormFieldService';
import EnumService from '../../service/EnumService';
import GeneralUtils from '../../utilities/GeneralUtils';
import { Checkbox } from 'primereact/checkbox';

const emptyFormField = {
    id: null,
    form: {
        id: null,
        name: ''
    },
    field: {
        id: null,
        completeName: '',
        code: '',
        label: '',
        type: ''
    },
    requestedValue: '',
    requestedItemId: null,
    requestedItem: null,
    entityLookupField: '',
    entityLookupLabel: '',
    isced: null,
    domain: null,
    spec: null
};

const EditFormFieldDialog = (props) => {

    const [formField, setFormField] = useState(emptyFormField);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [filteredFields, setFilteredFields] = useState(null);
    const [availableFields, setAvailableFields] = useState([]);
    const [isNew, setIsNew] = useState(false);
    const [entityLookupList, setEntityLookupList] = useState([]);
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);
    const [iscedList, setIscedList] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const [specList, setSpecList] = useState([]);
    const [disableDomain, setDisableDomain] = useState(true);
    const [disableSpec, setDisableSpec] = useState(true);

    const toast = useRef(null);

    const formFieldService = useMemo(() => new FormFieldService(), []);

    const booleanOptions = [
        { value: 'TRUE', label: translatedMessage('generic.true') },
        { value: 'FALSE', label: translatedMessage('generic.false') }
    ];

    useEffect(() => {
        const getEntityLookupList = async (fieldClass) => {
            const response = await EnumService.getEntityLookupWithParentIdByField(fieldClass)
            setEntityLookupList(response)
            return response
        }

        const getEntityLookupValue = async (id) => {
            const response = await EnumService.getEntityLookupValueById(id)
            return response
        }

        const getIscedList = async () => {
            const response = await EnumService.getEntityLookupWithParentIdByField('NOM:ISCED')
            setIscedList(response)
            return response
        }

        const getSpecList = async (parentId) => {
            if (parentId) {
                const response = await EnumService.getEntityLookupWithParentByParentId(parentId)
                setSpecList(response)
            }
        }

        const getDomainList = async (parentId) => {
            if (parentId) {
                const response = await EnumService.getEntityLookupWithParentByParentId(parentId)
                setDomainList(response)
            }
        }

        const computeFormField = async () => {
            let _formField = emptyFormField
            let isNew = true;

            if (props.formField && props.visible) {
                _formField = GeneralUtils.emptyInNullForObjectFields(props.formField)

                if (_formField.requestedItemId && _formField.entityLookupField) {
                    let _field = props.formField.field

                    if (_field.code === 'studiu') {
                        let _iscedList = await getIscedList()
                        let selectedItem = await getEntityLookupValue(_formField.requestedItemId)
                        if (selectedItem) {
                            if (selectedItem.entityLookupField === "NOM:EDU_SPEC") {
                                getSpecList(selectedItem.relatedId)
                                setDisableSpec(false)
                                _formField.spec = selectedItem

                                let selectedDomain = await getEntityLookupValue(selectedItem.relatedId)
                                if (selectedDomain) {
                                    getDomainList(selectedDomain.relatedId)
                                    setDisableDomain(false)
                                    _formField.domain = selectedDomain
                                }

                                let selectedIsced = _iscedList.filter(item => item.value === selectedDomain.relatedId)
                                _formField.isced = selectedIsced[0] ? selectedIsced[0] : null
                            } else if (selectedItem.entityLookupField === "NOM:EDU_DOM") {
                                getSpecList(selectedItem.value)
                                setDisableSpec(false)

                                getDomainList(selectedItem.relatedId)
                                setDisableDomain(false)
                                _formField.domain = selectedItem

                                let selectedIsced = _iscedList.filter(item => item.value === selectedItem.relatedId)
                                _formField.isced = selectedIsced[0] ? selectedIsced[0] : null
                            } else {
                                _formField.isced = selectedItem
                                getDomainList(selectedItem.value)
                                setDisableDomain(false)
                                setDisableSpec(true)
                            }
                        }
                    } else {
                        let _entityLookupResponse = await getEntityLookupList(_formField.entityLookupField)
                        let selectedItem = _entityLookupResponse.filter(item => item.value === _formField.requestedItemId)

                        _formField.requestedItem = selectedItem[0] ? selectedItem[0] : null
                    }
                } else if (!_formField.requestedValue) {
                    await getIscedList()
                }

                isNew = false
            }

            _formField.form = props.form

            setFormField(_formField)
            setIsNew(isNew)
            setDialogVisible(props.visible)
            setAvailableFields(props.availableFields)
        }

        computeFormField()
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        resertLookups()
        formik.resetForm();
    };

    const searchField = (event) => {
        setTimeout(() => {
            let _filteredFields;
            if (!event.query.trim().length) {
                _filteredFields = [...availableFields];
            } else {
                _filteredFields = availableFields.filter((item) => {
                    return item.code.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 ||
                        item.label.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 ||
                        item.type.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
                });
            }

            setFilteredFields(_filteredFields);
        }, 100);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formField,
        validate: (data) => {
            let errors = {};

            if (!data.field || !data.field.id) {
                errors.field = translatedMessage('form.error.field.required');
            }

            if (data.field && data.field.type && data.field.type === 'CUSTOM') {
                if (data.field.code === 'studiu') {
                    if (!data.isced && data.domain) {
                        errors.isced = translatedMessage('form.error.education.isced.required');
                    } else {
                        if (!data.domain && data.spec) {
                            errors.domain = translatedMessage('form.error.education.domain.missing');
                        }
                    }

                } else if ((data.field.code === 'experienta' || data.field.code === 'limbiStaine' || data.field.code === 'cunostinteIT') &&
                    !data.requestedValue) {

                    errors.requestedValue = translatedMessage('form.error.requestedValue.required');
                }
            }

            if (data.field && data.field.type && data.field.type === 'LIST') {
                if (!data.requestedItem) {
                    errors.requestedItem = translatedMessage('form.error.requestedValue.required');
                }
            }

            if (data.field.type !== 'CUSTOM' && data.field.type !== 'LIST') {
                if (data.field && data.field.type && !data.requestedValue) {
                    errors.requestedValue = translatedMessage('form.error.requestedValue.required');
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            data.requestedItemId = data.requestedItem && data.requestedItem.value ? data.requestedItem.value : data.requestedItemId
            data.entityLookupField = data.requestedItem && data.requestedItem.entityLookupField ? data.requestedItem.entityLookupField : null
            if (data.spec) {
                data.requestedItemId = data.spec.value
                data.entityLookupField = data.spec.entityLookupField
                data.entityLookupLabel = data.isced.label + " > " + data.domain.label + " > " + data.spec.label
            } else if (data.domain) {
                data.requestedItemId = data.domain.value
                data.entityLookupField = data.domain.entityLookupField
                data.entityLookupLabel = data.isced.label + " > " + data.domain.label
            } else if (data.isced) {
                data.requestedItemId = data.isced.value
                data.entityLookupField = data.isced.entityLookupField
                data.entityLookupLabel = data.isced.label
            } else {
                data.entityLookupLabel = null
            }

            if (data.requestedItemId) {
                data.requestedValue = null
            }

            saveFormField(data)
            hideDialog()
            setFormField(emptyFormField)
            formik.resetForm()
        }
    });

    const saveFormField = (data) => {
        formFieldService.saveFormField(data)
            .then((savedField) => {
                savedField.field.completeName = setCompleteName(savedField.field)
                savedField.entityLookupLabel = data.entityLookupLabel
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedField, isNew);
                    resertLookups()
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const resertLookups = () => {
        setDisableDomain(true)
        setDisableSpec(true)
        setFilteredFields([])
        setIscedList([])
        setDomainList([])
        setSpecList([])
    }

    const setCompleteName = (field) => {
        return field.id
            ? (field.label + ' (' + field.code + ') : ' + translatedMessage('FieldType.' + field.type))
            : '';
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const itemTemplate = (item) => {
        return (
            <span>{item.completeName}</span>
        );
    };

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="field-form" type="submit" />
        </>
    );

    const handleFieldChange = async (event) => {
        if (event.value) {
            formik.handleChange(event)

            let item = event.value
            if (item.type === 'LIST' && item.entityLookupField) {
                const response = await EnumService.getEntityLookupWithParentByField(item.entityLookupField)
                setEntityLookupList(response)
            } else if (item.type === 'CUSTOM' && item.code === 'studiu') {
                const response = await EnumService.getEntityLookupWithParentByField("NOM:ISCED")
                setIscedList(response)
                setDisableDomain(true)
                setDisableSpec(true)
            }
        }
    }

    const handleListChange = async (data, field) => {
        formik.setFieldValue(field, data.value)

        if (formik.values.field.code === 'studiu' && data.originalEvent.type === 'click') {
            if (field === 'isced') {
                const response = await EnumService.getEntityLookupWithParentByParentId(data.value.value)
                setDomainList(response)
                formik.setFieldValue('domain', null)
                formik.setFieldValue('spec', null)
                if (response.length > 0) {
                    setDisableDomain(false)
                }
            } else if (field === 'domain') {
                const response = await EnumService.getEntityLookupWithParentByParentId(data.value.value)
                setSpecList(response)
                formik.setFieldValue('spec', null)
                if (response.length > 0) {
                    setDisableSpec(false)
                }
            }
        }
    }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogVisible}
                header={translatedMessage('form.field.details')}
                modal
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
                onHide={hideDialog}
            >
                <form id="field-form" onSubmit={formik.handleSubmit}>

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="form.name" name="form.name" value={formik.values.form.name} onChange={formik.handleChange} disabled />
                            <label htmlFor="form.name">{translatedMessage('form.field.form')}*</label>
                        </span>
                    </div>

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <AutoComplete
                                id="field"
                                name="field"
                                value={formik.values && formik.values.field}
                                suggestions={filteredFields}
                                completeMethod={searchField}
                                field="completeName"
                                dropdown
                                forceSelection
                                itemTemplate={itemTemplate}
                                onChange={handleFieldChange}
                                aria-label="Fields"
                                dropdownAriaLabel="Select field"
                                scrollHeight="300px"
                                showEmptyMessage={true}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                autoFocus
                                className={classNames({ 'p-invalid': isFormFieldValid('field') })}
                            />

                            <label htmlFor="field" className={classNames({ 'p-error': isFormFieldValid('field') })}>
                                {translatedMessage('form.field')} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('field')}

                    {formik.values.field.type === 'STRING' &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <InputText type="text" id="requestedValue" name="requestedValue" value={formik.values.requestedValue} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('requestedValue') })} />
                                    <label htmlFor="requestedValue" className={classNames({ 'p-error': isFormFieldValid('requestedValue') })}>
                                        {translatedMessage('form.field.requestedValue')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('requestedValue')}
                        </>
                    }
                    {formik.values.field.type === 'BOOLEAN' &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <Dropdown id="requestedValue" name="requestedValue" value={formik.values.requestedValue} onChange={formik.handleChange}
                                        options={booleanOptions} className={classNames({ 'p-invalid': isFormFieldValid('requestedValue') })} />
                                    <label htmlFor="requestedValue" className={classNames({ 'p-error': isFormFieldValid('requestedValue') })}>
                                        {translatedMessage('form.field.requestedValue')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('requestedValue')}
                        </>
                    }
                    {formik.values.field.type === 'NUMBER' &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <InputNumber id="requestedValue" name="requestedValue" value={formik.values.requestedValue} onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('requestedValue') })} />
                                    <label htmlFor="requestedValue" className={classNames({ 'p-error': isFormFieldValid('requestedValue') })}>
                                        {translatedMessage('form.field.requestedValue')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('requestedValue')}
                        </>
                    }
                    {formik.values.field.type === 'LIST' &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <AutoComplete
                                        id="requestedItem"
                                        name="requestedItem"
                                        value={formik.values.requestedItem}
                                        suggestions={filteredentityLookups}
                                        completeMethod={(e) => searchListItems(e, entityLookupList)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        dropdown
                                        onChange={(d) => handleListChange(d, "requestedItem")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('requestedItem') })} />

                                    <label htmlFor="requestedItem" className={classNames({ 'p-error': isFormFieldValid('requestedItem') })}>
                                        {translatedMessage(formik.values.field.entityLookupField ? formik.values.field.entityLookupField.replace(":", "_") : 'form.field.requestedValue')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('requestedItem')}
                        </>
                    }
                    {formik.values.field.type === 'CUSTOM' && formik.values.field.code === 'studiu' &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <AutoComplete
                                        id="isced"
                                        name="isced"
                                        value={formik.values.isced}
                                        suggestions={filteredentityLookups}
                                        completeMethod={(e) => searchListItems(e, iscedList)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        dropdown
                                        onChange={(d) => handleListChange(d, "isced")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('isced') })} />

                                    <label htmlFor="isced" className={classNames({ 'p-error': isFormFieldValid('isced') })}>
                                        {translatedMessage("profile.education.isced")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('isced')}

                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <AutoComplete
                                        id="domain"
                                        name="domain"
                                        value={formik.values.domain}
                                        suggestions={filteredentityLookups}
                                        completeMethod={(e) => searchListItems(e, domainList)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        dropdown
                                        onChange={(d) => handleListChange(d, "domain")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('domain') })}
                                        disabled={disableDomain} />

                                    <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domain') })}>
                                        {translatedMessage("profile.education.domain")}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('domain')}

                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <AutoComplete
                                        id="spec"
                                        name="spec"
                                        value={formik.values.spec}
                                        suggestions={filteredentityLookups}
                                        completeMethod={(e) => searchListItems(e, specList)}
                                        virtualScrollerOptions={{ itemSize: 38 }}
                                        field="label"
                                        dropdown
                                        onChange={(d) => handleListChange(d, "spec")}
                                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        className={classNames({ 'p-invalid': isFormFieldValid('domainId') })}
                                        disabled={disableSpec} />

                                    <label htmlFor="spec" className={classNames({ 'p-error': isFormFieldValid('spec') })}>
                                        {translatedMessage('profile.education.specialization')}
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('spec')}
                        </>
                    }
                    {formik.values.field.type === 'CUSTOM' && (formik.values.field.code === 'experienta' ||
                        formik.values.field.code === 'limbiStaine' || formik.values.field.code === 'cunostinteIT') &&
                        <>
                            <div className="form p-inputgroup mt-3 mb-2">
                                <span className="p-float-label">
                                    <InputText type="text" id="requestedValue" name="requestedValue" value={formik.values.requestedValue} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('requestedValue') })} />
                                    <label htmlFor="requestedValue" className={classNames({ 'p-error': isFormFieldValid('requestedValue') })}>
                                        {translatedMessage('form.field.requestedValue')} *
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('requestedValue')}
                        </>
                    }

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Checkbox
                                inputId="mandatory"
                                name="mandatory"
                                value={formik.values.mandatory}
                                onChange={(e) => formik.setFieldValue("mandatory", e.checked)}
                                checked={formik.values.mandatory} />
                            <label htmlFor="mandatory" className={classNames({ 'p-error': isFormFieldValid('mandatory') })} style={{ marginLeft: '1rem' }}>
                                {translatedMessage('form.field.mandatory')} *
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('mandatory')}

                </form>
            </Dialog>
        </>
    );
};

export default EditFormFieldDialog;
