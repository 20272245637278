import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTableUtils from '../../utilities/DataTableUtils';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';
import useAuth from '../../hooks/useAuth';
import GeneralUtils from '../../utilities/GeneralUtils';
import EnumService from '../../service/EnumService';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import CompetitionUtils from '../../utilities/CompetitionUtils';
import DataTableExportUtils from '../../utilities/DataTableExportUtils';

const EvaluationCompetitionList = () => {
    const [competitions, setCompetitions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [competitionCategories, setCompetitionCategories] = useState([]);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.CONTAINS },
            positionCategory: { value: null, matchMode: FilterMatchMode.IN },
            institution: { value: null, matchMode: FilterMatchMode.IN },
            status: { value: null, matchMode: FilterMatchMode.IN }
        }
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        name: 'name',
        status: 'status',
        type: 'type',
        positionCategory: 'position_category',
        startDate: 'start_date',
        submitEndDate: 'submit_end_date'
    };
    const [totalRecords, setTotalRecords] = useState(0);
    const [firstLoading, setFirstLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();
    const navigate = useNavigate();

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getCompetitionTypes = async () => {
            await EnumService.getEnumByName('CompetitionType').then((_types) => setTypes(_types));
        };

        const getCompetitionStatuses = async () => {
            await EnumService.getEnumByName('CompetitionStatus').then((_statuses) => setStatuses(_statuses));
        };

        const getCompetitionCategories = async () => {
            setCompetitionCategories(await EnumService.getEnumByName('PositionCategory'));
        };

        const getInstitutions = async () => {
            await competitionService.getInstitutions({ name: null })
                .then((_institutions) => {
                    setInstitutions(_institutions);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetitionTypes();
        getCompetitionStatuses();
        getCompetitionCategories();
        getInstitutions();

        setFirstLoading(false);
    }, [competitionService]);

    useEffect(() => {
        loadCompetitions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('CompetitionEvaluationsList', lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id';
        criteria.name = lazyParams.filters.name.value;
        criteria.statuses = lazyParams.filters.status.value;
        criteria.type = lazyParams.filters.type.value;
        criteria.positionCategories = lazyParams.filters.positionCategory.value;
        criteria.institutions = lazyParams.filters.institution.value

        return criteria;
    };

    const loadCompetitions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService
            .getCompetitionListForEvaluationByCriteria(criteria)
            .then((data) => {
                setCompetitions(data.competitions);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const showCompetitionViewPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}`);
    };

    const showCompetitionCommitteePage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/committees`);
    };

    const showCompetitionResultsPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/results`);
    };

    const showCompetitionClarificationPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/clarifications`);
    };

    const showCompetitionContestationPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/contestations`);
    };

    const showCompetitionTestSessionsPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/test-session`);
    };

    const showCompetitionJobResultEditPage = (competition) => {
        navigate(`/competition-evaluation/${competition.id}/phase-result`);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            competitionService.getCompetitionListForEvaluationByCriteria(criteria)
                .then((data) => {
                    let exportData = data.competitions.map((item) => ({ ...item }));
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.status = translatedMessage('CompetitionStatus.' + item.status)
                        exportItem.type = translatedMessage('CompetitionType.' + item.type)
                        exportItem.institution = item.institution.name

                        delete exportItem.form
                        delete exportItem.testSessionsDistributionFinished
                        delete exportItem.eligibilityFinished
                        delete exportItem.description
                        delete exportItem.resultType

                        return exportItem
                    });

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return <ExportButtonComponent
            getExportData={handleExport}
            header={DataTableExportUtils.competitionListTableHeader()}
            sortOrderHeader={DataTableExportUtils.competitionListSortOrderHeader()}
            fileName={translatedMessage('competition.competitions')}
        />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('EVALUATION_VIEW') && (
                    <>
                        <Button
                            icon="pi pi-user"
                            className="p-button-rounded p-button-primary m-1"
                            onClick={() => showCompetitionViewPage(rowData)}
                            tooltip={translatedMessage('competition.applications')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        />

                        <Button
                            className="p-button-rounded p-button-primary p-button-icon-only m-1"
                            onClick={() => showCompetitionCommitteePage(rowData)}
                            tooltip={translatedMessage('competition.committees')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        >
                            <FontAwesomeIcon icon="fas fa-users" />
                        </Button>

                        <Button
                            className="p-button-rounded p-button-primary p-button-icon-only m-1"
                            onClick={() => showCompetitionClarificationPage(rowData)}
                            tooltip={translatedMessage('application.clarification.requests')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        >
                            <FontAwesomeIcon icon="fas fa-comments" />
                        </Button>

                        <Button
                            className="p-button-rounded p-button-primary p-button-icon-only m-1"
                            onClick={() => showCompetitionContestationPage(rowData)}
                            tooltip={translatedMessage('application.contestations')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        >
                            <FontAwesomeIcon icon="far fa-hand-peace" />
                        </Button>

                        <Button
                            className="p-button-rounded p-button-primary p-button-icon-only m-1"
                            onClick={() => showCompetitionResultsPage(rowData)}
                            tooltip={translatedMessage('competition.results')}
                            tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        >
                            <FontAwesomeIcon icon="fas fa-chart-simple" />
                        </Button>
                        {CompetitionUtils.isJobCompetition(rowData.type) ? (
                            <Button
                                className="p-button-rounded p-button-primary p-button-icon-only m-1"
                                onClick={() => showCompetitionJobResultEditPage(rowData)}
                                tooltip={translatedMessage('application.result.competitionPhase')}
                                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                                disabled={!rowData.eligibilityFinished}
                            >
                                <FontAwesomeIcon icon="fas fa-list-check" />
                            </Button>
                        ) : (
                            <Button
                                className="p-button-rounded p-button-primary p-button-icon-only m-1"
                                onClick={() => showCompetitionTestSessionsPage(rowData)}
                                tooltip={translatedMessage('testSession.testSessions')}
                                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                                disabled={!rowData.allowTestSessions}
                            >
                                <FontAwesomeIcon icon="fas fa-people-roof" />
                            </Button>
                        )}
                    </>
                )}
            </div>
        );
    };

    // const submitEndDateOnBodyTemplate = (rowData) => {
    //     return GeneralUtils.formatDate(rowData.submitEndDate);
    // };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h3 className="mb-0">{translatedMessage('competition.competitions')}</h3>

                                <Toolbar right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={competitions}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    responsiveLayout="stack"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    loading={isLoading}
                                >
                                    <Column field="name" header={translatedMessage('generic.nameRo')} sortable headerStyle={{ width: '20%', minWidth: '10rem' }} filter showFilterMenu={false} />
                                    <Column
                                        field="type"
                                        header={translatedMessage('generic.type')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.newTypeBodyTemplate(e.type)}
                                        headerStyle={{ width: '12%', minWidth: '12rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.newTypeFilterTemplate(e, types)}
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="positionCategory"
                                        header={translatedMessage('competition.positionCategory')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.positionCategory)}
                                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.categoryFilterTemplate(e, competitionCategories)}
                                        filterHeaderClassName="pcn-filter-column-250"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="institution"
                                        header={translatedMessage('competition.institution')}
                                        sortable
                                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                                        body={(e) => e.institution.name}
                                        filter
                                        filterField="institution"
                                        filterElement={(e) => CompetitionDataTableUtils.institutionFilterTemplate(e, institutions)}
                                        filterHeaderClassName="pcn-filter-column-300"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="status"
                                        header={translatedMessage('generic.status')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.statusBodyTemplate(e.status)}
                                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.statusFilterTemplate(e, statuses)}
                                        showFilterMenu={false}
                                    />
                                    {/* <Column field="submitEndDate" header={translatedMessage('competition.submitEndDate')} sortable body={submitEndDateOnBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }} /> */}
                                    <Column body={actionBodyTemplate} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default EvaluationCompetitionList;
