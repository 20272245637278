import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';

import { translatedMessage } from '../service/LanguageService';
import useInitUserData from '../hooks/useInitUserData';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Captcha } from 'primereact/captcha';
import PasswordRequirement from '../components/PasswordRequirementComponent'
import ApiService from '../service/ApiService';
import FooterBeforeLogin from '../components/FooterBeforeLogin';

const dialogInitialData = {
    title: translatedMessage("register.success.dialog.title"),
    subTitle: translatedMessage("register.success.dialog.subTitle"),
    message: translatedMessage("register.success.dialog.message"),
    class: 'pi-check-circle text-green-500'
}

const Register = () => {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [username, setUsername] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [dialogData, setDialogData] = useState(dialogInitialData);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("")
    const [captchaChecked, setCaptchaChecked] = useState(false)
    const [captchaInit, setCaptchaInit] = useState(true)

    const navigate = useNavigate();
    const toast = useRef(null);
    const initUserData = useInitUserData();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            username: '',
            password: '',
            confirmPassword: '',
            acceptTc: false,
            acceptPersonalData: false,
            acceptNotification: true,
            acceptMarketing: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.firstName || !data.firstName.trim()) {
                errors.firstName = translatedMessage("form.error.firstName.required");
            }

            if (!data.lastName || !data.lastName.trim()) {
                errors.lastName = translatedMessage("form.error.lastName.required");
            }

            if (!data.username || !data.username.trim()) {
                errors.username = translatedMessage("form.error.username.required");
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translatedMessage("form.error.username.invalid");
            }

            if (!data.password) {
                errors.password = translatedMessage("form.error.password.required");
            }
            else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/i.test(data.password.replace(" ", ""))) {
                errors.password = translatedMessage("form.error.password.invalid");
            }

            if (!data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.required");
            }
            else if (data.password !== data.confirmPassword) {
                errors.confirmPassword = translatedMessage("form.error.confirmPassword.noMatch");
            }

            if (!data.acceptTc) {
                errors.acceptTc = translatedMessage("form.error.terms.tc.required");
            }

            if (!data.acceptPersonalData) {
                errors.acceptPersonalData = translatedMessage("form.error.terms.gdpr.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            if (captchaChecked) {
                setDialogData(dialogInitialData)

                ApiService.instance.post('/user/register', {
                    username: data.username,
                    password: data.password,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    acceptTc: data.acceptTc,
                    acceptPersonalData: data.acceptPersonalData,
                    acceptNotification: data.acceptNotification,
                    acceptMarketing: data.acceptMarketing,
                    captchaResponse: captchaResponse
                })
                    .then(() => {
                        setUsername(data.username);
                        setRegisterSuccess(true);
                        setShowMessage(true);
                    })
                    .catch(error => {
                        let errorMessage = "register.error.generic";
                        if (error && error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message
                        }
                        setRegisterSuccess(false);
                        setDialogData({
                            title: translatedMessage("register.error.dialog.title"),
                            message: translatedMessage(errorMessage),
                            class: 'pi-times-circle text-red-500'
                        })
                        setShowMessage(true);
                    });
            } else {
                setCaptchaInit(false)
            }
        }
    });

    const dialogClose = () => {
        if (registerSuccess) {
            if (code && code.trim() && code.trim().length === 4) {
                setCodeError(null);
                ApiService.instance.post('/user/activate/token', {
                    username: username,
                    code: code
                })
                    .then(async tokenResponse => {
                        await ApiService.persistToken(tokenResponse.data);
                        await initUserData()
                            .then(() => {
                                navigate('/home');
                            })
                            .catch(error => {
                                let errorMessage = "login.error.generic";
                                if (error && error.response && error.response.data && error.response.data.message) {
                                    errorMessage = error.response.data.message
                                }
                                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage) });

                                navigate("/login");
                            });
                    })
                    .catch(error => {
                        let errorMessage = "register.error.generic";
                        if (error && error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message
                        }
                        setDialogData({
                            title: translatedMessage("register.error.dialog.title"),
                            message: translatedMessage(errorMessage),
                            class: 'pi-times-circle text-red-500'
                        })
                    });
            } else {
                setCodeError(translatedMessage("register.error.code.length"));
            }
        } else {
            setShowMessage(false);
        }
    }

    const resendCode = () => {
        ApiService.instance.post(`/user/register/notify?username=${username}`)
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage("register.code.resend.success"), life: 7000 });
            })
            .catch(error => {
                let errorMessage = "register.error.generic";
                if (error && error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message
                }
                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 7000 });
            });

    }

    const handleNumberValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
            setCode(event.target.value);
        }
    }

    const checkCaptchaResponse = async (event) => {
        setCaptchaResponse(event.response)
        setCaptchaChecked(true)
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const passwordFooter = <PasswordRequirement />;

    const dialogFooter =
        <div className="flex justify-content-center">
            <Button label={translatedMessage(registerSuccess ? "register.verify" : "generic.ok")} className="p-button-text" autoFocus onClick={() => dialogClose()} />
        </div>;

    return (
        <div className="pages-body login-page flex flex-column before-login-pages pcn-register">
            <div className='pcn-login-logos w-full flex justify-content-center py-1'></div>
            <Toast ref={toast}></Toast>
            <Dialog
                visible={showMessage}
                closable={!registerSuccess}
                onHide={() => setShowMessage(false)}
                position="top"
                footer={dialogFooter}
                showHeader={false}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ minWidth: '360px', width: '30vw' }}
            >
                <div className="flex align-items-center flex-column pt-2 px-3">
                    <i className={"pi ".concat(dialogData.class)} style={{ fontSize: '5rem' }}></i>
                    <h5>{dialogData.title}</h5>
                    {dialogData.subTitle &&
                        <h6>{dialogData.subTitle}</h6>
                    }
                    <p style={{ lineHeight: 1.5, textAlign: 'center' }}>
                        {dialogData.message}
                    </p>

                    {registerSuccess &&
                        <div className="field text-align-center mt-3">
                            <label htmlFor="verificationCode" className='font-bold'>{translatedMessage("register.verificationCode")}</label>
                            <br />
                            <InputText type="text" name="verificationCode" id="verificationCode"
                                value={code}
                                onChange={handleNumberValidation}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                maxLength={4}
                            />
                            {codeError &&
                                <small id="verificationCode-error" className="p-error block">{codeError}</small>
                            }

                            <div className='mt-2'>
                                {translatedMessage("register.code.resend.text")}
                                <span onClick={() => resendCode()} className="ml-1 underline text-primary font-bold cursor-pointer">
                                    {translatedMessage("generic.here")}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </Dialog>

            <div className="align-self-center mt-auto mb-auto pcn-register-form">
                <div className="pages-panel card flex flex-column px-2 md:px-3 py-1 md:py-3">
                    <div className="pages-header px-4 py-2 mb-0" style={{ height: '89px' }}>
                        <img src="assets/layout/images/logo.png" alt="logo" />
                    </div>
                    <h5 className='mb-0 mt-1'>Agenția Națională a Funcționarilor Publici</h5>
                    <h4 className='mt-2 mb-1'>{translatedMessage("login.welcome")}</h4>

                    <form id="login-form" onSubmit={formik.handleSubmit} className="mt-1">
                        <div className="input-panel flex flex-column mb-2 pcn-register-container">
                            <div className="field p-inputgroup mt-0">
                                <span className="p-float-label">
                                    <InputText type="text" name="firstName" id="firstName"
                                        value={formik.values.firstName} onChange={formik.handleChange} autoFocus
                                        className={classNames("pcn-register-inputtext", { 'p-invalid': isFormFieldValid('firstName') })} />
                                    <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>
                                        {translatedMessage("generic.firstName")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('firstName')}

                            <div className="field p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" name="lastName" id="lastName"
                                        value={formik.values.lastName} onChange={formik.handleChange}
                                        className={classNames("pcn-register-inputtext", { 'p-invalid': isFormFieldValid('lastName') })} />
                                    <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>
                                        {translatedMessage("generic.lastName")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('lastName')}

                            <div className="field p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="text" name="username" id="username"
                                        value={formik.values.username} onChange={formik.handleChange}
                                        className={classNames("pcn-register-inputtext", { 'p-invalid': isFormFieldValid('username') })} />
                                    <label htmlFor="username" className={classNames({ 'p-error': isFormFieldValid('username') })}>
                                        {translatedMessage("register.username")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('username')}

                            <div className="field p-inputgroup">
                                <span className="p-float-label">
                                    <Password type="password" name="password" id="new-password" autoComplete="new-password"
                                        value={formik.values.password} onChange={formik.handleChange}
                                        className={classNames("pcn-register-inputtext", { 'p-invalid': isFormFieldValid('password') })}
                                        toggleMask footer={passwordFooter} />
                                    <label htmlFor="new-password" className={classNames({ 'p-error': isFormFieldValid('password') })}>
                                        {translatedMessage("generic.password")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('password')}

                            <div className="field p-inputgroup">
                                <span className="p-float-label">
                                    <InputText type="password" name="confirmPassword" id="confirmPassword"
                                        value={formik.values.confirmPassword} onChange={formik.handleChange}
                                        className={classNames("pcn-register-inputtext", { 'p-invalid': isFormFieldValid('confirmPassword') })} />
                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>
                                        {translatedMessage("generic.confirmPassword")}*
                                    </label>
                                </span>
                            </div>
                            {getFormErrorMessage('confirmPassword')}

                            <div className="field-checkbox text-left">
                                <Checkbox inputId="acceptTc" name="acceptTc" checked={formik.values.acceptTc} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('acceptTc') })} />
                                <label htmlFor="acceptTc" className={classNames({ 'p-error': isFormFieldValid('acceptTc') })}>
                                    {translatedMessage("terms.tc.agree")} *
                                </label>
                            </div>
                            {getFormErrorMessage('acceptTc')}

                            <div className="field-checkbox text-left">
                                <Checkbox inputId="acceptPersonalData" name="acceptPersonalData" checked={formik.values.acceptPersonalData}
                                    onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('acceptPersonalData') })} />
                                <label htmlFor="acceptPersonalData" className={classNames({ 'p-error': isFormFieldValid('acceptPersonalData') })}>
                                    {translatedMessage("terms.gdpr.agree")} *
                                </label>
                            </div>
                            {getFormErrorMessage('acceptPersonalData')}

                            {/* <div className="field-checkbox text-left">
                                <Checkbox inputId="acceptNotification" name="acceptNotification" checked={formik.values.acceptNotification}
                                    onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('acceptNotification') })} />
                                <label htmlFor="acceptNotification" className={classNames({ 'p-error': isFormFieldValid('acceptNotification') })}>
                                    {translatedMessage("terms.notification.agree")}
                                </label>
                            </div> */}

                            <div className="field-checkbox text-left">
                                <Checkbox inputId="acceptMarketing" name="acceptMarketing" checked={formik.values.acceptMarketing}
                                    onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('acceptMarketing') })} />
                                <label htmlFor="acceptMarketing" className={classNames({ 'p-error': isFormFieldValid('acceptMarketing') })}>
                                    {translatedMessage("terms.marketing.agree")}
                                </label>
                            </div>

                            <Captcha siteKey={ApiService.getCaptchaSiteKey()} onResponse={checkCaptchaResponse} className="pcn-captcha mt-2 md:mt-3 mb-1" />
                            {!captchaInit && !captchaChecked &&
                                <small className="p-error text-align-left">{translatedMessage("form.error.captcha.required")}</small>
                            }
                        </div>
                    </form>

                    <Button className="login-button mb-2 md:mb-3 px-3" label={translatedMessage("register.register")} form="login-form" type="submit"></Button>

                    <div className="grid">
                        <div className="col text-align-center">
                            <Link to="/login">{translatedMessage('login.login')}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBeforeLogin />
        </div>
    );
};

export default Register;