import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

// import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';

import { ProgressSpinner } from 'primereact/progressspinner';
import EnumService from '../../service/EnumService';
import DeleteModalComponent from '../DeleteModalComponent';
import StorageFileUpload from '../file/StorageFileUpload';
import StorageFolderFileList from '../file/StorageFolderFileList';
import DataTableUtils from '../../utilities/DataTableUtils';
import EducationEditDialogComponent from './EducationEditDialogComponent';
import { ApplicationService } from '../../service/ApplicationService';
import ExportButtonComponent from '../ExportButtonComponent';

const tableHeader = [
    translatedMessage("profile.education.institutionName"),
    translatedMessage("profile.education.diploma"),
    translatedMessage("generic.startDate"),
    translatedMessage("generic.endDate"),
    translatedMessage("profile.education.isced"),
    translatedMessage("profile.education.specialization"),
    translatedMessage("profile.education.domain")
]

const EducationListComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    // const [globalFilter, setGlobalFilter] = useState(null);
    const [educationList, setEducationList] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [iscedTypes, setIscedTypes] = useState([]);
    const [domains, setDomains] = useState([]);
    const [specs, setSpecs] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);

    const [filters] = useState({
        'institutionName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'diploma': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'iscedId': { value: null, matchMode: FilterMatchMode.IN },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const userService = useMemo(() => new UserService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getIsced = async () => {
            const iscedResponse = await EnumService.getEntityLookupByField("NOM:ISCED")
            setIscedTypes(iscedResponse)
        }

        const getDom = async () => {
            const domResponse = await EnumService.getEntityLookupWithParentIdByField("NOM:EDU_DOM")
            domResponse.push({ label: translatedMessage("education.domain.other"), entityLookupField: "NOM:EDU_DOM", value: -1, relatedId: null})
            setDomains(domResponse)
        }

        const getSpec = async () => {
            const specResponse = await EnumService.getEntityLookupWithParentIdByField("NOM:EDU_SPEC")
            specResponse.push({ label: translatedMessage("education.specialization.other"), entityLookupField: "NOM:EDU_SPEC", value: -1, relatedId: -1})
            setSpecs(specResponse)
        }

        const getEducation = async () => {
            let _educationList = props ? props.education : []
            setEducationList(_educationList)

            if (props?.isForApplication && props?.updateFields && _educationList && _educationList.length > 0) {
                let _selectedRows = _educationList.filter(item => item.applicationEducationId)
                setSelectedRows(_selectedRows)
            }

            setIsLoading(false)
        }

        const getFileUsages = async () => {
            setUsageLookup(await EnumService.getEntityLookupByField("NOM:FILE_USAGE"))
        }

        getIsced()
        getDom()
        getSpec()
        getEducation()
        getFileUsages()
    }, [props]);

    const updateList = async (savedEducation, isNew) => {
        let updatedEducationList = DataTableUtils.updateTableList(educationList, savedEducation, isNew)

        setEducationList(updatedEducationList)
        props.onListUpdate(updatedEducationList)

        if (props?.isForApplication && props?.application && isNew) {
            let _selectedRows = selectedRows ? selectedRows : []
            let _savedEducation = savedEducation
            _savedEducation.applicationId = props?.application.id
            _savedEducation.profileEducationId = savedEducation.id
            _selectedRows.push(_savedEducation)

            onRowSelect(_selectedRows)
        }
    };

    // Table
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {(!props?.isForApplication || (props?.isForApplication && props?.updateFields)) && !props?.readOnly &&
                    <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => handleEdit(rowData)}
                            tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                        <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => handleUpload(rowData)}
                            tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                    </>
                }
                <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                    tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {!props?.isForApplication && !props?.readOnly &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => handleDelete(rowData)}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        // if (!props?.isForApplication || (props?.isForApplication && props?.updateFields)) {
        if (!props?.isForApplication && !props?.readOnly) {
            return (
                <React.Fragment>
                    <div className="my-2">
                        <Button label={translatedMessage("generic.add")} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => handleNew()} />
                    </div>
                </React.Fragment>
            )
        }
    };

    const handleExport = () => {
        let exportData = educationList.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.isced = DataTableUtils.getEntityLookupBodyTemplate(item.iscedId, iscedTypes)
            exportItem.domain = DataTableUtils.getEntityLookupBodyTemplate(item.domainId, domains)
            exportItem.specialization = DataTableUtils.getEntityLookupBodyTemplate(item.specializationId, specs)
            delete exportItem.iscedId
            delete exportItem.domainId
            delete exportItem.specializationId
            delete exportItem.folderId
            delete exportItem.applicationId
            delete exportItem.applicationEducationId
            delete exportItem.profileEducationId

            return item;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        if (props?.hasExportButton) {
            return (
                <ExportButtonComponent
                    getExportData={handleExport}
                    header={tableHeader}
                    fileName={translatedMessage("profile.education")}                    
                />
            );
        }
    }

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    const iscedBodyTemplate = (id) => {
        return DataTableUtils.getEntityLookupBodyTemplate(id, iscedTypes)
    }

    const domainBodyTemplate = (data) => {
        let _spec = data.specializationName
        if(!_spec) {
            const specList = specs.filter(element => element.value === data.specializationId);
            _spec = specList[0] ? specList[0].label : null
        }
        
        let _domain = data.domainName
        if(!_domain) {
            const domainList = domains.filter(element => element.value === data.domainId);
            _domain = domainList[0] ? domainList[0].label : null
        }
        
        return (
            <>
                {_spec &&
                    <>
                        <div style={{ display: 'block', marginBottom: 5 }}>{_spec}</div>
                        {_domain &&
                            <div>{"(" + _domain + ")"}</div>
                        }
                    </>
                }
            </>
        );
    }

    // Files dialog
    const dialogFilesFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    // Actions
    const handleNew = () => {
        setSelectedValue(null)
        setShowAddDialog(true)
    }

    const handleEdit = (data) => {
        setSelectedValue(data)
        setShowAddDialog(true)
    }

    const handleDelete = (data) => {
        setSelectedValue(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        userService.deleteEducation(selectedValue.id)
            .then(() => {
                let _educationList = educationList.filter(item => item.id !== selectedValue.id)
                setEducationList(_educationList)
                setShowDeleteDialog(false);

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    const handleUpload = (data) => {
        setSelectedValue(data)
        setShowUploadDialog(true)
    };

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleViewFiles = (data) => {
        setSelectedValue(data)
        setShowFilesDialog(true);
    }

    const onRowSelect = (data) => {
        if (props?.isForApplication && props?.application && props?.updateFields) {
            applicationService.saveApplicationEducation(props?.application.id, data)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.selection.save.success") })
                    setSelectedRows(data)
                    getEducation()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    }

    const getEducation = async () => {
        await applicationService.getApplicationEducation(props?.application.id)
            .then(educationRespone => setEducationList(educationRespone))
            .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
    }

    const iscedFilterTemplate = (options) => {
        return <MultiSelect
            value={options.value}
            options={iscedTypes}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder={translatedMessage('profile.education.isced')}
            className="p-column-filter"
            maxSelectedLabels={1}
            showSelectAll={false}
            selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
        />;
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="pl-0 pr-0"></Toolbar>

                <DataTable
                    ref={dt}
                    value={educationList}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    responsiveLayout="stack"
                    filterDisplay="row"
                    filters={filters}
                    globalFilterFields={['institutionName', 'diploma', 'iscedId']}
                    selectionMode="checkbox"
                    selection={selectedRows}
                    onSelectionChange={e => props?.updateFields ? onRowSelect(e.value) : false}
                >
                    {props?.isForApplication && props?.updateFields &&
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    }
                    <Column
                        field="institutionName"
                        header={translatedMessage("profile.education.institutionName")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        filter
                        showFilterMenu={false}
                    ></Column>
                    <Column
                        field="diploma"
                        header={translatedMessage("profile.education.diploma")}
                        sortable
                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                        filter
                        showFilterMenu={false}
                    ></Column>
                    <Column
                        field="startDate"
                        header={translatedMessage("generic.interval")}
                        sortable
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        body={dateBodyTemplate}
                    ></Column>
                    <Column
                        field="iscedId"
                        header={translatedMessage("profile.education.isced")}
                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                        body={(event) => iscedBodyTemplate(event.iscedId)}
                        showFilterMenu={false}
                        filter
                        filterElement={iscedFilterTemplate}
                    ></Column>
                    <Column
                        field="domainId"
                        header={translatedMessage("profile.education.specialization") + " (" + translatedMessage("profile.education.domain") + ")"}
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        body={(event) => domainBodyTemplate(event)}
                    ></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <EducationEditDialogComponent
                    education={selectedValue}
                    iscedTypes={iscedTypes}
                    domains={domains}
                    specs={specs}
                    visible={showAddDialog}
                    afterSave={updateList}
                    onClose={() => setShowAddDialog(false)}
                />

                <StorageFileUpload
                    entityId={selectedValue?.id}
                    folderId={selectedValue?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={false}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    addUsageOnUpload={true}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedValue && selectedValue.institutionName}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />

                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={dialogFilesFooter}
                >
                    <StorageFolderFileList
                        folderId={selectedValue?.folderId}
                        folderName={selectedValue?.institutionName}
                        readOnly={!props?.updateFields}
                    />
                </Dialog>
            </>
        );
    }
};

export default EducationListComponent;
