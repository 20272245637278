import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useLocation } from 'react-router-dom';
import { translatedMessage } from './service/LanguageService';
import useNavProps from './hooks/useNavProps';

const AppBreadcrumb = (props) => {
    const location = useLocation();
    const pathname = location.pathname === '/' ? [] : location.pathname.split('/').slice(1);
    // console.log("pathname:", pathname);

    const { getNavPropsValue } = useNavProps();

    // console.log("entityName: ", entityName)    

    let model = [];
    let itemLabel = 'menu'
    let url = '/#'
    pathname.forEach((item, index) => {
        if (isNaN(item)) {
            itemLabel += '.' + item.toString().toLowerCase()
            url += '/' + item.toString().toLowerCase()

            if(pathname.length > index+1 && !isNaN(pathname[index+1])){                
                let entity = getNavPropsValue(item.toString().toLowerCase(), parseInt(pathname[index+1]))
                if (entity && entity[0]) {
                    if(entity[0].hasChildUrl) {
                        url += '/' + pathname[index+1]
                    }
                }
            }
            model.push({ label: translatedMessage(itemLabel), url: url })
        } else {
            let entity = getNavPropsValue(pathname[index - 1].toString().toLowerCase(), parseInt(item))
            if (entity && entity[0]) {
                if(!entity[0].hasChildUrl) {
                    url += '/' + (entity[0].hasUrl ? item.toString().toLowerCase() : '#')
                }
                model.push({ label: entity[0].label, url: url })
            }
        }
    })

    if(model.length !== pathname.length){
        model[model.length - 1].url += '/' + pathname[pathname.length - 1];
    }

    const home = { icon: 'pi pi-home', url: '/#/home' };

    return (
        <div className="layout-breadcrumb-container flex justify-content-between align-items-center shadow-1">
            <BreadCrumb model={model} home={home} className="layout-breadcrumb p-pl-4 p-py-2" />
        </div>
    );
};

export default AppBreadcrumb;
