import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import LanguageListComponent from '../../components/profile/LanguageListComponent';

const MyLanguage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [languageList, setLanguageList] = useState(null);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);


    useEffect(() => {
        setIsLoading(true)
        const getLanguageList = async () => {
            await userService.getLanguageSkill()
                .then(languageRespone => {
                    setLanguageList(languageRespone)
                    setIsLoading(false)
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                    setIsLoading(false)
                })
        }

        getLanguageList()
    }, [userService]);


    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-left'>
                                <h5>{translatedMessage("profile.language")}</h5>
                            </div>

                            <LanguageListComponent 
                                language={languageList} 
                                onListUpdate={(list) => setLanguageList(list)} 
                                hasExportButton={true}
                                updateFields={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyLanguage;
