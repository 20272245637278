import GeneralUtils from "../utilities/GeneralUtils";
import ApiService from "./ApiService";

export class RoleService {
    async getRoles() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/roles', { startRow: 0, pageSize: 150 })
                .then((res) => {
                    let _roles = []
                    res.data.forEach(item => {
                        let _item = GeneralUtils.emptyInNullForObjectFields(item)

                        _roles.push(_item)
                    })

                    resolve(_roles)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveRole(role) {
        return ApiService.getAuthenticatedInstance().post('/role',
            { id: role.id, name: role.name, description: role.description });
    }

    async deleteRole(role) {
        return ApiService.getAuthenticatedInstance().delete('/role/' + role.id)
            .then((res) => res.data);
    }

    async getUserRoles(user) {
        return ApiService.getAuthenticatedInstance().get('/user/' + user.id + '/roles')
            .then((res) => res.data);
    }

    async saveUserRoles(user, roles) {
        return ApiService.getAuthenticatedInstance().post('/user/' + user.id + '/roles',
            roles);
    }

    async getPermissions() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/permissions')
                .then((res) => resolve(res.data))
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getRolePermissions(role) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/role/' + role.id + '/permissions')
                .then((res) =>  resolve(res.data))
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveRolePermissions(role, permissions) {
        return ApiService.getAuthenticatedInstance().post('/role/' + role.id + '/permissions',
            permissions);
    }
}