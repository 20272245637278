import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import WorkExperienceListComponent from '../../components/profile/WorkExperienceListComponent';

const MyWorkExperience = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [experienceList, setExperienceList] = useState(null);

    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getExperience = async () => {
            await userService.getWorkExperience()
                .then(experienceRespone => {
                    setExperienceList(experienceRespone)
                    setIsLoading(false)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        getExperience()
    }, [userService]);
 


    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <div className="grid h-full">
                <Toast ref={toast}></Toast>
                <div className="col-12">
                    <div className="card h-full">
                        <div className='w-full text-align-left'>
                            <h5>{translatedMessage("profile.workExperience")}</h5>
                        </div>

                        <WorkExperienceListComponent 
                            experience={experienceList} 
                            onListUpdate={(list) => setExperienceList(list)} 
                            hasExportButton={true} 
                            updateFields={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

export default MyWorkExperience;
