import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { CompetitionService } from '../../../service/CompetitionService';
import { Checkbox } from 'primereact/checkbox';

const emptyCompetitionTest = {
    id: null,
    competition: {
        id: null,
        name: ''
    },
    test: {
        id: null,
        name: ''
    },
    passingScore: '',
    advancedTest: false
};

const CompetitionTestEditDialog = (props) => {
    const [competitionTest, setCompetitionTest] = useState(emptyCompetitionTest);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [filteredFields, setFilteredFields] = useState(null);

    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        let _competitionTest = { ...props.competitionTest ? props.competitionTest : emptyCompetitionTest };
        _competitionTest.testCenter = props.testCenter;

        setCompetitionTest(_competitionTest);
        setIsNew(props.competitionTest?.id ? false : true);
    }, [props]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveCompetitionTest = (data) => {
        data.competition = props.competition;
        competitionService.saveCompetitionTest(data)
            .then((savedTest) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedTest, isNew);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
                let _competitionTest = { ...emptyCompetitionTest };
                _competitionTest.testCenter = props.testCenter;
                setCompetitionTest(_competitionTest);
                formik.resetForm();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: competitionTest,
        validate: (data) => {
            let errors = {};

            if (!data.test || !data.test.id ) {
                errors.test = translatedMessage('form.error.test.required');
            }
            if (!data.passingScore ) {
                errors.passingScore = translatedMessage('form.error.passingScore.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveCompetitionTest(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="competitionTest-form" type="submit" />
        </>
    );

    const searchField = (event) => {
        setTimeout(() => {
            let _filteredFields;
            if (!event.query.trim().length) {
                _filteredFields = [...props.availableTests];
            } else {
                _filteredFields = props.availableTests.filter((item) => {
                    return item.name.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 ||
                        item.template.toLowerCase().indexOf(event.query.toLowerCase()) !== -1;
                });
            }

            setFilteredFields(_filteredFields);
        }, 100);
    };

    const itemTemplate = (item) => {
        return (
            <span>{item.name}</span>
        );
    };    

    const handleChangeTest = (event) => {
        formik.handleChange(event)
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                visible={dialogVisible} 
                style={{ width: '450px' }} 
                header={translatedMessage('competitionTest.details')} 
                modal 
                className="p-fluid" 
                footer={testCenterDialogFooter} 
                onHide={hideDialog}
            >
                <form id="competitionTest-form" onSubmit={formik.handleSubmit}>

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                        <AutoComplete
                                id="test"
                                name="test"
                                value={formik.values && formik.values.test}
                                suggestions={filteredFields}
                                completeMethod={searchField}
                                field="name"
                                dropdown
                                forceSelection
                                itemTemplate={itemTemplate}
                                onChange={handleChangeTest}
                                aria-label="Tests"
                                dropdownAriaLabel="Select field"
                                scrollHeight="300px"
                                showEmptyMessage={true}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                autoFocus
                                className={classNames({ 'p-invalid': isFormFieldValid('test') })}
                            />
                            
                            <label htmlFor="test" className={classNames({ 'p-error': isFormFieldValid('test') })}>
                                {translatedMessage('test.name')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('test')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputNumber id="passingScore" name="passingScore" value={formik.values.passingScore} onValueChange={formik.handleChange} 
                                className={classNames({ 'p-invalid': isFormFieldValid('passingScore') })} />
                            <label htmlFor="passingScore" className={classNames({ 'p-error': isFormFieldValid('passingScore') })}>
                                {translatedMessage('test.passingScore')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('passingScore')}

                    <div className="field-checkbox text-left mt-3">
                        <Checkbox inputId="advancedTest" name="advancedTest" checked={formik.values.advancedTest}
                            onChange={formik.handleChange} />
                        <label htmlFor="advancedTest">{translatedMessage("test.advancedTest")}</label>
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default CompetitionTestEditDialog;
