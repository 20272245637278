import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import EnumService from '../../service/EnumService';

let emptyCommittee = {
    id: null,
    name: '',
    description: '',
    type: '',
    startDate: '',
    endDate: '',
    competitionId: null,
    competitionName: '',
    folderId: null
};

const CommitteeEditDialogComponent = (props) => {

    const [committee, setCommittee] = useState(emptyCommittee);
    const [isNew, setIsNew] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [committeeTypeOptions, setCommitteeTypeOptions] = useState([]);

    const toast = useRef(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    useEffect(() => {
        const getCommitteeTypes = async () => {
            setCommitteeTypeOptions(await EnumService.getEnumByNameAndParam('EvaluationCommitteeType', props?.competition.id));
        };

        getCommitteeTypes();
        setCommittee(props.value ? props.value : emptyCommittee);
        setIsNew(props.value ? false : true);
    }, [props]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: committee,
        validate: (data) => {
            let errors = {};

            if (!data.name) {
                errors.name = translatedMessage('form.error.name.required');
            }
            if (!data.type) {
                errors.type = translatedMessage('form.error.type.required');
            }            
            if (!data.startDate) {
                errors.startDate = translatedMessage('form.error.startDate.required');
            }
            if (!data.endDate) {
                errors.endDate = translatedMessage('form.error.endDate.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveCommittee(data);
            hideCommitteeDialog();
            setCommittee(emptyCommittee);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const hideCommitteeDialog = () => {
        formik.resetForm();
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter();
        }

        setDialogVisible(false)
    };

    const saveCommittee = (data) => {        
        data.competitionId = props.competition.id        
        evaluationService.saveCommittee(data)
            .then(savedCommittee => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedCommittee, isNew);
                }

                toast?.current?.show({ severity: 'success', summary: translatedMessage("generic.save.success") })                
            })
            .catch(error => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const committeeDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideCommitteeDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="committee-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogVisible}
                style={{ width: '450px' }}
                header={translatedMessage('committee.details')}
                modal
                className="p-fluid"
                footer={committeeDialogFooter} onHide={hideCommitteeDialog}
            >
                <form id="committee-form" onSubmit={formik.handleSubmit}>

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                {translatedMessage("generic.nameRo")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('name')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputTextarea type="text" id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('description') })}
                                rows={5} cols={30} autoResize/>
                            <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') })}>
                                {translatedMessage("generic.description")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('description')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="type" name="type" value={formik.values.type} onChange={formik.handleChange} options={committeeTypeOptions}></Dropdown>
                            <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') })}>
                                {translatedMessage("generic.type")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('type')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="competition.id" name="competition.id" value={props.competition?.name} disabled />
                            <label htmlFor="competition.id">
                                {translatedMessage("competition.competition")}*
                            </label>
                        </span>
                    </div>

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Calendar id="startDate" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                            <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                {translatedMessage("generic.startDate")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('startDate')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Calendar id="endDate" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} dateFormat="dd-mm-yy"
                                className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                            <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                {translatedMessage("generic.endDate")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('endDate')}
                </form>
            </Dialog>
        </>
    );
};

export default CommitteeEditDialogComponent;
