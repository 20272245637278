import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationService } from '../../../service/ApplicationService';
import { translatedMessage } from '../../../service/LanguageService';
import { useNavigate } from 'react-router-dom';
import GeneralUtils from '../../../utilities/GeneralUtils';
import DataTableUtils from '../../../utilities/DataTableUtils';
import CompetitionDataTableUtils from '../../../utilities/CompetitionDataTableUtils';
import EnumService from '../../../service/EnumService';
import ApplicationDataTableUtils from '../../../utilities/ApplicationDataTableUtils';

const MyApplicationList = () => {
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [applicationStatuses, setApplicationStatuses] = useState([]);
    const [competitionStatuses, setCompetitionStatuses] = useState([]);

    const [filters] = useState({
        'competition.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.IN },
        'competition.status': { value: null, matchMode: FilterMatchMode.IN },
        'competition.institution.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true);

        const getApplicationStatuses = async () => {
            let _statuses = await EnumService.getEnumByName('ApplicationStatus')
            setApplicationStatuses(_statuses.filter(item => item.value !== 'INDECISIVE'));
        };

        const getCompetitionStatuses = async () => {
            await EnumService.getEnumByName('CompetitionStatus').then((_statuses) => setCompetitionStatuses(_statuses));
        };

        const getApplications = async () => {
            await applicationService.getMyApplicationsList()
                .then((applications) => {
                    setApplications(applications)
                    getApplicationStatuses()
                    getCompetitionStatuses()
                    setIsLoading(false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        getApplications()
    }, [applicationService])

    const isApplicationSubmitted = (_application) => {
        if (_application.status === 'REGISTERED' || _application.status === 'APPLIED') {
            return false
        } else {
            return true
        }
    }

    const actionBodyTemplate = (_application) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/application/${_application.id}`)}
                    tooltip={translatedMessage(isApplicationSubmitted(_application) ? 'application.view.detailAndResult' : "application.fillIn")}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <FontAwesomeIcon icon="far fa-file-lines" />
                </Button>
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/application/${_application.id}/clarification`)}
                    tooltip={translatedMessage('application.clarification.request')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!isApplicationSubmitted(_application)}
                >
                    <FontAwesomeIcon icon="fas fa-comments" />
                </Button>
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/application/${_application.id}/contestation`)}
                    tooltip={translatedMessage('application.contestation')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!isApplicationSubmitted(_application)}
                >
                    <FontAwesomeIcon icon="far fa-hand-peace" />
                </Button>
                {_application.viewTestList && (
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => navigate(`/application/${_application.id}/tests`)}
                        tooltip={translatedMessage('test.tests')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={!isApplicationSubmitted(_application)}
                    >
                        <FontAwesomeIcon icon="fas fa-list-check" />
                    </Button>
                )}
            </div>
        );
    };

    const submitEndDateOnBodyTemplate = (rowData) => {
        return GeneralUtils.formatDate(rowData.competition.submitEndDate);
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-0">{translatedMessage('competition.competitions')}</h3>

                            <DataTable
                                ref={dt}
                                value={applications}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                responsiveLayout="scroll"
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['competition.name', 'status', 'competition.status']}
                            >
                                <Column
                                    field="competition.name"
                                    header={translatedMessage('competition.competition')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    header={translatedMessage('generic.status')}
                                    body={(e) => ApplicationDataTableUtils.statusBodyTemplate(e.status)}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '10rem' }}
                                    filter
                                    filterElement={(e) => ApplicationDataTableUtils.statusFilterTemplate(e, applicationStatuses)}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="competition.institution.name"
                                    header={translatedMessage('competition.institution')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '8rem' }}
                                    body={(e) => e.competition.institution.name}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="competition.status"
                                    header={translatedMessage('competition.status')}
                                    body={(e) => CompetitionDataTableUtils.statusBodyTemplate(e.competition.status)}
                                    sortable
                                    headerStyle={{ width: '15%', minWidth: '10rem' }}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.statusFilterTemplate(e, competitionStatuses)}
                                    showFilterMenu={false}
                                />
                                <Column field="submitEndDate"
                                    header={translatedMessage('competition.submitEndDate')}
                                    sortable
                                    body={submitEndDateOnBodyTemplate}
                                    headerStyle={{ width: '8%', minWidth: '8rem' }}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyApplicationList;
