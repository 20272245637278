import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import useAuth from '../../hooks/useAuth';
import ClarificationComponent from './ClarificationComponent';
import ClarificationDialog from './ClarificationDialog';
import SecurityService from '../../service/SecurityService';
import { Message } from 'primereact/message';

const Clarifications = (props) => {
    const [clarificationRequest, setClarificationRequest] = useState(null);
    const [clarificationResponse, setClarificationResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isResponse, setIsResponse] = useState(false);
    const [viewEditDialog, setViewEditDialog] = useState(false);
    const [canCreateClarificationRequest, setCanCreateClarificationRequest] = useState(false);
    const [canCreateClarificationResponse, setCanCreateClarificationResponse] = useState(false);

    const toast = useRef(null);
    const { auth } = useAuth();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true);

        const checkCanCreate = async () => {
            setCanCreateClarificationRequest(await SecurityService.check('CAN_CREATE_CLARIFICATION_REQUEST', props.application.id));
        };

        const checkCanCreateResponse = async () => {
            setCanCreateClarificationResponse(await SecurityService.check('CAN_CREATE_CLARIFICATION_RESPONSE', props.application.id));
        };

        const getClarifications = async () => {
            await applicationService.getClarifications(props.application.id)
                .then((_clarifications) => {
                    setClarificationRequest(_clarifications.find((clarification) => 'REQUEST' === clarification.type));
                    setClarificationResponse(_clarifications.find((clarification) => 'RESPONSE' === clarification.type));
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        checkCanCreate();
        checkCanCreateResponse();
        getClarifications();
    }, [props.application.id, applicationService]);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showEditClarificationRequestDialog = () => {
        setViewEditDialog(true);
        setIsResponse(false)
    };

    const showEditClarificationResponseDialog = () => {
        setViewEditDialog(true);
        setIsResponse(true)
    };

    const updateClarifications = (savedClarification) => {
        if ('REQUEST' === savedClarification.type) {
            setClarificationRequest(savedClarification);
        }
        if ('RESPONSE' === savedClarification.type) {
            setClarificationResponse(savedClarification);
        }
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
                <Toast ref={toast} />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className='grid'>
                    <div className='col-12'>
                        <h5 className='mb-1'>{translatedMessage('application.clarification.request')}</h5>
                        {!clarificationRequest && (
                            <div className='flex align-items-center'>
                                <span className='mr-2'>{translatedMessage('application.clarification.none')}</span>
                                <Button
                                    label={translatedMessage('application.clarification.request.create')}
                                    icon="pi pi-plus"
                                    className="p-button-text pcn-button-slim"
                                    onClick={showEditClarificationRequestDialog}
                                    visible={canCreateClarificationRequest && !props.readOnly}
                                />
                            </div>
                        )}
                        {clarificationRequest && (
                            (clarificationRequest.sent || hasPermission("EVALUATION_VIEW"))
                                ? <ClarificationComponent clarification={clarificationRequest} type="REQUEST" readOnly={props.readOnly} />
                                : <span className='mr-2'>{translatedMessage('application.clarification.none')}</span>
                        )}
                    </div>

                    {clarificationRequest && clarificationRequest.sent && (
                        <div className='col-12'>
                            <h5 className='mb-1'>{translatedMessage('application.clarification.response')}</h5>

                            {clarificationRequest.application.user.id === auth.user.id &&
                                <Message severity="warn" text={translatedMessage("application.clarification.response.info") + " " + translatedMessage('application.clarification.response.docs.info')} />
                            }
                            
                            {!clarificationResponse && (
                                <div className='flex align-items-center'>
                                    <Button
                                        label={translatedMessage('application.clarification.response.create')}
                                        icon="pi pi-plus"
                                        className="p-button-text pcn-button-slim"
                                        onClick={showEditClarificationResponseDialog}
                                        visible={!props.readOnly && clarificationRequest.sent && clarificationRequest.application.user.id === auth.user.id && canCreateClarificationResponse}
                                    />
                                    {!canCreateClarificationResponse &&
                                        <div className='mt-1'>{translatedMessage(
                                            clarificationRequest.application.user.id === auth.user.id
                                                ? "application.clarification.response.create.expired"
                                                : "application.clarification.response.notSent"
                                        )}</div>
                                    }
                                </div>
                            )}
                            {clarificationResponse &&
                                (clarificationResponse.sent || clarificationResponse.application.user.id === auth.user.id) && (
                                    <ClarificationComponent clarification={clarificationResponse} type="RESPONSE" readOnly={props.readOnly} />
                                )}
                        </div>
                    )}
                </div>

                <ClarificationDialog
                    clarification={isResponse ? clarificationResponse : clarificationRequest}
                    applicationId={props.application.id}
                    visible={viewEditDialog}
                    onHide={() => setViewEditDialog(false)}
                    afterSave={updateClarifications}
                    type={isResponse ? "RESPONSE" : "REQUEST"}
                />
            </>
        );
    }
};

export default Clarifications;
