import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TestService } from '../../service/TestService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';

const TestDemoList = () => {
    const [tests, setTests] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);    


    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        const getTests = async () => {
            await testService.getDemoTestList()
                .then((tests) => {
                    setTests(tests);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getTests();
    }, [testService]);


    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    const actionBodyTemplate = (_test) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    id={_test.id}
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => handleTakeTest(_test)}
                    tooltip={translatedMessage('test.start')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                >
                    <FontAwesomeIcon icon="fas fa-play" />
                </Button>
            </div>
        );
    };

    const handleTakeTest = async (_test) => {       
        const btn = document.getElementById(_test.id);
        btn.setAttribute("disabled", "disabled");

        await testService.runDemoTest(_test.id)
            .then((_url) => {
                if (_url && _url.length > 0) {
                    window.open(_url);
                } else {
                    toast.current.show({ severity: 'error', summary: translatedMessage('generic.error.get.data'), life: 5000 });
                }
                btn.removeAttribute("disabled");
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                btn.removeAttribute("disabled");
            })
    };


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{translatedMessage('test.tests')}</h3>

                            <DataTable
                                ref={dt}
                                value={tests}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="stack"
                            >
                                <Column field="name" header={translatedMessage('generic.nameRo')} sortable headerStyle={{ width: '30%', minWidth: '10rem' }} />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestDemoList;
