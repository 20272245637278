import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toolbar } from 'primereact/toolbar';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import EnumService from '../../../service/EnumService';
import DataTableUtils from '../../../utilities/DataTableUtils';
import useAuth from '../../../hooks/useAuth';
import CompetitionToolbar from '../CompetitionToolbar';
import ExportButtonComponent from '../../../components/ExportButtonComponent';

const tableHeader = [
    translatedMessage("competition.testCenter.name"),
    translatedMessage("generic.region"),
    translatedMessage("generic.county"),
    translatedMessage("generic.city"),
    translatedMessage("generic.address"),
    translatedMessage("generic.phone"),
    translatedMessage("generic.email"),
    translatedMessage("competition.testCenterRoom.name"),
    translatedMessage("testCenter.capacity"),
    translatedMessage("generic.status"),
    translatedMessage("competition.competition")
]

const CompetitionTestCenterRoomList = () => {
    const [competition, setCompetition] = useState([]);
    const [competitionTestCenterRooms, setCompetitionTestCenterRooms] = useState([]);

    const [regionOptions, setRegionOptions] = useState([]);
    const [countyOptions, setCountyOptions] = useState([]);
    const [competitionTestCenterRoomStatusOptions, setCompetitionTestCenterRoomStatusOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [filters] = useState({
        'testCenterRoom.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'testCenterRoom.testCenter.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'testCenterRoom.testCenter.region.id': { value: null, matchMode: FilterMatchMode.IN },
        'testCenterRoom.testCenter.county.id': { value: null, matchMode: FilterMatchMode.IN },
        'testCenterRoom.testCenter.city': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'testCenterRoom.capacity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const competitionService = useMemo(() => new CompetitionService(), []);

    const { competitionIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);
        const getOptions = async () => {
            setRegionOptions(await EnumService.getEntityLookupByField('NOM:REGION'));
            setCountyOptions(await EnumService.getEntityLookupByField('NOM:COUNTY'));
            setCompetitionTestCenterRoomStatusOptions(await EnumService.getEnumByName('CompetitionTestCenterRoomStatus'));
        };

        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then(async (comp) => {
                    setCompetition(comp);
                    await getTestRooms()
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getTestRooms = async () => {
            await competitionService
                .getTestCenterRoomList(competitionIdParam)
                .then((ctc) => {
                    setCompetitionTestCenterRooms(ctc);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getOptions();
        getCompetition();
    }, [competitionIdParam, competitionService]);

    // const updateComponentTestCenterStatus = async (competitionTestCenter, successCallback) => {
    //     await competitionService
    //         .saveCompetitionTestCenterRoom(competitionTestCenter)
    //         .then((ctc) => {
    //             toast.current.show({ severity: 'success', summary: translatedMessage('competition.testCenter.statusUpdated'), life: 3000 });
    //             successCallback();
    //         })
    //         .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    // };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const handleExport = () => {
        let exportData = competitionTestCenterRooms.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.testCenter = item.testCenterRoom.testCenter.name
            exportItem.region = item.testCenterRoom.testCenter.region.label
            exportItem.county = item.testCenterRoom.testCenter.county.label
            exportItem.city = item.testCenterRoom.testCenter.city
            exportItem.address = item.testCenterRoom.testCenter.address
            exportItem.phone = item.testCenterRoom.testCenter.phone
            exportItem.email = item.testCenterRoom.testCenter.email
            exportItem.roomName = item.testCenterRoom.name
            exportItem.capacity = item.testCenterRoom.capacity
            exportItem.roomStatus = translatedMessage('CompetitionTestCenterRoomStatus.' + item.status)
            exportItem.competitionName = item.competition.name
            delete exportItem.competition
            delete exportItem.testCenterRoom
            delete exportItem.status

            return exportItem;
        })

        return exportData
    }

    const updateTestCenterRooms = async () => {
        await competitionService
            .updateCompetitionTestCenterRooms(competitionIdParam)
            .then((ctcr) => {
                setCompetitionTestCenterRooms(ctcr);
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.testCenter.roomListUpdated'), life: 3000 });
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_EDIT') && <Button label={translatedMessage('competition.testCenter.updateRooms')} icon="pi pi-sync" className="p-button-primary mr-2" onClick={updateTestCenterRooms} />}</div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = (
        <>
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                fileName={translatedMessage("competition.testCenters")}
            />
        </>
    );

    const updateStatus = async (competitionTestCenter) => {
        let oldStatus = competitionTestCenter.status;
        let newStatus;
        if (oldStatus === 'ACTIVE') {
            newStatus = 'INACTIVE';
        } else {
            newStatus = 'ACTIVE';
        }

        await competitionService
            .saveCompetitionTestCenterRoom({ ...competitionTestCenter, status: newStatus })
            .then((ctc) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.testCenter.statusUpdated'), life: 3000 });
                competitionTestCenter.status = newStatus;
                let _competitionTestCenters = [...competitionTestCenterRooms];
                setCompetitionTestCenterRooms(_competitionTestCenters);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const statusTemplate = (rowData) => {
        let isActive = 'ACTIVE' === rowData.status;
        return (
            <>
                {hasPermission('COMPETITION_EDIT') && (
                    <ToggleButton
                        id={`status_${rowData.id}`}
                        name={`status_${rowData.id}`}
                        checked={isActive}
                        onChange={() => {
                            updateStatus(rowData);
                        }}
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        aria-label="Competition test center status"
                        className="pcn-status-toogle"
                        onLabel={translatedMessage('CompetitionTestCenterRoomStatus.ACTIVE')}
                        offLabel={translatedMessage('CompetitionTestCenterRoomStatus.INACTIVE')}
                        tooltip={translatedMessage(isActive ? 'competition.testCenter.inactivate' : 'competition.testCenter.activate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
                {!hasPermission('COMPETITION_EDIT') && <span className={`px-1 status-${rowData.status.toString().toLowerCase()}`}>{translatedMessage('CompetitionTestCenterRoomStatus.' + rowData.status)}</span>}
            </>
        );
    };

    const regionRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} options={regionOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={translatedMessage('testCenter.selectRegion')} className="p-column-filter" maxSelectedLabels={3} showClear />
        );
    };

    const countyRowFilterTemplate = (options) => {
        return (
            <MultiSelect value={options.value} options={countyOptions} onChange={(e) => options.filterApplyCallback(e.value)} placeholder={translatedMessage('testCenter.selectCounty')} className="p-column-filter" maxSelectedLabels={3} showClear />
        );
    };

    const statusItemTemplate = (option) => {
        return <span className={`px-1 status-${option.value.toString().toLowerCase()}`}>{option.label}</span>;
    };

    const statusRowFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={competitionTestCenterRoomStatusOptions} onChange={(e) => options.filterApplyCallback(e.value)} className="p-column-filter" showClear itemTemplate={statusItemTemplate} />;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <CompetitionToolbar competition={competition} code="TEST_CENTER" />

                            <div>
                                <h5 className="mb-1 font-weight-normal">{translatedMessage('competition.testCenters')}</h5>
                            </div>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={competitionTestCenterRooms}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={[
                                    'testCenterRoom.name',
                                    'testCenterRoom.testCenter.name',
                                    'testCenterRoom.testCenter.region.id',
                                    'testCenterRoom.testCenter.county.id',
                                    'testCenterRoom.testCenter.city',
                                    'testCenterRoom.capacity',
                                    'status'
                                ]}
                            >
                                <Column
                                    field="testCenterRoom.testCenter.region.label"
                                    filterField="testCenterRoom.testCenter.region.id"
                                    header={translatedMessage('generic.region')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    showFilterMenu={false}
                                    filter
                                    filterElement={regionRowFilterTemplate}
                                />
                                <Column
                                    field="testCenterRoom.testCenter.county.label"
                                    filterField="testCenterRoom.testCenter.county.id"
                                    header={translatedMessage('generic.county')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    showFilterMenu={false}
                                    filterMenuStyle={{ width: '14rem' }}
                                    style={{ minWidth: '14rem' }}
                                    filter
                                    filterElement={countyRowFilterTemplate}
                                />
                                <Column
                                    field="testCenterRoom.testCenter.city"
                                    header={translatedMessage('generic.city')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="testCenterRoom.testCenter.name"
                                    header={translatedMessage('competition.testCenter.name')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false} />
                                <Column
                                    field="testCenterRoom.name"
                                    header={translatedMessage('competition.testCenterRoom.name')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false} />
                                <Column
                                    field="testCenterRoom.capacity"
                                    header={translatedMessage('testCenter.capacity')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false} />
                                <Column
                                    field="status"
                                    filterField="status"
                                    header={translatedMessage('generic.status')}
                                    sortable
                                    body={statusTemplate}
                                    showFilterMenu={false}
                                    filterMenuStyle={{ width: '14rem' }}
                                    filter
                                    filterElement={statusRowFilterTemplate}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default CompetitionTestCenterRoomList;
