import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import ValidationService from '../../service/ValidationServices';
import { UserService } from '../../service/UserService';
import useAuth from '../../hooks/useAuth';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import EnumService from '../../service/EnumService';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';

const MyProfile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const [profile, setProfile] = useState();
    const [idCardTypes, setIdCardTypes] = useState([])
    const [counties, setCounties] = useState([])
    const [localities, setLocalities] = useState([])
    const [filteredentityLookups, setFilteredentityLookups] = useState([]);
    const { auth, setAuth } = useAuth();
    const [afterSave, setAfterSave] = useState(false);
    const toast = useRef(null);

    const userService = new UserService();

    useEffect(() => {
        const getIdType = async () => {
            const response = await EnumService.getEnumByName("IdCardType");
            setIdCardTypes(response)
        }

        getIdType()
    }, []);

    useEffect(() => {
        setIsLoading(true)

        if (afterSave) {
            toast.current.show({ severity: 'success', summary: translatedMessage("profile.save.success"), life: 3000 });
            setAfterSave(false)
        }

        const getCounty = async () => {
            const _counties = await EnumService.getEntityLookupByField("NOM:COUNTY")
            setCounties(_counties)
            return _counties
        }

        const computeProfile = async () => {
            let _profile = auth.user
            if (!_profile.idCardType) {
                _profile.idCardType = 'CI'
            }

            //compute county
            let _counties = await getCounty()
            let selectedCounty = _counties.filter(item => item.value === _profile.countyId)
            _profile.county = selectedCounty[0] ? selectedCounty[0] : null

            //compute locality
            if (_profile.countyId) {
                const _localities = await EnumService.getEntityLookupWithParentByParentId(_profile.countyId)
                setLocalities(_localities)
                let selectedLocality = _localities.filter(item => item.value === _profile.localityId)
                _profile.locality = selectedLocality[0] ? selectedLocality[0] : null
            }

            setProfile(_profile)
            setIsLoading(false)
        }

        computeProfile()
    }, [afterSave, auth]);

    const formik = useFormik({
        initialValues: profile ? profile : {
            firstName: '',
            lastName: '',
            pin: '',
            idCardType: 'CI',
            idCardSeries: '123',
            idCardNumber: '',
            phone: '',
            address: '',
            countyId: null,
            county: null,
            localityId: null,
            locality: null,
            publicServant: false
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.firstName || !data.firstName.trim()) {
                errors.firstName = translatedMessage("form.error.firstName.required");
            }

            if (!data.lastName || !data.lastName.trim()) {
                errors.lastName = translatedMessage("form.error.lastName.required");
            }

            if (!data.pin) {
                errors.pin = translatedMessage("form.error.pin.required");
            } else {
                let validationResponse = ValidationService.validateCNP(data.pin)
                if (!validationResponse.success) {
                    errors.pin = translatedMessage(validationResponse.message);
                }
            }

            if (!data.idCardSeries || !data.idCardSeries.trim()) {
                errors.idCardSeries = translatedMessage("form.error.idCardSeries.required");
            }

            if (!data.idCardNumber || !data.idCardNumber.trim()) {
                errors.idCardNumber = translatedMessage("form.error.idCardNumber.required");
            } else if (data.idCardType !== 'CIP' && data.idCardNumber.length > 6) {
                errors.idCardNumber = translatedMessage("form.error.idCardNumber.lenght");
            }


            if (!data.phone || !data.phone.trim()) {
                errors.phone = translatedMessage("form.error.phone.required");
            } else if (data.phone.trim().length !== 10) {
                errors.phone = translatedMessage("form.error.phone.length");
            }

            if (!data.county || !data.county.value) {
                errors.countyId = translatedMessage("form.error.county.required");
            }

            if (!data.locality || !data.locality.value) {
                errors.localityId = translatedMessage("form.error.locality.required");
            }

            if (!data.address) {
                errors.address = translatedMessage("form.error.address.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            data.countyId = data.county && data.county.value ? data.county.value : data.countyId
            data.localityId = data.locality && data.locality.value ? data.locality.value : data.localityId

            userService.saveProfile(data)
                .then(savedUser => {
                    let selectedCounty = counties.filter(item => item.value === savedUser.countyId)
                    savedUser.county = selectedCounty[0] ? selectedCounty[0] : null

                    if (savedUser.countyId) {
                        let selectedLocality = localities.filter(item => item.value === savedUser.localityId)
                        savedUser.locality = selectedLocality[0] ? selectedLocality[0] : null
                    }

                    setProfile(savedUser);
                    // toast.current.show({ severity: 'success', summary: translatedMessage("profile.save.success"), life: 3000 });                   
                    setAfterSave(true)
                    setAuth(prev => {
                        return {
                            ...prev,
                            user: savedUser
                        }
                    })
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleNameValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (/^[a-zA-Z!@#$%^&*)(+=._-]+$/.test(charCode)) {
            formik.handleChange(event);
        }
    }

    const handleTextValidation = (event) => {
        let charCode = event.nativeEvent.data;
        if (/^[a-zA-Z]+$/.test(charCode)) {
            event.target.value = event.target.value.toUpperCase();
            formik.handleChange(event);
        }
    }

    // const handleNumberValidation = (event) => {
    //     let charCode = event.nativeEvent.data;
    //     if (charCode === null || /^[0-9\b]+$/.test(charCode)) {
    //         formik.handleChange(event);
    //     }
    // }

    const searchListItems = (event, list) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredentityLookups(_filteredItems);
    }

    const handleListChange = async (data, field) => {
        // console.log("handleListChange")
        formik.setFieldValue(field, data.value)

        if (data.originalEvent.type === 'click') {
            if (field === 'county') {
                const response = await EnumService.getEntityLookupWithParentByParentId(data.value.value)
                setLocalities(response)
                formik.setFieldValue('locality', null)
                if (response.length > 0) {
                    // setDisableSpec(false)
                }
            }
        }
    }

    if (isLoading) {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className='w-full flex align-items-center'>
                    <ProgressSpinner />
                </div>
            </>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full" >
                    <div className="col-12">
                        <div className="card h-full">
                            <div className='w-full text-align-center mb-5'>
                                <h5>{translatedMessage("profile.myProfile")}</h5>
                            </div>

                            <div className="flex flex-column align-items-center">
                                <div className="col-12 lg:col-9 xl:col-6 mb-3">
                                    <div className="flex flex-column align-items-center">
                                        <Message severity="warn" text={translatedMessage("profile.info")} />
                                    </div>
                                </div>
                                <div className="col-12 lg:col-9 xl:col-6">
                                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                                        <div className="input-panel flex flex-column mb-5">
                                            <div className="field p-inputgroup mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="firstName" id="firstName"
                                                        value={formik.values.firstName} onChange={handleNameValidation} autoFocus
                                                        className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                                    <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>
                                                        {translatedMessage("generic.firstName")}*
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('firstName')}

                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="lastName" id="lastName"
                                                        value={formik.values.lastName} onChange={handleNameValidation}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                                    <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>
                                                        {translatedMessage("generic.lastName")}*
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('lastName')}


                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="uid" id="uid" value={profile.username} disabled />
                                                    <label htmlFor="username">{translatedMessage("register.username")}</label>
                                                </span>
                                            </div>

                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="uid" id="uid" value={profile.uid} disabled />
                                                    <label htmlFor="uid">{translatedMessage("profile.uid")}</label>
                                                </span>
                                            </div>

                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="pin" id="pin"
                                                        value={formik.values.pin} onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('pin') })}
                                                        keyfilter="int" maxLength={13} />
                                                    <label htmlFor="pin" className={classNames({ 'p-error': isFormFieldValid('pin') })}>
                                                        {translatedMessage("profile.pin")}*
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('pin')}

                                            <div className='mt-3 mb-3'>{translatedMessage("profile.idCard")}</div>
                                            <div className='grid'>
                                                <div className="col-12 md:col-4">
                                                    <div className="field p-inputgroup mb-2">
                                                        <span className="p-float-label">
                                                            <Dropdown id="idCardType" name="idCardType" value={formik.values.idCardType}
                                                                options={idCardTypes} onChange={(e) => formik.setFieldValue("idCardType", e.value)} />

                                                            <label htmlFor="idCardType">{translatedMessage("profile.idCardType")}*</label>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 md:col-4">
                                                    <div className="field p-inputgroup mb-2">
                                                        <span className="p-float-label">
                                                            <InputText type="text" name="idCardSeries" id="idCardSeries"
                                                                value={formik.values.idCardSeries} onChange={handleTextValidation}
                                                                className={classNames({ 'p-invalid': isFormFieldValid('idCardSeries') })}
                                                                maxLength={2} />
                                                            <label htmlFor="idCardSeries" className={classNames({ 'p-error': isFormFieldValid('idCardSeries') })}>
                                                                {translatedMessage("profile.idCardSeries")}*
                                                            </label>
                                                        </span>
                                                    </div>
                                                    {getFormErrorMessage('idCardSeries')}
                                                </div>

                                                <div className="col-12 md:col-4">
                                                    <div className="field p-inputgroup mb-2">
                                                        <span className="p-float-label">
                                                            <InputText type="text" name="idCardNumber" id="idCardNumber"
                                                                value={formik.values.idCardNumber} onChange={formik.handleChange}
                                                                className={classNames({ 'p-invalid': isFormFieldValid('idCardNumber') })}
                                                                keyfilter="int" maxLength={formik.values.idCardType === 'CIP' ? 9 : 6} />
                                                            <label htmlFor="idCardNumber" className={classNames({ 'p-error': isFormFieldValid('idCardNumber') })}>
                                                                {translatedMessage("profile.idCardNumber")}*
                                                            </label>
                                                        </span>
                                                    </div>
                                                    {getFormErrorMessage('idCardNumber')}
                                                </div>
                                            </div>

                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="phone" id="phone"
                                                        value={formik.values.phone} onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('phone') })}
                                                        keyfilter="int" maxLength={10}
                                                        tooltip={translatedMessage("profile.phone.format")} tooltipOptions={{ position: 'top' }} />
                                                    <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>
                                                        {translatedMessage("profile.phone")}*
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('phone')}

                                            <div className='grid'>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-inputgroup mt-3 mb-2">
                                                        <span className="p-float-label">
                                                            <AutoComplete
                                                                dropdown
                                                                id="countyId"
                                                                name="countyId"
                                                                value={formik.values.county}
                                                                suggestions={filteredentityLookups}
                                                                completeMethod={(e) => searchListItems(e, counties)}
                                                                virtualScrollerOptions={{ itemSize: 38 }}
                                                                field="label"
                                                                onChange={(d) => handleListChange(d, "county")}
                                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                                className={classNames({ 'p-invalid': isFormFieldValid('countyId') })}
                                                            />
                                                            <label htmlFor="countyId" className={classNames({ 'p-error': isFormFieldValid('countyId') })}>
                                                                {translatedMessage("generic.county")}*
                                                            </label>
                                                        </span>
                                                    </div>
                                                    {getFormErrorMessage('countyId')}
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-inputgroup mt-3 mb-2">
                                                        <span className="p-float-label">
                                                            <AutoComplete
                                                                dropdown
                                                                id="localityId"
                                                                name="localityId"
                                                                value={formik.values.locality}
                                                                suggestions={filteredentityLookups}
                                                                completeMethod={(e) => searchListItems(e, localities)}
                                                                virtualScrollerOptions={{ itemSize: 38 }}
                                                                field="label"
                                                                onChange={(d) => handleListChange(d, "locality")}
                                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                                className={classNames({ 'p-invalid': isFormFieldValid('localityId') })}
                                                            />
                                                            <label htmlFor="localityId" className={classNames({ 'p-error': isFormFieldValid('localityId') })}>
                                                                {translatedMessage("generic.locality")}*
                                                            </label>
                                                        </span>
                                                    </div>
                                                    {getFormErrorMessage('localityId')}
                                                </div>
                                            </div>

                                            <div className="field p-inputgroup mt-3 mb-2">
                                                <span className="p-float-label">
                                                    <InputText type="text" name="address" id="address"
                                                        value={formik.values.address} onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('address') })} />
                                                    <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') })}>
                                                        {translatedMessage("generic.address")}*
                                                    </label>
                                                </span>
                                            </div>
                                            {getFormErrorMessage('address')}

                                            <div className="field-checkbox mt-3 mb-2">
                                                <Checkbox inputId="publicServant" name="publicServant" checked={formik.values.publicServant} onChange={formik.handleChange} />
                                                <label htmlFor="publicServant">{translatedMessage('profile.publicServant')}</label>
                                            </div>
                                            {profile?.publicServant &&
                                                <Message severity="warn" text={translatedMessage("profile.publicServant.file.info")} className='justify-content-start' />
                                            }
                                        </div>
                                    </form>

                                </div>
                                <div className='w-full text-align-center mb-5'>
                                    <Button className="login-button px-3" label={translatedMessage("generic.save")} form="profile-form" type="submit"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
};

export default MyProfile;
