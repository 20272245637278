import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import EnumService from '../../../service/EnumService';
import { translatedMessage } from '../../../service/LanguageService';
import DataTableUtils from '../../../utilities/DataTableUtils';
import StorageFileUpload from '../../../components/file/StorageFileUpload';
import StorageFolderFileList from '../../../components/file/StorageFolderFileList';
import TestDataTableUtils from '../../../utilities/TestDataTableUtils';
import { TestService } from '../../../service/TestService';
import { Toolbar } from 'primereact/toolbar';
import useAuth from '../../../hooks/useAuth';
import TestVerificationScaleEditDialog from './TestVerificationScaleEditDialog';
import DeleteModalComponent from '../../../components/DeleteModalComponent';

const TestVerificationScaleList = () => {
    const [verificationScales, setVerificationScales] = useState([]);
    const [selectedVS, setSelectedVS] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [availableTests, setAvailableTests] = useState([]);
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [filters] = useState({
        'testName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.IN },
    });

    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        setIsLoading(true);

        const getStatuses = async () => {
            const response = await EnumService.getEnumByName("TestVerificationScaleStatus");
            setStatuses(response)
        }

        const getVerificationScales = async () => {
            await testService.getTestVerificationScaleList()
                .then(async (_response) => {
                    setVerificationScales(_response.data)
                    setCanEdit(_response.canEdit)
                    await getStatuses()
                    if(_response.canEdit) {
                        await getTests(_response.data)
                    }
                    setIsLoading(false)
                })
                .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getVerificationScales();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testService]);

    const getTests = async (_verificationScales) => {
        await testService.getTestList()
            .then((_tests) => {
                let _availableTests = _tests.filter(item =>
                    _verificationScales.findIndex(vs => vs.testId === item.id) === -1
                );                    
                setAvailableTests(_availableTests);
            })
            .catch((error) => toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const updateList = async (savedData, isNew) => {
        let updatedList = DataTableUtils.updateTableList(verificationScales, savedData, isNew)

        setVerificationScales(updatedList)
        await getTests(updatedList)
    };

    const showUploadFiles = (_verificationScale) => {
        setSelectedVS(_verificationScale);
        setUploadDialogVisible(true);
    };

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleEdit = (_verificationScale) => {
        setSelectedVS(_verificationScale);
        setEditDialogVisible(true);
    }

    const handleViewFiles = (_verificationScale) => {
        setSelectedVS(_verificationScale);
        setShowFilesDialog(true);
    }

    const handleDelete = (data) => {
        setSelectedVS(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        testService.deleteTestVerificationScale(selectedVS.id)
            .then(async () => {
                let _updatedList = verificationScales.filter(item => item.id !== selectedVS.id)
                setVerificationScales(_updatedList)
                await getTests(_updatedList)
                setShowDeleteDialog(false);

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    // Files dialog
    const dialogFilesFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {canEdit &&
                    <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => handleEdit(rowData)}
                            tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        />

                        <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => showUploadFiles(rowData)}
                            tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                            disabled={!rowData.folderId} />
                    </>
                }

                <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                    tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />

                {canEdit &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => handleDelete(rowData)}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('TEST_EDIT') &&
                        <Button label={translatedMessage('generic.add')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => handleEdit(null)} />
                    }
                </div>
            </React.Fragment>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-4'>{translatedMessage('testVerificationScales')}</h3>

                            <Toolbar left={leftToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={verificationScales}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                responsiveLayout="scroll"
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['name', 'type', 'status']}
                            >
                                <Column
                                    field="testName"
                                    header={translatedMessage('test.test')}
                                    sortable
                                    headerStyle={{ width: '30%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    header={translatedMessage('generic.status')}
                                    sortable
                                    body={(e) => TestDataTableUtils.verificationScaleStatusBodyTemplate(e.status)}
                                    headerStyle={{ width: '15%', minWidth: '12rem' }}
                                    filter
                                    filterElement={(e) => TestDataTableUtils.verificationScaleStatusFilterTemplate(e, statuses)}
                                    showFilterMenu={false}
                                    hidden={!canEdit}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <StorageFileUpload
                    entityId={selectedVS?.id}
                    folderId={selectedVS?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={true}
                    accept={["pdf", "image"]}
                    maxFileSize={26214400}
                    visible={uploadDialogVisible}
                    visibleSetter={setUploadDialogVisible}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                />

                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={dialogFilesFooter}
                >
                    <StorageFolderFileList
                        folderId={selectedVS?.folderId}
                        folderName={translatedMessage("testVerificationScale")}
                        readOnly={!canEdit}
                    />
                </Dialog>

                <TestVerificationScaleEditDialog
                    value={selectedVS}
                    visible={editDialogVisible}
                    afterSave={updateList}
                    statuses={statuses}
                    closeDialog={() => { setEditDialogVisible(false) }}
                    availableTests={availableTests}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedVS && selectedVS?.testName}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />
            </>
        );
    }
};

export default TestVerificationScaleList;
