import ApiService from './ApiService';
import GeneralUtils from '../utilities/GeneralUtils';

export class EvaluationService {
    async getCommitteeListByCompetitionId(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/competition/${competitionId}/committees`)
                .then((response) => {
                    response.data.map((item) => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCommittee(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}`)
                .then((response) => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveCommittee(committee) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post('/evaluation/evaluationCommittee', committee)
                .then((committeeResponse) => {
                    committeeResponse.data.startDate = new Date(Date.parse(committeeResponse.data.startDate));
                    committeeResponse.data.endDate = committeeResponse.data.endDate ? new Date(Date.parse(committeeResponse.data.endDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(committeeResponse.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async deleteCommittee(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/evaluation/evaluationCommittee/${committeeId}`)
                .then(resolve)
                .catch((error) => {
                    let errorMessage = 'generic.delete.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCommitteeMemberList(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/members`)
                .then((response) => {
                    response.data.map((item) => {
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async saveCommitteeMember(committeeMember) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post('/evaluation/evaluationCommittee/member', committeeMember)
                .then((committeeResponse) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(committeeResponse.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async deleteCommitteeMember(committeeMemberId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .delete(`/evaluation/evaluationCommittee/member/${committeeMemberId}`)
                .then(resolve)
                .catch((error) => {
                    let errorMessage = 'generic.delete.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async searchPossibleMembers(committeeId, searchText) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/searchForMembers/${searchText}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getPossibleCommitteeMembers(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/possibleMembers`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getMyCommitteesList(onlyActive = false) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/myCommittees/${onlyActive}`)
                .then((response) => {
                    response.data.map((item) => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = item.endDate ? new Date(Date.parse(item.endDate)) : '';
                        return item;
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getEvaluationList(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/evaluations`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCommitteeApplicationResult(committeeId, applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/results/${applicationId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationResultByType(applicationId, resultType) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/application/${applicationId}/result/${resultType}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getApplicationEvaluationList(committeeId, applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/evaluations/${applicationId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getEvaluationById(evaluationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/evaluation/${evaluationId}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async getApplicationFieldEvaluationsList(evaluationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/${evaluationId}/fieldEvaluations`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async updateApplicationFieldEvaluation(applicationFieldEvaluationId, status, comment = null) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put('/applicationFieldEvaluation', { id: applicationFieldEvaluationId, status: status, comment: comment })
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async updateEvaluation(evaluationId, status, comment) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put('/evaluation', { id: evaluationId, status: status, comment: comment })
                .then((response) => {
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data));
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async sendCommitteeClarificationRequests(committeeId){
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .put(`/evaluation/evaluationCommittee/${committeeId}/sendClarifications`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.save.error';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async getEvaluationsByCompetition(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/evaluations`, criteria)
                .then((response) => {
                    resolve({_applications: response.data.applications, totalCount: response.data.totalCount, maxEvaluationComments: response.data.maxEvaluationComments })
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }
    
    async getEvaluationsByApplication(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/evaluations`)
                .then((response) => {
                        resolve(response.data)                    
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async getCommitteeByApplication(applicationId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/application/${applicationId}/user-committee`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

    async getCommitteeRoles(committeeId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/evaluation/evaluationCommittee/${committeeId}/roles`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }    

}
