import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { EvaluationService } from '../../service/EvaluationService';
import useNavProps from '../../hooks/useNavProps';
import CommitteePageComponent from '../../components/committee/CommitteePageComponent';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import CompetitionUtils from '../../utilities/CompetitionUtils';

const emptyCommittee = {
    id: null,
    name: '',
    type: '',
    startDate: '',
    endDate: '',
    competitionId: null,
    competitionName: '',
    competitionType: '',
    folderId: null
};

const EvaluationCommitteePage = () => {
    const { setNavPropsValue } = useNavProps();

    const [committee, setCommittee] = useState(emptyCommittee);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");

    const toast = useRef(null);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    let { committeeIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true);

        const getCommittee = async () => {
            await evaluationService.getCommittee(committeeIdParam)
                .then((_committee) => {
                    setCommittee(_committee);
                    setIsLoading(false);

                    setNavPropsValue('competition-evaluation',  _committee.competitionId, _committee.competitionName)
                    setNavPropsValue('committees', _committee.id, _committee.name);
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false);
                    // toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })                    
                });
        };

        getCommittee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [committeeIdParam]);


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />
                <CommitteePageComponent committee={committee} canEdit={CompetitionUtils.isJobCompetition(committee.competitionType)}/>
            </>
        );
    }
};

export default EvaluationCommitteePage;
