import ApiService from "./ApiService";

const SecurityService = {
    async check(action, entityId) {
        let response = await ApiService.getAuthenticatedInstance().get(`/check/${action}/${entityId}`);
        //console.log("check action ", action, entityId, response.data)

        if(response && response.data && response.data === true) {
            return true;
        }
        return false;
    }
};

export default SecurityService;
