import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { translatedMessage } from '../../../service/LanguageService';
import { CompetitionService } from '../../../service/CompetitionService';
import GeneralUtils from '../../../utilities/GeneralUtils';

const ApplicationTestSessionPresenceDialog = (props) => {
    const [isPresent, setIsPresent] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [extendedTime, setExtendedTime] = useState(false);
    const [updatePresenceButtonDisabled, setUpdatePresenceButtonDisabled] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const presenceOptions = [
        { value: false, code: 'absent' },
        { value: true, code: 'present' }
    ];
    const extendedTimeOptions = [
        { value: false, code: 'no' },
        { value: true, code: 'yes' }
    ];
    const canceledOptions = [
        { value: false, code: 'notCanceled' },
        { value: true, code: 'canceled' }
    ];


    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    useEffect(() => {
        setIsPresent(props.applicationTestSession?.isPresent);
        setIsCanceled(props.applicationTestSession?.isCanceled);
        setExtendedTime(props.applicationTestSession?.extendedTime);
        setUpdatePresenceButtonDisabled(false);
    }, [props.applicationTestSession]);

    const updatePresence = async () => {
        setUpdatePresenceButtonDisabled(true);
        await competitionService
            .updateApplicationTestSessionPresence(props.applicationTestSession.id, isPresent, isCanceled, extendedTime)
            .then((_applicationTestSession) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') + '' });
                if (typeof props.onSave === 'function') {
                    props.onSave(_applicationTestSession);
                }
                hideDialog();
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const hideDialog = () => {
        if (typeof props.onCancel === 'function') {
            props.onCancel();
        }
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" onClick={updatePresence} disabled={updatePresenceButtonDisabled}/>
        </>
    );

    const onPresenceChange = (e) => {
        setIsPresent(e.value);
    };

    const onExtendedTimeChange = (e) => {
        setExtendedTime(e.value);
    };

    const onCanceledChange = (e) => {
        setIsCanceled(e.value);
    };

    const selectedPresenceTemplate = (option, props) => {
        if (option) {
            return (
                <>
                    <div className={`inline-block status status-applicationTestSession-${option.code.toString().toLowerCase()}`}>{translatedMessage('testSession.' + option.code)}</div>
                </>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const presenceOptionTemplate = (option) => {
        return (
            <>
                <div className={`inline-block status status-applicationTestSession-${option.code.toString().toLowerCase()}`}>{translatedMessage('testSession.' + option.code)}</div>
            </>
        );
    };

    const selectedExtendedTimeTemplate = (option, props) => {
        if (option) {
            return (
                <>
                    <div className={`inline-block pill option-${option.code.toString().toLowerCase()}`}>{translatedMessage('generic.' + option.code)}</div>
                </>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const extendedTimeOptionTemplate = (option) => {
        return (
            <>
                <div className={`inline-block pill option-${option.code.toString().toLowerCase()}`}>{translatedMessage('generic.' + option.code)}</div>
            </>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogVisible}
                style={{ width: '450px' }}
                header={
                    <>
                        {props.applicationTestSession?.application?.fullName} - {translatedMessage('testSession.editPresence')}
                    </>
                }
                modal
                className="pcn-dialog p-fluid"
                footer={testCenterDialogFooter}
                onHide={hideDialog}
            >
                <div className="mb-2">
                    <span>{translatedMessage('competition.testCenter.name') + ': '}</span>
                    {props.applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name + ', '}
                    {props.applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.name}
                </div>
                <div className="mb-2">
                <span>{translatedMessage('competition.testCenter.address') + ': '}</span>
                    {props.applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.address + ', '}
                    {props.applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city + ', '}
                    {props.applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.county?.label}
                </div>
                <div className="mb-2">
                    <span>{translatedMessage('competition.testSession.date') + ': '}</span>
                    {GeneralUtils.formatDate(props.applicationTestSession?.testSession?.startDate) +
                        ', ' +
                        GeneralUtils.formatTime(props.applicationTestSession?.testSession?.startDate) +
                        ' - ' +
                        GeneralUtils.formatTime(props.applicationTestSession?.testSession?.endDate)}
                </div>
                <div className="mb-2">
                    <span>{translatedMessage('testSession.candidate') + ': '}</span>                    
                    {props.applicationTestSession?.application?.fullName}
                </div>
                <div className="mb-2">
                    <span>{translatedMessage('application.profileAccessibility') + ': '}</span>                    
                    {props.applicationTestSession?.application?.profileAccessibility ? translatedMessage("generic.yes") : translatedMessage("generic.no")}
                </div>
                <div className="mb-5">
                    <span>{translatedMessage('application.accessibilityNeed') + ': '}</span>                    
                    {props.applicationTestSession?.application?.accessibilityNeed ? translatedMessage("generic.yes") : translatedMessage("generic.no")}
                </div>
                <span className="p-float-label">
                    <Dropdown id="presence" name="presence" value={isPresent} options={presenceOptions} onChange={onPresenceChange} optionLabel="code" valueTemplate={selectedPresenceTemplate} itemTemplate={presenceOptionTemplate} />
                    <label htmlFor="presence" className='font-bold'>{translatedMessage('testSession.presence')} *</label>
                </span>
                <span className="p-float-label mt-4">
                    <Dropdown id="extendedTime" name="extendedTime" value={extendedTime} options={extendedTimeOptions} onChange={onExtendedTimeChange} optionLabel="code" valueTemplate={selectedExtendedTimeTemplate} itemTemplate={extendedTimeOptionTemplate} 
                        disabled={props.applicationTestSession.isPresent || props.applicationTestSession.hasAllTestUrlsGenerated} />
                    <label htmlFor="extendedTime" className='font-bold'>{translatedMessage('testSession.extendedTime')} *</label>
                </span>
                <span className="p-float-label mt-4">
                    <Dropdown id="canceled" name="canceled" value={isCanceled} options={canceledOptions} onChange={onCanceledChange} optionLabel="code" valueTemplate={selectedPresenceTemplate} itemTemplate={presenceOptionTemplate} />
                    <label htmlFor="canceled" className='font-bold'>{translatedMessage('testSession.status')} *</label>
                </span>
            </Dialog>
        </>
    );
};

export default ApplicationTestSessionPresenceDialog;
