import React, { useRef, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import useInitUserData from '../hooks/useInitUserData';

import { translatedMessage } from '../service/LanguageService';
import ApiService from '../service/ApiService';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import FooterBeforeLogin from '../components/FooterBeforeLogin';

const dialogInitialData = {
    title: translatedMessage("login.2fa.success.dialog.title"),
    subTitle: translatedMessage("login.2fa.success.dialog.subTitle"),
    message: translatedMessage("login.2fa.success.dialog.message"),
    class: 'pi-check-circle text-green-500'
}

const Login = () => {
    const navigate = useNavigate();
    const toast = useRef(null);
    const initUserData = useInitUserData();
    let { username } = useParams();

    const [verifiedUsername, setVerifiedUsername] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [passwordCheckSuccess, setPasswordCheckSuccess] = useState(false);
    const [codeChecked, setCodeChecked] = useState(false);
    const [accountLocked, setAccountLocked] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [dialogData, setDialogData] = useState(dialogInitialData);

    const formik = useFormik({
        initialValues: {
            username: username ? username : '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.username) {
                errors.username = translatedMessage("form.error.username.required");
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9._-]+\.[A-Z]{2,4}$/i.test(data.username)) {
                errors.username = translatedMessage("form.error.username.invalid");
            }

            if (!data.password) {
                errors.password = translatedMessage("form.error.password.required");;
            }

            return errors;
        },
        onSubmit: (data) => {
            ApiService.loginInstance(data.username, data.password).post('/token')
                .then(async loginResponse => {
                    setVerifiedUsername(data.username)
                    if (loginResponse && loginResponse.status && loginResponse.data) {
                        if (loginResponse.status === 202 && loginResponse.data === '2FA') {
                            setDialogData(dialogInitialData);
                            setCode('');
                            setShowMessage(true);
                            setPasswordCheckSuccess(true);
                            setCodeChecked(false);
                            setAccountLocked(false);
                        } else if (loginResponse.status === 200) {
                            userLogIn(loginResponse);
                        }
                    }
                })
                .catch(error => {
                    setPasswordCheckSuccess(false);
                    let errorMessage = "login.error.generic";
                    if (error && error.response) {
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message
                        } else if (error.response.status && error.response.status === 401) {
                            errorMessage = "login.error.badCredentials"
                        }
                    }
                    toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage) });
                })
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const userLogIn = async (tokenResponse) => {
        await ApiService.persistToken(tokenResponse.data);
        await initUserData()
            .then(() => {
                let path = ApiService.getRedirectUrl();
                if (path) {
                    navigate(path);
                    localStorage.removeItem('_pcn_redirect');
                } else {
                    navigate('/home');
                }
            })
            .catch(error => {
                setPasswordCheckSuccess(false);
                let errorMessage = "generic.error.get.data";
                if (error && error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message
                }
                toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage) });
            });
    }

    const checkCode = () => {
        if (passwordCheckSuccess) {
            if (code && code.trim() && code.trim().length === 8) {
                setCodeError(null);
                ApiService.instance.post(`/token/2fa/${verifiedUsername}/${code}`)
                    .then(async tokenResponse => {
                        userLogIn(tokenResponse)
                    })
                    .catch(error => {
                        let errorMessage = "login.error.generic";
                        if (error && error.response && error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message
                        }
                        if (errorMessage.includes("locked")) {
                            setAccountLocked(true);
                        }
                        setDialogData({
                            title: translatedMessage("login.2fa.verificationCode.title"),
                            message: translatedMessage(errorMessage),
                            class: 'pi-times-circle text-red-500'
                        })
                        setCodeChecked(true)
                    });
            } else {
                setCodeError(translatedMessage("login.2fa.error.code.length"));
            }
        }
    }

    const dialogFooter =
        <div className="flex justify-content-center">
            {passwordCheckSuccess && !accountLocked &&
                <Button label={translatedMessage("login.2fa.verify")} className="p-button-text" autoFocus onClick={() => checkCode()} />
            }
            {codeChecked &&
                <Button label={translatedMessage("generic.close")} className="p-button-text" autoFocus onClick={() => setShowMessage(false)} />
            }
        </div>;

    return (
        <>
            <Toast ref={toast}></Toast>
            <Dialog
                visible={showMessage}
                closable={!passwordCheckSuccess}
                onHide={() => setShowMessage(false)}
                position="top"
                footer={dialogFooter}
                showHeader={false}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ minWidth: '360px', width: '30vw' }}
            >
                <div className="flex align-items-center flex-column pt-2 px-3">
                    <i className={"pi ".concat(dialogData.class)} style={{ fontSize: '5rem' }}></i>
                    <h5 className='text-center'>{dialogData.title}</h5>
                    {dialogData.subTitle &&
                        <h6>{dialogData.subTitle}</h6>
                    }
                    <p style={{ lineHeight: 1.5, textAlign: 'center' }}>
                        {dialogData.message}
                    </p>

                    {passwordCheckSuccess &&
                        <div className="field text-align-center mt-3">
                            <label htmlFor="verificationCode" className='font-bold'>{translatedMessage("login.2fa.verificationCode")}</label>
                            <br />
                            <InputText type="text" name="verificationCode" id="verificationCode"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                                maxLength={8}
                            />
                            {codeError &&
                                <small id="verificationCode-error" className="p-error block">{codeError}</small>
                            }
                        </div>
                    }
                </div>
            </Dialog>

            <div className="pages-body login-page flex flex-column before-login-pages">
                <div className='pcn-login-logos w-full flex justify-content-center py-1'></div>
                <div className="align-self-center mt-auto mb-auto">
                    <div className="pages-panel card flex flex-column">
                        <div className="pages-header px-4 py-2 mb-0" style={{ height: '89px' }}>
                            <img src="assets/layout/images/logo.png" alt="logo" />
                        </div>

                        <h5 className='mb-0 mt-1'>Agenția Națională a Funcționarilor Publici</h5>
                        <h4 className='mb-0'>{translatedMessage("login.welcome")}</h4>

                        <form id="login-form" onSubmit={formik.handleSubmit}>
                            <div className="input-panel flex flex-column mb-5">
                                <div className="field p-inputgroup mt-5 mb-2">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-envelope"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="text" name="username" id="username" autoComplete="username"
                                            value={formik.values.username} onChange={formik.handleChange} autoFocus
                                            className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                                        <label htmlFor="username">{translatedMessage("generic.username")}*</label>
                                    </span>
                                </div>
                                {getFormErrorMessage('username')}

                                <div className="p-inputgroup mt-5 mb-2">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText type="password" name="password" id="current-password" autoComplete="current-password"
                                            value={formik.values.password} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                                        <label htmlFor="current-password">{translatedMessage("generic.password")}*</label>
                                    </span>
                                </div>
                                {getFormErrorMessage('password')}
                            </div>
                        </form>

                        <Button className="login-button mb-3 px-3" label={translatedMessage("login.login")} form="login-form" type="submit"></Button>

                        <div className="grid">
                            {ApiService.getIsRegisterEnabled() &&
                                <div className="col text-align-left">
                                    <Link to="/register">{translatedMessage('register.register')}</Link>
                                </div>
                            }
                            <div className={"col ".concat(ApiService.getIsRegisterEnabled() ? "text-align-right" : "")}>
                                <Link to="/reset-password-request">{translatedMessage('login.forgotPassword')}</Link>
                            </div>
                        </div>

                        {!ApiService.getIsProduction() && <div style={{ color: "red", fontWeight: "bold", marginTop: "10px" }}>MEDIU DE TEST</div>}
                    </div>
                </div>
                <FooterBeforeLogin />
            </div>
        </>
    );
};

export default Login;
