import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { translatedMessage } from '../../service/LanguageService';
import FieldDisplay from './FieldDisplay';

const ViewFieldDialog = (props) => {

    const [dialogVisible, setDialogVisible] = useState(false);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const formDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );

    return (
        <>
            <Dialog
                visible={dialogVisible}
                header={translatedMessage('form.field.details')}
                modal
                onHide={() => props.closeDialog()}
                className="pcn-dialog p-fluid"
                footer={formDialogFooter}
            >
                <div className='w-full grid pcn-data-view-panel mx-0'>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.code')}</div>
                        <div className='filed-value'>{props?.field?.code}</div>
                    </div>                    
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.type')}</div>
                        <div className='filed-value'>{translatedMessage('FieldType.' + props?.field?.type)}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('form.field.preview')}</div>
                        <FieldDisplay field={props?.field} className="pcn-form-field-view"/>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ViewFieldDialog;
