import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';

import { ProgressSpinner } from 'primereact/progressspinner';
import EnumService from '../../service/EnumService';
import DeleteModalComponent from '../DeleteModalComponent';
import StorageFileUpload from '../file/StorageFileUpload';
import StorageFolderFileList from '../file/StorageFolderFileList';
import DataTableUtils from '../../utilities/DataTableUtils';
import LanguageEditDialogComponent from './LanguageEditDialogComponent';
import { ApplicationService } from '../../service/ApplicationService';
import ExportButtonComponent from '../ExportButtonComponent';

const tableHeader = [
    translatedMessage("profile.language.language"),
    translatedMessage("profile.language.write"),
    translatedMessage("profile.language.speak"),
    translatedMessage("profile.language.understand"),
]

const LanguageListComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [languageList, setLanguageList] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    const [languageLookup, setLanguageLookup] = useState([]);
    const [languageLevels, setLanguageLevels] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [allLanguagesUsed, setAllLanguagesUsed] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const userService = useMemo(() => new UserService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);

    const getLabelForValue = (value, array) => {
        const item = array.filter(x => x.value === value)
        return item[0] ? item[0].label : value
    }

    useEffect(() => {
        setIsLoading(true)

        const getFileUsages = async () => {
            setUsageLookup(await EnumService.getEntityLookupByField("NOM:FILE_USAGE"))
        }

        const getLanguageList = async () => {
            let _languageList = props ? props.language : []

            const languageLookupResponse = await EnumService.getEntityLookupByField("NOM:LANGUAGE")
            let _languageLookup = []
            let allUsed = true
            languageLookupResponse.forEach(item => {
                let _item = item
                if (_languageList?.filter(l => l.languageId === item.value).length > 0) {
                    _item.used = true
                } else {
                    _item.used = false
                    allUsed = false
                }

                _languageLookup.push(item)
            })
            setLanguageLookup(_languageLookup)
            setAllLanguagesUsed(allUsed)

            const levelResponse = await EnumService.getEnumByName("LanguageLevel")
            setLanguageLevels(levelResponse)

            _languageList?.map(item => {
                item.languageLabel = getLabelForValue(item.languageId, languageLookupResponse)
                item.writeLabel = getLabelForValue(item.write, levelResponse)
                item.speakLabel = getLabelForValue(item.speak, levelResponse)
                item.understandLabel = getLabelForValue(item.understand, levelResponse)

                return item
            })

            setLanguageList(_languageList)

            if (props?.isForApplication && props?.updateFields && _languageList && _languageList.length > 0) {
                let _selectedRows = _languageList.filter(item => item.applicationLanguageId)
                setSelectedRows(_selectedRows)
            }

            setIsLoading(false)
        }

        getLanguageList()
        getFileUsages()
    }, [props]);

    const updateList = async (savedLanguage, isNew) => {
        let updatedLanguageList = DataTableUtils.updateTableList(languageList, savedLanguage, isNew)

        setLanguageList(updatedLanguageList)
        computeLanguageOptions(updatedLanguageList)
        props.onListUpdate(updatedLanguageList)

        if (props?.isForApplication && props?.application && isNew) {
            let _selectedRows = selectedRows ? selectedRows : []
            let _savedLanguage = savedLanguage
            _savedLanguage.applicationId = props?.application.id
            _savedLanguage.profileLanguageId = savedLanguage.id
            _selectedRows.push(_savedLanguage)

            onRowSelect(_selectedRows)
        }
    };

    const computeLanguageOptions = (_languageList) => {
        let _languageLookup = []
        let allUsed = true
        languageLookup.forEach(item => {
            let _item = item
            if (_languageList.filter(l => l.languageId === item.value).length > 0) {
                _item.used = true
            } else {
                _item.used = false
                allUsed = false
            }

            _languageLookup.push(item)
        })
        setLanguageLookup(_languageLookup)
        setAllLanguagesUsed(allUsed)
    }

    // Table
    const header = (
        <div className="flex justify-content-end">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage("generic.search")} />
            </span>
        </div>
    );

    const doOnFilter = (data) => { }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {(!props?.isForApplication || (props?.isForApplication && props?.updateFields)) && !props?.readOnly &&
                    <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => handleEdit(rowData)}
                            tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                        <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => handleUpload(rowData)}
                            tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                    </>
                }
                <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                    tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {!props?.isForApplication && !props?.readOnly &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => handleDelete(rowData)}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        // if (!props?.isForApplication || (props?.isForApplication && props?.updateFields)) {
        if (!props?.isForApplication && !props?.readOnly) {
            return (
                <React.Fragment>
                    <div className="my-2">
                        <Button label={translatedMessage("generic.add")} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => handleNew()}
                            disabled={allLanguagesUsed} />
                    </div>
                </React.Fragment>
            )
        }
    };

    const handleExport = () => {
        let exportData = languageList.map(item => ({ ...item }))
        exportData.map(item => {
            let exportItem = item
            delete exportItem.languageId
            delete exportItem.write
            delete exportItem.speak
            delete exportItem.understand
            delete exportItem.folderId
            delete exportItem.applicationLanguageId
            delete exportItem.applicationId
            delete exportItem.profileLanguageId

            return item
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        if (props?.hasExportButton) {
            return (
                <ExportButtonComponent
                    getExportData={handleExport}
                    header={tableHeader}
                    fileName={translatedMessage("profile.language")}                    
                />
            );
        }
    };

    // Files dialog
    const dialogFilesFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    // Actions
    const handleNew = () => {
        setSelectedValue(null)
        setShowAddDialog(true)
    }

    const handleEdit = (data) => {
        setSelectedValue(data)
        setShowAddDialog(true)
    }

    const handleDelete = (data) => {
        setSelectedValue(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        userService.deleteLanguageSkill(selectedValue.id)
            .then(() => {
                let _languageList = languageList.filter(item => item.id !== selectedValue.id)
                setLanguageList(_languageList)
                computeLanguageOptions(_languageList)
                setShowDeleteDialog(false);

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    const handleUpload = (data) => {
        setSelectedValue(data)
        setShowUploadDialog(true)
    };

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleViewFiles = (data) => {
        setSelectedValue(data)
        setShowFilesDialog(true);
    }

    const onRowSelect = (data) => {
        if (props?.isForApplication && props?.application && props?.updateFields) {
            applicationService.saveApplicationLanguage(props?.application.id, data)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.selection.save.success") })
                    setSelectedRows(data)
                    getLanguageList()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    }

    const getLanguageList = async () => {
        await applicationService.getApplicationLanguage(props?.application.id)
            .then(languageRespone => {
                languageRespone.map(item => {
                    item.languageLabel = getLabelForValue(item.languageId, languageLookup)
                    item.writeLabel = getLabelForValue(item.write, languageLevels)
                    item.speakLabel = getLabelForValue(item.speak, languageLevels)
                    item.understandLabel = getLabelForValue(item.understand, languageLevels)

                    return item
                })

                setLanguageList(languageRespone)
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            })
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="pl-0 pr-0  pb-0"></Toolbar>

                <DataTable
                    ref={dt}
                    value={languageList}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    globalFilter={globalFilter}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    header={header}
                    responsiveLayout="stack"
                    onFilter={doOnFilter}
                    selectionMode="checkbox"
                    selection={selectedRows}
                    onSelectionChange={e => props?.updateFields ? onRowSelect(e.value) : false}
                >
                    {props?.isForApplication && props?.updateFields &&
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    }
                    <Column
                        field="languageLabel"
                        header={translatedMessage("profile.language.language")}
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        sortable
                    ></Column>
                    <Column
                        field="writeLabel"
                        header={translatedMessage("profile.language.write")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                    ></Column>
                    <Column
                        field="speakLabel"
                        header={translatedMessage("profile.language.speak")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                    ></Column>
                    <Column
                        field="understandLabel"
                        header={translatedMessage("profile.language.understand")}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                    ></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <LanguageEditDialogComponent
                    language={selectedValue}
                    languages={languageLookup}
                    languageLevels={languageLevels}
                    visible={showAddDialog}
                    afterSave={updateList}
                    onClose={() => setShowAddDialog(false)}
                />

                <StorageFileUpload
                    entityId={selectedValue?.id}
                    folderId={selectedValue?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={false}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    addUsageOnUpload={true}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedValue?.languageLabel}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />

                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={dialogFilesFooter}
                >
                    <StorageFolderFileList
                        folderId={selectedValue?.folderId}
                        folderName={selectedValue?.languageLabel}
                        readOnly={!props?.updateFields}
                    />
                </Dialog>
            </>
        );
    }
};

export default LanguageListComponent;
