import ApiService from "./ApiService";
import { translatedMessage } from "./LanguageService";

const EnumService = {
  async getEnumByName(enumName) {
    let response = await ApiService.getAuthenticatedInstance().get(`/lovs/${enumName}`);
    // console.log("enum response: ", response.data)

    let enums = []
    response.data.forEach(element => {
      enums.push({ label: translatedMessage(element.i18nKey), value: element.name })
    });

    return enums;
  },

  async getEnumByNameAndParam(enumName, param) {
    let response = await ApiService.getAuthenticatedInstance().get(`/lovs/${enumName}/${param}`);
    // console.log("enum response: ", response.data)

    let enums = []
    response.data.forEach(element => {
      enums.push({ label: translatedMessage(element.i18nKey), value: element.name })
    });

    return enums;
  },

  async getEntityLookupValueById(id) {
    let response = await ApiService.getAuthenticatedInstance().get(`/entityLookup/item/${id}`);
    // console.log("entity lookup response: ", response.data)

    return response.data
      ? {
        label: response.data.label,
        value: response.data.id,
        code: response.data.code,
        entityLookupField: response.data.fieldClass,
        relatedId: response.data.relatedEntityLookup?.id
      }
      : null;
  },

  async getEntityLookupByField(fieldClass) {
    let response = await ApiService.getAuthenticatedInstance().get(`/entityLookup/${fieldClass}`);
    // console.log("entity lookup response: ", response.data)

    let entityLookups = []
    response.data.forEach(element => {
      entityLookups.push({ label: element.label, value: element.id, code: element.code })
    });

    return entityLookups;
  },

  async getEntityLookupWithParentIdByField(fieldClass) {
    let response = await ApiService.getAuthenticatedInstance().get(`/entityLookup/${fieldClass}`);
    // console.log("entity lookup response: ", response.data)

    let entityLookups = []
    response.data.forEach(element => {
      entityLookups.push({
        label: element.label,
        value: element.id,
        code: element.code,
        entityLookupField: element.fieldClass,
        relatedId: element.relatedEntityLookup?.id
      })
    });

    return entityLookups;
  },

  async getEntityLookupWithParentByField(fieldClass) {
    let response = await ApiService.getAuthenticatedInstance().get(`/entityLookup/${fieldClass}`);
    // console.log("entity lookup response: ", response.data)

    let entityLookups = []
    response.data.forEach(element => {
      entityLookups.push({
        label: element.label,
        value: element.id,
        code: element.code,
        entityLookupField: element.fieldClass,
        relatedEntity: element.relatedEntityLookup
      })
    });

    return entityLookups;
  },

  async getEntityLookupWithParentByParentId(parentId) {
    let response = await ApiService.getAuthenticatedInstance().get(`/entityLookup/${parentId}/children`);
    // console.log("entity lookup response: ", response.data)

    let entityLookups = []
    response.data.forEach(element => {
      entityLookups.push({
        label: element.label,
        value: element.id,
        code: element.code,
        entityLookupField: element.fieldClass,
        relatedId: element.relatedEntityLookup?.id
      })
    });

    return entityLookups;
  },
};

export default EnumService;