import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import { setCurrentUser } from '../service/UserService';

const useLogout = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        setAuth({});
        localStorage.removeItem('_t');
        localStorage.removeItem('_pcn_redirect');    
        localStorage.removeItem('_pcn_lang');
        localStorage.removeItem('_pcn_navProps');
        sessionStorage.removeItem('_filter_values_');
        setCurrentUser(null);
        navigate('/login');

        try {
            await auth.authenticatedInstance.post('/token/logout');
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout