import React, { useState, useEffect } from 'react';
import FieldDisplay from './FieldDisplay';
import { ProgressSpinner } from 'primereact/progressspinner';

const FormDisplay = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props.formFields) {
            setIsLoading(false);
        }

    }, [props.formFields]);

    const onFieldValueChange = (applicationFormFieldId, code, value) => {
        if (typeof props.onFieldValueChange === 'function') {
            props.onFieldValueChange(applicationFormFieldId, code, value);
        }
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <div>
                {props?.formFields?.map(item => {
                    return (
                        (!props?.isForAplicant || (props?.isForAplicant && item.formField?.field?.type !== 'CUSTOM')) &&
                        <div key={item.id} className="col-12 mb-2">
                            <FieldDisplay
                                field={item}
                                className="pcn-form-field-view"
                                onFieldValueChange={onFieldValueChange}
                                updateField={props?.updateFields}
                            />
                        </div>
                    )
                })}
            </div >
        );
    }
};

export default FormDisplay;
