import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import { useNavigate } from 'react-router-dom';
import GeneralUtils from '../../utilities/GeneralUtils';
import EnumService from '../../service/EnumService';
import DeleteModalComponent from '../../components/DeleteModalComponent';
import CompetitionEditDialog from './CompetitionEditDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';
import useAuth from '../../hooks/useAuth';
import ExportButtonComponent from '../../components/ExportButtonComponent';
import DataTableExportUtils from '../../utilities/DataTableExportUtils';
import ApiService from '../../service/ApiService';

const CompetitionList = () => {
    const [competitions, setCompetitions] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    const [competitionStatuses, setCompetitionStatuses] = useState([]);
    const [competitionTypes, setCompetitionTypes] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [competitionCategories, setCompetitionCategories] = useState([]);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.CONTAINS },
            positionCategory: { value: null, matchMode: FilterMatchMode.IN },
            institution: { value: null, matchMode: FilterMatchMode.IN },
            status: { value: null, matchMode: FilterMatchMode.IN }
        }
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        name: 'name',
        status: 'status',
        type: 'type',
        positionCategory: 'position_category',
        startDate: 'start_date',
        submitEndDate: 'submit_end_date'
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();
    const navigate = useNavigate();

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getCompetitionStatuses = async () => {
            setCompetitionStatuses(await EnumService.getEnumByName('CompetitionStatus'));
        };

        const getCompetitionTypes = async () => {
            setCompetitionTypes(await EnumService.getEnumByName('CompetitionType'));
        };

        const getCompetitionCategories = async () => {
            setCompetitionCategories(await EnumService.getEnumByName('PositionCategory'));
        };

        const getInstitutions = async () => {
            await competitionService.getInstitutions({ name: null })
                .then((_institutions) => {
                    setInstitutions(_institutions);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetitionStatuses();
        getCompetitionTypes();
        getCompetitionCategories();
        getInstitutions()
        setFirstLoading(false);
    }, [competitionService]);

    useEffect(() => {
        loadCompetitions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('CompetitionAdministrationList', lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id';
        criteria.name = lazyParams.filters.name.value;
        criteria.statuses = lazyParams.filters.status.value;
        criteria.type = lazyParams.filters.type.value;
        criteria.positionCategories = lazyParams.filters.positionCategory.value;
        criteria.institutions = lazyParams.filters.institution.value

        return criteria;
    };

    const loadCompetitions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService
            .getCompetitionsByCriteria(criteria)
            .then((data) => {
                setCompetitions(data.competitions);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                //setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const updateList = async (savedCompetition, isNew) => {
        let updatedCompetitions = DataTableUtils.updateTableList(competitions, savedCompetition, isNew);

        setCompetitions(updatedCompetitions);
    };

    const showCompetitionViewPage = (competition) => {
        navigate(`/competition/${competition.id}`);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedCompetition(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (competition) => {
        setSelectedCompetition(competition);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (competition) => {
        setSelectedCompetition(competition);
        setDeleteDialogVisible(true);
    };

    const deleteCompetition = () => {
        competitionService
            .deleteCompetition(selectedCompetition.id)
            .then(() => {
                let _competitions = competitions.filter((item) => item.id !== selectedCompetition.id);
                setCompetitions(_competitions);
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedCompetition(null);
            });
    };

    const generateJobCompetition = (_category) => {
        competitionService.generateJobCompetition(_category)
            .then((_competition) => {
                updateList(_competition, true);
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('COMPETITION_CREATE') &&
                        <>
                            <Button label={translatedMessage('competition.new')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />
                            {!ApiService.getIsProduction() &&
                                <>
                                    <Button label={translatedMessage('competition.new.job.execution')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => generateJobCompetition(1)} />
                                    <Button label={translatedMessage('competition.new.job.management')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => generateJobCompetition(2)} />
                                    <Button label={translatedMessage('competition.new.job.high')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => generateJobCompetition(3)} />
                                </>
                            }
                        </>
                    }
                </div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            competitionService.getCompetitionsByCriteria(criteria)
                .then((data) => {
                    let exportData = data.competitions.map((item) => ({ ...item }));
                    // console.log(exportData);
                    exportData.map((item) => {
                        let exportItem = item;
                        exportItem.status = translatedMessage('CompetitionStatus.' + item.status)
                        // exportItem.type = translatedMessage('CompetitionType.' + item.type)
                        exportItem.positionCategory = item.positionCategory ? translatedMessage('PositionCategory.' + item.positionCategory) : ""
                        exportItem.positionGrade = item.positionGrade ? translatedMessage('PositionGrade.' + item.positionGrade) : ""
                        exportItem.positionType = item.positionType ? translatedMessage('PositionType.' + item.positionType) : ""
                        exportItem.institution = item.institution.name

                        delete exportItem.form
                        delete exportItem.testSessionsDistributionFinished
                        delete exportItem.eligibilityFinished
                        delete exportItem.description
                        delete exportItem.resultType
                        delete exportItem.allowTestSessions
                        delete exportItem.bibliography
                        delete exportItem.lastModifiedOn
                        delete exportItem.testSessionsChooseEnabled
                        delete exportItem.type

                        return exportItem
                    });

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    };

    const rightToolbarTemplate = () => {
        return <ExportButtonComponent
            getExportData={handleExport}
            header={DataTableExportUtils.competitionListTableHeader()}
            sortOrderHeader={DataTableExportUtils.competitionListSortOrderHeader()}
            fileName={GeneralUtils.replaceSpaces(translatedMessage('competition.competitions'))}
        />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') && (
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showCompetitionViewPage(rowData)} tooltip={translatedMessage('generic.view')} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                )}
                {hasPermission('COMPETITION_EDIT') && (
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)} tooltip={translatedMessage('generic.edit')} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                )}
                {hasPermission('COMPETITION_CREATE') && (
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger m-1"
                        onClick={() => showDeleteDialog(rowData)}
                        disabled={rowData.id < 1000}
                        tooltip={translatedMessage('generic.delete')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.submitEndDate)
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h3 className="mb-0">{translatedMessage('competition.competitions')}</h3>

                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                                <DataTable
                                    ref={dt}
                                    value={competitions}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    responsiveLayout="scroll"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    loading={isLoading}
                                >
                                    <Column
                                        field="name"
                                        header={translatedMessage('generic.nameRo')}
                                        sortable
                                        headerStyle={{ width: '20%', minWidth: '10rem' }}
                                        filter
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="type"
                                        header={translatedMessage('generic.type')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.newTypeBodyTemplate(e.type)}
                                        headerStyle={{ width: '12%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.newTypeFilterTemplate(e, competitionTypes)}
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="positionCategory"
                                        header={translatedMessage('competition.positionCategory')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.positionCategory)}
                                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.categoryFilterTemplate(e, competitionCategories)}
                                        filterHeaderClassName="pcn-filter-column-250"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="institution"
                                        header={translatedMessage('competition.institution')}
                                        sortable
                                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                                        body={(e) => e.institution.name}
                                        filter
                                        filterField="institution"
                                        filterElement={(e) => CompetitionDataTableUtils.institutionFilterTemplate(e, institutions)}
                                        filterHeaderClassName="pcn-filter-column-300"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="status"
                                        header={translatedMessage('generic.status')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.statusBodyTemplate(e.status)}
                                        headerStyle={{ width: '10%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.statusFilterTemplate(e, competitionStatuses)}
                                        filterHeaderClassName="pcn-filter-column-250"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="startDate"
                                        header={translatedMessage('competition.submitInterval')}
                                        sortable
                                        body={dateBodyTemplate}
                                        headerStyle={{ width: '8%', minWidth: '8rem' }}
                                    />
                                    <Column body={actionBodyTemplate}></Column>
                                </DataTable>

                                <CompetitionEditDialog
                                    value={selectedCompetition}
                                    visible={editDialogVisible}
                                    afterSave={updateList}
                                    visibleSetter={setEditDialogVisible}
                                    statuses={competitionStatuses}
                                    types={competitionTypes}
                                />

                                <DeleteModalComponent
                                    visible={deleteDialogVisible}
                                    item={selectedCompetition ? selectedCompetition.name : ''}
                                    closeDialog={() => {
                                        setDeleteDialogVisible(false);
                                    }}
                                    deleteRecord={() => deleteCompetition()}
                                    info="competition.delete.info"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CompetitionList;
