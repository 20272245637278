import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';
import DataTableUtils from '../../../utilities/DataTableUtils';
import EnumService from '../../../service/EnumService';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import GeneralUtils from '../../../utilities/GeneralUtils';
import DataTableExportUtils from '../../../utilities/DataTableExportUtils';
import CompetitionUtils from '../../../utilities/CompetitionUtils';

const ResultList = () => {
    const [competition, setCompetition] = useState([]);
    const [results, setResults] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);
    const [resultTypes, setResultTypes] = useState([]);
    const [tests, setTests] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);
    const navigate = useNavigate();

    const applicationService = useMemo(() => new ApplicationService(), []);
    const competitionService = useMemo(() => new CompetitionService(), []);

    const { competitionIdParam } = useParams();

    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: 'application_id',
        sortOrder: 1,
        filters: {
            competitionId: { value: competitionIdParam, matchMode: FilterMatchMode.EQUALS },
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            eligibility: { value: null, matchMode: FilterMatchMode.EQUALS },
            eligibilityContestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            eligibilityFinal: { value: null, matchMode: FilterMatchMode.EQUALS },
            preliminaryTest: { value: null, matchMode: FilterMatchMode.EQUALS },
            preliminaryTestContestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            preliminaryTestFinal: { value: null, matchMode: FilterMatchMode.EQUALS },
            advancedTest: { value: null, matchMode: FilterMatchMode.EQUALS },
            advancedTestContestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            advancedTestFinal: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest1: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest1Contestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest1Final: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest2: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest2Contestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest2Final: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest3: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest3Contestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobSupplementaryTest3Final: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobWrittenTest: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobWrittenTestContestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobWrittenTestFinal: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobInterview: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobInterviewContestation: { value: null, matchMode: FilterMatchMode.EQUALS },
            jobInterviewFinal: { value: null, matchMode: FilterMatchMode.EQUALS },
            competitionFinal: { value: null, matchMode: FilterMatchMode.EQUALS }
        }
    });
    const propToColumnMap = {
        fullName: 'full_name',
        eligibility: 'eligibility',
        eligibilityContestation: 'eligibility_contestation',
        eligibilityFinal: 'eligibility_final',
        preliminaryTest: 'preliminary_test',
        preliminaryTestContestation: 'preliminary_test_contestation',
        preliminaryTestFinal: 'preliminary_test_final',
        advancedTest: 'advanced_test',
        advancedTestContestation: 'advanced_test_contestation',
        advancedTestFinal: 'advanced_test_final',
        jobSupplementaryTest1: 'job_supplementary_test_1',
        jobSupplementaryTest1Contestation: 'job_supplementary_test_1_contestation',
        jobSupplementaryTest1Final: 'job_supplementary_test_1_final',
        jobSupplementaryTest2: 'job_supplementary_test_2',
        jobSupplementaryTest2Contestation: 'job_supplementary_test_2_contestation',
        jobSupplementaryTest2Final: 'job_supplementary_test_2_final',
        jobSupplementaryTest3: 'job_supplementary_test_3',
        jobSupplementaryTest3Contestation: 'job_supplementary_test_3_contestation',
        jobSupplementaryTest3Final: 'job_supplementary_test_3_final',
        jobWrittenTest: 'job_written_test',
        jobWrittenTestContestation: 'job_written_test_contestation',
        jobWrittenTestFinal: 'job_written_test_final',
        jobInterview: 'job_interview',
        jobInterviewContestation: 'job_interview_contestation',
        jobInterviewFinal: 'job_interview_final',
        competitionFinal: 'competition_final'
    };

    const resultOptions = [
        { label: translatedMessage('Result.ACCEPTED'), value: 'ACCEPTED' },
        { label: translatedMessage('Result.REJECTED'), value: 'REJECTED' },
        { label: translatedMessage('Result.ABSENT'), value: 'ABSENT' },
    ]

    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    useEffect(() => {
        const getCompetition = async () => {
            await competitionService
                .getCompetition(competitionIdParam)
                .then((competitionData) => {
                    setCompetition(competitionData);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getResultTypes = async () => {
            setResultTypes(await EnumService.getEnumByNameAndParam('ResultType', competitionIdParam));
        };

        const getTests = async () => {
            await competitionService.getCompetitionTestList(competitionIdParam)
                .then((_competitionTests) => {
                    let _tests = _competitionTests.map(item => { return item.test })
                    setTests(_tests.sort((a, b) => (a.id < b.id ? -1 : 1)));
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetition();
        getResultTypes();
        getTests();
    }, [applicationService, competitionIdParam, competitionService]);

    useEffect(() => {
        setLoading(true);
        loadTestResults();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        let criteria = {};
        GeneralUtils.processSessionFilterValues('ResultList_' + competitionIdParam, lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'application_id';
        criteria.competitionId = competitionIdParam;
        //criteria.applicationId = lazyParams.filters.applicationId.value;
        criteria.fullName = lazyParams.filters.fullName.value;
        criteria.eligibility = lazyParams.filters.eligibility.value;
        criteria.eligibilityContestation = lazyParams.filters.eligibilityContestation.value;
        criteria.eligibilityFinal = lazyParams.filters.eligibilityFinal.value;
        criteria.preliminaryTest = lazyParams.filters.preliminaryTest.value;
        criteria.preliminaryTestContestation = lazyParams.filters.preliminaryTestContestation.value;
        criteria.preliminaryTestFinal = lazyParams.filters.preliminaryTestFinal.value;
        criteria.advancedTest = lazyParams.filters.advancedTest.value;
        criteria.advancedTestContestation = lazyParams.filters.advancedTestContestation.value;
        criteria.advancedTestFinal = lazyParams.filters.advancedTestFinal.value;
        criteria.jobSupplementaryTest1 = lazyParams.filters.jobSupplementaryTest1.value;
        criteria.jobSupplementaryTest1Contestation = lazyParams.filters.jobSupplementaryTest1Contestation.value;
        criteria.jobSupplementaryTest1Final = lazyParams.filters.jobSupplementaryTest1Final.value;
        criteria.jobSupplementaryTest2 = lazyParams.filters.jobSupplementaryTest2.value;
        criteria.jobSupplementaryTest2Contestation = lazyParams.filters.jobSupplementaryTest2Contestation.value;
        criteria.jobSupplementaryTest2Final = lazyParams.filters.jobSupplementaryTest2Final.value;
        criteria.jobSupplementaryTest3 = lazyParams.filters.jobSupplementaryTest3.value;
        criteria.jobSupplementaryTest3Contestation = lazyParams.filters.jobSupplementaryTest3Contestation.value;
        criteria.jobSupplementaryTest3Final = lazyParams.filters.jobSupplementaryTest3Final.value;
        criteria.jobWrittenTest = lazyParams.filters.jobWrittenTest.value;
        criteria.jobWrittenTestContestation = lazyParams.filters.jobWrittenTestContestation.value;
        criteria.jobWrittenTestFinal = lazyParams.filters.jobWrittenTestFinal.value;
        criteria.jobInterview = lazyParams.filters.jobInterview.value;
        criteria.jobInterviewContestation = lazyParams.filters.jobInterviewContestation.value;
        criteria.jobInterviewFinal = lazyParams.filters.jobInterviewFinal.value;
        criteria.competitionFinal = lazyParams.filters.competitionFinal.value;

        return criteria
    }

    const loadTestResults = async () => {
        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await applicationService.getCompetitionResults(criteria)
            .then((data) => {
                setResults(data.items);
                setTotalRecords(data.totalCount);
                setFirstLoading(false);
                setLoading(false);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const hasResultType = (_resultType) => {
        return resultTypes.find((resultType) => resultType.value === _resultType);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    const leftToolbarTemplate = () => {
        return <React.Fragment></React.Fragment>;
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            applicationService.getCompetitionResults(criteria)
                .then((data) => {
                    let exportData = data.items.map(item => ({ ...item }));
                    resolve(DataTableExportUtils.computeResultExportData(exportData, competition, tests))
                })
                .catch((error) => reject(error));
        });
    }

    const handleTPExport = async () => {
        return new Promise((resolve, reject) => {
            applicationService.getCompetitionPreliminaryTestResults(competitionIdParam)
                .then((data) => {
                    let exportData = data.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.competition = competition.name
                        exportItem.result = item.result ? translatedMessage("Result." + item.result) : translatedMessage("Result.ABSENT")

                        tests.forEach((test, index) => {
                            let _testResult = item.testResults.filter(t => t.testId === test.id)[0]
                            let _result = _testResult?.result
                            if (_testResult?.testType === "QUIZ") {
                                _result = _testResult?.quizResult ? translatedMessage("Result." + _testResult?.quizResult) : null
                            }
                            exportItem['test' + index] = _result ? _result : null
                        })

                        delete exportItem.testResults

                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const getTPTableHeader = () => {
        let tableHeader = [
            translatedMessage("generic.firstName"), translatedMessage("generic.lastName"), translatedMessage("generic.username"), translatedMessage("profile.uid"),
            translatedMessage("application.registrationNumber"), translatedMessage('ResultType.PRELIMINARY_TEST')
        ]

        tests.forEach((test, index) => {
            tableHeader.push(test.name)
        })

        tableHeader.push(translatedMessage("test.preliminaryTest.overallScore"))
        tableHeader.push(translatedMessage("competition.competition"))
        return tableHeader
    }

    const getTPHeaderSort = () => {
        let tableSort = ['firstName', 'lastName', 'username', 'uid', 'registrationNumber', 'result']

        tests.forEach((test, index) => {
            tableSort.push('test' + index)
        })
        tableSort.push('preliminaryTestScore')
        tableSort.push('competition')

        return tableSort
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <ExportButtonComponent
                    getExportData={handleExport}
                    header={DataTableExportUtils.resultListTableHeader(competition, tests)}
                    sortOrderHeader={DataTableExportUtils.resultListSortOrderHeader(competition, tests)}
                    fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.results"))}
                    menuButtonClassName="pcn-export-dropdown-1"
                />

                {CompetitionUtils.isNationalCompetition(competition?.type) &&
                    <ExportButtonComponent
                        getExportData={handleTPExport}
                        header={getTPTableHeader()}
                        sortOrderHeader={getTPHeaderSort()}
                        fileName={GeneralUtils.replaceSpaces(translatedMessage("ResultType.PRELIMINARY_TEST"))}
                        label={'Export teste preliminare'}
                        class={'ml-2'}
                        menuButtonClassName="pcn-export-dropdown-2"
                    />
                }
            </>
        );
    };

    const resultTemplate = (_result, resultType) => {
        return <>{_result[resultType] && <span className={`status status-result-${_result[resultType].toString().toLowerCase()}`}>{translatedMessage('Result.' + _result[resultType])}</span>}</>;
    };

    const resultFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={resultOptions}
                onChange={(e) => options.filterApplyCallback(e.value)}
                className="p-column-filter"
                itemTemplate={(_result) => {
                    return <span className={`status status-result-${_result.value.toString().toLowerCase()}`}>{translatedMessage('Result.' + _result.value)}</span>;
                }}
            />
        );
    };

    if (firstLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="card w-full">
                    <h3 className="mb-1">{competition?.name}</h3>
                    <div className="mb-3">
                        <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/competition-evaluation`)}>
                            <div className="flex align-items-center">
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left" className="mr-1 " />
                                <span>{translatedMessage('generic.backToList')}</span>
                            </div>
                        </Button>
                    </div>
                    <div>
                        <h5 className="mb-1 font-weight-normal">{translatedMessage('competition.results')}</h5>
                    </div>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                    <DataTable
                        ref={dt}
                        value={results}
                        dataKey="id"
                        loading={loading}
                        lazy
                        paginator
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                    >
                        <Column
                            field="fullName"
                            header={translatedMessage('application.applicant')}
                            headerStyle={{ width: '10%', minWidth: '10rem' }}
                            sortable
                            filter
                            filterField="fullName"
                            showFilterMenu={false}
                        />
                        {hasResultType('ELIGIBILITY') && (
                            <Column
                                field="eligibility"
                                header={translatedMessage('ResultType.ELIGIBILITY')}
                                body={(_result) => resultTemplate(_result, 'eligibility')}
                                sortable
                                filter
                                filterField="eligibility"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('ELIGIBILITY_CONTESTATION') && (
                            <Column
                                field="eligibilityContestation"
                                header={translatedMessage('ResultType.ELIGIBILITY_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'eligibilityContestation')}
                                sortable
                                filter
                                filterField="eligibilityContestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('ELIGIBILITY_FINAL') && (
                            <Column
                                field="eligibilityFinal"
                                header={translatedMessage('ResultType.ELIGIBILITY_FINAL')}
                                body={(_result) => resultTemplate(_result, 'eligibilityFinal')}
                                sortable
                                filter
                                filterField="eligibilityFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('PRELIMINARY_TEST') && (
                            <Column
                                field="preliminaryTest"
                                header={translatedMessage('ResultType.PRELIMINARY_TEST')}
                                body={(_result) => resultTemplate(_result, 'preliminaryTest')}
                                sortable
                                filter
                                filterField="preliminaryTest"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('PRELIMINARY_TEST_CONTESTATION') && (
                            <Column
                                field="preliminaryTestContestation"
                                header={translatedMessage('ResultType.PRELIMINARY_TEST_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'preliminaryTestContestation')}
                                sortable
                                filter
                                filterField="preliminaryTestContestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('PRELIMINARY_TEST_FINAL') && (
                            <Column
                                field="preliminaryTestFinal"
                                header={translatedMessage('ResultType.PRELIMINARY_TEST_FINAL')}
                                body={(_result) => resultTemplate(_result, 'preliminaryTestFinal')}
                                sortable
                                filter
                                filterField="preliminaryTestFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('ADVANCED_TEST') && (
                            <Column
                                field="advancedTest"
                                header={translatedMessage('ResultType.ADVANCED_TEST')}
                                body={(_result) => resultTemplate(_result, 'advancedTest')}
                                sortable
                                filter
                                filterField="advancedTest"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('ADVANCED_TEST_CONTESTATION') && (
                            <Column
                                field="advancedTestContestation"
                                header={translatedMessage('ResultType.ADVANCED_TEST_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'advancedTestContestation')}
                                sortable
                                filter
                                filterField="advancedTestContestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('ADVANCED_TEST_FINAL') && (
                            <Column
                                field="advancedTestFinal"
                                header={translatedMessage('ResultType.ADVANCED_TEST_FINAL')}
                                body={(_result) => resultTemplate(_result, 'advancedTestFinal')}
                                sortable
                                filter
                                filterField="advancedTestFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}

                        {hasResultType('JOB_SUPPLEMENTARY_TEST_1') && (
                            <Column
                                field="jobSupplementaryTest1"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest1')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest1"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest1Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_1_CONTESTATION') && (
                            <Column
                                field="jobSupplementaryTest1Contestation"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest1Contestation')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest1Contestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest1Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_1_FINAL') && (
                            <Column
                                field="jobSupplementaryTest1Final"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_1_FINAL')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest1Final')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest1Final"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest1Name}
                            />
                        )}

                        {hasResultType('JOB_SUPPLEMENTARY_TEST_2') && (
                            <Column
                                field="jobSupplementaryTest2"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest2')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest2"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest2Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_2_CONTESTATION') && (
                            <Column
                                field="jobSupplementaryTest2Contestation"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest2Contestation')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest2Contestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest2Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_2_FINAL') && (
                            <Column
                                field="jobSupplementaryTest2Final"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_2_FINAL')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest2Final')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest2Final"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest2Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_3') && (
                            <Column
                                field="jobSupplementaryTest3"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest3')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest3"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest3Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_3_CONTESTATION') && (
                            <Column
                                field="jobSupplementaryTest3Contestation"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest3Contestation')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest3Contestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest3Name}
                            />
                        )}
                        {hasResultType('JOB_SUPPLEMENTARY_TEST_3_FINAL') && (
                            <Column
                                field="jobSupplementaryTest3Final"
                                header={translatedMessage('ResultType.JOB_SUPPLEMENTARY_TEST_3_FINAL')}
                                body={(_result) => resultTemplate(_result, 'jobSupplementaryTest3Final')}
                                sortable
                                filter
                                filterField="jobSupplementaryTest3Final"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                                hidden={!competition?.supplementaryTest3Name}
                            />
                        )}
                        {hasResultType('JOB_WRITTEN_TEST') && (
                            <Column
                                field="jobWrittenTest"
                                header={translatedMessage('ResultType.JOB_WRITTEN_TEST')}
                                body={(_result) => resultTemplate(_result, 'jobWrittenTest')}
                                sortable
                                filter
                                filterField="jobWrittenTest"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false} />
                        )}
                        {hasResultType('JOB_WRITTEN_TEST_CONTESTATION') && (
                            <Column
                                field="jobWrittenTestContestation"
                                header={translatedMessage('ResultType.JOB_WRITTEN_TEST_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'jobWrittenTestContestation')}
                                sortable
                                filter
                                filterField="jobWrittenTestContestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('JOB_WRITTEN_TEST_FINAL') && (
                            <Column
                                field="jobWrittenTestFinal"
                                header={translatedMessage('ResultType.JOB_WRITTEN_TEST_FINAL')}
                                body={(_result) => resultTemplate(_result, 'jobWrittenTestFinal')}
                                sortable
                                filter
                                filterField="jobWrittenTestFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('JOB_INTERVIEW') && (
                            <Column
                                field="jobInterview"
                                header={translatedMessage('ResultType.JOB_INTERVIEW')}
                                body={(_result) => resultTemplate(_result, 'jobInterview')}
                                sortable
                                filter
                                filterField="jobInterview"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false} />
                        )}
                        {hasResultType('JOB_INTERVIEW_CONTESTATION') && (
                            <Column
                                field="jobInterviewContestation"
                                header={translatedMessage('ResultType.JOB_INTERVIEW_CONTESTATION')}
                                body={(_result) => resultTemplate(_result, 'jobInterviewContestation')}
                                sortable
                                filter
                                filterField="jobInterviewContestation"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('JOB_INTERVIEW_FINAL') && (
                            <Column
                                field="jobInterviewFinal"
                                header={translatedMessage('ResultType.JOB_INTERVIEW_FINAL')}
                                body={(_result) => resultTemplate(_result, 'jobInterviewFinal')}
                                sortable
                                filter
                                filterField="jobInterviewFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                        {hasResultType('COMPETITION_FINAL') && (
                            <Column
                                field="competitionFinal"
                                header={translatedMessage('ResultType.COMPETITION_FINAL')}
                                body={(_result) => resultTemplate(_result, 'competitionFinal')}
                                sortable
                                filter
                                filterField="competitionFinal"
                                filterElement={resultFilterTemplate}
                                showFilterMenu={false}
                            />
                        )}
                    </DataTable>
                </div>
            </>
        );
    }
};

export default ResultList;
