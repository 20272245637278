import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';

import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';

import { Toast } from 'primereact/toast';
import WorkExperienceListComponent from '../../../components/profile/WorkExperienceListComponent';
import useAuth from '../../../hooks/useAuth';
import FieldEvaluationDropdownComponent from '../../../components/evaluation/FieldEvaluationDropdownComponent';


const ApplicationWorkExperience = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [experienceList, setExperienceList] = useState(null);
    const [pageTitle, setPageTitle] = useState(translatedMessage("profile.workExperience"));
    const [pageSubTitle, setPageSubTitle] = useState(null);
    const [experienceRequirement, setExperienceRequirement] = useState('');
    const [fieldEvaluation, setFieldEvaluation] = useState(null);

    const toast = useRef(null);
    const { auth } = useAuth();

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getExperience = async () => {
            await applicationService.getApplicationExperience(props?.application.id)
                .then(experienceRespone => {
                    setExperienceList(experienceRespone)
                })
                .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
        }

        const getExpereinceRequirement = () => {
            if (props?.formFields && props?.formFields.length > 0) {
                props.formFields.forEach(item => {
                    if (item.formField.field.code === 'experienta') {
                        setPageTitle(item.formField.field.label)
                        setPageSubTitle(item.formField.field.preDescription)
                        setExperienceRequirement(item.formField.requestedValue)
                    }
                })
            }
        }

        const getEvaluation = () => {
            if (props?.isForEvaluation && props?.evaluation?.fieldsEvaluation) {
                props?.evaluation?.fieldsEvaluation.forEach(item => {
                    if (item.applicationFormField.formField.field.code === 'experienta') {
                        setFieldEvaluation(item)
                    }
                })
            }
            setIsLoading(false)
        }

        getExperience()
        getExpereinceRequirement()
        getEvaluation()
    }, [applicationService, props]);

    const onFieldEvaluationChange = (applicationFieldEvaluationId, comment, status) => {
        let _fieldEvaluation = fieldEvaluation
        _fieldEvaluation.status = status;
        _fieldEvaluation.comment = comment;

        setFieldEvaluation({..._fieldEvaluation})
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        let canBeEdited = props?.isCommitteeActive && fieldEvaluation?.evaluation?.evaluationCommitteeUser?.user?.id === auth.user.id && !props?.viewOnly

        let infoText = translatedMessage("application.expereince.info")
        
        if(props?.application?.competition?.positionCategory === 'INALTI_FUNCTIONARI_PUBLICI' || props?.application?.competition?.positionCategory === 'FUNCTII_PUBLICE_DE_CONDUCERE') {
            infoText += " " + translatedMessage("application.experience.documents.info")
        }

        return (
            <>
                <Toast ref={toast}></Toast>

                <div className='w-full text-align-left'>
                    <h5 className='mb-1'>{pageTitle}</h5>
                    {pageSubTitle &&
                        <div className='mb-1 font-italic'>{pageSubTitle}</div>
                    }
                    {!props?.hideFillInExpanation &&
                        <>
                            <div>
                                <Message severity="warn" text={infoText}/>
                                {/* <div className='mt-1'>{translatedMessage("application.expereince.info2")}</div> */}
                            </div>     
                        </>
                    }

                    <div className='mt-2'>
                        <span className='font-bold'>{translatedMessage("form.field.requestedValue.description") + " "}</span>
                        {experienceRequirement ? experienceRequirement : "-"}
                    </div>

                    {props?.isForEvaluation &&
                        <>
                            <h5 className='mb-1'>{translatedMessage("evaluation.evaluation")}</h5>
                            <div className="w-full grid pcn-data-view-panel">
                                <div className='col-12 pb-0 p-fluid'>
                                    <div className="filed-label">{translatedMessage('generic.status')}</div>
                                    <FieldEvaluationDropdownComponent
                                        applicationFieldEvaluation={fieldEvaluation}
                                        afterSave={onFieldEvaluationChange}
                                        statusOptions={props?.statusOptions}
                                        canBeEdited={canBeEdited}
                                    />
                                </div>                                
                            </div>
                        </>
                    }
                </div>

                <WorkExperienceListComponent
                    experience={experienceList}
                    isForApplication={true}
                    application={props?.application}
                    onListUpdate={(list) => setExperienceList(list)}
                    updateFields={props?.updateFields}
                />
            </>
        );
    }
};

export default ApplicationWorkExperience;
