import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { translatedMessage } from '../../../service/LanguageService';

const TestCenterRoomViewDialog = (props) => {

    const testDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );


    return (
        <>
            <Dialog
                visible={props.visible}
                header={translatedMessage('testCenter.room.details')}
                style={{ width: '450px' }} 
                modal
                onHide={() => props.closeDialog()}
                className="p-fluid"
                footer={testDialogFooter}
            >
                <div className='w-full grid pcn-data-view-panel'>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                        <div className='filed-value'>{props?.testCenterRoom?.name}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('testCenter.room.capacity')}</div>
                        <div className='filed-value'>{props?.testCenterRoom?.capacity}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('testCenter.room.detailsLabel')}</div>
                        <div className='filed-value'>{props?.testCenterRoom?.details}</div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default TestCenterRoomViewDialog;
