import React from 'react';
import { translatedMessage } from '../../service/LanguageService';

const CommitteeUsedRoles = (props) => {
    return (
        <span>
            {props.roles?.map((role) => (
                <div key={role.name} style={{ display: 'block', marginBottom: 5 }}>
                    {translatedMessage('committee.member.' + role.name) + " (" + role.number + ")"}
                </div>
            ))}
        </span>
    );
};

export default CommitteeUsedRoles;
