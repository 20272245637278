import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import DataTableUtils from '../../utilities/DataTableUtils';
import { ApplicationService } from '../../service/ApplicationService';
import useAuth from '../../hooks/useAuth';
import { UserService } from '../../service/UserService';
import ErrorDialog from '../../components/ErrorDialog';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';
import EnumService from '../../service/EnumService';

const AvailableCompetitionList = () => {
    const [competitions, setCompetitions] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [viewErrorDialog, setViewErrorDialog] = useState(false);
    const [types, setTypes] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [competitionCategories, setCompetitionCategories] = useState([]);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.CONTAINS },
            positionCategory: { value: null, matchMode: FilterMatchMode.IN },
            institution: { value: null, matchMode: FilterMatchMode.IN },
        }
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        name: 'name',
        type: 'type',
        startDate: 'start_date',
        positionCategory: 'position_category',
        submitEndDate: 'submit_end_date'
    };
    const [totalRecords, setTotalRecords] = useState(0);
    const [firstLoading, setFirstLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const navigate = useNavigate();
    const { auth } = useAuth();

    const competitionService = useMemo(() => new CompetitionService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);
    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const getCompetitionTypes = async () => {
            await EnumService.getEnumByName('CompetitionType').then((_types) => setTypes(_types));
        };

        const getCompetitionCategories = async () => {
            setCompetitionCategories(await EnumService.getEnumByName('PositionCategory'));
        };

        const getInstitutions = async () => {
            await competitionService.getInstitutions({ name: null })
                .then((_institutions) => {
                    setInstitutions(_institutions);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetitionTypes();
        getCompetitionCategories();
        getInstitutions();
        setFirstLoading(false);
    }, [competitionService]);

    useEffect(() => {
        loadCompetitions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('AvailableCompetitionsList', lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id';
        criteria.name = lazyParams.filters.name.value;
        criteria.type = lazyParams.filters.type.value;
        criteria.positionCategories = lazyParams.filters.positionCategory.value;
        criteria.institutions = lazyParams.filters.institution.value

        return criteria;
    };

    const loadCompetitions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await competitionService
            .getMyAvailableCompetitionListByCriteria(criteria)
            .then((data) => {
                setCompetitions(data.competitions);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const showCompetitionViewPage = (competition) => {
        navigate(`/active-competition/${competition.id}`);
    };

    const applyToCompetition = (competition) => {
        if (userService.checkUserForCompetitionRegister(auth.user)) {
            confirmDialog({
                message: translatedMessage('competition.apply.confirm') + ' "' + competition.name + '" ?',
                header: translatedMessage('generic.confirm'),
                acceptClassName: 'p-button-text',
                acceptIcon: 'pi pi-check',
                rejectClassName: 'p-button-text',
                rejectIcon: 'pi pi-times',
                className: 'pcn-confirm-dialog no-icon',
                accept: () => acceptRegistration(competition.id, competition.name)
            });
        } else {
            setViewErrorDialog(true);
        }
    };

    const acceptRegistration = (competitionId, competitionName) => {
        applicationService
            .applyToCompetition(competitionId)
            .then((response) => {
                navigate(`/application/${response?.id}`);
                toast.current.show({ severity: 'success', detail: translatedMessage('competition.apply.confirmation') + ' "' + competitionName + '".', life: 3000 });
                // getAvailableCompetitions()
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showCompetitionViewPage(rowData)} tooltip={translatedMessage('generic.view')} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {rowData.canRegister && (
                    <Button
                        icon="pi pi-file-edit"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => applyToCompetition(rowData)}
                        tooltip={translatedMessage('competition.apply.register')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                )}
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.submitEndDate)
    }
    
    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && (
                <>
                    <ConfirmDialog />
                    <div className="grid h-full">
                        <div className="col-12">
                            <div className="card h-full">
                                <h3 className="mb-0">{translatedMessage('competition.competitions')}</h3>

                                <DataTable
                                    ref={dt}
                                    value={competitions}
                                    dataKey="id"
                                    paginator
                                    lazy
                                    totalRecords={totalRecords}
                                    first={lazyParams.first}
                                    rows={lazyParams.rows}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                    className="datatable-responsive pcn-datatable"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    responsiveLayout="scroll"
                                    filterDisplay="row"
                                    filters={lazyParams.filters}
                                    loading={isLoading}
                                >
                                    <Column field="name" header={translatedMessage('generic.nameRo')} sortable headerStyle={{ width: '25%', minWidth: '10rem' }} filter showFilterMenu={false} />
                                    <Column
                                        field="type"
                                        header={translatedMessage('generic.type')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.newTypeBodyTemplate(e.type)}
                                        headerStyle={{ width: '12%', minWidth: '12rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.newTypeFilterTemplate(e, types)}
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="positionCategory"
                                        header={translatedMessage('competition.positionCategory')}
                                        sortable
                                        body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.positionCategory)}
                                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                                        filter
                                        filterElement={(e) => CompetitionDataTableUtils.categoryFilterTemplate(e, competitionCategories)}
                                        filterHeaderClassName="pcn-filter-column-250"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="institution"
                                        header={translatedMessage('competition.institution')}
                                        sortable
                                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                                        body={(e) => e.institution.name}
                                        filter
                                        filterField="institution"
                                        filterElement={(e) => CompetitionDataTableUtils.institutionFilterTemplate(e, institutions)}
                                        filterHeaderClassName="pcn-filter-column-300"
                                        showFilterMenu={false}
                                    />
                                    <Column
                                        field="startDate"
                                        header={translatedMessage('competition.submitInterval')}
                                        sortable
                                        body={dateBodyTemplate}
                                        headerStyle={{ width: '8%', minWidth: '8rem' }}
                                    />
                                    <Column body={actionBodyTemplate}></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <ErrorDialog
                        visible={viewErrorDialog}
                        closeDialog={() => setViewErrorDialog(false)}
                        message={translatedMessage('profile.error.competition.notComplete')}
                        hasLink={true}
                        link={'/my-profile'}
                        linkInfo={translatedMessage('profile.goTo.text')}
                        linkText={translatedMessage('generic.here')}
                    />
                </>
            )}
        </>
    );
};

export default AvailableCompetitionList;
