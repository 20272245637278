import React, { useEffect } from 'react';
import MyCommitteesComponent from '../../components/committee/MyCommitteesComponent';
import useAuth from '../../hooks/useAuth';

const MyCommittee = () => {
    const { auth } = useAuth();

    useEffect(() => { }, []);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className="grid dashboard">
                {hasPermission("IS_EVALUATOR") &&
                    <div className="col-12">
                        <div className="card height-100">
                            <MyCommitteesComponent showOnlyActive={false} showFilter={true}/>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default MyCommittee;
