import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import DataTableUtils from '../../../utilities/DataTableUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../../../hooks/useAuth';
import SecurityService from '../../../service/SecurityService';
import { EvaluationService } from '../../../service/EvaluationService';
import EnumService from '../../../service/EnumService';
import ApplicationDataTableUtils from '../../../utilities/ApplicationDataTableUtils';
import EvaluationDataTableUtils from '../../../utilities/EvaluationDataTableUtils';
import ProcessingDataModalComponent from '../../../components/ProcessingDataModalComponent';
import MessageDisplayModalComponent from '../../../components/MessageDisplayModalComponent';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import GeneralUtils from '../../../utilities/GeneralUtils';
import SendAdvancedTestNotificationDialog from '../../../components/evaluation/SendAdvancedTestNotificationDialog';
import ApiService from '../../../service/ApiService';
import CompetitionUtils from '../../../utilities/CompetitionUtils';
import DOMPurify from 'dompurify';
import DataTableExportUtils from '../../../utilities/DataTableExportUtils';

const ApplicationEvaluationList = (props) => {
    const [competition, setCompetition] = useState([]);
    const [applications, setApplications] = useState([]);
    const [applicationView, setApplicationView] = useState({});
    const [applicationStatuses, setApplicationStatuses] = useState([]);
    const [evaluationStatuses, setEvaluationStatuses] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [canPublishResults, setCanPublishResults] = useState(false);
    const [canDistributeApplications, setCanDistributeApplications] = useState(false);
    const [canEndContestationOpenStatus, setCanEndContestationOpenStatus] = useState(false);
    const [committees, setCommittees] = useState([]);
    const [showSelectCommitteeDialog, setShowSelectCommitteeDialog] = useState(false);
    const [selectedCommittee, setSelectedCommittee] = useState(null);
    const [error, setError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isDistributing, setIsDistributing] = useState(false);
    const [showPublishInfoDialog, setShowPublishInfoDialog] = useState(false);
    const [showAdvancedTestNotificationDialog, setShowAdvancedTestNotificationDialog] = useState(false);
    const [maxComments, setMaxComments] = useState(0);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 100,
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            applicationStatus: { value: null, matchMode: FilterMatchMode.IN },
            committeeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            evaluationStatus: { value: null, matchMode: FilterMatchMode.IN },
            accessibility: { value: null, matchMode: FilterMatchMode.EQUALS }
        }
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);

    const propToColumnMap = {
        'fullName': 'full_name',
        'applicationStatus': 'status',
        'evaluationCommitteeName': 'evaluation_committee_name',
        'evaluationStatus': 'evaluation_status',
        'accessibility': 'accessibility'
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    const competitionService = useMemo(() => new CompetitionService(), []);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    const { competitionIdParam } = useParams();

    useEffect(() => {
        setFirstLoading(true)

        const getApplicationStatuses = async () => {
            let _statuses = await EnumService.getEnumByName('ApplicationStatus')
            setApplicationStatuses(_statuses)
        }

        const getEvaluationStatuses = async () => {
            let _statuses = await EnumService.getEnumByName('EvaluationStatus')
            setEvaluationStatuses(_statuses)
        }

        const checkCanPublishResults = async () => {
            setCanPublishResults(await SecurityService.check('CAN_PUBLISH_RESULTS', competitionIdParam))
        }

        const checkCanDistributeApplications = async () => {
            setCanDistributeApplications(await SecurityService.check('CAN_DISTRIBUTE_APPLICATIONS', competitionIdParam))
        }

        const checkCanEndContestationOpenStatus = async () => {
            setCanEndContestationOpenStatus(await SecurityService.check('CAN_END_CONTESTATION_OPEN_STATUS', competitionIdParam))
        }

        setCompetition(props?.competition)
        getApplicationStatuses()
        getEvaluationStatuses()
        getCommitteeListForClarifications()
        checkCanPublishResults()
        checkCanDistributeApplications()
        checkCanEndContestationOpenStatus()
        setFirstLoading(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionIdParam, props]);

    useEffect(() => {
        loadApplicationsOrEvaluations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('ApplicationEvaluationList_' + competitionIdParam, lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || ('application_id');
        criteria.competitionId = competitionIdParam;
        criteria.applicationStatuses = lazyParams.filters.applicationStatus.value;
        criteria.evaluationStatuses = lazyParams.filters.evaluationStatus.value;
        //criteria.userId = null;
        //criteria.committeeType=lazyParams.filters.committeeType.value;
        criteria.fullName = lazyParams.filters.fullName.value;
        criteria.committeeName = lazyParams.filters.committeeName.value;
        criteria.accessibility = lazyParams.filters.accessibility.value;

        return criteria;
    }

    const loadApplicationsOrEvaluations = async () => {
        setLoading(true)

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await evaluationService.getEvaluationsByCompetition(criteria)
            .then((data) => {
                setApplications(data._applications)
                setTotalRecords(data.totalCount)
                setMaxComments(data.maxEvaluationComments)
                setLoading(false)
            })
            .catch((error) => {
                setError(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const getCommitteeListForClarifications = async () => {
        await competitionService.getCommitteeListForClarifications(competitionIdParam)
            .then((data) => {
                let _committees = []
                data.forEach(item => {
                    if (item.id) {
                        _committees.push({ value: item.id, label: item.name, active: item.active })
                    }
                })

                setCommittees(_committees)
                setSelectedCommittee(_committees.length > 0 ? _committees[0] : null)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const getCompetition = async () => {
        await competitionService.getCompetition(competitionIdParam)
            .then((competitionData) => {
                setCompetition(competitionData)
            })
            .catch((error) => { });
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const checkCanPublishResults = async () => {
        setCanPublishResults(await SecurityService.check('CAN_PUBLISH_RESULTS', competitionIdParam));
    };

    const checkCanDistributeApplications = async () => {
        setCanDistributeApplications(await SecurityService.check('CAN_DISTRIBUTE_APPLICATIONS', competitionIdParam));
    };

    const checkCanEndContestationOpenStatus = async () => {
        setCanEndContestationOpenStatus(await SecurityService.check('CAN_END_CONTESTATION_OPEN_STATUS', competitionIdParam))
    }

    const confirmPublishResults = () => {
        confirmDialog({
            message: translatedMessage('competition.publish.results.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => publishResults()
        });
    };

    const publishResults = () => {
        setIsPublishing(true)
        setIsProcessing(true)
        competitionService.publishResults(competitionIdParam)
            .then((_competition) => {
                setCompetition(_competition)
                checkCanPublishResults()
                checkCanDistributeApplications()
                checkCanEndContestationOpenStatus()
                setIsPublishing(false)
                setIsProcessing(false)
                setShowPublishInfoDialog(true)
            })
            .catch((error) => {
                setIsPublishing(false)
                setIsProcessing(false)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const closeMessageDisplayDialog = async () => {
        await getCompetition()
        checkCanPublishResults()
        checkCanDistributeApplications()
        checkCanEndContestationOpenStatus()
        loadApplicationsOrEvaluations()
        setShowPublishInfoDialog(false)
    }

    const confirmSendClarification = () => {
        confirmDialog({
            message: translatedMessage('competition.send.clarification.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => setShowSelectCommitteeDialog(true)
        });
    };


    // const confirmSendNotification = () => {
    //     confirmDialog({
    //         message: translatedMessage('competition.notification.registrationNumber.confirm'),
    //         header: translatedMessage('generic.confirm'),
    //         acceptClassName: 'p-button-text',
    //         acceptIcon: 'pi pi-check',
    //         rejectClassName: 'p-button-text',
    //         rejectIcon: 'pi pi-times',
    //         className: 'pcn-confirm-dialog no-icon',
    //         accept: sendRegistrationNumberNotification
    //     });
    // }

    // const sendRegistrationNumberNotification = () => {
    //     notificationService
    //         .sendEligibilityNotification(competitionIdParam)
    //         .then((_numberOfNotificationsSent) => {
    //             toast.current.show({ severity: 'success', summary: translatedMessage('competition.notification.registrationNumber.send.success', _numberOfNotificationsSent) });
    //         })
    //         .catch((error) => {
    //             toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
    //         });
    // };

    const sendAllClarificationRequests = async () => {
        setIsProcessing(true)
        await evaluationService
            .sendCommitteeClarificationRequests(selectedCommittee.value)
            .then((_clarifications) => {
                getCommitteeListForClarifications()
                setShowSelectCommitteeDialog(false)
                setIsProcessing(false)
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.send.clarification.success'), life: 5000 })
            })
            .catch((error) => {
                setIsProcessing(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    };

    const confirmDistributeApplications = async () => {
        confirmDialog({
            message: translatedMessage('competition.applications.distribute.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => distributeApplications()
        });
    };

    const distributeApplications = async () => {
        setIsDistributing(true)
        setIsProcessing(true)
        await competitionService
            .distributeApplicationsToEvaluators(competitionIdParam)
            .then((_numberOfEvaluationsCreated) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.applications.distribute.success', _numberOfEvaluationsCreated), life: 5000 });
                checkCanDistributeApplications();
                loadApplicationsOrEvaluations();
                setIsProcessing(false)
                setIsDistributing(false)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                setIsProcessing(false)
                setIsDistributing(false)
            });
    };

    const confirmEndContestationOpenStatus = async () => {
        confirmDialog({
            message: translatedMessage('competition.contestations.end.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => endContestationOpenStatus()
        });
    };

    const endContestationOpenStatus = () => {
        setIsProcessing(true);
        competitionService
            .endContestationOpenStatus(competitionIdParam)
            .then((_competition) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.contestations.end.success'), life: 5000 });
                setCompetition(_competition);
                loadApplicationsOrEvaluations();
                checkCanPublishResults();
                checkCanDistributeApplications();
                checkCanEndContestationOpenStatus();
                setIsProcessing(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                setIsProcessing(false);
            });
    };

    const updateApplicationAdvancedTestNotificationSent = (_applicationId) => {
        let _applications = [...applications];
        _applications.filter((application) => application.applicationId === _applicationId).forEach((application) => application.advancedTestNotificationSent = true);
        setApplications(_applications);
    }

    const viewApplication = (rowData) => {
        navigate(`/competition-evaluation/${competition.id}/application-view/${rowData.applicationId}`);
    };

    const evaluateApplication = (rowData) => {
        if (rowData.evaluationCommitteeType === "PRELIMINARY_TEST_CONTESTATION" || rowData.evaluationCommitteeType === "ADVANCED_TEST" ||
            rowData.evaluationCommitteeType === "ADVANCED_TEST_CONTESTATION") {

            navigate(`/competition-evaluation/${competition.id}/evaluation-testing-edit/${rowData.evaluationId}`);
        } else {
            navigate(`/competition-evaluation/${competition.id}/evaluation-edit/${rowData.evaluationId}`);
        }
    };

    const evaluateApplicationSecretary = (rowData) => {
        navigate(`/competition-evaluation/${competition.id}/application-edit/${rowData.applicationId}`);
    };

    const notifyApplication = (rowData) => {
        setApplicationView(rowData);
        setShowAdvancedTestNotificationDialog(true);
    };

    async function downloadFile(backEndUrl, fileName) {
        try {
            const response = await ApiService.getAuthenticatedInstance().get(backEndUrl, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        } catch (e) {
            console.log(e);
        }
    }

    const handlePrint = async (_applicationId) => {
        downloadFile(`/application/${_applicationId}/jobCompetitionForm`, `formular-inscriere-candidat-${_applicationId}.pdf`);
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    const leftToolbarTemplate = () => {
        return (
            <>
                {canPublishResults &&
                    <Button
                        label={translatedMessage('competition.results.publish.' + competition?.status)}
                        icon="pi pi-globe"
                        className="p-button-info mr-1"
                        onClick={confirmPublishResults}
                        loading={isPublishing}
                        disabled={isProcessing}
                    />
                }

                {committees.length > 0 &&
                    <Button
                        label={translatedMessage('application.clarification.send')}
                        icon="pi pi-send"
                        className="p-button-info mr-1"
                        onClick={confirmSendClarification}
                        disabled={isProcessing}
                    />
                }

                {canDistributeApplications &&
                    <Button
                        label={translatedMessage('competition.application.distribution')}
                        icon="pi pi-send"
                        className="p-button-info mr-1"
                        onClick={confirmDistributeApplications}
                        loading={isDistributing}
                        disabled={isProcessing}
                    />
                }

                {/* {canDistributeApplications && competition?.status === 'ELIGIBILITY' &&
                    <Button
                        label={translatedMessage('competition.notification.registrationNumber')}
                        icon="pi pi-globe"
                        className="p-button-info mr-1"
                        onClick={confirmSendNotification}
                        loading={isPublishing}
                        disabled={isProcessing}
                    />
                } */}

                {canEndContestationOpenStatus &&
                    <Button
                        label={translatedMessage('competition.contestations.end')}
                        icon="pi pi-step-forward"
                        className="p-button-info mr-1"
                        onClick={confirmEndContestationOpenStatus}
                        title={translatedMessage('competition.contestations.end.tooltip')}
                        disabled={isProcessing}
                    />
                }
            </>
        );
    };

    const handleExport = async () => {
        let criteria = setCriteria();
        return new Promise((resolve, reject) => {
            evaluationService.getEvaluationsByCompetition(criteria)
                .then((data) => {
                    setMaxComments(data.maxEvaluationComments)
                    let exportData = data._applications.map(item => ({ ...item }));
                    exportData.map(item => {
                        let exportItem = item
                        exportItem.applicationStatus = item.applicationStatus ? translatedMessage("ApplicationStatus." + item.applicationStatus) : ""
                        exportItem.evaluationStatus = item.evaluationStatus ? translatedMessage("EvaluationStatus." + item.evaluationStatus) : ""
                        exportItem.evaluationCommitteeType = item.evaluationCommitteeType ? translatedMessage("EvaluationCommitteeType." + item.evaluationCommitteeType) : ""
                        exportItem.competitionStatus = item.competitionStatus ? translatedMessage("CompetitionStatus." + item.competitionStatus) : ""
                        exportItem.competition = competition.name
                        exportItem.accessibility = item.accessibility ? translatedMessage("generic.yes") : translatedMessage("generic.no")

                        exportItem.evaluationComments.forEach((_comm, index) => {
                            exportItem['evaluationComment_' + index] = _comm
                        })
                                                
                        exportItem.rejectionReason = DOMPurify.sanitize(item.rejectionReason, { ALLOWED_TAGS: [] })
                        exportItem.rejectionReason = exportItem.rejectionReason.replaceAll('&nbsp;', ' ')
                        exportItem.rejectionReason = exportItem.rejectionReason.replaceAll('&lt;', '<')
                        exportItem.rejectionReason = exportItem.rejectionReason.replaceAll('&gt;', '>')

                        delete exportItem.competitionId
                        delete exportItem.evaluationCommitteeId
                        delete exportItem.evaluationId
                        delete exportItem.firstName
                        delete exportItem.lastName
                        delete exportItem.isSecretary
                        delete exportItem.isCompetitionAdmin
                        delete exportItem.committeeActive
                        delete exportItem.applicationId
                        delete exportItem.userId
                        delete exportItem.advancedTestNotificationSent
                        delete exportItem.evaluationComment

                        return exportItem;
                    })

                    resolve(exportData)
                })
                .catch((error) => reject(error));
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={DataTableExportUtils.applicationEvaluationListTableHeader(maxComments)}
                sortOrderHeader={DataTableExportUtils.applicationEvaluationListSortOrderHeader(maxComments)}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.applications"))}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-primary m-1"
                    onClick={() => viewApplication(rowData)}
                    visible={hasPermission('APPLICATION_VIEW')}
                    tooltip={translatedMessage('application.view')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                />
                {rowData.userId === auth.user.id && !rowData.isSecretary && (
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => evaluateApplication(rowData)}
                        disabled={!rowData.committeeActive || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                        tooltip={translatedMessage('evaluation.evaluate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    >
                        <FontAwesomeIcon icon="fas fa-file-pen" />
                    </Button>
                )}
                {rowData.isSecretary && (
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => evaluateApplicationSecretary(rowData)}
                        disabled={!rowData.committeeActive || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                        tooltip={translatedMessage('evaluation.evaluate')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    >
                        <FontAwesomeIcon icon="fas fa-file-pen" />
                    </Button>
                )}
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/competition-evaluation/${competitionIdParam}/evaluations/${rowData.applicationId}`)}
                    tooltip={translatedMessage('committee.evaluations')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={rowData.application?.status === 'REGISTERED' || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                >
                    <FontAwesomeIcon icon="fas fa-list-check" />
                </Button>
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/competition-evaluation/${competitionIdParam}/application-clarification/${rowData.applicationId}`)}
                    tooltip={translatedMessage('application.clarification.request')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={rowData.application?.status === 'REGISTERED' || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                >
                    <FontAwesomeIcon icon="fas fa-comments" />
                </Button>
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(`/competition-evaluation/${competitionIdParam}/application-contestation/${rowData.applicationId}`)}
                    tooltip={translatedMessage('application.contestation')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={rowData.application?.status === 'REGISTERED' || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                >
                    <FontAwesomeIcon icon="far fa-hand-peace" />
                </Button>
                {CompetitionUtils.isJobCompetition(competition?.type) &&
                    <Button
                        icon="pi pi-print"
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => handlePrint(rowData.applicationId)}
                        tooltip={translatedMessage('competition.downloadForm')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        disabled={rowData.application?.status === 'REGISTERED' || isProcessing || competition?.status === 'REGISTRATION_OPEN'}
                    />
                }
                {rowData.competitionStatus === "ADVANCED_TEST" && rowData.evaluationCommitteeType === "ADVANCED_TEST" && rowData.userId === auth.user.id && rowData.isSecretary && (
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => notifyApplication(rowData)}
                        disabled={rowData.advancedTestNotificationSent}
                        tooltip={translatedMessage('notification.details')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    >
                        <FontAwesomeIcon icon="fas fa-envelope" />
                    </Button>
                )}
            </div>
        );
    };

    const committeeSelectDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowSelectCommitteeDialog(false)} />
            <Button label={translatedMessage("generic.send")} icon="pi pi-check" className="p-button-text" onClick={sendAllClarificationRequests} />
        </>
    )

    const onCommitteeChange = (committeeId) => {
        let _committee = committees.find(item => item.value === committeeId)
        setSelectedCommittee(_committee)
    }

    return (
        <>
            <Toast ref={toast} />
            {firstLoading && (
                <div className="w-full flex align-items-center">
                    <ProgressSpinner />
                </div>
            )}
            {!firstLoading && !error && (
                <>
                    <ConfirmDialog />
                    <div className="mb-4">
                        <h5 className="mb-1 font-weight-normal">{translatedMessage('competition.applications')}</h5>
                        <div className="flex align-items-center">
                            <div className="font-bold mr-1">{translatedMessage('competition.status') + ':'}</div>
                            <div className={`inline-block status status-competition-${competition?.status?.toString().toLowerCase()}`}>{translatedMessage('CompetitionStatus.' + competition?.status)}</div>
                        </div>
                    </div>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>
                    <DataTable
                        ref={dt}
                        value={applications}
                        dataKey="id"
                        loading={loading}
                        lazy
                        paginator
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                        filterDisplay="row"
                        filters={lazyParams.filters}
                    >
                        <Column
                            field="fullName"
                            header={translatedMessage('generic.name')}
                            sortable
                            headerStyle={{ width: '25%', minWidth: '10rem' }}
                            filter
                            filterField="fullName"
                            showFilterMenu={false}
                        />
                        <Column
                            field="applicationStatus"
                            header={translatedMessage('generic.status')}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '10rem' }}
                            body={(e) => ApplicationDataTableUtils.statusBodyTemplate(e.applicationStatus)}
                            filter
                            filterField="applicationStatus"
                            filterElement={(e) => ApplicationDataTableUtils.statusFilterTemplate(e, applicationStatuses)}
                            showFilterMenu={false}
                        />
                        <Column
                            field="evaluationCommitteeName"
                            header={translatedMessage('committee.committee')}
                            sortable
                            headerStyle={{ width: '25%', minWidth: '10rem' }}
                            filter
                            filterField="committeeName"
                            showFilterMenu={false}
                        />
                        <Column
                            field="evaluationStatus"
                            header={translatedMessage('evaluation.my')}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '10rem' }}
                            body={(e) => EvaluationDataTableUtils.statusBodyTemplate(e.evaluationStatus)}
                            filter
                            filterField="evaluationStatus"
                            filterElement={(e) => EvaluationDataTableUtils.statusFilterTemplate(e, evaluationStatuses)}
                            showFilterMenu={false}
                        />
                        <Column
                            field="accessibility"
                            header={translatedMessage('profile.accessibility')}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={(e) => DataTableUtils.yesNoTemplate(e.accessibility)}
                            filter
                            filterElement={(e) => DataTableUtils.yesNoFilterTemplate(e)}
                            showFilterMenu={false}
                        />
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    {/* committee selection dialog */}
                    <Dialog
                        visible={showSelectCommitteeDialog}
                        style={{ width: '450px' }}
                        header={translatedMessage("committee.choose")}
                        modal
                        footer={committeeSelectDialogFooter}
                        onHide={() => setShowSelectCommitteeDialog(false)}
                    >
                        <div className="flex align-items-center">
                            <Dropdown id="selectedCommittee" name="selectedCommittee" value={selectedCommittee?.value} onChange={(e) => onCommitteeChange(e.value)}
                                options={committees} className="w-full" />
                        </div>
                    </Dialog>

                    <ProcessingDataModalComponent
                        visible={isPublishing || isDistributing}
                        dialogTitle={
                            isPublishing
                                ? 'competition.results.publish.' + competition?.status
                                : "competition.application.distribution"
                        }
                        dialogInfo={
                            isPublishing ? 'competition.results.publish.info' : "competition.distributing.info"
                        }
                    />

                    <MessageDisplayModalComponent
                        visible={showPublishInfoDialog}
                        dialogTitle={'competition.results.publish.' + competition?.status}
                        dialogInfo={'competition.results.afterPublish.info'}
                        closeDialog={closeMessageDisplayDialog}
                        severity={'warning'}
                    />

                    <SendAdvancedTestNotificationDialog
                        visible={showAdvancedTestNotificationDialog}
                        onHide={() => { setShowAdvancedTestNotificationDialog(false) }}
                        onSend={updateApplicationAdvancedTestNotificationSent}
                        applicationView={applicationView}
                    />
                </>
            )}
        </>
    );
};

export default ApplicationEvaluationList;
