import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { CSSTransition } from 'react-transition-group';
import { NotificationService } from '../../service/NotificationService';
import { translatedMessage } from '../../service/LanguageService';
import { useNavigate } from 'react-router-dom';
import ViewNotificationDialog from './ViewNotificationDialog';
import GeneralUtils from '../../utilities/GeneralUtils';

const NOTIFICATION_INTERVAL_CHECK = 30 * 1000; //30 sec
const SYSTEM_NOTIFICATION_MARK = '[SYSTEM]';

const NotificationBell = (props) => {
    const [newNotificationsNumber, setNewNotificationsNumber] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);

    const notificationService = useMemo(() => new NotificationService(), []);

    const toast = useRef(null);
    const topbarRef2 = useRef(null);

    const navigate = useNavigate();

    let intervalVar;

    useEffect(() => {
        setTimeout(getNewNotifications, 300);
        if (!intervalVar) {
            intervalVar = setInterval(getNewNotifications, NOTIFICATION_INTERVAL_CHECK);
        }
    }, [notificationService]);

    const getNewNotifications = async () => {
        notificationService
            .getMyUnreadNotifications()
            .then((_notifications) => {
                setNewNotificationsNumber(_notifications.filter((notification) => !notification.isRead).length);
                setNotifications(_notifications);
                let _notification = _notifications.find((notification) => !notification.isRead && notification.subject.indexOf(SYSTEM_NOTIFICATION_MARK) !== -1);
                if (_notification) {
                    viewNotification(_notification);
                }
            })
            .catch((error) => {
                setNewNotificationsNumber(0);
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const markNotificationAsRead = (_notification) => {
        _notification.isRead = true;
        setNotifications(notifications.filter((n) => n.id !== _notification.id));
        setNewNotificationsNumber(notifications.filter((notification) => !notification.isRead).length);
        notificationService
            .markNotificationAsRead(_notification.id)
            .then(() => {})
            .catch((error) => {
                toast?.current?.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const viewNotification = (_notification, e) => {
        setNotification(_notification);
        setDialogVisible(true);
    };

    const setNotificationDialogVisible = (visible) => {
        setDialogVisible(visible);
        if (visible === false) {
            markNotificationAsRead(notification);
        }
    };

    const viewAllNotifications = () => {
        navigate('/notifications');
    };

    const getContentTemplate = (content) => {
        let cleanContent = GeneralUtils.getHTMLSubstringWithEllipsis(content, 100);
        return <span dangerouslySetInnerHTML={{ __html: cleanContent }} />;
    };

    return (
        <>
            <Toast ref={toast} />
            <Button className="layout-topbar-action rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'notifications' })}>
                <span className="p-overlay-badge pcn-notification-badge">
                    <i className="pi pi-bell fs-large">{newNotificationsNumber > 0 && <Badge value={newNotificationsNumber}></Badge>}</i>
                </span>
            </Button>

            <CSSTransition nodeRef={topbarRef2} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'notifications'} unmountOnExit>
                <ul ref={topbarRef2} className="layout-topbar-action-panel shadow-6 fadeInDown">
                    <li className="flex align-items-center justify-content-between mb-3">
                        <span className="px-3 fs-small" dangerouslySetInnerHTML={{ __html: translatedMessage('notification.number', '<b>' + newNotificationsNumber + '</b>') }} />
                        <Button label={translatedMessage('notification.viewAll')} className="p-button-link p-button-sm" onClick={viewAllNotifications} />
                    </li>
                    {notifications.map((notification) => (
                        <li key={notification.id} className="layout-topbar-action-item" onClick={(e) => viewNotification(notification, e)}>
                            <div className="flex flex-row align-items-center">
                                <div className={classNames('flex flex-column')} style={{ flexGrow: '1' }}>
                                    <div className="flex align-items-center justify-content-between mb-1">
                                        <span className="fs-small font-bold">{notification.subject}</span>
                                        <small style={{ width: '70px', textAlign: 'right' }}>
                                            {GeneralUtils.formatDate(notification.sentDate)} {GeneralUtils.formatTime(notification.sentDate)}
                                        </small>
                                    </div>
                                    <span className="fs-small">{getContentTemplate(notification?.content)}</span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </CSSTransition>

            <ViewNotificationDialog notification={notification} visible={dialogVisible} visibleSetter={setNotificationDialogVisible} />
        </>
    );
};

export default NotificationBell;
