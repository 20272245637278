import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';
import { CompetitionService } from '../../../service/CompetitionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import StorageFolderFileList from '../../../components/file/StorageFolderFileList';
import useNavProps from '../../../hooks/useNavProps';
import GeneralUtils from '../../../utilities/GeneralUtils';
import SecurityService from '../../../service/SecurityService';
import ApplicationEducation from './ApplicationEducation';
import ApplicationWorkExperience from './ApplicationWorkExperience';
import ApplicationLanguage from './ApplicationLanguage';
import ApplicationITKnowledge from './ApplicationITKnowledge';
import MyApplicationForm from './MyApplicationForm';
import ApplicationResults from '../../../components/application/ApplicationResults';
import EnumService from '../../../service/EnumService';
import ApplicationAccessibilityDialog from '../../../components/application/ApplicationAccessibilityDialog';
import CompetitionUtils from '../../../utilities/CompetitionUtils';
import ApiService from '../../../service/ApiService';
import ApplicationValidationErrorModalComponent from '../../../components/application/ApplicationValidationErrorModalComponent';
import { DataTable } from 'primereact/datatable';
import DataTableUtils from '../../../utilities/DataTableUtils';
import { Column } from 'primereact/column';

const sections = [
    { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye' },
    { label: translatedMessage('profile.education'), code: 'EDUCATION', icon: 'fa-solid fa-user-graduate' },
    { label: translatedMessage('profile.workExperience'), code: 'EXPERIENCE', icon: 'fa-solid fa-building-columns' },
    { label: translatedMessage('profile.itKnowledge'), code: 'IT', icon: 'fa-solid fa-computer' },
    { label: translatedMessage('profile.language'), code: 'LANGUAGE', icon: 'fa-solid fa-globe' },
    { label: translatedMessage('application.form'), code: 'FORM', icon: 'fa-regular fa-file-lines' },
    { label: translatedMessage('application.files'), code: 'FILE', icon: 'fa-regular fa-file' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

const MyApplicationPage = () => {
    const [application, setApplication] = useState(null);
    const [applicationFormFields, setApplicationFormFields] = useState([]);
    const [positionId, setPositionId] = useState(null);
    // const [jobId, setJobId] = useState(null);
    const [positions, setPositions] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [positionError, setPositionError] = useState(false);
    // const [jobError, setJobError] = useState(false);
    const [isJobCompetition, setIsJobCompetition] = useState(false);
    const [hasApplied, setHasApplied] = useState(false);
    const [applicationResults, setApplicationResults] = useState([]);
    const [contestations, setContestations] = useState([]);
    const [showLanguageOption, setShowLanguageOption] = useState(false);
    const [accessibilityNeed, setAccessibilityNeed] = useState(false);
    const [showAccessibilityDialog, setShowAccessibilityDialog] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [languageError, setLanguageError] = useState(false);
    const [showValidationErrorDialog, setShowValidationErrorDialog] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const [canEdit, setCanEdit] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [section, setSection] = useState(sections[0]);

    const toast = useRef(null);
    const message = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);
    const competitionService = useMemo(() => new CompetitionService(), []);

    const { applicationIdParam } = useParams();
    const [searchParams] = useSearchParams();
    const { setNavPropsValue } = useNavProps();

    const navigate = useNavigate();
    const dt = useRef(null);

    useEffect(() => {
        setIsLoading(true);

        if (searchParams.get('section')) {
            setSection(sections.find((section) => section.code === searchParams.get('section')));
        }

        const getPositions = async (_competition) => {
            if (_competition?.positionCategory === "FUNCTII_PUBLICE_DE_EXECUTIE") {
                const levels = await EnumService.getEntityLookupByField("NOM:PUBLIC_POSITION_LEVEL")
                let positionLevelId = null
                let filedCode = _competition.positionCategory + "_" + (_competition?.positionGrade === 'DEBUTANT' ? _competition?.positionGrade : "ALTELE")

                levels.map(item => {
                    if (item.code === filedCode) positionLevelId = item.value
                    return item
                })

                let positions = []
                if (positionLevelId) {
                    positions = await EnumService.getEntityLookupWithParentByParentId(positionLevelId)
                } else {
                    positions = await EnumService.getEntityLookupByField("NOM:PUBLIC_POSITION")
                }

                setPositions(positions)
            }

            // set the language options
            if (_competition.positionCategory === 'INALTI_FUNCTIONARI_PUBLICI') {
                setShowLanguageOption(true)
            }
        }

        const getJobs = async (competitionId) => {
            await competitionService.getCompetitionJobs(competitionId)
                .then((_jobs) => {
                    console.log(_jobs)
                    // V4 - se scoate posibilitatea alegerii unui singur post
                    // _jobs.map(item => {
                    //     item.label = item.position +
                    //         (item.jobClass ? " - " + item.jobClass : "") +
                    //         (item.level ? " - " + item.level : "") +
                    //         (item.departmentType ? " - " + item.departmentType : "") +
                    //         (item.departmentName ? " - " + item.departmentName : "")

                    //     return item
                    // })
                    setJobs(_jobs);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getLanguageTest = async () => {
            setLanguages(await EnumService.getEnumByName('LanguageTestOption'));
        }

        const getFormFields = async () => {
            applicationService.getApplicationFormFieldList(applicationIdParam)
                .then((_fields) => {
                    setApplicationFormFields(_fields);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplication = async () => {
            await applicationService.getApplication(applicationIdParam)
                .then(async (applicationData) => {
                    setApplication(applicationData);
                    setAccessibilityNeed(applicationData.accessibilityNeed)
                    if (CompetitionUtils.isNationalCompetition(applicationData.competition.type)) {
                        await getPositions(applicationData.competition)
                    }
                    await getApplicationResults()
                    await getContestations()
                    await getLanguageTest()
                    if (CompetitionUtils.isJobCompetition(applicationData.competition.type)) {
                        setIsJobCompetition(true)
                        await getJobs(applicationData.competition.id)
                    }

                    setPositionId(applicationData.positionId ? applicationData.positionId : null)
                    // setJobId(applicationData.jobId)
                    setHasApplied(applicationData.status === "REGISTERED" ? false : true)

                    setNavPropsValue('application', applicationData.id, applicationData.competition.name);

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationResults = async () => {
            await applicationService
                .getApplicationResults(applicationIdParam)
                .then((_applicationResult) => {
                    setApplicationResults(_applicationResult);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getContestations = async () => {
            await applicationService
                .getAllContestations(applicationIdParam)
                .then((_contestations) => {
                    setContestations(_contestations);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };



        const checkCanEdit = async () => {
            setCanEdit(await SecurityService.check('CAN_EDIT_APPLICATION', applicationIdParam));
        };

        checkCanEdit()
        getFormFields()
        getApplication()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, applicationIdParam]);

    const navigationTemplate = (option) => {
        return (
            <>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </>
        );
    };

    const handleSectionChange = (value) => {
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/application`);
            } else {
                setSection(value);
            }
        }
    };

    const handlePositionChange = (data) => {
        setPositionId(data.value)
        if (data.value) {
            setPositionError(false)
        }
    }

    // const handleJobChange = (data) => {
    //     setJobId(data.value)
    //     if (data.value) {
    //         setJobError(false)
    //     }
    // }

    const handleLanguageChange = (data) => {
        let _application = { ...application }
        _application.languageTest = data.value
        setApplication(_application)
        if (data.value) {
            setLanguageError(false)
        }
    }

    const checkForErrors = () => {
        let isError = false
        if (!positionId && CompetitionUtils.isNationalCompetition(application.competition.type) && application.competition.positionCategory === "FUNCTII_PUBLICE_DE_EXECUTIE") {
            setPositionError(true)
            isError = true
        }

        // V4 - se scoate posibilitatea alegerii unui singur post
        // if (CompetitionUtils.isJobCompetition(application.competition.type) && !jobId) {
        //     setJobError(true)
        //     isError = true
        // }

        if (showLanguageOption && !application.languageTest) {
            setLanguageError(true)
            isError = true
        }

        return isError
    }

    const saveApplication = () => {
        let isError = checkForErrors()

        if (!isError) {
            let _application = application
            _application.positionId = positionId
            // _application.jobId = jobId

            applicationService.saveMyApplication(_application)
                .then(response => {
                    setApplication(response.application)
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error.errorMessage), life: 5000 }));
        }
    }

    const applyForCompetition = () => {
        let isError = checkForErrors()

        if (!isError) {
            let _application = { ...application }
            _application.positionId = positionId
            // _application.jobId = jobId
            _application.status = "APPLIED"

            applicationService.saveMyApplication(_application, true)
                .then(response => {
                    setApplication(response.application)
                    setHasApplied(true)
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                })
                .catch((error) => {
                    if (error.hasValidationError) {
                        setValidationErrors(error.errorCodes)
                        setShowValidationErrorDialog(true)
                    } else {
                        toast.current.show({ severity: 'error', summary: translatedMessage(error.errorMessage), life: 5000 })
                    }
                });
        }
    }

    const onCreateContestation = (savedContestation) => {
        if (savedContestation?.applicationResult) {
            navigate(`/application/${applicationIdParam}/contestation/result/${savedContestation.applicationResult.id}`)
        } else {
            navigate(`/application/${applicationIdParam}/contestation/`)
        }
    };

    const confirmRemoveAccessibility = () => {
        confirmDialog({
            message:
                <div>
                    <div className='mb-2 font-bold'>{translatedMessage('application.accessibility.no.confirm')}</div>
                    <div className='mb-2'>{translatedMessage('application.accessibility.no.confirm.details')}</div>
                </div>,
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            closable: false,
            closeOnEscape: false,
            accept: () => removeAccessibility(),
            reject: () => setAccessibilityNeed(true)
        });
    };

    const onAccessibilityNeedChange = (event) => {
        setAccessibilityNeed(event.value)
        if (accessibilityNeed !== event.value) {
            if (event.value) {
                setShowAccessibilityDialog(true)
            } else {
                confirmRemoveAccessibility()
            }
        }
    };

    const removeAccessibility = async () => {
        const _accessibility = {
            applicationId: application?.id,
            noAdaptation: true
        }
        await applicationService.saveApplicationAccessibility(_accessibility)
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') })
            })
            .catch((error) => {
                setAccessibilityNeed(true)
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            })
    }

    const handleAccessibilityAdaptation = () => {
        setShowAccessibilityDialog(true)
    }

    const onAccessibilityDialogClose = (value, saveSuccess) => {
        if (!value.id) {
            setAccessibilityNeed(false)
        }
        setShowAccessibilityDialog(false)

        if (saveSuccess) {
            toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') })
        }
    }

    async function downloadFile(backEndUrl, fileName) {
        try {
            const response = await ApiService.getAuthenticatedInstance().get(backEndUrl, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            setTimeout(() => window.URL.revokeObjectURL(url), 0);
        } catch (error) {
            let errorMessage = "error.file.not_found";
            if (error && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message
            }
            toast?.current?.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 5000 })
        }
    }

    const handlePrint = async () => {
        let downloadURL = CompetitionUtils.isJobCompetition(application?.competition.type)
            ? `/application/${application?.id}/jobCompetitionForm`
            : `/application/${application?.id}/nationalCompetitionForm`

        downloadFile(downloadURL, `formular-inscriere-candidat-${application?.id}.pdf`)
    }

    const getJobDetails = (data) => {
        return (
            <>
                {data.jobClass && <div><span className="font-bold">{translatedMessage("job.jobClass") + ": "}</span>{data.jobClass}</div>}
                {data.level && <div><span className="font-bold">{translatedMessage("job.level") + ": "}</span>{data.level}</div>}
                {data.departmentType && <div><span className="font-bold">{translatedMessage("job.departmentType") + ": "}</span>{data.departmentType}</div>}
                {data.departmentName && <div><span className="font-bold">{translatedMessage("job.departmentName") + ": "}</span>{data.departmentName}</div>}
            </>
        );
    };

    const getJobLocation = (data) => {
        return (
            <>
                {data.institution?.name && <div>{data.institution.name}</div>}
                {data.institution?.locality && <div><span className="font-bold">{translatedMessage("generic.locality") + ": "}</span>{data.institution.locality}</div>}
                {data.institution?.county && <div><span className="font-bold">{translatedMessage("generic.county") + ": "}</span>{data.institution.county}</div>}
            </>
        );
    };

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Messages ref={message} />
                <ConfirmDialog />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.competition.name}</h3>
                            <div className="mb-3">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemplate} className="pcn-selectbutton-slim" />
                            </div>

                            {section.code === 'DETAIL' && (
                                <div className="w-full grid pcn-data-view-panel">
                                    {/* Application */}
                                    <div className="col-12">
                                        {!hasApplied &&
                                            <Message severity="warn" text={translatedMessage("application.applied.info")} />
                                        }
                                        <h5 className="my-1 font-weight-normal">{translatedMessage('application.details')}</h5>
                                        <Button className="pcn-button-slim p-button-text" onClick={saveApplication}
                                            icon="pi pi-check" label={translatedMessage('generic.save')} disabled={!canEdit} />
                                        <Button className="pcn-button-slim p-button-text" onClick={applyForCompetition} disabled={!canEdit || hasApplied}>
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon='fa-solid fa-paper-plane' className="mr-1" />
                                                <span>{translatedMessage('application.apply')}</span>
                                            </div>
                                        </Button>
                                        <Button icon="pi pi-print" className="pcn-button-slim p-button-text" onClick={() => handlePrint()}
                                            label={translatedMessage('competition.downloadForm')}
                                            // disabled={application?.status === 'REGISTERED' || application?.status === 'APPLIED' || application?.competition?.status === 'REGISTRATION_OPEN'}
                                        />
                                    </div>
                                    {CompetitionUtils.isNationalCompetition(application.competition.type) && positions.length > 0 && (
                                        <div className={showLanguageOption ? "col-12 md:col-6" : "col-12"}>
                                            <div className="filed-label">{translatedMessage('application.publicPosition')}</div>
                                            <div className="p-inputgroup">
                                                <Dropdown id="positionId" name="positionId" value={positionId} options={positions} onChange={handlePositionChange}
                                                    className={classNames({ 'p-invalid': positionError })} disabled={!canEdit} />
                                            </div>
                                            <small className={"p-error text-align-left mt-1 ".concat(positionError ? "block" : "hidden")}>
                                                {translatedMessage("application.publicPosition.required")}
                                            </small>
                                        </div>
                                    )}
                                    {/* V4 - se scoate posibilitatea alegerii unui singur post
                                        {CompetitionUtils.isJobCompetition(application.competition.type) && (
                                            <div className="col-12">
                                                <div className="filed-label">{translatedMessage('application.job')}</div>
                                                <div className="p-inputgroup">
                                                    <Dropdown id="jobId" name="jobId" value={jobId} options={jobs} onChange={handleJobChange}
                                                        className={classNames({ 'p-invalid': jobError })} disabled={!canEdit} optionLabel="label" optionValue="id" />
                                                </div>
                                                <small className={"p-error text-align-left mt-1 ".concat(jobError ? "block" : "hidden")}>
                                                    {translatedMessage("application.job.required")}
                                                </small>
                                            </div>
                                        )} 
                                    */}
                                    {showLanguageOption &&
                                        <div className={"mb-2 ".concat(positions.length > 0 ? "col-12 md:col-6" : "col-12")}>
                                            <>
                                                <div className="filed-label">{translatedMessage('application.test.language')}</div>
                                                <div className="p-inputgroup">
                                                    <Dropdown id="languageTest" name="languageTest" value={application?.languageTest} options={languages} onChange={handleLanguageChange}
                                                        className={classNames({ 'p-invalid': languageError })} disabled={!canEdit} />
                                                </div>
                                                <small className={"p-error text-align-left mt-1 ".concat(languageError ? "block" : "hidden")}>
                                                    {translatedMessage("form.error.application.test.language.required")}
                                                </small>
                                            </>

                                        </div>
                                    }
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                                        <div className="filed-value">
                                            <span className={`w-min status status-application-${application?.status.toString().toLowerCase()}`}>
                                                {translatedMessage('ApplicationStatus.' + application?.status)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('application.registrationDate')}</div>
                                        <div className="filed-value">{GeneralUtils.formatDate(application?.registrationDate)}</div>
                                    </div>

                                    {/* Accessibility */}
                                    <div className="col-12 mt-3">
                                        <h5 className="my-1 font-weight-normal">{translatedMessage('profile.accessibility')}</h5>
                                        <div className='w-full text-align-left mt-2'>
                                            {translatedMessage("application.accessibility.info") + " " + translatedMessage("application.accessibility.info.linkText") + " "}
                                            <Link to={"/my-accessibility"}>
                                                <span className='font-bold underline'>{translatedMessage('generic.here').toString().toUpperCase()}</span>
                                            </Link>
                                            {"."}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className='pb-2'>
                                            <div className="flex align-items-center">
                                                <RadioButton
                                                    id="accessibilityYes"
                                                    name="accessibilityRadio"
                                                    value={true}
                                                    onChange={(e) => onAccessibilityNeedChange(e)}
                                                    checked={accessibilityNeed}
                                                    disabled={!canEdit || !application?.profileAccessibility}
                                                />
                                                <label htmlFor="accessibilityYes" className="ml-1">{translatedMessage("application.accessibility.yes.text")}</label>
                                                <div>
                                                    <Button className="pcn-button-slim p-button-text ml-2" onClick={handleAccessibilityAdaptation}
                                                        icon="pi pi-pencil"
                                                        label={translatedMessage(canEdit ? 'application.accessibility.yes.button.edit' : 'application.accessibility.yes.button.view')}
                                                        disabled={!accessibilityNeed}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-2'>
                                            <div className="flex align-items-center">
                                                <RadioButton
                                                    id="accessibilityNo"
                                                    name="accessibilityRadio"
                                                    value={false}
                                                    onChange={(e) => onAccessibilityNeedChange(e)}
                                                    checked={!accessibilityNeed}
                                                    disabled={!canEdit || !application?.profileAccessibility}
                                                />
                                                <label htmlFor="accessibilityNo" className="ml-1">{translatedMessage("application.accessibility.no.text")}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Competition */}
                                    <div className="col-12 mt-3">
                                        <h5 className="mb-1 font-weight-normal">{translatedMessage('competition.details')}</h5>
                                    </div>
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('competition.institution')}</div>
                                        <div className='filed-value'>{application?.competition.institution?.name ? application?.competition.institution.name : "-"}</div>
                                    </div>
                                    {CompetitionUtils.isNationalCompetition(application?.competition.type) &&
                                        <>
                                            <div className="col-12 md:col-6">
                                                <div className="filed-label">{translatedMessage('competition.positionCategory')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionCategory." + application?.competition.positionCategory)}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="filed-label">{translatedMessage('competition.positionGrade')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionGrade." + application?.competition.positionGrade)}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="filed-label">{translatedMessage('competition.positionType')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionType." + application?.competition.positionType)}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {CompetitionUtils.isJobCompetition(application?.competition.type) && application?.competition.positionCategory &&
                                        <>
                                            <div className="col-12">
                                                <div className="filed-label">{translatedMessage('competition.positionCategory')}</div>
                                                <div className="filed-value">
                                                    {translatedMessage("PositionCategory." + application?.competition.positionCategory)}
                                                </div>
                                            </div>                                            
                                        </>
                                    }
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.submitEndDate')}</div>
                                        <div className="filed-value">{GeneralUtils.formatDate(application?.competition.submitEndDate)}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="filed-label">{translatedMessage('generic.status')}</div>
                                        <div className="filed-value">
                                            <span className={`w-min status status-competition-${application?.competition.status.toString().toLowerCase()}`}>
                                                {translatedMessage('CompetitionStatus.' + application?.competition.status)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.clarificationInterval')}</div>
                                        <div className="filed-value">
                                            {application?.competition.clarificationInterval} {translatedMessage('generic.days')}
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="filed-label">{translatedMessage('competition.contestationInterval')}</div>
                                        <div className="filed-value">
                                            {application?.competition.contestationInterval} {translatedMessage('generic.days')}
                                        </div>
                                    </div>
                                    {CompetitionUtils.isJobCompetition(application?.competition.type) &&
                                        <>
                                            <div className='col-12 md:col-6'>
                                                <div className='filed-label'>{translatedMessage('competition.writtenTestDate')}</div>
                                                <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition.writtenTestDate)}</div>
                                            </div>
                                            <div className='col-12 md:col-6'>
                                                <div className='filed-label'>{translatedMessage('competition.interviewTestDate')}</div>
                                                <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition.interviewTestDate)}</div>
                                            </div>

                                            {application?.competition?.supplementaryTest1Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest1Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest1Date)}</div>
                                                    </div>
                                                </>
                                            }

                                            {application?.competition?.supplementaryTest2Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest2Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest2Date)}</div>
                                                    </div>
                                                </>
                                            }
                                            {application?.competition?.supplementaryTest3Name &&
                                                <>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Name')}</div>
                                                        <div className='filed-value'>{application?.competition?.supplementaryTest3Name}</div>
                                                    </div>
                                                    <div className='col-12 md:col-6'>
                                                        <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Date')}</div>
                                                        <div className='filed-value'>{GeneralUtils.formatDateTime(application?.competition?.supplementaryTest3Date)}</div>
                                                    </div>
                                                </>
                                            }
                                            {jobs.length > 0 &&
                                            <div className='col-12 mb-3'>
                                                <div className='filed-label mt-3 mb-2'>{translatedMessage('job.paragraphTitle')}</div>
                                                <DataTable
                                                    ref={dt}
                                                    value={jobs}
                                                    dataKey="id"
                                                    paginator
                                                    rows={DataTableUtils.defalRowsPerPage()}
                                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                                    className="datatable-responsive pcn-datatable"
                                                    responsiveLayout="scroll"
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                >
                                                    <Column
                                                        field="position"
                                                        header={translatedMessage('job.position')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="jobClass"
                                                        header={translatedMessage('generic.details')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobDetails}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="institution.name"
                                                        header={translatedMessage('job.location')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobLocation}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                </DataTable>
                                            </div>
                                        }
                                        </>
                                    }
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                        <div className='filed-value'>
                                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(application?.competition.description) }} />
                                        </div>
                                    </div>
                                    {CompetitionUtils.isJobCompetition(application?.competition?.type) && application?.competition?.bibliography &&
                                        <div className='col-12'>
                                            <div className='filed-label'>{translatedMessage('competition.bibliography')}</div>
                                            <div className='filed-value'>
                                                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(application.competition.bibliography) }} />
                                            </div>
                                        </div>
                                    }
                                    {applicationResults.length > 0 &&
                                        <div className="col-12 lg:col-6 mt-3">
                                            <h5>{translatedMessage('competition.results')}</h5>
                                            <ApplicationResults
                                                results={applicationResults}
                                                contestations={contestations}
                                                onCreateContestation={onCreateContestation}
                                                viewLink={`/application/${applicationIdParam}/contestation/result/`}
                                                testLink={`/application/${applicationIdParam}/tests`}
                                                isJobCompetition={isJobCompetition}
                                            />
                                        </div>
                                    }
                                </div>
                            )}

                            {section.code === 'EDUCATION' && <ApplicationEducation application={application} formFields={applicationFormFields} updateFields={canEdit} />}

                            {section.code === 'EXPERIENCE' && <ApplicationWorkExperience application={application} formFields={applicationFormFields} updateFields={canEdit} />}

                            {section.code === 'IT' && <ApplicationITKnowledge application={application} formFields={applicationFormFields} updateFields={canEdit} />}

                            {section.code === 'LANGUAGE' && <ApplicationLanguage application={application} formFields={applicationFormFields} updateFields={canEdit} />}

                            {section.code === 'FORM' && <MyApplicationForm application={application} updateFields={canEdit} />}

                            {section.code === 'FILE' && (application?.status === 'REGISTERED' || application?.status === 'APPLIED') &&
                                <StorageFolderFileList
                                    folderId={application?.profileFolderId}
                                    folderName={application?.competition.name}
                                    applicationId={application?.id} 
                                    showUploadButton={false}
                                    pageTitle={translatedMessage('application.files')}
                                    pageSubTitle={translatedMessage('application.file.info')}
                                    pageInfo={translatedMessage('application.profile.file.details')}
                                    pageInfoAddFiles={translatedMessage('application.profile.file.getFromProfile.info')}
                                    readOnly={true}
                                    userApplicationDocFolder={true}
                                    updateFiles={canEdit}
                                />
                            }
                            {section.code === 'FILE' && application?.status !== 'REGISTERED' && application?.status !== 'APPLIED' &&
                                <StorageFolderFileList
                                    folderId={application?.folder?.id}
                                    folderName={application?.competition.name}
                                    applicationId={application?.id} 
                                    showUploadButton={false}
                                    pageTitle={translatedMessage('application.files')}
                                    pageSubTitle={translatedMessage('application.file.info')}
                                    pageInfo={translatedMessage('application.profile.file.details')}
                                    readOnly={true}
                                    userApplicationDocFolder={false}
                                    updateFiles={false}
                                />
                            }
                        </div>
                    </div>
                </div>

                {showAccessibilityDialog &&
                    <ApplicationAccessibilityDialog
                        visible={showAccessibilityDialog}
                        applicationId={application?.id}
                        onHide={(value, saveSuccess) => onAccessibilityDialogClose(value, saveSuccess)}
                    />
                }

                {showValidationErrorDialog &&
                    <ApplicationValidationErrorModalComponent
                        visible={showValidationErrorDialog}
                        errors={validationErrors}
                        closeDialog={() => setShowValidationErrorDialog(false)}
                    />
                }
            </>
        );
    }
};

export default MyApplicationPage;
