import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormFieldService } from '../../service/FormFieldService';
import { translatedMessage } from '../../service/LanguageService';
import useAuth from '../../hooks/useAuth';
import DataTableUtils from '../../utilities/DataTableUtils';
import DeleteModalComponent from '../DeleteModalComponent';
import ViewFormFieldDialog from './ViewFormFieldDialog';
import EditFormFieldDialog from './EditFormFieldDialog';
import FormViewDialog from './FormViewDialog';
import { Toast } from 'primereact/toast';

const FormFieldListComponent = (props) => {
    const [form, setForm] = useState({});
    const [formFields, setFormFields] = useState([]);
    const [selectedFormField, setSelectedFormField] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [viewFormDialogVisible, setViewFormDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [allFields, setAllFields] = useState([]);
    const [availableFields, setAvailableFields] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const formFieldService = useMemo(() => new FormFieldService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getFormFields = async () => {
            await formFieldService.getFormFieldList(props.form.id)
                .then(async (fields) => {
                    fields.map(item => {
                        item.field.completeName = setCompleteName(item.field)
                        return item
                    })
                    setFormFields(fields);                    
                    await getAllFields()
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getAllFields = async () => {
            await formFieldService.getFieldList()
                .then((allFields) => {
                    allFields.map(item => {
                        item.completeName = setCompleteName(item)
                        return item
                    })

                    setAllFields(allFields)
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        setForm(props.form)
        getFormFields()        
    }, [formFieldService, props.form]);

    const updateList = async (savedField, isNew) => {
        let updatedFormFields = DataTableUtils.updateTableList(formFields, savedField, isNew)

        setFormFields(updatedFormFields);
        populateAvailableFields(updatedFormFields)
    };

    const updateFormFieldsOrder = async (_orderedFormFields) => {
        setIsLoading(true);
        await formFieldService.updateFormFieldsOrder(_orderedFormFields)
            .then(_newFormFields => {
                setFormFields(_newFormFields);
                setIsLoading(false);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    }

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const populateAvailableFields = (fields) => {
        let _availableFields = allFields.filter(item => {
            let code = item.code;
            let usedFF = fields.find(ff => ff.field.code === code);
            return !usedFF;
        });
        setAvailableFields(_availableFields);
    };

    const setCompleteName = (field) => {
        return field.id
            ? (field.label + ' (' + field.code + ') : ' + translatedMessage('FieldType.' + field.type))
            : '';
    };

    const showNewDialog = () => {
        populateAvailableFields(formFields);
        setSelectedFormField(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (field) => {
        populateAvailableFields(formFields);
        setSelectedFormField(field);
        setEditDialogVisible(true);
    };

    const hideEditDialog = () => {
        setSelectedFormField(null);
        setEditDialogVisible(false);
    };

    const showViewDialog = (field) => {
        setSelectedFormField(field);
        setViewDialogVisible(true);
    };

    const hideViewDialog = () => {
        setSelectedFormField(null);
        setViewDialogVisible(false);
    };

    const showDeleteDialog = (field) => {
        setSelectedFormField(field);
        setDeleteDialogVisible(true);
    };

    const deleteField = () => {
        formFieldService.deleteFormField(selectedFormField.id)
            .then(() => {
                let _formFields = formFields.filter(item => item.id !== selectedFormField.id)
                setFormFields(_formFields)
                populateAvailableFields(_formFields)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedFormField(null);
            });
    };

    const leftToolbarTemplate = () => {
        let _disableAdd = formFields?.length === allFields?.length;
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={translatedMessage('competition.viewForm')} icon="pi pi-eye" className="p-button-primary mr-2"
                        onClick={() => setViewFormDialogVisible(true)}/>
                    {
                        hasPermission('COMPETITION_CREATE') &&
                        <Button label={translatedMessage('form.field.newFormField')} icon="pi pi-plus" className="p-button-primary mr-2"
                            onClick={showNewDialog} disabled={_disableAdd} />
                    }
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={translatedMessage('generic.export')} icon="pi pi-download" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {(hasPermission('COMPETITION_VIEW')  || props?.hasEditPermission) &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {(hasPermission('COMPETITION_EDIT') ||  props?.hasEditPermission) &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    const typeBodyTemplate = (rowData) => {
        return <>{translatedMessage('FieldType.' + rowData.field.type)}</>;
    };

    const valueBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.requestedValue
                    ? rowData.field?.type === 'BOOLEAN'
                        ? translatedMessage("generic." + rowData.requestedValue.toLowerCase())
                        : rowData.requestedValue
                    : (rowData.entityLookupLabel ? rowData.entityLookupLabel : '')}
            </>
        );
    };

    const onRowReorder = (e) => {
        setFormFields(e.value);
        updateFormFieldsOrder(e.value);
        toast.current.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Toolbar className="px-0 pt-0" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable
                    ref={dt}
                    value={formFields}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    globalFilter={globalFilter}
                    onFilter={doOnFilter}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    header={header}
                    responsiveLayout="stack"
                    reorderableRows onRowReorder={onRowReorder}
                    loading={isLoading}
                >
                    <Column rowReorder style={{ width: '2em' }} />
                    <Column
                        field="fieldOrder"
                        header={translatedMessage('form.field.fieldOrder')}
                        sortable
                        headerStyle={{ width: '5%', minWidth: '6rem' }}
                    />
                    <Column
                        field="field.code"
                        header={translatedMessage('generic.code')}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                    />
                    <Column
                        field="field.type"
                        header={translatedMessage('generic.type')}
                        sortable
                        body={typeBodyTemplate}
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                    />
                    <Column
                        field="requestedValue"
                        header={translatedMessage('form.field.requestedValue')}
                        sortable
                        body={valueBodyTemplate}
                        headerStyle={{ width: '25%', minWidth: '8rem' }}
                    />
                    <Column
                        field="mandatory"
                        header={translatedMessage('form.field.mandatory')}
                        sortable
                        body={(e) => e.mandatory ? translatedMessage("generic.yes") : translatedMessage("generic.no")}
                        headerStyle={{ width: '10%', minWidth: '5rem' }}
                    />
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <EditFormFieldDialog
                    formField={selectedFormField}
                    form={form}
                    visible={editDialogVisible}
                    afterSave={updateList}
                    visibleSetter={hideEditDialog}
                    availableFields={availableFields}
                />

                <DeleteModalComponent
                    visible={deleteDialogVisible}
                    item={selectedFormField?.field?.code}
                    closeDialog={() => { setDeleteDialogVisible(false) }}
                    deleteRecord={() => deleteField()}
                />

                <ViewFormFieldDialog
                    visible={viewDialogVisible}
                    formField={selectedFormField}
                    closeDialog={hideViewDialog} />

                <FormViewDialog
                    formFields={formFields}
                    visible={viewFormDialogVisible}
                    closeDialog={() => { setViewFormDialogVisible(false) }}
                />
            </>
        );
    }
};

export default FormFieldListComponent;
