import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../../service/LanguageService';
import { UserService } from '../../../service/UserService';

import { Toast } from 'primereact/toast';

import { ProgressSpinner } from 'primereact/progressspinner';
import ITKnowledgeComponent from '../../../components/profile/ITKnowledgeComponent';
import useAuth from '../../../hooks/useAuth';
import FieldEvaluationDropdownComponent from '../../../components/evaluation/FieldEvaluationDropdownComponent';

const ApplicationITKnowledge = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [itKnowledge, setItKnowledge] = useState({});
    const [pageTitle, setPageTitle] = useState(translatedMessage("profile.itKnowledge"));
    const [pageSubTitle, setPageSubTitle] = useState(null);
    const [educationRequirement, setEducationRequirement] = useState('');
    const [fieldEvaluation, setFieldEvaluation] = useState(null);

    const toast = useRef(null);
    const { auth } = useAuth();

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getITRequirement = () => {
            if (props?.formFields && props?.formFields.length > 0) {
                props.formFields.forEach(item => {
                    if (item.formField.field.code === 'cunostinteIT') {
                        setPageTitle(item.formField.field.label)
                        setPageSubTitle(item.formField.field.preDescription)
                        setEducationRequirement(item.formField.requestedValue)
                    }
                })
            }
        }

        const getEvaluation = () => {
            if (props?.isForEvaluation && props?.evaluation?.fieldsEvaluation) {
                props?.evaluation?.fieldsEvaluation.forEach(item => {
                    if (item.applicationFormField.formField.field.code === 'cunostinteIT') {
                        setFieldEvaluation(item)
                    }
                })
            }
        }

        const getData = async () => {
            if (props?.updateFields) {
                await userService.getItKnowledge()
                    .then(response => {
                        setItKnowledge(response)
                        setIsLoading(false)
                    })
                    .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
            } else {
                setItKnowledge({description: props?.application?.itKnowledge, folderId: props?.application?.itFolder?.id})
                setIsLoading(false)
            }
        }

        getITRequirement()
        getEvaluation()
        getData()
    }, [userService, props]);

    const onFieldEvaluationChange = (applicationFieldEvaluationId, comment, status) => {
        let _fieldEvaluation = fieldEvaluation
        _fieldEvaluation.status = status;
        _fieldEvaluation.comment = comment;

        setFieldEvaluation({..._fieldEvaluation})
    };

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        let canBeEdited = props?.isCommitteeActive && fieldEvaluation?.evaluation?.evaluationCommitteeUser?.user?.id === auth.user.id && !props?.viewOnly

        return (
            <>
                <Toast ref={toast}></Toast>

                <div className='w-full text-align-left mb-0'>
                    <h5 className='mb-1'>{pageTitle}</h5>
                    {pageSubTitle &&
                        <div className='mb-1 font-italic'>{pageSubTitle}</div>
                    }

                    <div className='mt-2'>
                        <span className='font-bold'>{translatedMessage("form.field.requestedValue.description") + " "}</span>
                        {educationRequirement ? educationRequirement : "-"}
                    </div>

                    {props?.isForEvaluation &&
                        <>
                            <h5 className='mb-1'>{translatedMessage("evaluation.evaluation")}</h5>
                            <div className="w-full grid pcn-data-view-panel mb-4">
                                <div className='col-12 pb-0 p-fluid'>
                                    <div className="filed-label">{translatedMessage('generic.status')}</div>
                                    <FieldEvaluationDropdownComponent
                                        applicationFieldEvaluation={fieldEvaluation}
                                        afterSave={onFieldEvaluationChange}
                                        statusOptions={props?.statusOptions}
                                        canBeEdited={canBeEdited}
                                    />                                    
                                </div>
                            </div>
                        </>
                    }
                </div>

                <ITKnowledgeComponent itKnowledge={itKnowledge} isForApplication={true} updateFields={props?.updateFields} />
            </>
        );
    }
};

export default ApplicationITKnowledge;
