import React, { useState, useEffect, useRef, useMemo } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { confirmDialog } from 'primereact/confirmdialog';
import { translatedMessage } from '../../service/LanguageService';
import { NotificationService } from '../../service/NotificationService';
import GeneralUtils from '../../utilities/GeneralUtils';

const emptyNotificationData = {
    location: '',
    date: ''
};

const SendAdvancedTestNotificationDialog = (props) => {
    const [notificationData, setNotificationData] = useState(emptyNotificationData);
    const [dialogVisible, setDialogVisible] = useState(false);

    const notificationService = useMemo(() => new NotificationService(), []);

    const toast = useRef(null);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.onHide === 'function') {
            props.onHide();
        }
        setDialogVisible(false);
    };

    const confirmSendNotification = (data) => {
        confirmDialog({
            message: translatedMessage('notification.advancedTest.send.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => sendNotification(data)
        });
    };

    const sendNotification = (data) => {
        notificationService
            .sendAdvancedTestNotification(props.applicationView?.applicationId, data.location, GeneralUtils.formatDateTime(data.date))
            .then((result) => {
                if (typeof props.onSend === 'function') {
                    props.onSend(props.applicationView?.applicationId);
                }
                setNotificationData(emptyNotificationData);
                formik.resetForm();
                toast.current.show({ severity: 'success', summary: translatedMessage('notification.advancedTest.send.success') });
                hideDialog();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: notificationData,
        validate: (data) => {
            let errors = {};

            if (!data.location) {
                errors.location = translatedMessage('notification.advancedTest.location.required');
            }
            if (!data.date) {
                errors.date = translatedMessage('notification.advancedTest.date.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            confirmSendNotification(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.send')} icon="pi pi-save" className="p-button-text" form="committee-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} style={{ width: '450px' }} header={translatedMessage('notification.details')} modal className="pcn-dialog p-fluid" footer={dialogFooter} onHide={hideDialog}>
                <div>
                    {translatedMessage('notification.advancedTest.send')}
                    <span className="font-bold">{' ' + props?.applicationView?.fullName}</span>

                    <form id="committee-form" onSubmit={formik.handleSubmit}>
                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <InputTextarea
                                    type="text"
                                    id="location"
                                    name="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('location') })}
                                    rows={5}
                                    cols={30}
                                    autoResize
                                />
                                <label htmlFor="location" className={classNames({ 'p-error': isFormFieldValid('location') })}>
                                    {translatedMessage('notification.advancedTest.location')}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('location')}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Calendar
                                    id="date"
                                    name="date"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    dateFormat="dd-mm-yy"
                                    showTime
                                    hourFormat="24"
                                    stepMinute={10}
                                    className={classNames({ 'p-invalid': isFormFieldValid('date') })}
                                />
                                <label htmlFor="date" className={classNames({ 'p-error': isFormFieldValid('date') })}>
                                    {translatedMessage('notification.advancedTest.date')}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('date')}
                    </form>
                </div>
            </Dialog>
        </>
    );
};

export default SendAdvancedTestNotificationDialog;
