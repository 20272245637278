import React, { useState, useEffect, useRef, useMemo } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import GeneralUtils from '../../utilities/GeneralUtils';

const ApplicationEvaluationDialog = (props) => {
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState('');
    const [applicationEvaluationId, setApplicationEvaluationId] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);

    const evaluationService = useMemo(() => new EvaluationService(), []);

    const toast = useRef(null);

    useEffect(() => {
        if (props.applicationEvaluation) {
            setComment(props.applicationEvaluation.comment);
            setStatus(props.applicationEvaluation.status);
            setApplicationEvaluationId(props.applicationEvaluation.id);
        }
        setDialogVisible(props.visible);
    }, [props]);

    const saveEvaluation = () => {
        if (applicationEvaluationId) {
            if (status === 'REJECTED' && (!comment || !GeneralUtils.nullIfEmptyHTMLEditor(comment))) {
                toast.current.show({ severity: 'error', summary: translatedMessage('form.error.comment.required'), life: 5000 });
            } else {
                evaluationService
                    .updateEvaluation(applicationEvaluationId, status, comment)
                    .then((updatedEvaluation) => {
                        toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success'), life: 3000 });
                        if (typeof props.afterSave === 'function') {
                            props.afterSave(applicationEvaluationId, comment, status, updatedEvaluation);
                        }
                        hideDialog();
                    })
                    .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
            }
        }
    };

    const hideDialog = () => {
        setDialogVisible(false);
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
    };

    const dialogHeader = (
        <>
            {translatedMessage('evaluation.evaluation')} {translatedMessage('generic.for')}
            {' ' + props?.applicationEvaluation?.application?.user?.firstName + ' ' + props?.applicationEvaluation?.application?.user?.lastName + ' (' + props?.applicationEvaluation?.application?.user?.username + ')'}
        </>
    );

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" onClick={saveEvaluation} />
        </>
    );

    const resultTemplate = (option) => {
        return (
            <div>
                <span className={`status status-evaluation-${option?.value?.toString().toLowerCase()}`}>{translatedMessage('EvaluationStatus.' + option?.value)}</span>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog visible={dialogVisible} header={dialogHeader} modal className="pcn-dialog p-fluid" footer={dialogFooter} onHide={hideDialog}>
                <div>
                    {translatedMessage('evaluation.comment')}:<br />
                    <InputTextarea rows={3} cols={30} value={comment || ''} onChange={(event) => setComment(event.target.value)} autoResize />
                    <br />
                    <br />
                    {translatedMessage('evaluation.status')}:<br />
                    <Dropdown 
                        value={status} 
                        options={props.statusOptions} 
                        onChange={(e) => setStatus(e.value)} 
                        itemTemplate={resultTemplate} 
                        valueTemplate={resultTemplate} 
                        disabled={!props?.applicationEvaluation?.evaluationCommitteeUser?.evaluationCommittee?.active || props?.readOnly}/>
                </div>
            </Dialog>
        </>
    );
};

export default ApplicationEvaluationDialog;
