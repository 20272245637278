import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { translatedMessage } from '../../../service/LanguageService';

const TestCenterViewDialog = (props) => {

    const testDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );


    return (
        <>
            <Dialog
                visible={props.visible}
                header={translatedMessage('testCenter.details')}
                modal
                onHide={() => props.closeDialog()}
                className="pcn-dialog p-fluid"
                footer={testDialogFooter}
            >
                <div className='w-full grid pcn-data-view-panel'>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                        <div className='filed-value'>{props?.testCenter?.name}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.region')}</div>
                        <div className='filed-value'>{props?.testCenter?.region.labelRo}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.county')}</div>
                        <div className='filed-value'>{props?.testCenter?.county.labelRo}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.city')}</div>
                        <div className='filed-value'>{props?.testCenter?.city}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('generic.address')}</div>
                        <div className='filed-value'>{props?.testCenter?.address}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.email')}</div>
                        <div className='filed-value'>{props?.testCenter?.email}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.phone')}</div>
                        <div className='filed-value'>{props?.testCenter?.phone}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('testCenter.capacity')}</div>
                        <div className='filed-value'>{props?.testCenter?.capacity}</div>
                    </div>
                    <div className='col-12 md:col-6'>
                        <div className='filed-label'>{translatedMessage('generic.status')}</div>
                        <div className='filed-value'>{translatedMessage('TestCenterStatus.' + props?.testCenter?.status)}</div>
                    </div>                              
                </div>
            </Dialog>
        </>
    );
};

export default TestCenterViewDialog;
