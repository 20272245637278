import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { UserService } from '../service/UserService';
import { translatedMessage } from '../service/LanguageService';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import DataTableUtils from '../utilities/DataTableUtils';
import EnumService from '../service/EnumService';
import GeneralUtils from '../utilities/GeneralUtils';
import InstitutionDataTableUtils from '../utilities/InstitutionDataTableUtils';

const SelectInstitutionDialog = (props) => {
    const [visible, setVisible] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [institutionTypes, setInstitutionTypes] = useState([]);
    const [counties, setCounties] = useState([]);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: DataTableUtils.defalRowsPerPage(),
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            name: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: null, matchMode: FilterMatchMode.IN },
            status: { value: null, matchMode: FilterMatchMode.IN },
            county: { value: null, matchMode: FilterMatchMode.IN },
            locality: { value: null, matchMode: FilterMatchMode.CONTAINS },
            cif: { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    });
    const [lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession] = useState(false);
    const propToColumnMap = {
        name: 'name',
        type: 'type_id',
        status: 'status',
        county: 'county_id',
        locality: 'locality',
        cif: 'cif'
    };
    const [totalRecords, setTotalRecords] = useState(0);

    const [firstLoading, setFirstLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setFirstLoading(true);

        const setStaticValues = async () => {
            setInstitutionTypes(await EnumService.getEntityLookupByField('NOM:INSTITUTION_TYPE'));
            setCounties(await EnumService.getEntityLookupByField('NOM:COUNTY'));
        };

        setStaticValues();
        setFirstLoading(false);
    }, [userService]);

    useEffect(() => {
        loadInstitutions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const setCriteria = () => {
        GeneralUtils.processSessionFilterValues('InstitutionsFilter', lazyParams, lazyParamsUpdatedFromSession, setLazyParamsUpdatedFromSession);

        let criteria = {};
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'id';
        criteria.name = lazyParams.filters.name.value;
        criteria.statuses = lazyParams.filters.status.value;
        criteria.typeIds = lazyParams.filters.type.value;
        criteria.countyIds = lazyParams.filters.county.value;
        criteria.locality = lazyParams.filters.locality.value;
        criteria.cif = lazyParams.filters.cif.value;

        return criteria;
    };

    const loadInstitutions = async () => {
        setIsLoading(true);

        let criteria = setCriteria();
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;

        await userService
            .getInstitutionsByCriteria(criteria)
            .then((data) => {
                setInstitutions(data.institutions);
                setTotalRecords(data.totalCount);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const setChoosenInstitution = (institution) => {
        if (typeof props.onChooseInstitution === 'function') {
            props.onChooseInstitution(institution);
        }
        setVisible(false);
    };

    const dialogFooter = (
        <>
            <Button
                label={translatedMessage('generic.close')}
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setVisible(false);
                }}
                type="button"
            />
        </>
    );

    const actionBodyTemplate = (institution) => {
        return (
            <Button
                icon="pi pi-plus-circle"
                className="p-button-rounded p-button-info m-1"
                onClick={() => setChoosenInstitution(institution)}
                tooltip={translatedMessage('generic.add')}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                disabled={props.selectedInstitutions.findIndex((inst) => inst.id === institution.id) !== -1}
            />
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    const rightToolbarTemplate = () => {
        return (
            <Button
                label={translatedMessage('institution.select')}
                icon="pi pi-plus"
                className="p-button-primary mr-2"
                onClick={() => {
                    setVisible(true);
                }}
                type="button"
            />
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Toolbar left={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>
            <Dialog
                visible={visible}
                header={translatedMessage('institution.list.label')}
                modal
                className="pcn-dialog p-fluid"
                footer={dialogFooter}
                onHide={() => {
                    setVisible(false);
                }}
            >
                {firstLoading && (
                    <div className="w-full flex align-items-center">
                        <ProgressSpinner />
                    </div>
                )}
                {!firstLoading && (
                    <DataTable
                        ref={dt}
                        value={institutions}
                        dataKey="id"
                        paginator
                        lazy
                        totalRecords={totalRecords}
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}
                        onPage={onPage}
                        onSort={onSort}
                        onFilter={onFilter}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                        responsiveLayout="stack"
                        filterDisplay="row"
                        filters={lazyParams.filters}
                        loading={isLoading}
                    >
                        <Column field="name" header={translatedMessage('generic.name')} sortable headerStyle={{ width: '25%', minWidth: '10rem' }} filter showFilterMenu={false} />
                        <Column
                            field="type"
                            header={translatedMessage('generic.type')}
                            sortable
                            body={(e) => InstitutionDataTableUtils.typeBodyTemplate(e.type)}
                            headerStyle={{ width: '25%', minWidth: '12rem' }}
                            filter
                            filterElement={(e) => InstitutionDataTableUtils.typeFilterTemplate(e, institutionTypes)}
                            showFilterMenu={false}
                        />                        
                        <Column
                            field="county"
                            header={translatedMessage('generic.county')}
                            sortable
                            body={(e) => InstitutionDataTableUtils.countyBodyTemplate(e.county)}
                            headerStyle={{ width: '15%', minWidth: '8rem' }}
                            showFilterMenu={false}
                            filter
                            filterElement={(e) => InstitutionDataTableUtils.countyRowFilterTemplate(e, counties)}
                        />
                        <Column 
                            field="locality" 
                            header={translatedMessage('generic.locality')} 
                            sortable 
                            filter 
                            showFilterMenu={false} 
                            headerStyle={{ width: '15%', minWidth: '8rem' }}
                        />
                        <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }}></Column>
                    </DataTable>
                )}
            </Dialog>
        </>
    );
};

export default SelectInstitutionDialog;
