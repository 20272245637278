import React, { useState, useEffect, useRef, useMemo } from 'react';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';

import { ProgressSpinner } from 'primereact/progressspinner';
import EnumService from '../../service/EnumService';
import DeleteModalComponent from '../DeleteModalComponent';
import StorageFileUpload from '../file/StorageFileUpload';
import StorageFolderFileList from '../file/StorageFolderFileList';
import DataTableUtils from '../../utilities/DataTableUtils';
import WorkExperienceEditDialogComponent from './WorkExperienceEditDialogComponent';
import { ApplicationService } from '../../service/ApplicationService';
import ExportButtonComponent from '../ExportButtonComponent';

const tableHeader = [
    translatedMessage("profile.workExperience.organizationName"),
    translatedMessage("profile.workExperience.position"),
    translatedMessage("generic.startDate"),
    translatedMessage("generic.endDate"),
]

const WorkExperienceListComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [experienceList, setExperienceList] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [domainTypes, setDomainTypes] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);

    const [filters] = useState({
        'organizationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'position': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'domainId': { value: null, matchMode: FilterMatchMode.IN },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const userService = useMemo(() => new UserService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getDomain = async () => {
            const domainResponse = await EnumService.getEntityLookupByField("NOM:WORK_DOMAIN");
            setDomainTypes(domainResponse)
        }

        const getExperience = async () => {
            let _experienceList = props ? props.experience : []
            setExperienceList(_experienceList)

            if (props?.isForApplication && props?.updateFields && _experienceList && _experienceList.length > 0) {
                let _selectedRows = _experienceList.filter(item => item.applicationWorkExperienceId)
                setSelectedRows(_selectedRows)
            }

            setIsLoading(false)
        }

        const getFileUsages = async () => {
            setUsageLookup(await EnumService.getEntityLookupByField("NOM:FILE_USAGE"))
        }

        getDomain()
        getExperience()
        getFileUsages()
    }, [props]);


    const updateList = async (savedExpereince, isNew) => {
        let updatedExperienceList = DataTableUtils.updateTableList(experienceList, savedExpereince, isNew)

        setExperienceList(updatedExperienceList)
        props.onListUpdate(updatedExperienceList)

        if (props?.isForApplication && props?.application && isNew) {
            let _selectedRows = selectedRows ? selectedRows : []
            let _savedExpereince = savedExpereince
            _savedExpereince.applicationId = props?.application.id
            _savedExpereince.profileWorkExperienceId = savedExpereince.id
            _selectedRows.push(_savedExpereince)

            onRowSelect(_selectedRows)
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {(!props?.isForApplication || (props?.isForApplication && props?.updateFields)) && !props?.readOnly &&
                    <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => handleEdit(rowData)}
                            tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                        <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => handleUpload(rowData)}
                            tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                    </>
                }
                <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                    tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {!props?.isForApplication && !props?.readOnly &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => handleDelete(rowData)}
                        ooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const leftToolbarTemplate = () => {
        // if (!props?.isForApplication || (props?.isForApplication && props?.updateFields)) {
        if (!props?.isForApplication && !props?.readOnly) {
            return (
                <React.Fragment>
                    <div className="my-2">
                        <Button label={translatedMessage("generic.add")} icon="pi pi-plus" className="p-button-primary mr-2" onClick={() => handleNew()} />
                    </div>
                </React.Fragment>
            )
        }
    };

    const handleExport = () => {
        let exportData = experienceList.map(item => ({ ...item }))
        exportData.map(item => {
            let exportItem = item
            // exportItem.domain = domainBodyTemplate(item.domainId)
            delete exportItem.domainId
            delete exportItem.folderId
            delete exportItem.applicationWorkExperienceId
            delete exportItem.applicationId
            delete exportItem.profileWorkExperienceId

            return item
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        if (props?.hasExportButton) {
            return (
                <ExportButtonComponent
                    getExportData={handleExport}
                    header={tableHeader}
                    fileName={translatedMessage("profile.workExperience")}
                />
            )
        }
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    // const domainBodyTemplate = (id) => {
    //     const entityLookup = domainTypes.filter(element => element.value === id);
    //     return entityLookup[0] ? entityLookup[0].label : '';
    // }

    // Files dialog
    const dialogFilesFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    );

    // Actions
    const handleNew = () => {
        setSelectedValue(null)
        setShowAddDialog(true)
    }

    const handleEdit = (data) => {
        setSelectedValue(data)
        setShowAddDialog(true)
    }

    const handleDelete = (data) => {
        setSelectedValue(data)
        setShowDeleteDialog(true)
    }

    const deleteRecord = () => {
        userService.deleteWorkExperience(selectedValue.id)
            .then(() => {
                let _experienceList = experienceList.filter(item => item.id !== selectedValue.id)
                setExperienceList(_experienceList)
                setShowDeleteDialog(false);

                toast.current.show({ severity: 'success', summary: translatedMessage("generic.delete.success") });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
    };

    const handleUpload = (data) => {
        setSelectedValue(data)
        setShowUploadDialog(true)
    };

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleViewFiles = (data) => {
        setSelectedValue(data)
        setShowFilesDialog(true);
    }

    const onRowSelect = (data) => {
        if (props?.isForApplication && props?.application && props?.updateFields) {
            applicationService.saveApplicationExperience(props?.application.id, data)
                .then(() => {
                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.selection.save.success") })
                    getExperience()
                    setSelectedRows(data)
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    }

    const getExperience = async () => {
        await applicationService.getApplicationExperience(props?.application.id)
            .then(experienceRespone => setExperienceList(experienceRespone))
            .catch(error => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }))
    }

    // const domainFilterTemplate = (options) => {
    //     return <MultiSelect
    //         value={options.value}
    //         options={domainTypes}
    //         onChange={(e) => options.filterApplyCallback(e.value)}
    //         placeholder={translatedMessage('profile.workExperience.domain')}
    //         className="p-column-filter"
    //         maxSelectedLabels={1}
    //         showSelectAll={false}
    //         selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
    //     />;
    // }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast}></Toast>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="pl-0 pr-0"></Toolbar>

                <DataTable
                    ref={dt}
                    value={experienceList}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                    responsiveLayout="stack"
                    filterDisplay="row"
                    filters={filters}
                    globalFilterFields={['organizationName', 'position', 'domainId']}
                    selectionMode="checkbox"
                    selection={selectedRows}
                    onSelectionChange={e => props?.updateFields ? onRowSelect(e.value) : false}
                >
                    {props?.isForApplication && props?.updateFields &&
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    }
                    <Column
                        field="organizationName"
                        header={translatedMessage("profile.workExperience.organizationName")}
                        sortable
                        headerStyle={{ width: '40%', minWidth: '8rem' }}
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="position"
                        header={translatedMessage("profile.workExperience.position")}
                        sortable
                        headerStyle={{ width: '25%', minWidth: '8rem' }}
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="startDate"
                        header={translatedMessage("generic.interval")}
                        sortable
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        body={dateBodyTemplate}
                    />
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>

                <WorkExperienceEditDialogComponent
                    experience={selectedValue}
                    domainTypes={domainTypes}
                    visible={showAddDialog}
                    afterSave={updateList}
                    onClose={() => setShowAddDialog(false)}
                />

                <StorageFileUpload
                    entityId={selectedValue?.id}
                    folderId={selectedValue?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={false}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    addUsageOnUpload={true}
                />

                <DeleteModalComponent
                    visible={showDeleteDialog}
                    item={selectedValue?.organizationName}
                    closeDialog={() => setShowDeleteDialog(false)}
                    deleteRecord={() => deleteRecord()}
                />

                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={dialogFilesFooter}
                >
                    <StorageFolderFileList
                        folderId={selectedValue?.folderId}
                        folderName={selectedValue?.organizationName}
                        readOnly={!props?.updateFields}
                    />
                </Dialog>
            </>
        );
    }
};

export default WorkExperienceListComponent;
