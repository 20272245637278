import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { translatedMessage } from '../../../service/LanguageService';
import { CompetitionService } from '../../../service/CompetitionService';
import { ApplicationService } from '../../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTableUtils from '../../../utilities/DataTableUtils';
import GeneralUtils from '../../../utilities/GeneralUtils';

const ApplicationTestSessionDialog = (props) => {
    const [availableTestSessions, setAvailableTestSessions] = useState([]);
    const [applicationTestSession, setApplicationTestSession] = useState({});
    const [dialogVisible, setDialogVisible] = useState(false);
    const [testSessionDialogVisible, setTestSessionDialogVisible] = useState(false);
    const [testSessionDate, setTestSessionDate] = useState("");

    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    useEffect(() => {
        const getAvailableSessions = async (competitionId) => {
            if (competitionId) {
                await competitionService
                    .getAvailableSessions(competitionId)
                    .then((_testSessionsResult) => {
                        setAvailableTestSessions(_testSessionsResult);
                    })
                    .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
            }
        };
        getAvailableSessions(props?.applicationData?.application?.competition?.id);
    }, [competitionService, props.applicationData]);

    const assignTestSession = async (testSessionData) => {
        // console.log('application', props.applicationData.application);

        await applicationService
            .assignApplicationToTestSession(props.applicationData.application.id, testSessionData.testSessionId)
            .then((_applicationTestSession) => {
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') + '' });
                setApplicationTestSession(_applicationTestSession);
                setTestSessionDialogVisible(true);

                let _testSessionDate = GeneralUtils.formatDate(_applicationTestSession.testSession.startDate) + ", " +
                    GeneralUtils.formatTime(_applicationTestSession.testSession.startDate) + " - " +
                    GeneralUtils.formatTime(_applicationTestSession.testSession.endDate)
                setTestSessionDate(_testSessionDate)

                if (typeof props.onSave === 'function') {
                    props.onSave(_applicationTestSession);
                }
                hideDialog();
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const hideDialog = () => {
        props.onCancel();
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const prefferedTestCenterTemplate = (testSessionData) => {
        return (
            testSessionData.testSessionId === props.applicationData.chosenTestSession?.id
                ? (
                    <>
                        <Tooltip target=".custom-target-icon" />
                        <span style={{ color: 'green' }} className="custom-target-icon mr-1 " data-pr-tooltip={translatedMessage('application.testSession.preferred')}>
                            <FontAwesomeIcon icon="far fa-star" size="xl" beat />
                        </span>
                    </>
                )
                : ''
        );
    };

    const testCenterBody = (testSessionData) => {
        return (
            <>
                <div>{testSessionData.testCenterCounty}</div>
                <div>{testSessionData.testCenterCity}</div>
                <div>{testSessionData.testCenterName}</div>
            </>

        );
    }

    const actionBodyTemplate = (testSessionData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    icon="pi pi-check"
                    className="p-button-rounded p-button-info m-1"
                    onClick={() => assignTestSession(testSessionData)}
                    tooltip={translatedMessage('testSession.application.assignToTestSession')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={testSessionData?.testSessionId === props.applicationData?.testSession?.id}
                />
            </div>
        );
    };

    const testSessionBodyTemplate = (_testSessionData) => {
        return (
            <div>
                {_testSessionData.roomName}
                <div className="mt-1">
                    {GeneralUtils.formatDateTime(_testSessionData.testSessionStartDate) + ' - ' +
                        GeneralUtils.formatDateTime(_testSessionData.testSessionEndDate)}</div>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={dialogVisible}
                header={
                    <>
                        {props.applicationData.application?.user?.fullName} - {translatedMessage('testSession.assignToTestSession')}
                    </>
                }
                modal
                className="pcn-dialog p-fluid"
                footer={testCenterDialogFooter}
                onHide={hideDialog}
            >
                <DataTable
                    ref={dt}
                    value={availableTestSessions}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    responsiveLayout="stack"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                >
                    <Column field="testCenterName"
                        body={testCenterBody} header={translatedMessage('competition.testCenter.name')} sortable />
                    <Column
                        field="roomName"
                        header={translatedMessage('testSession.testSession')}
                        sortable
                        body={testSessionBodyTemplate}
                        headerStyle={{ width: '40%', minWidth: '25rem' }}
                    />
                    <Column field="availableCapacity" header={translatedMessage('testCenter.room.capacity.available')} />
                    <Column body={prefferedTestCenterTemplate} header={translatedMessage('testSession.preferredTestCenter')} />
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </Dialog>
            <Dialog
                visible={testSessionDialogVisible}
                style={{ width: '450px' }}
                header={applicationTestSession.application?.user?.fullName}
                modal
                className="pcn-dialog p-fluid"
                footer={
                    <Button
                        label={translatedMessage('generic.close')}
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={() => {
                            setTestSessionDialogVisible(false);
                        }}
                    />
                }
                onHide={() => setTestSessionDialogVisible(false)}
            >
                <>
                    <div>
                        {translatedMessage("competition.testSession.distribution.message.session",
                            applicationTestSession?.application?.fullName, testSessionDate
                        )}
                    </div>
                    <div>
                        {translatedMessage("competition.testSession.distribution.message.testCenter",
                            applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.name, applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.name
                        )}
                    </div>
                    <div>
                        {translatedMessage("competition.testCenter.address") + ": "}
                        {applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.address + ", "}
                        {applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.city + ", "}
                        {applicationTestSession?.testSession?.competitionTestCenterRoom?.testCenterRoom?.testCenter?.county?.label}
                    </div>
                </>
            </Dialog>
        </>
    );
};

export default ApplicationTestSessionDialog;
