import React from 'react';
import { translatedMessage } from "../service/LanguageService";
import { MultiSelect } from 'primereact/multiselect';
import DataTableUtils from './DataTableUtils';

const TestDataTableUtils = {

  verificationScaleStatusBodyTemplate(_status) {
    return (
      <div className={`inline-block status status-${_status.toLowerCase()}`}>{translatedMessage("TestVerificationScaleStatus." + _status)}</div>
    )
  },

  verificationScaleStatusFilterTemplate(options, statuses) {
    return <MultiSelect
      value={options.value}
      options={statuses}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder={translatedMessage('generic.status')}
      className="p-column-filter"
      maxSelectedLabels={1}
      showSelectAll={false}
      selectedItemsLabel={"{0} " + translatedMessage("multiselect.selectedItems")}
      selectedItemTemplate={DataTableUtils.filterSelectedItemTemplate(options.value, statuses, "generic.status")}
      itemTemplate={(e) => this.verificationScaleStatusBodyTemplate(e.value)}
    />
  },

};

export default TestDataTableUtils;