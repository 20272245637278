import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import DOMPurify from 'dompurify';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import { ProgressSpinner } from 'primereact/progressspinner';
import GeneralUtils from '../../utilities/GeneralUtils';
import ApiService from '../../service/ApiService';
import StorageFolderFileList from '../file/StorageFolderFileList';
import StorageFileUpload from '../file/StorageFileUpload';
import EnumService from '../../service/EnumService';

const defaultNewValue = {
    description: '',
    folderId: null
}

const ITKnowledgeComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [itKnowledge, setItKnowledge] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [usageLookup, setUsageLookup] = useState([]);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        setIsLoading(true)
        
        const getFileUsages = async () => {
            setUsageLookup(await EnumService.getEntityLookupByField("NOM:FILE_USAGE"))
        }

        let _itKnowledge = props ? props.itKnowledge : null
        setItKnowledge(_itKnowledge)
        getFileUsages()
        setIsLoading(false)
    }, [props]);

    const formik = useFormik({
        initialValues: itKnowledge ? itKnowledge : defaultNewValue,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            // if (!GeneralUtils.nullIfEmptyHTMLEditor(data.description)) {
            //     errors.description = translatedMessage("form.error.itKnowledge.required");;
            // }

            return errors;
        },
        onSubmit: async (data) => {
            if (!GeneralUtils.nullIfEmptyHTMLEditor(data.description)) {
                data.description = null
            }
            
            await userService.saveItKnowledge(data.description)
                .then((_itKnowledge) => {
                    setItKnowledge(_itKnowledge)
                    setShowEditDialog(false)

                    if (typeof props.afterSave === 'function') {
                        props.afterSave(_itKnowledge);
                    }

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const onEditorChange = (content) => {
        let _itKnowledge = itKnowledge
        _itKnowledge.description = content
        setItKnowledge(_itKnowledge)
        formik.setFieldValue("description", content)
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left mt-1">{formik.errors[name]}</small>;
    };

    const editDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => setShowEditDialog(false)} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="itKnowledge-form" type="submit" />
        </>
    );

    const filesDialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>

                <div className="flex flex-column align-items-center">
                    <div className="col-12 px-0">
                        <div className="input-panel flex flex-column mb-5">
                            {!props?.isForApplication &&
                                <label htmlFor="itKnowledge" className='mb-2'>{translatedMessage('profile.itKnowledge.info')}</label>
                            }

                            <div className="mt-3 font-bold">{translatedMessage('generic.details')}</div>

                            <div className="mb-1">
                                {(!props?.isForApplication || (props?.isForApplication && props?.updateFields)) && !props?.readOnly
                                    && <>
                                        <Button className="pcn-button-slim p-button-text" onClick={() => setShowEditDialog(true)} type="button">
                                            <div className='flex align-items-center'>
                                                <FontAwesomeIcon icon={'fa-solid '.concat(itKnowledge?.folderId ? 'fa-pencil' : 'fa-plus')} className="mr-1 " />
                                                <span>{translatedMessage(itKnowledge?.description ? 'generic.edit' : 'generic.add')}</span>
                                            </div>
                                        </Button>

                                        {itKnowledge?.folderId && !props?.isForApplication &&
                                            <Button className="pcn-button-slim p-button-text" onClick={() => setShowUploadDialog(true)} type="button">
                                                <div className='flex align-items-center'>
                                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" className="mr-1 " />
                                                    <span>{translatedMessage('generic.file.upload')}</span>
                                                </div>
                                            </Button>
                                        }
                                    </>
                                }
                                {itKnowledge?.folderId &&
                                    <Button className="pcn-button-slim p-button-text" onClick={() => setShowFilesDialog(true)} type="button">
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-regular fa-file' className="mr-1 " />
                                            <span>{translatedMessage('generic.files')}</span>
                                        </div>
                                    </Button>
                                }
                            </div>

                            {itKnowledge?.description &&
                                <div className="p-inputtext pcn-html-editor-view-container mb-3">
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(itKnowledge?.description) }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <Dialog
                    visible={showEditDialog}
                    header={translatedMessage('profile.itKnowledge')}
                    modal
                    resizable={false}
                    className="pcn-dialog p-fluid"
                    footer={editDialogFooter}
                    onHide={() => setShowEditDialog(false)}
                >
                    <form id="itKnowledge-form" onSubmit={formik.handleSubmit}>
                        <div className="input-panel flex flex-column mb-5">
                            <SunEditor
                                id="description" name="description"
                                lang={LanguageService.getCurrentLanguage}
                                height="350px"
                                width='100%'
                                setContents={formik.values.description}
                                setOptions={{
                                    buttonList: GeneralUtils.getSunEditorButtons(),
                                    font: GeneralUtils.getSunEditorFonts(),
                                    defaultStyle: GeneralUtils.getSunEditorDefaultStyle(),
                                    charCounter: ApiService.getIsProduction() ? true : false,
                                    maxCharCount: ApiService.getIsProduction() ? 10000 : null
                                }}
                                autoFocus={true}
                                onChange={(e) => onEditorChange(e)}
                            />
                            {getFormErrorMessage('itKnowledge')}
                        </div>
                    </form>
                </Dialog>


                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={filesDialogFooter}
                >
                    <StorageFolderFileList
                        folderId={itKnowledge?.folderId}
                        folderName={translatedMessage("profile.itKnowledge").toString().toLowerCase()}
                        readOnly={!props?.updateFields || !props?.readOnly}
                    />
                </Dialog>

                <StorageFileUpload
                    entityId={itKnowledge?.folderId}
                    folderId={itKnowledge?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={false}
                    accept={["pdf", "image"]}
                    visible={showUploadDialog}
                    visibleSetter={setShowUploadDialog}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                    usageLookup={usageLookup}
                    addUsageOnUpload={true}
                />
            </>
        );
    }
};

export default ITKnowledgeComponent;
