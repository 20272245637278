import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { translatedMessage } from '../../service/LanguageService';
import ContestationDialog from './ContestationDialog';
import DescriptionDisplayModalComponent from '../DescriptionDisplayModalComponent';
import { Message } from 'primereact/message';

const ApplicationResults = (props) => {
    const [result, setResult] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [contestationDialogVisible, setContestationDialogVisible] = useState(false);
    const [reasonDialogVisible, setReasonDialogVisible] = useState(false);
    const [commentDialogVisible, setCommentDialogVisible] = useState(false);
    const [showResultInfo, setShowResultInfo] = useState(false);

    const [selectedResult, setSelectedResult] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        props?.isJobCompetition && props?.results && 
            props?.results[0] && props?.results[0].application?.competition?.status === "FINISHED" 
                ? setShowResultInfo(true) 
                : setShowResultInfo(false)

        setIsLoading(false);
    }, [props]);

    const createContestation = (_result) => {
        setResult(_result);
        setContestationDialogVisible(true);
    };

    const contestationCreated = (savedContestation) => {
        if (typeof props.onCreateContestation === 'function') {
            props.onCreateContestation(savedContestation);
        }
    };

    const resultTypeTemplate = (_result) => {
        return (<>
            {props?.isJobCompetition && _result.type === 'COMPETITION_FINAL'
                ? translatedMessage("job.COMPETITION_FINAL")
                : translatedMessage('ResultType.' + _result.type)
            }
        </>);
    };

    const resultTemplate = (_result) => {
        return <span className={`status status-result-${_result.result.toString().toLowerCase()}`}>{translatedMessage('Result.' + _result.result)}</span>;
    };

    const viewReason = (_result) => {
        setSelectedResult(_result)
        setReasonDialogVisible(true)
    }

    const viewCommentAndFiles = (_result) => {
        setSelectedResult(_result)
        setCommentDialogVisible(true)
    }

    const resultActionTemplate = (_result) => {
        return (
            <>
                {_result?.result === 'REJECTED' && _result?.rejectionReason &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => viewReason(_result)}
                        tooltip={translatedMessage('application.result.rejectionReason.view')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                }
                {_result?.comment &&
                    <Button
                        icon="pi pi-eye"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => viewCommentAndFiles(_result)}
                        tooltip={translatedMessage('application.result.comment.view')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    />
                }
                {_result?.type === "PRELIMINARY_TEST" &&
                    <Button
                        className="p-button-rounded p-button-primary p-button-icon-only m-1"
                        onClick={() => navigate(props?.testLink ? props?.testLink : `#`)}
                        tooltip={translatedMessage('test.tests')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    >
                        <FontAwesomeIcon icon="fas fa-list-check" />
                    </Button>
                }
                <Button
                    className="p-button-rounded p-button-primary p-button-icon-only m-1"
                    onClick={() => navigate(props?.viewLink ? props?.viewLink + _result.id : `#`)}
                    tooltip={translatedMessage('application.contestation.view')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    visible={props.contestations.filter((contestation) => contestation.applicationResult.id === _result.id).length > 0}
                >
                    <FontAwesomeIcon icon="far fa-hand-peace" />
                </Button>
                {_result.application?.user.id === auth.user.id &&
                    <Button
                        icon="pi pi-plus"
                        className="p-button-rounded p-button-primary m-1"
                        onClick={() => createContestation(_result)}
                        tooltip={translatedMessage('application.contestation.request.create')}
                        tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        visible={_result.canBeContested && props.contestations.filter((contestation) => contestation.applicationResult.id === _result.id).length === 0}
                    />
                }
            </>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <DataTable ref={dt} value={props.results} dataKey="id" className="datatable-responsive pcn-datatable" responsiveLayout="stack" emptyMessage={translatedMessage('generic.tableEmptyMessage')}>
                    <Column
                        header={translatedMessage('application.result.type')}
                        headerStyle={{ width: '40%', minWidth: '10rem' }}
                        body={resultTypeTemplate}
                    />
                    <Column
                        header={translatedMessage('application.result')}
                        headerStyle={{ width: '10%', minWidth: '6rem' }}
                        body={resultTemplate}
                    />
                    <Column
                        header={translatedMessage('application.result.score')}
                        headerStyle={{ width: '10%', minWidth: '3rem' }}
                        body={(e) => e.score ? e.score : "-"}
                        hidden={!props?.isJobCompetition}
                    />
                    <Column
                        headerStyle={{ width: '20%', minWidth: '8rem' }}
                        alignHeader='right'
                        body={resultActionTemplate}
                        bodyStyle={{ textAlign: 'right' }}
                    />
                </DataTable>
                {showResultInfo &&
                    <div className='mt-3 flex align-items-center'>                        
                        <Message severity="warn" text={translatedMessage("job.COMPETITION_FINAL.info")} />
                    </div>
                }

                <ContestationDialog
                    result={result}
                    type="REQUEST"
                    visible={contestationDialogVisible}
                    onHide={() => setContestationDialogVisible(false)}
                    afterSave={contestationCreated}
                />

                <DescriptionDisplayModalComponent
                    dialogTitle={"application.result.rejectionReason"}
                    dialogInfo={selectedResult?.rejectionReason}
                    visible={reasonDialogVisible}
                    closeDialog={() => setReasonDialogVisible(false)}
                />

                <DescriptionDisplayModalComponent
                    dialogTitle={"application.result.comment"}
                    dialogInfo={selectedResult?.comment}
                    folder={selectedResult?.folder}
                    visible={commentDialogVisible}
                    closeDialog={() => setCommentDialogVisible(false)}
                />
            </>
        );
    }
};

export default ApplicationResults;
