import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import EnumService from '../../service/EnumService';
import { CompetitionService } from '../../service/CompetitionService';

const FinalReportCompetitionEditDialog = (props) => {    
    const [finalReport, setFinalReport] = useState({});
    const [statusValues, setStatusValues] = useState([]);

    const toast = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        const getEnumValues = async () => {
            const response = await EnumService.getEnumByName("CompetitionFinalReportStatus");
            setStatusValues(response)
        }

        getEnumValues();
    }, []);

    useEffect(() => {
        setFinalReport(props?.value ? props?.value : {});
    }, [props]);

    const hideDialog = () => {
        if (typeof props.onHide === 'function') {
            props.onHide()
        }
        setFinalReport({})
        formik.resetForm()
    };

    const saveFinalReport = (_data) => {
        competitionService.saveCompetitionFinalReport(_data)
                .then(_finalReport => {                    
                    if (typeof props.afterSave === 'function') {
                        props.afterSave(_finalReport);
                    }

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                    hideDialog()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: finalReport,
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            saveFinalReport(data);
            hideDialog();
            setFinalReport({});
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-check" className="p-button-text" form="finalReport-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />

            <Dialog
                visible={props?.visible}
                header={translatedMessage("finalReport.competition") + ( props?.value?.competitionName ? ": " + props?.value?.competitionName : "" )}
                modal
                resizable={false}
                className="pcn-dialog p-fluid"
                footer={dialogFooter}
                onHide={hideDialog}
            >
                <form id="finalReport-form" onSubmit={formik.handleSubmit}>
                    {formik.values.id && (
                        <div className="field">
                            <label htmlFor="status">{translatedMessage('generic.status')}</label>
                            <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={statusValues}></Dropdown>
                            {getFormErrorMessage('status')}
                        </div>
                    )}
                </form>
            </Dialog>
        </>
    );
};

export default FinalReportCompetitionEditDialog;
