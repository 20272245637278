import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterMatchMode } from 'primereact/api';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';
import { ApplicationService } from '../../../service/ApplicationService';
import DataTableUtils from '../../../utilities/DataTableUtils';
import TestCenterDataTableUtils from '../../../utilities/TestCenterDataTableUtils';
import TestSessionDataTableUtils from '../../../utilities/TestSessionDataTableUtils';
import EnumService from '../../../service/EnumService';

const ChooseTestSessionDialog = (props) => {
    const [competitionTestSessions, setCompetitionTestSessions] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [counties, setCounties] = useState([]);

    const [filters] = useState({
        'competitionTestCenterRoom.testCenterRoom.testCenter.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'competitionTestCenterRoom.testCenterRoom.testCenter.county.id': { value: null, matchMode: FilterMatchMode.IN },
        'competitionTestCenterRoom.testCenterRoom.testCenter.city': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'competitionTestCenterRoom.testCenterRoom.testCenter.address': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'competitionTestCenterRoom.testCenterRoom.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);
    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        const getOptions = async () => {
            setCounties(await EnumService.getEntityLookupWithParentIdByField("NOM:COUNTY"))
        }

        const getCompetitionTestSessions = async () => {
            let criteria = {};
            criteria.competitionId = props.application.competition.id;

            await testService
                .getTestSessionsAvailableToChoseFrom(criteria)
                .then((data) => {
                    let _testSessions = data.items;
                    setCompetitionTestSessions(_testSessions);
                    getOptions()
                    setIsEdit(props?.isEdit)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getCompetitionTestSessions()
    }, [testService, props])

    const saveChoosenTestSession = async (applicationId, testSessionId) => {
        await applicationService
            .saveApplicationTestSessionChoice(applicationId, testSessionId)
            .then((atsc) => {
                if (typeof props.onSave === 'function') {
                    props.onSave(atsc);
                }
                hideDialog();
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const chooseTestSession = (rowData) => {
        saveChoosenTestSession(props.application.id, rowData.id);
    };

    const hideDialog = () => {
        setDialogVisible(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-check" className="p-button-rounded p-button-info m-1" onClick={() => chooseTestSession(rowData)}
                    tooltip={translatedMessage('generic.choose')} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={props.chosenTestSessionId === rowData.id} />
            </div>
        );
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    return (
        <>
            <Toast ref={toast} />

            <Button className="pcn-button-slim p-button-text" onClick={() => setDialogVisible(true)}>
                <div className="flex align-items-center">
                    <FontAwesomeIcon icon={"fa-solid ".concat(isEdit ? "fa-pencil" : "fa-plus")} className="mr-1 " />
                    <span>{translatedMessage(isEdit ? 'application.changeTestSession' : 'application.chooseTestSession')}</span>
                </div>
            </Button>

            <Dialog
                visible={dialogVisible}
                header={translatedMessage('application.chooseTestSession')}
                modal
                className="pcn-dialog p-fluid"
                footer={testCenterDialogFooter}
                onHide={hideDialog}
            >
                <DataTable
                    ref={dt}
                    value={competitionTestSessions}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    responsiveLayout="stack"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    filterDisplay="row"
                    filters={filters}
                    globalFilterFields={['competitionTestCenterRoom.testCenterRoom.testCenter.name', 'competitionTestCenterRoom.testCenterRoom.testCenter.county.id', 'competitionTestCenterRoom.testCenterRoom.testCenter.city', 'competitionTestCenterRoom.testCenterRoom.testCenter.address', 'competitionTestCenterRoom.testCenterRoom.name']}
                >
                    <Column
                        field="competitionTestCenterRoom.testCenterRoom.testCenter.county.label"
                        header={translatedMessage('generic.county')}
                        sortable
                        filterField="competitionTestCenterRoom.testCenterRoom.testCenter.county.id"
                        showFilterMenu={false}
                        filter
                        filterElement={(e) => TestCenterDataTableUtils.countyRowFilterTemplate(e, counties)}
                    />
                    <Column
                        field="competitionTestCenterRoom.testCenterRoom.testCenter.city"
                        header={translatedMessage('generic.city')}
                        sortable
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="competitionTestCenterRoom.testCenterRoom.testCenter.name"
                        header={translatedMessage('competition.testCenter.name')}
                        sortable
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="competitionTestCenterRoom.testCenterRoom.testCenter.address"
                        header={translatedMessage('generic.address')}
                        sortable
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="competitionTestCenterRoom.testCenterRoom.name"
                        header={translatedMessage('competition.testCenterRoom.name')}
                        sortable
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="startDate"
                        header={translatedMessage('generic.interval')}
                        sortable
                        body={(_testSession) => TestSessionDataTableUtils.dateTimeBodyTemplate(_testSession)}
                        headerStyle={{ width: '5%', minWidth: '12rem' }}
                    />
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </Dialog>
        </>
    );
};

export default ChooseTestSessionDialog;
