import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';

import { StorageService } from '../../service/StorageService';
import { translatedMessage } from '../../service/LanguageService';
import DataTableUtils from '../../utilities/DataTableUtils';
import StorageFileDownloadTableButton from './StorageFileDownloadTableButton';
import StorageFileViewTableButton from './StorageFileViewTableButton';
import GeneralUtils from '../../utilities/GeneralUtils';
import { Dialog } from 'primereact/dialog';

const ApplicationFileListModalComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [fileList, setFileList] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedRows, setSelectedRows] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const storageService = useMemo(() => new StorageService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getFiles = async () => {
            setFileList(props.files)
            
            let selectedFiles = []
            if(props?.files.length > 0){
                props.files.forEach(item => {
                    if(item.copyToFolder) {
                        selectedFiles.push(item)
                    }
                })

                setSelectedRows(selectedFiles)
            }

            setIsLoading(false)
        }

        getFiles();
    }, [props, storageService]);

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div className="mt-2 md:mt-0 p-input-icon-left flex justify-content-end">
                <div className="p-input-icon-left w-auto">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage("generic.search")}
                        className="my-1 md:my-1" />
                </div>
            </div>
        </div>
    );

    const doOnFilter = (data) => { }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {<StorageFileViewTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}
                {<StorageFileDownloadTableButton fileUuid={rowData.uuid} fileName={rowData.name} />}
            </div>
        );
    };

    const usageBodyTemplate = (rowData) => {
        return (
            <div><span className='mr-2'>{rowData.usageLabel ? rowData.usageLabel : "-"}</span></div>
        )
    }

    const sizeBodyTemplate = (rowData) => {
        let roundedSize = (rowData.size / (1024 * 1024)).toFixed(2)
        return roundedSize.toString().concat(" MB");
    }

    const dateBodyTemplate = (rowData) => {
        return GeneralUtils.formatDateTime(rowData.fileDate);;
    }

    const dialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog(false)} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" onClick={() => addFiles()} />
        </>
    )

    const onRowSelect = (data) => {
        setSelectedRows(data)
    }

    const addFiles = () => {
        storageService.copyFilesToApplication(props?.applicationId, selectedRows)
            .then(() => {
                toast.current.show({ severity: 'success', summary: translatedMessage("generic.selection.save.success") })
                props.closeDialog(true)
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Dialog
                    visible={props.visible}
                    className="pcn-dialog p-fluid"
                    header={translatedMessage(props.dialogTitle)}
                    modal
                    footer={dialogFooter}
                    onHide={() => props.closeDialog(false)}>

                    <DataTable
                        ref={dt}
                        value={fileList}
                        dataKey="id"
                        paginator={props?.hidePaginator ? false : true}
                        rows={DataTableUtils.defalRowsPerPage()}
                        rowsPerPageOptions={props?.hidePaginator ? 25 : DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        globalFilter={globalFilter}
                        emptyMessage={translatedMessage("generic.tableEmptyMessage")}
                        header={header}
                        responsiveLayout="stack"
                        onFilter={doOnFilter}
                        selectionMode="checkbox"
                        selection={selectedRows}
                        onSelectionChange={e => onRowSelect(e.value)}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                        <Column
                            field="name"
                            header={translatedMessage("generic.nameRo")}
                            sortable
                            headerStyle={{ width: '50%', minWidth: '8rem' }}
                        ></Column>
                        <Column
                            field="usageLabel"
                            header={translatedMessage("file.usage")}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={usageBodyTemplate}
                        ></Column>
                        <Column
                            field="size"
                            header={translatedMessage("file.size")}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={sizeBodyTemplate}
                        ></Column>
                        <Column
                            field="date"
                            header={translatedMessage("file.date")}
                            sortable
                            headerStyle={{ width: '10%', minWidth: '8rem' }}
                            body={dateBodyTemplate}
                        ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </Dialog>
            </>
        );
    }
};

export default ApplicationFileListModalComponent;