import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { translatedMessage } from '../../service/LanguageService';



const TestViewDialog = (props) => {

    const testDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={() => props.closeDialog()} />
        </>
    );


    return (
        <>
            <Dialog
                visible={props.visible}
                style={{ width: '450px' }}
                header={translatedMessage('test.details')}
                modal
                onHide={() => props.closeDialog()}
                className="p-fluid"
                footer={testDialogFooter}
            >
                <div className='w-full grid pcn-data-view-panel'>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                        <div className='filed-value'>{props?.test?.name}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('test.template')}</div>
                        <div className='filed-value'>{props?.test?.template}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('test.minScore')}</div>
                        <div className='filed-value'>{props?.test?.minScore}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('test.maxScore')}</div>
                        <div className='filed-value'>{props?.test?.maxScore}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('test.demoTest')}</div>
                        <div className='filed-value'>{translatedMessage(props?.test?.demo ? "generic.yes" : "generic.no")}</div>
                    </div>
                    <div className='col-12'>
                        <div className='filed-label'>{translatedMessage('test.isLanguageTest')}</div>
                        <div className='filed-value'>{translatedMessage(props?.test?.isLanguageTest ? "generic.yes" : "generic.no")}</div>
                    </div>
                    {props?.test?.isLanguageTest && props?.test?.languageTest &&
                        <div className='col-12'>
                            <div className='filed-label'>{translatedMessage('test.language')}</div>
                            <div className='filed-value'>{translatedMessage('LanguageTestOption.' + props?.test?.languageTest)}</div>
                        </div>
                    }
                </div>
            </Dialog>
        </>
    );
};

export default TestViewDialog;
