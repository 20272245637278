import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { ApplicationService } from '../../../service/ApplicationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clarifications from '../../../components/application/Clarifications';
import useNavProps from '../../../hooks/useNavProps';

const MyApplicationClarificationPage = () => {
    const [application, setApplication] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    const { applicationIdParam } = useParams();

    const navigate = useNavigate();
    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);


        const getApplication = async () => {
            await applicationService.getApplication(applicationIdParam)
                .then((applicationData) => {
                    setApplication(applicationData);

                    setNavPropsValue('application', applicationData.id, applicationData.competition.name);

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        // const checkCanEdit = async () => {
        //     setCanEdit(await SecurityService.check('CAN_EDIT_CLARIFICATION', applicationIdParam));
        // };

        getApplication()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationService, applicationIdParam])


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{application?.competition.name}</h3>
                            <div className="mb-3">
                                <Button className="pcn-button-slim p-button-text" onClick={() => navigate(`/application`)}>
                                    <div className='flex align-items-center'>
                                        <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                        <span>{translatedMessage('generic.backToList')}</span>
                                    </div>
                                </Button>
                            </div>


                            <div className="w-full pcn-data-view-panel">
                                <Clarifications application={application} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MyApplicationClarificationPage;
