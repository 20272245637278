import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

const defaulNewValue = {
    id: null,
    languageId: '',
    write: '',
    speak: '',
    understand: '',
    version: 0
}

const LanguageEditDialogComponent = (props) => {
    const [language, setLanguage] = useState(defaulNewValue);
    const [isNew, setIsNew] = useState(false);
    const [languageLookup, setLanguageLookup] = useState([]);
    const [languageLevels, setLanguageLevels] = useState([]);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    const getLabelForValue = (value, array) => {
        const item = array.filter(x => x.value === value)
        return item[0] ? item[0].label : value
    }

    useEffect(() => {
        let _language = props.language ? props.language : defaulNewValue

        const getLanguageList = async () => {
            let _languages = []
            props?.languages.forEach(item => {
                if(item.value === _language.languageId || !item.used){
                    _languages.push(item)
                }
            })

            setLanguageLookup(_languages)
            setLanguageLevels(props?.languageLevels)
            setLanguage(_language)
            setIsNew(_language.id ? false : true);
        }

        getLanguageList()
    }, [props]);

    const formik = useFormik({
        initialValues: language,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.languageId) {
                errors.languageId = translatedMessage("form.error.language.language.required");
            }

            if (!data.write) {
                errors.write = translatedMessage("form.error.language.write.required");
            }

            if (!data.speak) {
                errors.speak = translatedMessage("form.error.language.speak.required");
            }

            if (!data.understand) {
                errors.understand = translatedMessage("form.error.language.understand.required");
            }

            return errors;
        },
        onSubmit: (data) => {
            userService.saveLanguageSkill(data)
                .then(savedLanguage => {
                    savedLanguage.languageLabel = getLabelForValue(savedLanguage.languageId, languageLookup)
                    savedLanguage.writeLabel = getLabelForValue(savedLanguage.write, languageLevels)
                    savedLanguage.speakLabel = getLabelForValue(savedLanguage.speak, languageLevels)
                    savedLanguage.understandLabel = getLabelForValue(savedLanguage.understand, languageLevels)
                    savedLanguage.applicationLanguageId = data.applicationLanguageId
                    savedLanguage.applicationId = data.applicationId
                    savedLanguage.profileLanguageId = data.profileLanguageId

                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedLanguage, isNew);
                    }                    

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })

                    handleDialogClose()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => handleDialogClose()} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" form="language-form" type="submit" />
        </>
    )

    const handleDialogClose = () => {
        props.onClose()
        formik.resetForm()
    }

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props?.visible}
                closable={true}
                onHide={handleDialogClose}
                style={{ width: '450px' }}
                header={translatedMessage("profile.language.dialog.title")}
                modal
                className="p-fluid"
                footer={dialogFooter}
            >
                <form id="language-form" className='mb-0' onSubmit={formik.handleSubmit}>
                    <div className="input-panel flex flex-column mb-5">
                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Dropdown id="languageId" name="languageId" value={formik.values.languageId}
                                    options={languageLookup} onChange={(e) => formik.setFieldValue("languageId", e.value)} />

                                <label htmlFor="languageId" className={classNames({ 'p-error': isFormFieldValid('languageId') })}>
                                    {translatedMessage("profile.language.language")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('languageId')}

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Dropdown id="write" name="write" value={formik.values.write}
                                    options={languageLevels} onChange={(e) => formik.setFieldValue("write", e.value)} />

                                <label htmlFor="write" className={classNames({ 'p-error': isFormFieldValid('write') })}>
                                    {translatedMessage("profile.language.write")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('write')}

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Dropdown id="speak" name="speak" value={formik.values.speak}
                                    options={languageLevels} onChange={(e) => formik.setFieldValue("speak", e.value)} />

                                <label htmlFor="speak" className={classNames({ 'p-error': isFormFieldValid('speak') })}>
                                    {translatedMessage("profile.language.speak")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('speak')}

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Dropdown id="understand" name="understand" value={formik.values.understand}
                                    options={languageLevels} onChange={(e) => formik.setFieldValue("understand", e.value)} />

                                <label htmlFor="understand" className={classNames({ 'p-error': isFormFieldValid('understand') })}>
                                    {translatedMessage("profile.language.understand")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('understand')}
                    </div>
                </form>
                <Accordion className="pcn-accordion-language-level">
                    <AccordionTab header={<React.Fragment><i className="pi pi-question mr-1"></i><span>{translatedMessage("language.levels.about")}</span></React.Fragment>}
                        className="text-justify">
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.A1").concat(" - ")}</span>
                            {translatedMessage("language.levels.a1.about")}
                        </p>
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.A2").concat(" - ")}</span>
                            {translatedMessage("language.levels.a2.about")}
                        </p>
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.B1").concat(" - ")}</span>
                            {translatedMessage("language.levels.b1.about")}
                        </p>
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.B2").concat(" - ")}</span>
                            {translatedMessage("language.levels.b2.about")}
                        </p>
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.C1").concat(" - ")}</span>
                            {translatedMessage("language.levels.c1.about")}
                        </p>
                        <p>
                            <span className='font-bold'>{translatedMessage("LanguageLevel.C2").concat(" - ")}</span>
                            {translatedMessage("language.levels.c2.about")}
                        </p>
                    </AccordionTab>
                </Accordion>
            </Dialog>
        </>
    );
};

export default LanguageEditDialogComponent;
