import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';
import CompetitionTestEditDialog from './CompetitionTestEditDialog';
import DataTableUtils from '../../../utilities/DataTableUtils';
import useAuth from '../../../hooks/useAuth';
import CompetitionToolbar from '../CompetitionToolbar';
import DeleteModalComponent from '../../../components/DeleteModalComponent';
import ExportButtonComponent from '../../../components/ExportButtonComponent';

const tableHeader = [
    translatedMessage("test.test"),
    translatedMessage("test.passingScore"),
    translatedMessage("test.order"),
    translatedMessage("competition.competition")
]

const CompetitionTestList = () => {
    const [competition, setCompetition] = useState([]);
    const [competitionTests, setCompetitionTests] = useState([]);
    const [selectedCompetitionTest, setSelectedCompetitionTest] = useState(null);
    const [availableTests, setAvailableTests] = useState([]);
    const [allTests, setAllTests] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);

    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const competitionService = useMemo(() => new CompetitionService(), []);
    const testService = useMemo(() => new TestService(), []);

    const { competitionIdParam } = useParams();

    useEffect(() => {
        setIsLoading(true)

        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then(async (competitionData) => {
                    setCompetition(competitionData);
                    await getCompetitionTests()
                    if(hasPermission('COMPETITION_EDIT')){
                        await getAllTests()
                    } else {
                        setIsLoading(false)
                    }
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getCompetitionTests = async () => {
            await competitionService.getCompetitionTestList(competitionIdParam)
                .then(_competitionTests => {
                    setCompetitionTests(_competitionTests);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getAllTests = async () => {
            await testService.getTestListForCompetition(competitionIdParam)
                .then(_allTests => {
                    setAllTests(_allTests)
                    setIsLoading(false)
                })
                .catch((error) => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }

        getCompetition()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionIdParam, competitionService, testService]);


    const updateList = async (savedTest, isNew) => {
        let updatedTests = DataTableUtils.updateTableList(competitionTests, savedTest, isNew)

        setCompetitionTests(updatedTests);
        getAvailableTests(updatedTests)
    };

    const updateCompetitionTestsOrder = async (_orderedCompetitionTests) => {
        setIsLoading(true);
        await competitionService.updateCompetitionTestsOrder(_orderedCompetitionTests)
            .then(_competitionTests => {
                setCompetitionTests(_competitionTests);
                setIsLoading(false);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    }

    const getAvailableTests = (tests) => {
        let _availableTests = allTests.filter(item =>
            tests.findIndex(ct => ct.test.id === item.id) === -1
        );
        setAvailableTests(_availableTests);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        getAvailableTests(competitionTests);
        setSelectedCompetitionTest(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (competitionTest) => {
        getAvailableTests(competitionTests);
        setSelectedCompetitionTest(competitionTest);
        setEditDialogVisible(true);
    };

    const showDeleteDialog = (competitionTest) => {
        setSelectedCompetitionTest(competitionTest);
        setDeleteDialogVisible(true);
    };

    const deleteCompetitionTest = () => {
        competitionService.deleteCompetitionTest(competition.id, selectedCompetitionTest.id)
            .then(() => {
                let _tests = competitionTests.filter(item => item.id !== selectedCompetitionTest.id)
                setCompetitionTests(_tests)
                getAvailableTests(_tests);
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedCompetitionTest(null);
            });
    };

    const leftToolbarTemplate = () => {
        let _disableAdd = competitionTests?.length === allTests?.length;
        return (
            <React.Fragment>
                <div className="my-2">{
                    hasPermission('COMPETITION_CREATE') &&
                    <Button label={translatedMessage('competitionTest.newCompetitionTest')} icon="pi pi-plus" className="p-button-primary mr-2"
                        onClick={showNewDialog} disabled={_disableAdd} />
                }</div>
            </React.Fragment>
        );
    };

    const handleExport = () => {
        let exportData = competitionTests.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.test = item.test.name
            exportItem.competitionName = item.competition.name
            delete exportItem.competition

            return exportItem;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                fileName={translatedMessage("competitionTest.list")}
            />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const onRowReorder = (e) => {
        if (hasPermission('COMPETITION_EDIT')) {
            setCompetitionTests(e.value);
            updateCompetitionTestsOrder(e.value);
            toast.current.show({ severity: 'success', summary: 'Rows Reordered', life: 3000 });
        }
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <CompetitionToolbar competition={competition} code="TEST" />
                            <div>
                                <h5 className='mb-1 font-weight-normal'>{translatedMessage("competitionTest.list")}</h5>
                            </div>
                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={competitionTests}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                header={header}
                                reorderableRows
                                onRowReorder={onRowReorder}
                            >
                                {hasPermission('COMPETITION_EDIT') &&
                                    <Column rowReorder style={{ width: '2em' }} />
                                }
                                <Column
                                    field="testOrder"
                                    header={translatedMessage('test.order')}
                                    sortable
                                    headerStyle={{ width: '5%', minWidth: '6rem' }} />
                                <Column
                                    field="test.name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '50%', minWidth: '10rem' }} />
                                <Column
                                    field="passingScore"
                                    header={translatedMessage('test.passingScore')}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }} />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            <CompetitionTestEditDialog
                                competitionTest={selectedCompetitionTest}
                                visible={editDialogVisible}
                                visibleSetter={setEditDialogVisible}
                                afterSave={updateList}
                                availableTests={availableTests}
                                competition={competition}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedCompetitionTest ? selectedCompetitionTest.test?.name : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteCompetitionTest()}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default CompetitionTestList;
