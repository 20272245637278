import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { CompetitionService } from '../../service/CompetitionService';
import { translatedMessage } from '../../service/LanguageService';
import EnumService from '../../service/EnumService';
import DataTableUtils from '../../utilities/DataTableUtils';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';
import StorageFileUpload from '../../components/file/StorageFileUpload';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';
import FinalReportCompetitionEditDialog from './FinalReportCompetitionEditDialog';


const FinalReportCompetitionList = () => {
    const [finalReports, setFinalReports] = useState([]);
    const [selectedFinalReport, setSelectedFinalReport] = useState(null);
    const [statusValues, setStatusValues] = useState([]);
    const [competitionTypes, setCompetitionTypes] = useState([]);
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [showFilesDialog, setShowFilesDialog] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [competitionCategories, setCompetitionCategories] = useState([]);

    const [filters] = useState({
        'competitionName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'competitionType': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'competitionPositionCategory': { value: null, matchMode: FilterMatchMode.IN },
        'status': { value: null, matchMode: FilterMatchMode.IN },
    });

    const [isLoading, setIsLoading] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setIsLoading(true);

        const getStatuses = async () => {
            const response = await EnumService.getEnumByName("CompetitionFinalReportStatus");
            setStatusValues(response)
        }

        const getCompetitionTypes = async () => {
            setCompetitionTypes(await EnumService.getEnumByName('CompetitionType'));
        };

        const getCompetitionCategories = async () => {
            setCompetitionCategories(await EnumService.getEnumByName('PositionCategory'));
        };

        const getFinalReportCompetitionList = async () => {
            await competitionService.getFinalReportCompetitionList()
                .then(async (_reports) => {
                    setFinalReports(_reports.data);
                    setCanEdit(_reports.canEdit)
                    await getStatuses()
                    await getCompetitionTypes();
                    await getCompetitionCategories();
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getFinalReportCompetitionList();
    }, [competitionService]);


    const updateList = async (savedData) => {
        let updatedList = DataTableUtils.updateTableList(finalReports, savedData, false)

        setFinalReports(updatedList)
    };

    const showUploadFiles = (_finalReport) => {
        setSelectedFinalReport(_finalReport);
        setUploadDialogVisible(true);
    };

    const onFileUploadSuccess = (id, response) => {
        toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    };

    const onFileUploadError = (errorMessage) => {
        toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    };

    const handleEdit = (_finalReport) => {
        setSelectedFinalReport(_finalReport);
        setEditDialogVisible(true);
    }

    const handleViewFiles = (_finalReport) => {
        setSelectedFinalReport(_finalReport);
        setShowFilesDialog(true);
    }

    // Files dialog
    const dialogFilesFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowFilesDialog(false)} />
        </>
    )

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {rowData.canEdit &&
                    <>
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => handleEdit(rowData)}
                            tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                        />

                        <Button icon="pi pi-upload" className="p-button-rounded p-button-info m-1" onClick={() => showUploadFiles(rowData)}
                            tooltip={translatedMessage("generic.file.upload")} tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                            disabled={!rowData.folderId} />
                    </>
                }

                <Button icon="pi pi-file" className="p-button-rounded p-button-info m-1" onClick={() => handleViewFiles(rowData)}
                    tooltip={translatedMessage("generic.files")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-4'>{translatedMessage('finalReport.competition')}</h3>

                            <DataTable
                                ref={dt}
                                value={finalReports}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                responsiveLayout="scroll"
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['name', 'type', 'status']}
                            >
                                <Column
                                    field="competitionName"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '30%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="status"
                                    header={translatedMessage('generic.status')}
                                    sortable
                                    body={(e) => CompetitionDataTableUtils.finalReportStatusBodyTemplate(e.status)}
                                    headerStyle={{ width: '15%', minWidth: '12rem' }}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.finalReportStatusFilterTemplate(e, statusValues)}
                                    showFilterMenu={false}
                                    hidden={!canEdit}
                                />
                                <Column
                                    field="competitionType"
                                    header={translatedMessage('generic.type')}
                                    sortable
                                    body={(e) => CompetitionDataTableUtils.newTypeBodyTemplate(e.competitionType)}
                                    headerStyle={{ width: '15%', minWidth: '12rem' }}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.newTypeFilterTemplate(e, competitionTypes)}
                                    showFilterMenu={false}
                                />
                                <Column
                                    field="competitionPositionCategory"
                                    header={translatedMessage('competition.positionCategory')}
                                    sortable
                                    body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.competitionPositionCategory)}
                                    headerStyle={{ width: '15%', minWidth: '8rem' }}
                                    filter
                                    filterElement={(e) => CompetitionDataTableUtils.categoryFilterTemplate(e, competitionCategories)}
                                    filterHeaderClassName="pcn-filter-column-250"
                                    showFilterMenu={false}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <StorageFileUpload
                    entityId={selectedFinalReport?.id}
                    folderId={selectedFinalReport?.folderId}
                    usageId={null}
                    deletePrevious={false}
                    multiple={true}
                    accept={["pdf", "image"]}
                    maxFileSize={26214400}
                    visible={uploadDialogVisible}
                    visibleSetter={setUploadDialogVisible}
                    onUploadSuccess={onFileUploadSuccess}
                    onUploadError={onFileUploadError}
                />

                <Dialog
                    visible={showFilesDialog}
                    closable={true}
                    onHide={() => setShowFilesDialog(false)}
                    header={translatedMessage("generic.files")}
                    modal
                    className="pcn-dialog"
                    footer={dialogFilesFooter}
                >
                    <StorageFolderFileList
                        folderId={selectedFinalReport?.folderId}
                        folderName={translatedMessage("finalReport.competition")}
                        readOnly={!canEdit}
                    />
                </Dialog>

                <FinalReportCompetitionEditDialog
                    value={selectedFinalReport}
                    visible={editDialogVisible}
                    afterSave={updateList}
                    onHide={() => setEditDialogVisible(false)}
                />
            </>
        );
    }
};

export default FinalReportCompetitionList;
