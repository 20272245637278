import React from 'react';

const FooterBeforeLogin = () => {

  return (
    <div className='w-full flex flex-column justify-content-center py-2' style={{ height: 'auto', backgroundColor: "#FFFFFF" }}>
      <div className='w-full text-center my-1'>
        <span className='mr-1'>PNRR. Finanțat de Uniunea Europeană – UrmătoareaGenerațieUE</span>
      </div>
      <div className='w-full text-center my-1'>Conținutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene sau a Guvernului României</div>
      <div className='w-full text-center my-1 flex flex-wrap justify-content-center'>
        <a href="https://mfe.gov.ro/pnrr" target="_blank" rel="noreferrer">https://mfe.gov.ro/pnrr</a>
        <span className='mx-2'>|</span>
        <a href="https://www.facebook.com/PNRROficial" target="_blank" rel="noreferrer">https://www.facebook.com/PNRROficial</a>
        <span className='mx-2'>|</span>
        <a href="https://concurs-national.anfp.gov.ro/" target="_blank" rel="noreferrer">https://concurs-national.anfp.gov.ro</a>
      </div>
    </div>

  );
};

export default FooterBeforeLogin;
