import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';

import LanguageService, { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';
import useAuth from '../../hooks/useAuth';

import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DataTableUtils from '../../utilities/DataTableUtils';

import { ProgressSpinner } from 'primereact/progressspinner';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import EnumService from '../../service/EnumService';

import SelectInstitutionDialog from '../../components/SelectInstitutionDialog';
import InstitutionDataTableUtils from '../../utilities/InstitutionDataTableUtils';

const MySettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error] = useState({ isError: false, message: '' });
    const [profile, setProfile] = useState();
    const [languages, setLanguages] = useState([])
    const [institutions, setInstitutions] = useState([])
    const { auth, setAuth } = useAuth();
    const toast = useRef(null);
    const dt = useRef(null);

    const userService = new UserService();

    useEffect(() => {
        const getLanguages = async () => {
            const languageResponse = await EnumService.getEnumByName("I18NLanguage");
            setLanguages(languageResponse)
        }

        getLanguages();
    }, []);

    useEffect(() => {
        setIsLoading(true)
        setProfile(auth.user);
        setInstitutions(auth.user.institutions);
        setIsLoading(false);
    }, [auth]);

    const formik = useFormik({
        initialValues: profile ? profile : {
            language: 'ro'
        },
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.language) {
                data.language = 'ro';
            }

            return errors;
        },
        onSubmit: (data) => {
            let _profile = profile;
            _profile.language = data.language
            _profile.acceptNotification = true
            _profile.acceptMarketing = data.acceptMarketing
            //_profile.acceptNotificationJobHigh = data.acceptNotificationJobHigh
            //_profile.acceptNotificationJobDebutant = data.acceptNotificationJobDebutant
            _profile.acceptNotificationJobExecutivePublicFunctions = data.acceptNotificationJobExecutivePublicFunctions;
            _profile.acceptNotificationJobPublicManagementPositions = data.acceptNotificationJobPublicManagementPositions;
            _profile.acceptNotificationJobHighCivilServants = data.acceptNotificationJobHighCivilServants;

            _profile.institutions = institutions
            userService.saveProfileSettings(_profile)
                .then(savedUser => {
                    setProfile(savedUser);
                    setInstitutions(savedUser.institutions);
                    setAuth(prev => {
                        return {
                            ...prev,
                            user: savedUser
                        }
                    });
                    LanguageService.setCurrentLanguage(savedUser.language);
                    updateLanguage();

                    toast.current.show({ severity: 'success', summary: translatedMessage("profile.save.success") });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                });
        }
    });

    const updateLanguage = async () => {
        const languageResponse = await EnumService.getEnumByName("I18NLanguage");
        setLanguages(languageResponse)
    }

    const onChooseInstitution = (_institution) => {
        if (institutions.findIndex((inst) => inst.id === _institution.id) === -1) {
            institutions.push(_institution);
            setInstitutions([...institutions]);
        }
    };

    const deleteInstitution = (_institution) => {
        setInstitutions(institutions.filter((inst) => inst.id !== _institution.id));
    };

    const actionBodyTemplate = (institution) => {
        return (
            <Button
                icon="pi pi-minus-circle"
                className="p-button-rounded p-button-danger m-1"
                onClick={() => deleteInstitution(institution)}
                tooltip={translatedMessage('generic.delete')}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                type="button"
            />
        );
    };

    // const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    // const getFormErrorMessage = (name) => {
    //     return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    // };

    if (isLoading) {
        return (
            <div className='w-full flex align-items-center'>
                <ProgressSpinner />
            </div>
        );
    } else if (error.isError) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={error.message} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast}></Toast>
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <div className="w-full text-align-center mb-5">
                                <h5>{translatedMessage('generic.settings')}</h5>
                            </div>

                            <div className="flex flex-column align-items-center">
                                <div className="col-12 lg:col-9 xl:col-6">
                                    <form id="profile-form" onSubmit={formik.handleSubmit}>
                                        <div className="input-panel flex flex-column mb-5">
                                            <div className="grid">
                                                <div className="col-12">
                                                    <div className="field p-inputgroup mb-2">
                                                        <span className="p-float-label">
                                                            <Dropdown id="language" name="language" value={formik.values.language} options={languages} onChange={(e) => formik.setFieldValue('language', e.value)} />

                                                            <label htmlFor="language">{translatedMessage('generic.language.select')}*</label>
                                                        </span>
                                                    </div>

                                                    <div className="mt-5 font-bold">{translatedMessage('terms.notification.label')}</div>
                                                    {/* <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotification" name="acceptNotification" checked={formik.values.acceptNotification}
                                                            onChange={formik.handleChange}/>
                                                        <label htmlFor="acceptNotification">
                                                            {translatedMessage("terms.notification.agree")}
                                                        </label>
                                                    </div> */}

                                                    <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptMarketing" name="acceptMarketing" checked={formik.values.acceptMarketing} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptMarketing">{translatedMessage('terms.marketing.agree')}</label>
                                                    </div>

                                                    {/* <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotificationJobHigh" name="acceptNotificationJobHigh" checked={formik.values.acceptNotificationJobHigh} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptNotificationJobHigh">{translatedMessage('terms.notification.jobHigh')}</label>
                                                    </div>

                                                    <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotificationJobDebutant" name="acceptNotificationJobDebutant" checked={formik.values.acceptNotificationJobDebutant} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptNotificationJobDebutant">{translatedMessage('terms.notification.jobDebutant')}</label>
                                                    </div> */}

                                                    <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotificationJobExecutivePublicFunctions" name="acceptNotificationJobExecutivePublicFunctions" checked={formik.values.acceptNotificationJobExecutivePublicFunctions} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptNotificationJobExecutivePublicFunctions">{translatedMessage('terms.notification.jobExecutivePublicFunctions')}</label>
                                                    </div>

                                                    <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotificationJobPublicManagementPositions" name="acceptNotificationJobPublicManagementPositions" checked={formik.values.acceptNotificationJobPublicManagementPositions} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptNotificationJobPublicManagementPositions">{translatedMessage('terms.notification.jobPublicManagementPositions')}</label>
                                                    </div>

                                                    <div className="field-checkbox mt-3 mb-2">
                                                        <Checkbox inputId="acceptNotificationJobHighCivilServants" name="acceptNotificationJobHighCivilServants" checked={formik.values.acceptNotificationJobHighCivilServants} onChange={formik.handleChange} />
                                                        <label htmlFor="acceptNotificationJobHighCivilServants">{translatedMessage('terms.notification.jobHighCivilServants')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="col-12">
                                    {(//formik.values.acceptNotificationJobHigh || formik.values.acceptNotificationJobDebutant ||
                                      formik.values.acceptNotificationJobExecutivePublicFunctions || formik.values.acceptNotificationJobPublicManagementPositions || formik.values.acceptNotificationJobHighCivilServants) &&
                                        <div className="mb-2" >
                                            <h5 className="mb-3 font-bold">{translatedMessage('terms.notification.institutions')}</h5>
                                            <SelectInstitutionDialog onChooseInstitution={onChooseInstitution} selectedInstitutions={institutions} />

                                            <DataTable
                                                ref={dt}
                                                value={institutions}
                                                dataKey="id"
                                                paginator
                                                rows={5}
                                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                                className="datatable-responsive pcn-datatable"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                responsiveLayout="stack"
                                            >
                                                <Column field="name" header={translatedMessage('generic.nameRo')} sortable />
                                                <Column field="type" header={translatedMessage('generic.type')} sortable body={(e) => InstitutionDataTableUtils.typeBodyTemplate(e.type)} />
                                                <Column field="county" header={translatedMessage('generic.county')} sortable body={(e) => InstitutionDataTableUtils.countyBodyTemplate(e.county)} />
                                                <Column field="locality" header={translatedMessage('generic.locality')} sortable />
                                                <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }}></Column>
                                            </DataTable>
                                        </div>
                                    }
                                </div>

                                <div className="w-full text-align-center mt-3 mb-5">
                                    <Button className="login-button px-3" label={translatedMessage('generic.save')} form="profile-form" type="submit"></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default MySettings;
