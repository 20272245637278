import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TestService } from '../../service/TestService';
import { translatedMessage } from '../../service/LanguageService';
import DeleteModalComponent from '../../components/DeleteModalComponent';
import TestViewDialog from './TestViewDialog';
import TestEditDialog from './TestEditDialog';
import DataTableUtils from '../../utilities/DataTableUtils';
import useAuth from '../../hooks/useAuth';
import EnumService from '../../service/EnumService';

const TestList = () => {
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [providers, setProviders] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const { auth } = useAuth();

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        const getTests = async () => {
            await testService.getTestList()
                .then((_tests) => {
                    setTests(_tests);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const populateProviders = async () => {
            await EnumService.getEnumByName('TestProvider').then((_providers) => setProviders(_providers));
        };

        populateProviders();
        getTests();
    }, [testService]);

    const updateList = async (savedTest, isNew) => {
        let updatedTests = DataTableUtils.updateTableList(tests, savedTest, isNew)

        setTests(updatedTests);
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const showNewDialog = () => {
        setSelectedTest(null);
        setEditDialogVisible(true);
    };

    const showEditDialog = (test) => {
        setSelectedTest(test);
        setEditDialogVisible(true);
    };

    const showViewDialog = (test) => {
        setSelectedTest(test);
        setViewDialogVisible(true);
    };

    const showDeleteDialog = (test) => {
        setSelectedTest(test);
        setDeleteDialogVisible(true);
    };

    const deleteTest = () => {
        testService.deleteTest(selectedTest.id)
            .then(() => {
                let _tests = tests.filter(item => item.id !== selectedTest.id)
                setTests(_tests)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedTest(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('test.newTest')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={translatedMessage('generic.export')} icon="pi pi-download" className="p-button-help" onClick={exportCSV} /> */}
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {hasPermission('COMPETITION_VIEW') &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const doOnFilter = (data) => { };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-end md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder={translatedMessage('generic.search.dots')} />
            </span>
        </div>
    );

    const testTemplateBody = (_test) => {
        let jobProfile = _test.jobProfileId ? <div className='mt-2'>{translatedMessage("test.jobProfile") + ": " + _test.jobProfileId}</div> : ""

        return (
            <>
                <div>{_test.template}</div>
                {jobProfile}
            </>
        );
    };


    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-0'>{translatedMessage('test.tests')}</h3>

                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={tests}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                globalFilter={globalFilter}
                                onFilter={doOnFilter}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                header={header}
                                responsiveLayout="scroll"
                            >
                                <Column
                                    field="name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '25%', minWidth: '10rem' }}
                                />
                                <Column
                                    field="provider"
                                    header={translatedMessage('test.provider')}
                                    body={(e) => translatedMessage('TestProvider.' + e.provider)}
                                    sortable
                                    headerStyle={{ width: '10%', minWidth: '10rem' }}
                                />
                                <Column
                                    field="template"
                                    header={translatedMessage('test.template')}
                                    sortable
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    body={testTemplateBody}
                                />
                                <Column
                                    field="minScore"
                                    header={translatedMessage('test.minScore')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                />
                                <Column
                                    field="maxScore"
                                    header={translatedMessage('test.maxScore')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                />
                                <Column
                                    field="languageTest"
                                    header={translatedMessage('test.isLanguageTest')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                    body={(e) => e.isLanguageTest ? translatedMessage("LanguageTestOption." + e.languageTest) : "-"}
                                />
                                <Column
                                    field="demo"
                                    header={translatedMessage('test.demoTest')}
                                    sortable
                                    headerStyle={{ width: '8%', minWidth: '5rem' }}
                                    body={(e) => DataTableUtils.yesNoTemplate(e.demo)}
                                />
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            <TestEditDialog
                                value={selectedTest}
                                visible={editDialogVisible}
                                visibleSetter={setEditDialogVisible}
                                afterSave={updateList}
                                providers={providers}
                            />

                            <DeleteModalComponent
                                visible={deleteDialogVisible}
                                item={selectedTest ? selectedTest.name : ''}
                                closeDialog={() => { setDeleteDialogVisible(false) }}
                                deleteRecord={() => deleteTest()}
                            />

                            <TestViewDialog
                                test={selectedTest}
                                visible={viewDialogVisible}
                                closeDialog={() => { setViewDialogVisible(false) }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestList;
