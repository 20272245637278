import React, { useState, useEffect, useRef, useMemo} from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';

const emptyTestCenter = {
    id: null,
    name: '',
    region: { id: null },
    county: { id: null },
    city: '',
    address: '',
    email: '',
    phone: '',
    capacity: 0,
    status: 'INACTIVE'
};

const TestCenterEditDialog = (props) => {

    const [testCenter, setTestCenter] = useState(emptyTestCenter);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [countyOptions, setCountyOptions] = useState([]);

    const toast = useRef(null);

    const testService = useMemo(() => new TestService(), []);

    useEffect(() => {
        setTestCenter(props.value ? props.value : emptyTestCenter);
        setIsNew(props.value ? false : true);
        setCountyOptions(props.countyOptions);
    }, [props]);

    useEffect(() => {
        setDialogVisible(props.visible);
    }, [props.visible]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        setDialogVisible(false);
        formik.resetForm();
    };

    const saveTestCenter = (data) => {
        testService.saveTestCenter(data)
        .then(savedTestCenter => {
            if (typeof props.afterSave === 'function') {
                props.afterSave(savedTestCenter, isNew);
            }
            toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
            hideDialog();
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: testCenter,
        validate: (data) => {
            let errors = {};
            if (!data.name) {
                errors.name = translatedMessage('form.error.name.required');
            }
            if (!data.region || !data.region.id) {
                errors.region = translatedMessage('form.error.region.required');
            }
            if (!data.county || !data.county.id) {
                errors.county = translatedMessage('form.error.county.required');
            }
            if (!data.city) {
                errors.city = translatedMessage('form.error.city.required');
            }
            if (!data.address) {
                errors.address = translatedMessage('form.error.address.required');
            }
            if (!data.email) {
                errors.email = translatedMessage('form.error.email.required');
            }
            if (!data.phone) {
                errors.phone = translatedMessage('form.error.phone.required');
            }
            return errors;
        },
        onSubmit: (data) => {
            saveTestCenter(data);
            formik.resetForm();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const handleRegionChange = (data) => {
        formik.setFieldValue("region.id", data.value);
        let newOptions = props.countyOptions.filter(county => county.relatedId === data.value);
        setCountyOptions(newOptions);
    }

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="testCenter-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog 
                visible={dialogVisible} 
                style={{ width: '450px' }} 
                header={translatedMessage('testCenter.details')} 
                modal 
                className="p-fluid" 
                footer={testCenterDialogFooter} 
                onHide={hideDialog}
            >
                <form id="testCenter-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="name" name="name" value={formik.values.name} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('name') })}/>
                            <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') })}>
                                {translatedMessage("generic.nameRo")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('name')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="region.id" name="region.id" value={formik.values.region.id} onChange={handleRegionChange} options={props.regionOptions}
                                className={classNames({ 'p-error': isFormFieldValid('region')})}></Dropdown>
                            <label htmlFor="region" className={classNames({ 'p-error': isFormFieldValid('region') })}>
                                {translatedMessage("generic.region")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('region')}

                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="county.id" name="county.id" value={formik.values.county.id} onChange={formik.handleChange} options={countyOptions}
                                className={classNames({ 'p-error': isFormFieldValid('county')})}></Dropdown>
                            <label htmlFor="county" className={classNames({ 'p-error': isFormFieldValid('county')})}>
                                {translatedMessage("generic.county")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('county')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="city" name="city" value={formik.values.city} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('city') })}/>
                            <label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid('city') })}>
                                {translatedMessage("generic.city")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('city')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="address" name="address" value={formik.values.address} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('address') })}/>
                            <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') })}>
                                {translatedMessage("generic.address")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('address')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })}/>
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>
                                {translatedMessage("generic.email")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('email')}

                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputText type="text" id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('phone') })}/>
                            <label htmlFor="phone" className={classNames({ 'p-error': isFormFieldValid('phone') })}>
                                {translatedMessage("generic.phone")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('phone')}

                    {/* <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <InputNumber id="capacity" name="capacity" value={formik.values.capacity} onValueChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('capacity') })}/>
                            <label htmlFor="capacity" className={classNames({ 'p-error': isFormFieldValid('capacity') })}>
                                {translatedMessage("testCenter.capacity")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('capacity')}
                    
                    <div className="field p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            <Dropdown id="status" name="status" value={formik.values.status} onChange={formik.handleChange} options={props.testCenterStatusOptions}></Dropdown>
                            <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') })}>
                                {translatedMessage("generic.status")}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('status')}                     */}
                </form>
            </Dialog>
        </>
    );
};

export default TestCenterEditDialog;
