import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useFormik } from 'formik';

import { translatedMessage } from '../../service/LanguageService';
import { UserService } from '../../service/UserService';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';

const defaulNewValue = {
    id: null,
    institutionName: '',
    diploma: '',
    startDate: '',
    endDate: '',
    iscedId: '',
    domainId: null,
    domain: null,
    domainName: '',
    specializationId: null,
    specialization: null,
    specializationName: '',
    version: 0
}

const EducationEditDialogComponent = (props) => {
    const [education, setEducation] = useState(defaulNewValue);
    const [isNew, setIsNew] = useState(false);
    const [iscedTypes, setIscedTypes] = useState([]);
    const [domains, setDomains] = useState([]);
    const [specs, setSpecs] = useState([]);
    const [filteredDomains, setFilteredDomains] = useState([]);
    const [iscedDomains, setIscedDomains] = useState([]);
    const [filteredSpecs, setFilteredSpecs] = useState([]);
    const [domainSpecs, setDomainSpecs] = useState([]);
    const [isDomainDisabled, setIsDomainDisabled] = useState(true);
    const [isSpecDisabled, setIsSpecDisabled] = useState(true);
    const toast = useRef(null);

    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        let _education = props.education ? props.education : defaulNewValue

        const getIsced = async () => {
            setIscedTypes(props?.iscedTypes)
        }

        const getDom = async () => {
            if (_education.id) {
                let _domain = {}
                if (_education.domainId) {
                    _domain = props?.domains.filter(item => item.value === _education.domainId)
                } else if (_education.domainName) {
                    _domain = props?.domains.filter(item => item.value === -1)
                }

                _education.domain = _domain[0] ? _domain[0] : _domain.domain
            }

            setDomains(props?.domains)
        }

        const getSpec = async () => {
            if (_education.id) {
                let _spec = {}
                if (_education.specializationId) {
                    _spec = props?.specs.filter(item => item.value === _education.specializationId)
                } else if (_education.specializationName) {
                    _spec = props?.specs.filter(item => item.value === -1)
                }

                _education.specialization = _spec[0] ? _spec[0] : _spec.specialization
            }

            setSpecs(props?.specs)
        }

        const computeEducation = async () => {
            setEducation(_education);
            setIsNew(_education.id ? false : true);
            handleIscedChange({ value: _education.iscedId }, false)
            if (_education.domain) {
                handleDomainChange({ value: _education.domain }, false)
            }
            if (_education.specialization) {
                handleSpecializationChange({ value: _education.specialization }, false)
            }

        }

        getIsced()
        getDom()
        getSpec()
        computeEducation()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    // Form
    const formik = useFormik({
        initialValues: education,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};

            if (!data.institutionName || !data.institutionName.trim()) {
                errors.institutionName = translatedMessage("form.error.education.institution.required");
            }

            if (!data.diploma || !data.diploma.trim()) {
                errors.diploma = translatedMessage("form.error.education.diploma.required");
            }

            if (!data.startDate) {
                errors.startDate = translatedMessage("form.error.education.startDate.required");
            }

            if (data.endDate && data.startDate > data.endDate) {
                errors.endDate = translatedMessage("form.error.education.endDate.toEarly");
            }

            if (!data.iscedId) {
                errors.iscedId = translatedMessage("form.error.education.isced.required");
            }

            if (data.iscedId) {
                let isced = iscedTypes.filter(item => item.value === data.iscedId)
                if (isced && isced[0] && (isced[0].code.toLowerCase() === 'isced6' || isced[0].code.toLowerCase() === 'isced7')) {
                    if (!data.domain) {
                        errors.domainId = translatedMessage("form.error.education.domain.required");
                    } else {
                        if (data.domain?.value === -1 && (!data.domainName || !data.domainName.trim())) {
                            errors.domainName = translatedMessage("form.error.education.domainName.required")
                        }
                    }

                    if (!data.specialization) {
                        errors.specializationId = translatedMessage("form.error.education.specialization.required");
                    } else {
                        if (data.specialization?.value === -1 && (!data.specializationName || !data.specializationName.trim())) {
                            errors.specializationName = translatedMessage("form.error.education.specializationName.required")
                        }
                    }
                }
            }

            return errors;
        },
        onSubmit: (data) => {
            if (data.domain && data.domain.value) {
                if (data.domain.value === -1) {
                    data.domainId = null
                } else {
                    data.domainId = data.domain.value
                    data.domainName = data.domain.label
                }
            }

            if (data.specialization && data.specialization.value) {
                if (data.specialization.value === -1) {
                    data.specializationId = null
                } else {
                    data.specializationId = data.specialization.value
                    data.specializationName = data.specialization.label
                }
            }

            userService.saveEducation(data)
                .then(savedEducation => {
                    savedEducation.applicationEducationId = data.applicationEducationId
                    savedEducation.applicationId = data.applicationId
                    savedEducation.profileEducationId = data.profileEducationId

                    if (typeof props.afterSave === 'function') {
                        props.afterSave(savedEducation, isNew);
                    }

                    toast.current.show({ severity: 'success', summary: translatedMessage("generic.save.success") })
                    handleDialogClose()
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const dialogFooter = (
        <>
            <Button label={translatedMessage("generic.cancel")} icon="pi pi-times" className="p-button-text" onClick={() => handleDialogClose()} />
            <Button label={translatedMessage("generic.save")} icon="pi pi-check" className="p-button-text" form="education-form" type="submit" />
        </>
    )

    // Actions
    const resertLookups = () => {
        setIsDomainDisabled(true)
        setIsSpecDisabled(true)
        setFilteredDomains([])
        setIscedDomains([])
        setFilteredSpecs([])
        setDomainSpecs([])
    }

    const handleDialogClose = () => {
        props.onClose()
        formik.resetForm()
        resertLookups()
    }

    const handleIscedChange = (data, resetValues = true) => {
        // console.log("handleIscedChange")
        formik.setFieldValue("iscedId", data.value)

        let isced = iscedTypes.filter(item => item.value === data.value)

        let _domainItems = []
        if (isced && isced[0] && (isced[0].code.toLowerCase() === 'isced6' || isced[0].code.toLowerCase() === 'isced7')) {
            _domainItems = domains.filter(item => (item.relatedId && item.relatedId === isced[0].value) || item.relatedId === null)

            setIscedDomains(_domainItems);
            setIsDomainDisabled(false)
        } else {
            setIsDomainDisabled(true)
            setIsSpecDisabled(true)
        }

        if (resetValues) {
            formik.setFieldValue("domainId", null)
            formik.setFieldValue("domain", null)
            formik.setFieldValue("domainName", '')
            formik.setFieldValue("specializationId", null)
            formik.setFieldValue("specialization", null)
            formik.setFieldValue("specializationName", '')
        }
    }

    const handleDomainChange = (data, resetValues = true) => {
        // console.log("handleDomainChange")
        formik.setFieldValue("domain", data.value)

        let _specItems = []
        if (data && data.value) {
            _specItems = specs.filter(item => item.relatedId && item.relatedId === data.value.value)

            setDomainSpecs(_specItems);
            setIsSpecDisabled(false)
        } else {
            setIsSpecDisabled(true)
        }

        if (resetValues) {
            formik.setFieldValue("domainName", '')
            formik.setFieldValue("specializationId", null)
            formik.setFieldValue("specialization", null)
            formik.setFieldValue("specializationName", '')
        }
    }

    const handleSpecializationChange = (data, resetValues = true) => {
        // console.log("handleSpecializationChange")
        formik.setFieldValue("specialization", data.value)

        if (resetValues) {
            formik.setFieldValue("specializationName", '')
        }
    }

    const searchDomainItems = (event) => {
        // console.log("searchDomainItems")
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < iscedDomains.length; i++) {
            let item = iscedDomains[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredDomains(_filteredItems);
    }

    const searchSpecializationItems = (event) => {
        let query = event.query;
        let _filteredItems = [];

        for (let i = 0; i < domainSpecs.length; i++) {
            let item = domainSpecs[i];
            if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                _filteredItems.push(item);
            }
        }

        setFilteredSpecs(_filteredItems);
    }


    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                closable={true}
                onHide={handleDialogClose}
                style={{ width: '450px' }}
                header={translatedMessage("profile.education.dialog.title")}
                modal
                className="p-fluid"
                footer={dialogFooter}
            >
                <form id="education-form" onSubmit={formik.handleSubmit}>
                    <div className="input-panel flex flex-column mb-5">
                        <div className="field p-inputgroup mb-2">
                            <span className="p-float-label">
                                <InputText type="text" name="institutionName" id="institutionName"
                                    value={formik.values.institutionName} onChange={formik.handleChange} autoFocus
                                    className={classNames({ 'p-invalid': isFormFieldValid('institutionName') })} />
                                <label htmlFor="institutionName" className={classNames({ 'p-error': isFormFieldValid('institutionName') })}>
                                    {translatedMessage("profile.education.institutionName")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('institutionName')}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <InputText type="text" name="diploma" id="diploma"
                                    value={formik.values.diploma} onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('diploma') })} />
                                <label htmlFor="diploma" className={classNames({ 'p-error': isFormFieldValid('diploma') })}>
                                    {translatedMessage("profile.education.diploma")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('diploma')}

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Dropdown id="iscedId" name="iscedId" value={formik.values.iscedId}
                                    options={iscedTypes} onChange={handleIscedChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('iscedId') })} />

                                <label htmlFor="iscedId" className={classNames({ 'p-error': isFormFieldValid('iscedId') })}>
                                    {translatedMessage("profile.education.isced")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('iscedId')}

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <AutoComplete value={formik.values.domain} suggestions={filteredDomains} completeMethod={searchDomainItems}
                                    virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown id="domainId" name="domainId"
                                    onChange={handleDomainChange}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    className={classNames({ 'p-invalid': isFormFieldValid('domainId') })}
                                    disabled={isDomainDisabled} />

                                <label htmlFor="domainId" className={classNames({ 'p-error': isFormFieldValid('domainId') })}>
                                    {translatedMessage("profile.education.domain")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('domainId')}

                        {formik.values.domain?.value === -1 &&
                            <>
                                <div className="field p-inputgroup mt-3 mb-2">
                                    <span className="p-float-label">
                                        <InputText type="text" name="domainName" id="domainName"
                                            value={formik.values.domainName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('domainName') })} />
                                        <label htmlFor="domainName" className={classNames({ 'p-error': isFormFieldValid('domainName') })}>
                                            {translatedMessage("profile.education.domainName")}*
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('domainName')}
                            </>
                        }

                        <div className="p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <AutoComplete value={formik.values.specialization} suggestions={filteredSpecs} completeMethod={searchSpecializationItems}
                                    virtualScrollerOptions={{ itemSize: 38 }} field="label" dropdown id="specializationId" name="specializationId"
                                    onChange={handleSpecializationChange}
                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                    className={classNames({ 'p-invalid': isFormFieldValid('specializationId') })}
                                    disabled={isSpecDisabled} />

                                <label htmlFor="specializationId" className={classNames({ 'p-error': isFormFieldValid('specializationId') })}>
                                    {translatedMessage("profile.education.specialization")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('specializationId')}

                        {formik.values.specialization?.value === -1 &&
                            <>
                                <div className="field p-inputgroup mt-3 mb-2">
                                    <span className="p-float-label">
                                        <InputText type="text" name="specializationName" id="specializationName"
                                            value={formik.values.specializationName} onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('specializationName') })} />
                                        <label htmlFor="specializationName" className={classNames({ 'p-error': isFormFieldValid('specializationName') })}>
                                            {translatedMessage("profile.education.specializationName")}*
                                        </label>
                                    </span>
                                </div>
                                {getFormErrorMessage('specializationName')}
                            </>
                        }

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Calendar id="basic" value={formik.values.startDate} onChange={(e) => formik.setFieldValue("startDate", e.value)} dateFormat="dd-mm-yy"
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') })} />
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') })}>
                                    {translatedMessage("profile.education.startDate")}*
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('startDate')}

                        <div className="field p-inputgroup mt-3 mb-2">
                            <span className="p-float-label">
                                <Calendar id="basic" value={formik.values.endDate} onChange={(e) => formik.setFieldValue("endDate", e.value)} dateFormat="dd-mm-yy"
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') })} />
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') })}>
                                    {translatedMessage("profile.education.endDate")}
                                </label>
                            </span>
                        </div>
                        {getFormErrorMessage('endDate')}
                    </div>
                </form>
            </Dialog>
        </>
    );
};

export default EducationEditDialogComponent;
