import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';
import { translatedMessage } from '../../service/LanguageService';
import GeneralUtils from '../../utilities/GeneralUtils';
import ApiService from "../../service/ApiService";
import DataTableUtils from '../../utilities/DataTableUtils';
import StorageFileDownloadTableButton from '../../components/file/StorageFileDownloadTableButton';
import StorageFileViewTableButton from '../../components/file/StorageFileViewTableButton';
import DOMPurify from 'dompurify';

const RecruitmentPlanPublishedList = () => {
    const [recruitmentPlans, setRecruitmentPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [recruitmentPlan, setRecruitmentPlan] = useState(null);
    const [viewDialogVisible, setViewDialogVisible] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    useEffect(() => {
        setIsLoading(true)

        const getRecruitmentPlans = async () => {
            async function getAll() {
                return ApiService.getAuthenticatedInstance().get('/recruitment/list/published')
                    .then((res) => res.data)
                    .catch((error) => {
                        let errorMessage = "generic.error.get.data";
                        if (error && error.response && error.response.data && error.response.data.message && error.response.data.status !== 404) {
                            errorMessage = error.response.data.message
                        }                     
                        toast.current.show({ severity: 'error', summary: translatedMessage(errorMessage), life: 5000 })
                    });
            }

            const rp = await getAll();
            setIsLoading(false)
            return setRecruitmentPlans(rp);
        };

        getRecruitmentPlans()
    }, []);


    const showViewDialog = (recruitmentPlan) => {
        let rp = { ...recruitmentPlan };
        setRecruitmentPlan(rp);
        setViewDialogVisible(true);
    };

    const hideViewDialog = () => {
        setViewDialogVisible(false);
    }

    const viewRecruitmentPlanDialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={hideViewDialog} />
        </>
    );

    const lastUpdatedOnBodyTemplate = (rowData) => {
        return GeneralUtils.formatDate(rowData.lastUpdatedOn);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showViewDialog(rowData)}
                    tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                {<StorageFileViewTableButton fileUuid={rowData.fileUuid} fileName={rowData.fileName} />}
                <StorageFileDownloadTableButton fileUuid={rowData.fileUuid} fileName={rowData.fileName} />
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className='mb-5'>{translatedMessage('recruitmentPlan.recruitmentPlans')}</h3>

                            <DataTable
                                ref={dt}
                                value={recruitmentPlans}
                                dataKey="id"
                                paginator
                                rows={DataTableUtils.defalRowsPerPage()}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                responsiveLayout="stack"
                                filterDisplay="row"
                                filters={filters}
                                globalFilterFields={['name', 'status']}
                            >
                                <Column
                                    field="name"
                                    header={translatedMessage('generic.nameRo')}
                                    sortable
                                    headerStyle={{ width: '60%', minWidth: '10rem' }}
                                    filter
                                    showFilterMenu={false}
                                ></Column>
                                <Column
                                    field="lastUpdatedOn"
                                    header={translatedMessage('generic.lastUpdated.on')}
                                    sortable
                                    body={lastUpdatedOnBodyTemplate}
                                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                                ></Column>
                                <Column body={actionBodyTemplate}></Column>
                            </DataTable>

                            {/* View dialog */}
                            <Dialog
                                visible={viewDialogVisible}
                                header={recruitmentPlan?.name}
                                modal
                                footer={viewRecruitmentPlanDialogFooter}
                                onHide={hideViewDialog}
                                className="pcn-dialog p-fluid"
                            >
                                <div className="p-inputtext pcn-html-editor-view-container mb-3">
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(recruitmentPlan?.description) }} />
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default RecruitmentPlanPublishedList;
