import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import DataTableUtils from '../../../utilities/DataTableUtils';

const CompetitionTestSessionsStatisticsDialog = (props) => {
    const [testCenterStats, setTestCenterStats] = useState(null);
    const [statistics, setStatistics] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    useEffect(() => {
        setIsLoading(true)
        
        const getStatistics = async () => {
            await competitionService
                .getTestSessionsStatistics(props?.competition?.id)
                .then((_statistics) => {
                    setStatistics(_statistics);
                    setTestCenterStats(_statistics.testCenters);
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        }

        getStatistics();
    }, [props, competitionService]);

    const testCenterBodyTemplate = (testCenterStats) => {
        return (
            <div>
                {testCenterStats.testCenter.name}
                <div className="mt-1">
                    {testCenterStats.testCenter.city + ' ('}
                    {testCenterStats.testCenter.county.labelRo + ')'}
                </div>
            </div>
        );
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.close')} icon="pi pi-times" className="p-button-text" onClick={props.onCancel} />
        </>
    );

    // Render
    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <Dialog
                    visible={props.visible}
                    header={<>{translatedMessage('testSession.data')} {translatedMessage('generic.for')} {props.competition?.name}</>}
                    modal
                    className="pcn-dialog p-fluid"
                    footer={testCenterDialogFooter}
                    onHide={props.onCancel}
                >
                    <div className="grid mb-2">
                        <div className="col-12 md:col-6">
                            <span className="filed-label font-bold">{translatedMessage('competition.testSession.totalCapacity')}: </span>
                            <span className="filed-value">{statistics.totalCapacity}</span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="filed-label font-bold">{translatedMessage('competition.testSession.distributedApplications')}: </span>
                            <span className="filed-value">{statistics.distributedApplications}</span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="filed-label font-bold">{translatedMessage('competition.testSession.qualifiedApplications')}: </span>
                            <span className="filed-value">{statistics.totalApplications}</span>
                        </div>
                        <div className="col-12 md:col-6">
                            <span className="filed-label font-bold">{translatedMessage('competition.testSession.notDistributedApplications')}: </span>
                            <span className="filed-value">{statistics.notDistributedApplications}</span>
                        </div>
                    </div>

                    <DataTable
                        ref={dt}
                        value={testCenterStats}
                        dataKey="id"
                        loading={isLoading}
                        rowGroupMode="rowspan"
                        paginator
                        rows={DataTableUtils.defalRowsPerPage()}
                        rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                        className="datatable-responsive pcn-datatable"
                        responsiveLayout="stack"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                        emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    >
                        <Column field="testCenter.name" header={translatedMessage('testCenter.testCenter')} sortable body={testCenterBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }} />
                        <Column field="totalCapacity" header={translatedMessage('testCenter.room.capacity')} sortable headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="distributedApplications" header={translatedMessage('testCenter.room.capacity.used')} sortable headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="availableCapacity" header={translatedMessage('testCenter.room.capacity.available')} sortable headerStyle={{ width: '15%', minWidth: '10rem' }} />
                        <Column field="notDistributedApplicationThatChoseThisCenter" header={translatedMessage('testCenter.application.chosers')} sortable headerStyle={{ width: '15%', minWidth: '10rem' }} />
                    </DataTable>
                </Dialog>
            </>
        );
    }
};

export default CompetitionTestSessionsStatisticsDialog;
