import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toolbar } from 'primereact/toolbar';
import { CompetitionService } from '../../../service/CompetitionService';
import { translatedMessage } from '../../../service/LanguageService';
import { TestService } from '../../../service/TestService';
import DeleteDialog from '../../../components/util/DeleteDialog';
import useAuth from '../../../hooks/useAuth';
import DataTableUtils from '../../../utilities/DataTableUtils';
import GeneralUtils from '../../../utilities/GeneralUtils';
import CompetitionTestSessionsStatisticsDialog from './CompetitionTestSessionsStatisticsDialog';
import TestSessionEditDialog from './TestSessionEditDialog';
import TestSessionDataTableUtils from '../../../utilities/TestSessionDataTableUtils';
import SecurityService from '../../../service/SecurityService';
import ExportButtonComponent from '../../../components/ExportButtonComponent';
import DataTableExportUtils from '../../../utilities/DataTableExportUtils';

const sections = [
    { label: translatedMessage('competition.testSessions'), code: 'TEST_SESSION', icon: 'fa-regular fa-calendar', url: '/test-session' },
    { label: translatedMessage('testSession.candidatesAssignedToTestSessions'), code: 'APPLICATIONS', icon: 'fa-regular fa-user', url: '/application-test-sessions' },
    { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
];

const TestSessionList = () => {
    const [testSession, setTestSession] = useState(null);
    const [competition, setCompetition] = useState({});

    const [totalRecords, setTotalRecords] = useState(0);
    const [testSessions, setTestSessions] = useState([]);

    const [firstLoading, setFirstLoading] = useState(true);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [statisticsDialogVisible, setStatisticsDialogVisible] = useState(false);
    const [section, setSection] = useState(sections[0]);

    const [testCenters, setTestCenters] = useState([]);
    const [testCenterRooms, setTestCenterRooms] = useState([]);
    const [testCenterRoomsToFilterBy, setTestCenterRoomsToFilterBy] = useState([]);

    const [isInOrganizationCommittee, setIsInOrganizationCommittee] = useState(false);

    // const [showUploadDialog, setShowUploadDialog] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();

    let { competitionIdParam } = useParams();

    const [loading, setLoading] = useState(false);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: 'id',
        sortOrder: 1,
        filters: {
            competitionId: { value: competitionIdParam, matchMode: FilterMatchMode.EQUALS },
            testCenterId: { value: '', matchMode: FilterMatchMode.EQUALS },
            testCenterRoomId: { value: '', matchMode: FilterMatchMode.EQUALS },
            startDate: { value: '', matchMode: FilterMatchMode.EQUALS }
        }
    });

    const propToColumnMap = {
        testCenterId: 'test_center_id',
        testCenterRoomId: 'test_center_room_id'
    };

    const competitionService = useMemo(() => new CompetitionService(), []);
    const testService = useMemo(() => new TestService(), []);

    const navigate = useNavigate();

    useEffect(() => {
        setFirstLoading(true)

        const getCompetition = async () => {
            await competitionService
                .getCompetition(competitionIdParam)
                .then((comp) => {
                    setCompetition(comp);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getTestCenters = async () => {
            await competitionService
                .getTestCenters(competitionIdParam)
                .then((_testCenters) => {
                    setTestCenters(_testCenters);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getTestCenterRooms = async () => {
            await competitionService
                .getTestCenterRooms(competitionIdParam)
                .then((_testCentersRooms) => {
                    setTestCenterRooms(_testCentersRooms);
                    setTestCenterRoomsToFilterBy(_testCentersRooms);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const checkIsInOrganizationCommittee = async () => {
            setIsInOrganizationCommittee(await SecurityService.check('IS_IN_PRELIMINARY_TEST_ORGANIZATION_COMMITTEE', competitionIdParam));
        };

        getCompetition();
        getTestCenters();
        getTestCenterRooms();
        checkIsInOrganizationCommittee();
    }, [competitionIdParam, competitionService, testService]);

    useEffect(() => {
        filterRoomsByTestCenter();
        lazyLoadTestSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyParams]);

    const lazyLoadTestSessions = () => {
        setLoading(true);
        loadTestSessions();
    };

    const filterRoomsByTestCenter = () => {
        let testCenterId = lazyParams.filters.testCenterId.value;
        let _testCentersRooms = [...testCenterRooms];
        if (testCenterId) {
            _testCentersRooms = _testCentersRooms.filter((room) => room.testCenter.id === testCenterId);
        }
        setTestCenterRoomsToFilterBy(_testCentersRooms);
        if (!_testCentersRooms.find((room) => room.id === lazyParams.filters.testCenterRoomId.value)) {
            lazyParams.filters.testCenterRoomId.value = '';
        }
    };

    const loadTestSessions = async () => {
        let criteria = {};
        criteria.startRow = lazyParams.first;
        criteria.pageSize = lazyParams.rows;
        criteria.sortOrder = lazyParams.sortOrder < 0 ? 'DESC' : 'ASC';
        criteria.sortField = propToColumnMap[lazyParams.sortField] || 'competition_test_center_room_id';
        criteria.competitionId = competitionIdParam;
        criteria.testCenterId = lazyParams.filters.testCenterId.value;
        criteria.testCenterRoomId = lazyParams.filters.testCenterRoomId.value;
        criteria.startDate = lazyParams.filters.startDate.value;

        await testService
            .getTestSessions(criteria)
            .then((data) => {
                setTestSessions(data.items);
                setTotalRecords(data.totalCount);
                setLoading(false);
                setFirstLoading(false);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    const navigationTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        );
    };

    const handleSectionChange = (value) => {
        let url = null;
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/competition-evaluation`);
            } else {
                if (value && value.url && value.url !== '#') {
                    url = `/competition-evaluation/${competition.id}${value.url}`;
                    navigate(url);
                } else {
                    setSection(value);
                }
            }
        }
    };

    // Actions
    const deleteTestSession = () => {
        return testService
            .deleteTestSession(testSession.id)
            .then(
                () => {
                    // remove testSession from list
                    let _testSessions = testSessions.filter((item) => item.id !== testSession.id);
                    setTestSessions(_testSessions);
                    toast.current.show({ severity: 'success', summary: translatedMessage('generic.success'), detail: translatedMessage('generic.delete.success'), life: 3000 });
                },
                (error) => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
                }
            )
            .finally(() => {
                setDeleteDialogVisible(false);
                setTestSession(null);
            });
    };

    const handleExport = () => {
        let exportData = testSessions.map((item) => {
            let exportItem = {};
            exportItem.region = item.competitionTestCenterRoom.testCenterRoom.testCenter.region.label;
            exportItem.county = item.competitionTestCenterRoom.testCenterRoom.testCenter.county.label;
            exportItem.city = item.competitionTestCenterRoom.testCenterRoom.testCenter.city;
            exportItem.center = item.competitionTestCenterRoom.testCenterRoom.testCenter.name;
            exportItem.room = item.competitionTestCenterRoom.testCenterRoom.name;
            exportItem.capacity = item.competitionTestCenterRoom.testCenterRoom.capacity;
            exportItem.usedCapacity = item.usedCapacity;
            exportItem.startDate = GeneralUtils.formatDateTime(item.startDate);
            exportItem.endDate = GeneralUtils.formatDateTime(item.endDate);
            return exportItem;
        });

        return exportData
    };

    const handleNew = () => {
        setTestSession(null);
        setEditDialogVisible(true);
    };

    const handleEdit = (testSession) => {
        setTestSession(testSession);
        setEditDialogVisible(true);
    };

    const hideEditDialog = () => {
        setEditDialogVisible(false);
    };

    const handleDelete = (testSession) => {
        setTestSession(testSession);
        setDeleteDialogVisible(true);
    };

    // Used for eSkill tests to  upload scales
    // const handleUpload = () => {
    //     setShowUploadDialog(true)
    // };

    // const onFileUploadSuccess = (id, response) => {
    //     toast.current.show({ severity: 'success', summary: translatedMessage('generic.file.upload.success') });
    // };

    // const onFileUploadError = (errorMessage) => {
    //     toast.current.show({ severity: 'error', summary: errorMessage, life: 5000 });
    // };

    const viewApplications = (testSession) => {
        navigate(`/competition-evaluation/${competitionIdParam}/test-session/${testSession.id}`);
    };

    const confirmTestSessionsChoose = async () => {
        confirmDialog({
            message: translatedMessage('competition.testSession.enableSessionsChoose.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => startTestSessionsChoose()
        });
    };

    const startTestSessionsChoose = async () => {
        await competitionService.enableTestSessionsChoose(competitionIdParam)
            .then((_competition) => {
                setCompetition(_competition);
                toast.current.show({ severity: 'success', summary: translatedMessage('competition.testSession.enableSessionsChoose') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    // Table
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {hasPermission('EVALUATION_CREATE') && isInOrganizationCommittee && (
                        <Button
                            label={translatedMessage('testSession.newTestSession')}
                            icon="pi pi-plus"
                            className="p-button-primary mr-2"
                            onClick={handleNew}
                            disabled={competition?.testSessionsDistributionFinished}
                        />
                    )}
                    <Button
                        label={translatedMessage('testSession.viewData')}
                        icon="pi pi-eye"
                        className="p-button-primary mr-2"
                        onClick={() => {
                            setStatisticsDialogVisible(true);
                        }}
                    />
                    {isInOrganizationCommittee && (
                        <Button
                            label={translatedMessage('testSession.enableSessionChoose')}
                            icon="pi pi-check"
                            className="p-button-primary mr-2"
                            onClick={confirmTestSessionsChoose}
                            disabled={competition?.testSessionsChooseEnabled || competition.status !== 'PRELIMINARY_TEST'}
                        />
                    )}
                    {/* <Button
                        label={translatedMessage('test.scale.result.upload')}
                        icon="pi pi-upload"
                        className="p-button-primary mr-2"
                        onClick={handleUpload}
                        disabled={!competition?.testSessionsDistributionFinished || !hasPermission('EVALUATION_VIEW_ALL')}
                    /> */}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={DataTableExportUtils.testSessionListTableHeader()}
                sortOrderHeader={DataTableExportUtils.testSessionListSortOrderHeader()}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.testSessions"))}
            />
        );
    };

    const testCenterDisplayName = () => {
        return (
            <>
                {testSession && testSession.competitionTestCenterRoom && testSession.competitionTestCenterRoom.testCenterRoom
                    ? testSession.competitionTestCenterRoom.testCenterRoom.name + ' : ' + GeneralUtils.formatDateTime(testSession.startDate) + ' - ' + GeneralUtils.formatDateTime(testSession.endDate)
                    : ''}
            </>
        );
    };

    const actionBodyTemplate = (testSession) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-info m-1"
                    onClick={() => handleEdit(testSession)}
                    tooltip={translatedMessage('generic.edit')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!hasPermission('EVALUATION_CREATE') || !isInOrganizationCommittee || 'ACTIVE' !== testSession.competitionTestCenterRoom.status || testSession.competitionTestCenterRoom.competition.testSessionsDistributionFinished}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger m-1"
                    onClick={() => handleDelete(testSession)}
                    tooltip={translatedMessage('generic.delete')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!hasPermission('EVALUATION_CREATE') || !isInOrganizationCommittee || testSession.usedCapacity > 0 || testSession.competitionTestCenterRoom.competition.testSessionsDistributionFinished}
                />
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-info m-1"
                    onClick={() => viewApplications(testSession)}
                    tooltip={translatedMessage('testSession.applications.view')}
                    tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                    disabled={!hasPermission('EVALUATION_CREATE') || testSession.usedCapacity === 0}
                />
            </div>
        );
    };

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.first = event.first;
        _lazyParams.page = event.page;
        _lazyParams.rows = event.rows;
        setLazyParams(_lazyParams);
    };

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams.sortField = event.sortField;
        _lazyParams.sortOrder = event.sortOrder;
        setLazyParams(_lazyParams);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    };

    // Render
    if (firstLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <ConfirmDialog />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">{competition.name}</h3>
                            <div className="mb-3">
                                <SelectButton value={section} options={sections} onChange={(e) => handleSectionChange(e.value)} itemTemplate={navigationTemplate} className="pcn-selectbutton-slim" />
                            </div>
                            <div>
                                <h5 className="mb-1 font-weight-normal">{translatedMessage('testSession.testSessions')}</h5>
                            </div>
                            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                            <DataTable
                                ref={dt}
                                value={testSessions}
                                dataKey="id"
                                loading={loading}
                                lazy
                                paginator
                                totalRecords={totalRecords}
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                sortField={lazyParams.sortField}
                                sortOrder={lazyParams.sortOrder}
                                onPage={onPage}
                                onSort={onSort}
                                onFilter={onFilter}
                                rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                className="datatable-responsive pcn-datatable"
                                responsiveLayout="stack"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate={'{first} - {last} ' + translatedMessage('generic.of') + ' {totalRecords}'}
                                emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                filterDisplay="row"
                                filters={lazyParams.filters}
                            >
                                <Column
                                    header={translatedMessage('testCenter.testCenter')}
                                    sortable
                                    body={(_testSession) => TestSessionDataTableUtils.testCenterBodyTemplate(_testSession.competitionTestCenterRoom.testCenterRoom.testCenter)}
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    filterField="testCenterId"
                                    showFilterMenu={false}
                                    filterElement={(e) => TestSessionDataTableUtils.testCenterFilterTemplate(e, testCenters)}
                                />
                                <Column
                                    header={translatedMessage('competition.testCenterRoom.name')}
                                    sortable
                                    body={(_testSession) => TestSessionDataTableUtils.testCenterRoomBodyTemplate(_testSession.competitionTestCenterRoom.testCenterRoom)}
                                    headerStyle={{ width: '20%', minWidth: '10rem' }}
                                    filter
                                    filterField="testCenterRoomId"
                                    showFilterMenu={false}
                                    filterElement={(e) => TestSessionDataTableUtils.testCenterRoomFilterTemplate(e, testCenterRoomsToFilterBy)}
                                />
                                <Column field="competitionTestCenterRoom.testCenterRoom.capacity" header={translatedMessage('testCenter.room.capacity')} headerStyle={{ width: '5%', minWidth: '10rem' }} />
                                <Column field="usedCapacity" header={translatedMessage('testCenter.room.capacity.used')} headerStyle={{ width: '5%', minWidth: '10rem' }} />
                                <Column
                                    field="startDate"
                                    header={translatedMessage('generic.interval')}
                                    sortable
                                    body={(_testSession) => TestSessionDataTableUtils.dateTimeBodyTemplate(_testSession)}
                                    headerStyle={{ width: '5%', minWidth: '12rem' }}
                                    filter
                                    filterField="startDate"
                                    showFilterMenu={false}
                                    filterElement={(e) => TestSessionDataTableUtils.testCenterStartDateFilterTemplate(e)}
                                />
                                <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }}></Column>
                            </DataTable>

                            <TestSessionEditDialog visible={editDialogVisible} onSave={lazyLoadTestSessions} onCancel={hideEditDialog} value={testSession} competitionId={competitionIdParam} />

                            <CompetitionTestSessionsStatisticsDialog
                                visible={statisticsDialogVisible}
                                onCancel={() => {
                                    setStatisticsDialogVisible(false);
                                }}
                                competition={competition}
                            />

                            <DeleteDialog
                                visible={deleteDialogVisible}
                                recordDisplayName={testCenterDisplayName()}
                                onDelete={deleteTestSession}
                                onCancel={() => {
                                    setDeleteDialogVisible(false);
                                }}
                            />

                            {/* <FileUploadComponent
                                endpoint={`/test/eskill/${competitionIdParam}/results`}
                                entityId={null}
                                usageId={null}
                                multiple={false}
                                accept={["xlsx"]}
                                maxFileSize={26214400}
                                visible={showUploadDialog}
                                visibleSetter={setShowUploadDialog}
                                onUploadSuccess={onFileUploadSuccess}
                                onUploadError={onFileUploadError}
                            /> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TestSessionList;
