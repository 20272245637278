import ApiService from "./ApiService";
import GeneralUtils from '../utilities/GeneralUtils';

export class CompetitionService {

    async getCompetitionsByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/list`, criteria)
                .then((response) => {
                    response.data.competitions.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.submitEndDate = item.submitEndDate ? new Date(Date.parse(item.submitEndDate)) : '';
                        item.writtenTestDate = item.writtenTestDate ? new Date(Date.parse(item.writtenTestDate)) : '';
                        item.interviewTestDate = item.interviewTestDate ? new Date(Date.parse(item.interviewTestDate)) : '';
                        item.supplementaryTest1Date = item.supplementaryTest1Date ? new Date(Date.parse(item.supplementaryTest1Date)) : '';
                        item.supplementaryTest2Date = item.supplementaryTest2Date ? new Date(Date.parse(item.supplementaryTest2Date)) : '';
                        item.supplementaryTest3Date = item.supplementaryTest3Date ? new Date(Date.parse(item.supplementaryTest3Date)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCompetition(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    response.data.writtenTestDate = response.data.writtenTestDate ? new Date(Date.parse(response.data.writtenTestDate)) : '';
                    response.data.interviewTestDate = response.data.interviewTestDate ? new Date(Date.parse(response.data.interviewTestDate)) : '';
                    response.data.supplementaryTest1Date = response.data.supplementaryTest1Date ? new Date(Date.parse(response.data.supplementaryTest1Date)) : '';
                    response.data.supplementaryTest2Date = response.data.supplementaryTest2Date ? new Date(Date.parse(response.data.supplementaryTest2Date)) : '';
                    response.data.supplementaryTest3Date = response.data.supplementaryTest3Date ? new Date(Date.parse(response.data.supplementaryTest3Date)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getCompetitionDetails(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/details`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveCompetition(competition) {
        competition.supplementaryTest1Name = competition.supplementaryTest1Name ? competition.supplementaryTest1Name : null
        competition.supplementaryTest2Name = competition.supplementaryTest2Name ? competition.supplementaryTest2Name : null
        competition.supplementaryTest3Name = competition.supplementaryTest3Name ? competition.supplementaryTest3Name : null
        competition.description = competition.description ? competition.description : null
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/competition', competition)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    response.data.writtenTestDate = response.data.writtenTestDate ? new Date(Date.parse(response.data.writtenTestDate)) : '';
                    response.data.interviewTestDate = response.data.interviewTestDate ? new Date(Date.parse(response.data.interviewTestDate)) : '';
                    response.data.supplementaryTest1Date = response.data.supplementaryTest1Date ? new Date(Date.parse(response.data.supplementaryTest1Date)) : '';
                    response.data.supplementaryTest2Date = response.data.supplementaryTest2Date ? new Date(Date.parse(response.data.supplementaryTest2Date)) : '';
                    response.data.supplementaryTest3Date = response.data.supplementaryTest3Date ? new Date(Date.parse(response.data.supplementaryTest3Date)) : '';
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteCompetition(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/competition/${competitionId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }
    
    async getMyAvailableCompetitionListByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/available`, criteria)
                .then((response) => {
                    response.data.competitions.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.submitEndDate = item.submitEndDate ? new Date(Date.parse(item.submitEndDate)) : '';
                        item.writtenTestDate = item.writtenTestDate ? new Date(Date.parse(item.writtenTestDate)) : '';
                        item.interviewTestDate = item.interviewTestDate ? new Date(Date.parse(item.interviewTestDate)) : '';
                        item.supplementaryTest1Date = item.supplementaryTest1Date ? new Date(Date.parse(item.supplementaryTest1Date)) : '';
                        item.supplementaryTest2Date = item.supplementaryTest2Date ? new Date(Date.parse(item.supplementaryTest2Date)) : '';
                        item.supplementaryTest3Date = item.supplementaryTest3Date ? new Date(Date.parse(item.supplementaryTest3Date)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    // competition test centers

    async getTestCenterRoomList(competitionId, status) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/testCenterRooms${status ? '?status=' + status : ''}`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestCenters(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/testCenters`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestCenterRooms(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/rooms`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveCompetitionTestCenterRoom(competitionTestCenter) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/competition/testCenterRoom', competitionTestCenter)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async updateCompetitionTestCenterRooms(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/competition/${competitionId}/updateRooms`, competitionId)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    // competition test

    async getCompetitionTestList(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/tests`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async saveCompetitionTest(competitionTest) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/competition/test', competitionTest)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async updateCompetitionTestsOrder(competitionTests) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/competition/tests`, competitionTests)
                .then(response => {
                    response.data.map(item => {
                        return GeneralUtils.emptyInNullForObjectFields(item)
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async deleteCompetitionTest(competitionId, competitionTestId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().delete(`/competition/${competitionId}/test/${competitionTestId}`)
                .then(resolve)
                .catch(error => {
                    let errorMessage = "generic.delete.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async publishResults(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/publishResults`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getCompetitionListForEvaluationByCriteria(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .post(`/competition/list/evaluation`, criteria)
                .then((response) => {
                    response.data.competitions.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.submitEndDate = item.submitEndDate ? new Date(Date.parse(item.submitEndDate)) : '';
                        item.writtenTestDate = item.writtenTestDate ? new Date(Date.parse(item.writtenTestDate)) : '';
                        item.interviewTestDate = item.interviewTestDate ? new Date(Date.parse(item.interviewTestDate)) : '';                        
                        item.supplementaryTest1Date = item.supplementaryTest1Date ? new Date(Date.parse(item.supplementaryTest1Date)) : '';
                        item.supplementaryTest2Date = item.supplementaryTest2Date ? new Date(Date.parse(item.supplementaryTest2Date)) : '';
                        item.supplementaryTest3Date = item.supplementaryTest3Date ? new Date(Date.parse(item.supplementaryTest3Date)) : '';
                        return item;
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async getCompetitionResultByType(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/application/result`, criteria)
                .then((response) => {
                    resolve({ results: response.data.items, totalCount: response.data.totalCount })
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message && error.response.data.status !== 404) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async distributeCandidates(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/distributeCandidatesToTestSessions`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getTestSessionsStatistics(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/testSessionsStatistics`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getApplicationsTestSessionsAndTestCenters(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/applicationsTestSessions`, criteria)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getApplicationsTestSessions(competitionId, testSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/testSessions/${testSessionId}/applications`)
                .then(response => {
                    response.data.map(item => {
                        item.startDate = new Date(Date.parse(item.startDate));
                        item.endDate = new Date(Date.parse(item.endDate));
                        return item;
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async updateApplicationTestSessionPresence(applicationTestSessionId, isPresent, isCanceled, extendedTime) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/applicationTestSession/presence`, 
                { id: applicationTestSessionId, isPresent: isPresent, isCanceled: isCanceled, extendedTime: extendedTime }
            )
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async generateApplicationTestsUrls(applicationTestSessionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/applicationTestSession/generateTestUrls`, { id: applicationTestSessionId })
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.endDate = response.data.endDate ? new Date(Date.parse(response.data.endDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getAvailableTestCenters(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/availableTestCenters`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getAvailableSessions(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`/competition/${competitionId}/availableTestSessions`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getClarifications(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/clarifications`, criteria)
                .then(response => {
                    response.data.items.map((item) => {
                        item.fullName = item.firstName + ' ' + item.lastName;

                        return item;
                    });
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getContestations(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/contestations`, criteria)
                .then(response => {
                    response.data.items.map((item) => {
                        item.fullName = item.firstName + ' ' + item.lastName;

                        return item;
                    });
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }


    async moveToNextStatus(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/competition/${competitionId}/testOnly/moveToNextStatus`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async distributeApplicationsToEvaluators(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().put(`/competition/${competitionId}/distributeApplicationsToEvaluators`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getCommitteeListForClarifications(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/competition/${competitionId}/committees/clarifications`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async finishTestSessionsDistribution(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/finishTestSessionDistributions`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async enableTestSessionsChoose(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/enableSessionsChoose`)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getFinalReportCompetitionList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/competition/final-report/list')
                .then(response => {
                    let canEdit = false
                    if(response.data && response.data.length > 0) {
                        response.data.forEach(item => {
                            canEdit = !canEdit ? item.canEdit : canEdit
                        })
                        
                    }
                    resolve({data: response.data, canEdit: canEdit})
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }   

    async saveCompetitionFinalReport(finalReport) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post('/competition/final-report/edit', finalReport)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }    
    async getCompetitionJobs(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance()
                .get(`/competition/${competitionId}/jobs`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    let errorMessage = 'generic.error.get.data';
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                });
        });
    }

    async endContestationOpenStatus(competitionId) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/${competitionId}/endContestationOpenStatus`)
                .then(response => {
                    response.data.startDate = new Date(Date.parse(response.data.startDate));
                    response.data.submitEndDate = response.data.submitEndDate ? new Date(Date.parse(response.data.submitEndDate)) : '';
                    resolve(GeneralUtils.emptyInNullForObjectFields(response.data))
                })
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }

    async getInstitutions(criteria) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/institutions`, criteria)
                .then(response => { 
                    let _institutions = []
                    response.data.forEach(element => {
                        _institutions.push({label: element.name, value: element.id})
                    });            

                    resolve(_institutions) 
                })
                .catch(error => {
                    let errorMessage = "generic.error.get.data";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    reject(errorMessage)
                })
        })
    }   
    
    async generateJobCompetition(category) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().post(`/competition/generate-job-competition/${category}`)
                .then((response) => resolve(response.data))
                .catch(error => {
                    let errorMessage = "generic.save.error";
                    if (error && error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    }
                    reject(errorMessage);
                })
        })
    }    
}