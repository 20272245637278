import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { EvaluationService } from '../../service/EvaluationService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DOMPurify from 'dompurify';
import ApplicationEvaluationDialog from '../../components/evaluation/ApplicationEvaluationDialog';
import useAuth from '../../hooks/useAuth';
import EnumService from '../../service/EnumService';
import ErrorMessageDisplayComponent from '../../components/ErrorMessageDisplayComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StorageFolderFileList from '../../components/file/StorageFolderFileList';
import ApplicationTestQuestionsDialog from '../../components/application/ApplicationTestQuestionsDialog';
import ResultDataTableUtils from '../../utilities/ResultDataTableUtils';

const EvaluationTestingPage = () => {
    const [committee, setCommittee] = useState({});
    const [application, setApplication] = useState(null);
    const [evaluation, setEvaluation] = useState({});
    const [result, setResult] = useState({});
    const [previousResult, setPreviousResult] = useState({});

    const [applicationEvaluationDialogVisible, setApplicationEvaluationDialogVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("generic.error.get.data");
    const [evaluationStatuses, setEvaluationStatuses] = useState([]);
    const [applicationTests, setApplicationTests] = useState(null);
    const [isPreliminaryTest, setIsPreliminaryTest] = useState(false);
    const [isAdvancedTest, setIsAdvancedTest] = useState(false);
    const [isAdvancedTestContestation, setIsAdvancedTestContestation] = useState(false);
    const [testQuestions, setTestQuestions] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);
    const [answersDialogVisible, setAnswersDialogVisible] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);
    const evaluationService = useMemo(() => new EvaluationService(), []);

    const { competitionIdParam, evaluationIdParam } = useParams();

    const { auth } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true)

        const populateEvaluationStatuses = async () => {
            await EnumService.getEnumByName('EvaluationStatus').then((statuses) => {
                let _statuses = statuses.filter(item => item.value !== "CLARIFICATION")
                setEvaluationStatuses(_statuses)
            });
        };

        const getEvaluation = async () => {
            await evaluationService.getEvaluationById(evaluationIdParam)
                .then(async (_evaluation) => {
                    if (_evaluation?.evaluationCommitteeUser?.user.id === auth.user.id) {
                        setEvaluation(_evaluation)
                        setApplication(_evaluation.application)

                        populateEvaluationStatuses();
                        setCommittee(_evaluation.evaluationCommitteeUser.evaluationCommittee);

                        if (_evaluation.evaluationCommitteeUser.evaluationCommittee.type === "PRELIMINARY_TEST_CONTESTATION") {
                            await getApplicationTests(_evaluation.application?.id)
                            setIsPreliminaryTest(true)

                            if (!_evaluation.evaluationCommitteeUser.evaluationCommittee.active) {
                                await getApplicationResult(_evaluation?.evaluationCommitteeUser.evaluationCommittee.id, _evaluation?.application?.id);
                            }
                        } else {
                            setIsAdvancedTest(true)
                            await getApplicationResult(_evaluation?.evaluationCommitteeUser.evaluationCommittee.id, _evaluation?.application?.id);
                            if (_evaluation?.evaluationCommitteeUser.evaluationCommittee.type === "ADVANCED_TEST_CONTESTATION") {
                                await getApplicationResultByType(_evaluation?.application?.id, "ADVANCED_TEST")
                                setIsAdvancedTestContestation(true)
                            }
                        }


                    } else {
                        setError(true)
                        setErrorMessage("generic.access-denied")
                    }

                    setIsLoading(false)
                })
                .catch((error) => {
                    setError(true)
                    setErrorMessage(error)
                    setIsLoading(false)
                    toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
                });
        };

        const getApplicationResult = async (committeeId, applicationId) => {
            evaluationService.getCommitteeApplicationResult(committeeId, applicationId)
                .then((_result) => {
                    setResult(_result);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationResultByType = async (applicationId, resultType) => {
            await evaluationService.getApplicationResultByType(applicationId, resultType)
                .then((_result) => {
                    setPreviousResult(_result);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getApplicationTests = async (applicationId) => {
            await applicationService.getApplicationTests(applicationId)
                .then((response) => {
                    let _applicationTests = response.applicationTestList

                    if (response.preliminaryTestScore) {
                        let _preliminaryTests = _applicationTests.filter(item => !item.competitionTest.advancedTest)
                        let _advancedTests = _applicationTests.filter(item => item.competitionTest.advancedTest)
                        _applicationTests = _preliminaryTests

                        _applicationTests.push({
                            competitionTest: {
                                test: { name: translatedMessage("test.preliminaryTest.overallScore") },
                                passingScore: response.preliminaryTestPassingScore
                            },
                            result: response.preliminaryTestScore,
                            isOverAllScore: true
                        })

                        _advancedTests.forEach(item => _applicationTests.push(item))
                    }
                    setApplicationTests(_applicationTests);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getEvaluation()
    }, [applicationService, evaluationService, evaluationIdParam, auth.user.id]);

    const onEvaluationChange = (evaluationId, comment, status, updatedEvaluation) => {
        setEvaluation(updatedEvaluation)
        setApplication(updatedEvaluation.application)
    };

    const getSelectedAnswers = async (_applicationTest) => {
        await applicationService.getSelectedAnswers(_applicationTest)
            .then(_answers => {
                setTestQuestions(_answers)
                setSelectedTest(_applicationTest)
                setAnswersDialogVisible(true)
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const getCorrectionScale = async (_applicationTest) => {
        await applicationService.getCorrectionScale(_applicationTest)
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 })
            });
    }

    const actionBodyTemplate = (_applicationTest) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {!_applicationTest.isOverAllScore && <>
                    <Button icon="pi pi-list" className="p-button-rounded p-button-info m-1"
                        onClick={() => getSelectedAnswers(_applicationTest)}
                        tooltip={translatedMessage("test.scale.view")} tooltipOptions={{ position: 'top' }} />

                    <Button icon="pi pi-download" className="p-button-rounded p-button-info m-1"
                        onClick={() => getCorrectionScale(_applicationTest)}
                        disabled={_applicationTest?.competitionTest?.test?.provider !== "DIGITEST" || !_applicationTest.hasFile}
                        tooltip={translatedMessage("test.scale.view")} tooltipOptions={{ position: 'top' }} />
                </>}
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else if (error) {
        return (
            <div className='w-full flex align-items-center'>
                <ErrorMessageDisplayComponent message={errorMessage} />
            </div>
        )
    } else {
        return (
            <>
                <Toast ref={toast} />

                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <h3 className="mb-1">
                                {translatedMessage('evaluation.evaluation')} {translatedMessage('generic.for')} {application?.firstName} {application?.lastName}
                            </h3>
                            <Button className="pcn-button-slim p-button-text mb-3"
                                onClick={() => navigate(`/competition-evaluation/${competitionIdParam}`)}>
                                <div className='flex align-items-center'>
                                    <FontAwesomeIcon icon='fa-solid fa-arrow-left' className="mr-1 " />
                                    <span>{translatedMessage('generic.backToList')}</span>
                                </div>
                            </Button>

                            <div className="w-full grid pcn-data-view-panel">
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('competition.competition')}</div>
                                    <div className="filed-value">{application?.competition?.name}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('evaluation.committee')}</div>
                                    <div className="filed-value">{committee.name}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('generic.type')}</div>
                                    <div className="filed-value">{translatedMessage('EvaluationCommitteeType.' + committee.type)}</div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="filed-label">{translatedMessage('committee.member.role')}</div>
                                    <div className="filed-value">
                                        {evaluation.evaluationCommitteeUser.isPresident
                                            ? translatedMessage('committee.member.isPresident')
                                            : evaluation.evaluationCommitteeUser.isSecretary
                                                ? translatedMessage('committee.member.isSecretary')
                                                : translatedMessage('committee.member.isSimpleMember')
                                        }
                                    </div>
                                </div>

                                <div className="col-12 mt-3">
                                    <h5 className='mb-1'>{translatedMessage(committee.active ? 'evaluation.evaluationStatus' : 'evaluation.result')}</h5>
                                    <div className="filed-value flex align-items-center">
                                        {committee.active ? (
                                            <span className={`status status-application-${application.status.toString().toLowerCase()}`}>{translatedMessage('ApplicationStatus.' + application.status)}</span>
                                        ) : (
                                            <span className={`status status-result-${result?.result?.toString().toLowerCase()}`}>{translatedMessage('Result.' + result?.result)}</span>
                                        )}
                                    </div>
                                </div>

                                {isPreliminaryTest &&
                                    <div className="col-12 mt-3 mb-3">
                                        <h5 className="mb-1">{translatedMessage('test.tests')}</h5>
                                        <DataTable
                                            ref={dt}
                                            value={applicationTests}
                                            dataKey="id"
                                            className="datatable-responsive pcn-datatable"
                                            responsiveLayout="stack"
                                            emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                        >
                                            <Column
                                                field="competitionTest.testOrder"
                                                header={translatedMessage('test.order')}
                                                headerStyle={{ width: '5%', minWidth: '6rem' }}
                                                body={(e) => e.isOverAllScore ? "" : e.competitionTest.testOrder}
                                            />
                                            <Column
                                                field="competitionTest.test.name"
                                                header={translatedMessage('test.name')}
                                                headerStyle={{ width: '50%', minWidth: '10rem' }}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column
                                                field="competitionTest.passingScore"
                                                header={translatedMessage('test.passingScore')}
                                                headerStyle={{ width: '15%', minWidth: '10rem' }}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column
                                                field="result"
                                                header={translatedMessage('test.result')}
                                                headerStyle={{ width: '15%', minWidth: '10rem' }}
                                                body={(e) => ResultDataTableUtils.resultBodyTemplate(e)}
                                                bodyClassName={(e) => e.isOverAllScore ? 'font-bold' : ""}
                                            />
                                            <Column body={actionBodyTemplate}></Column>
                                        </DataTable>
                                    </div>
                                }

                                {isAdvancedTest && (
                                    <>
                                        <div className="col-12 mt-3 mb-3">
                                            <h5 className="mb-1">{translatedMessage('test.advanced.result')}</h5>
                                            <div className="filed-label">{translatedMessage('generic.files')}</div>
                                            <StorageFolderFileList
                                                folderId={!isAdvancedTestContestation ? result?.folder?.id : previousResult?.folder?.id}
                                                folderName={result?.folder?.name}
                                                showUploadButton={true}
                                                readOnly={true}
                                                hidePaginator={true} />

                                            <div className="mt-5 filed-label">{translatedMessage('application.result.comment')}</div>
                                            <div className='filed-value'>
                                                {(result?.comment || previousResult?.comment)
                                                    ? <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(!isAdvancedTestContestation ? result?.comment : previousResult?.comment) }} />
                                                    : "-"
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="col-12">
                                    <div className='flex align-items-center'>
                                        <h5 className='mb-1'>{translatedMessage("evaluation.my")}</h5>
                                        {committee.active &&
                                            <Button label={translatedMessage('generic.edit')} icon="pi pi-pencil" className="p-button-text pcn-button-slim ml-2"
                                                onClick={() => setApplicationEvaluationDialogVisible(true)} />
                                        }
                                    </div>
                                    <div className="w-full grid pcn-data-view-panel">
                                        <div className='col-12 pb-0 p-fluid mb-2'>
                                            <div className="filed-label">{translatedMessage('generic.status')}</div>
                                            <span className={`status status-evaluation-${evaluation?.status.toString().toLowerCase()}`} >
                                                {translatedMessage('EvaluationStatus.' + evaluation?.status)}
                                            </span>
                                        </div>
                                        <div className='col-12 pb-0'>
                                            <div className="filed-label mb-0">{translatedMessage('evaluation.comment')}</div>
                                            <div className='filed-value text-justify'>{evaluation?.comment || '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {applicationEvaluationDialogVisible &&
                    <ApplicationEvaluationDialog
                        visible={applicationEvaluationDialogVisible}
                        visibleSetter={setApplicationEvaluationDialogVisible}
                        applicationEvaluation={evaluation}
                        afterSave={onEvaluationChange}
                        statusOptions={evaluationStatuses}
                    />
                }

                {answersDialogVisible &&
                    <ApplicationTestQuestionsDialog
                        visible={answersDialogVisible}
                        questions={testQuestions}
                        applicationTest={selectedTest}
                        title={translatedMessage("test.scale.view")}
                        closeDialog={() => setAnswersDialogVisible(false)}
                    />
                }
            </>
        );
    }
};

export default EvaluationTestingPage;
