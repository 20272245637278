import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DOMPurify from 'dompurify';
import { translatedMessage } from '../../service/LanguageService';
import { CompetitionService } from '../../service/CompetitionService';
import useNavProps from '../../hooks/useNavProps';
import GeneralUtils from '../../utilities/GeneralUtils';
import CompetitionToolbar from './CompetitionToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompetitionEditDialog from './CompetitionEditDialog';
import EnumService from '../../service/EnumService';
import MoveCompetitionStatusForward from '../../components/competition/MoveCompetitionStatusForward';
import ApiService from '../../service/ApiService';
import CompetitionUtils from '../../utilities/CompetitionUtils';
import DataTableUtils from '../../utilities/DataTableUtils';
import SecurityService from '../../service/SecurityService';

const CompetitionView = () => {
    const [competition, setCompetition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [competitionStatuses, setCompetitionStatuses] = useState([]);
    const [competitionTypes, setCompetitionTypes] = useState([]);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [jobs, setJobs] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const competitionService = useMemo(() => new CompetitionService(), []);

    const { competitionIdParam } = useParams();

    const { setNavPropsValue } = useNavProps();

    useEffect(() => {
        setIsLoading(true);
        const getCompetitionStatuses = async () => {
            setCompetitionStatuses(await EnumService.getEnumByName('CompetitionStatus'));
        };

        const getCompetitionTypes = async () => {
            setCompetitionTypes(await EnumService.getEnumByName('CompetitionType'));
        };

        const checkEditPermission = async () => {
            setHasEditPermission(await SecurityService.check('CAN_EDIT_JOB_COMPETITION', competitionIdParam));
        };

        const getCompetition = async () => {
            await competitionService.getCompetition(competitionIdParam)
                .then(async (competitionData) => {
                    // console.log(competitionData);
                    setCompetition(competitionData);

                    if (CompetitionUtils.isJobCompetition(competitionData.type)) {
                        await getJobs(competitionIdParam)
                    }

                    await checkEditPermission()

                    setNavPropsValue('competition', competitionData.id, competitionData.name)

                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        const getJobs = async (competitionId) => {
            await competitionService.getCompetitionJobs(competitionId)
                .then((_jobs) => {
                    setJobs(_jobs);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCompetitionStatuses();
        getCompetitionTypes();
        getCompetition();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competitionService, competitionIdParam]);

    const getJobDetails = (data) => {
        return (
            <>
                {data.jobClass && <div><span className="font-bold">{translatedMessage("job.jobClass") + ": "}</span>{data.jobClass}</div>}
                {data.level && <div><span className="font-bold">{translatedMessage("job.level") + ": "}</span>{data.level}</div>}
                {data.departmentType && <div><span className="font-bold">{translatedMessage("job.departmentType") + ": "}</span>{data.departmentType}</div>}
                {data.departmentName && <div><span className="font-bold">{translatedMessage("job.departmentName") + ": "}</span>{data.departmentName}</div>}
            </>
        );
    };

    const getJobLocation = (data) => {
        return (
            <>
                {data.institution?.name && <div>{data.institution.name}</div>}
                {data.institution?.locality && <div><span className="font-bold">{translatedMessage("generic.locality") + ": "}</span>{data.institution.locality}</div>}
                {data.institution?.county && <div><span className="font-bold">{translatedMessage("generic.county") + ": "}</span>{data.institution.county}</div>}
            </>
        );
    };

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="card h-full">
                            <CompetitionToolbar competition={competition} code="DETAIL" />


                            <div className='w-full grid pcn-data-view-panel'>
                                <div className='col-12'>
                                    <h5 className='mb-1 font-weight-normal'>{translatedMessage("competition.details")}</h5>
                                    <Button
                                        className="pcn-button-slim p-button-text"
                                        onClick={() => setEditDialogVisible(true)}
                                        visible={hasEditPermission}
                                    >
                                        <div className='flex align-items-center'>
                                            <FontAwesomeIcon icon='fa-solid fa-pencil' className="mr-1 " />
                                            <span>{translatedMessage('generic.edit')}</span>
                                        </div>
                                    </Button>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('generic.nameRo')}</div>
                                    <div className='filed-value'>{competition.name}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('generic.status')}</div>
                                    <div className='filed-value'>
                                        <span className={`pill status status-competition-${competition.status.toLowerCase()}`}>{translatedMessage("CompetitionStatus." + competition.status)}</span>
                                    </div>
                                </div>
                                {CompetitionUtils.isNationalCompetition(competition.type) && competition.positionType &&
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('competition.positionType')}</div>
                                        <div className='filed-value'>{translatedMessage("PositionType." + competition.positionType)}</div>
                                    </div>
                                }
                                {CompetitionUtils.isJobCompetition(competition.type) && competition.positionCategory &&
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('competition.positionCategory')}</div>
                                        <div className='filed-value'>{translatedMessage("PositionCategory." + competition.positionCategory)}</div>
                                    </div>
                                }
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('competition.institution')}</div>
                                    <div className='filed-value'>{competition.institution?.name ? competition.institution.name : "-"}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.startDate')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(competition.startDate)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.submitEndDate')}</div>
                                    <div className='filed-value'>{GeneralUtils.formatDate(competition.submitEndDate)}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.clarificationInterval')}</div>
                                    <div className='filed-value'>{competition.clarificationInterval} {translatedMessage("generic.days")}</div>
                                </div>
                                <div className='col-12 md:col-6'>
                                    <div className='filed-label'>{translatedMessage('competition.contestationInterval')}</div>
                                    <div className='filed-value'>{competition.contestationInterval} {translatedMessage("generic.days")}</div>
                                </div>
                                {CompetitionUtils.isJobCompetition(competition.type) &&
                                    <>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.writtenTestDate')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.writtenTestDate)}</div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.interviewTestDate')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.interviewTestDate)}</div>
                                        </div>

                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Name')}</div>
                                            <div className='filed-value'>{competition.supplementaryTest1Name ? competition.supplementaryTest1Name : "-"}</div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest1Date')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest1Date)}</div>
                                        </div>

                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Name')}</div>
                                            <div className='filed-value'>{competition.supplementaryTest2Name ? competition.supplementaryTest2Name : "-"}</div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest2Date')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest2Date)}</div>
                                        </div>

                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Name')}</div>
                                            <div className='filed-value'>{competition.supplementaryTest3Name ? competition.supplementaryTest3Name : "-"}</div>
                                        </div>
                                        <div className='col-12 md:col-6'>
                                            <div className='filed-label'>{translatedMessage('competition.supplementaryTest3Date')}</div>
                                            <div className='filed-value'>{GeneralUtils.formatDateTime(competition.supplementaryTest3Date)}</div>
                                        </div>

                                        {jobs.length > 0 &&
                                            <div className='col-12 mb-3'>
                                                <div className='filed-label mt-3 mb-2'>{translatedMessage('job.paragraphTitle')}</div>
                                                <DataTable
                                                    ref={dt}
                                                    value={jobs}
                                                    dataKey="id"
                                                    paginator
                                                    rows={DataTableUtils.defalRowsPerPage()}
                                                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                                                    className="datatable-responsive pcn-datatable"
                                                    responsiveLayout="scroll"
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                                                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                                                >
                                                    <Column
                                                        field="position"
                                                        header={translatedMessage('job.position')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="jobClass"
                                                        header={translatedMessage('generic.details')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobDetails}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                    <Column
                                                        field="institution.name"
                                                        header={translatedMessage('job.location')}
                                                        sortable
                                                        headerStyle={{ width: '25%', minWidth: '10rem' }}
                                                        body={getJobLocation}
                                                        filter
                                                        showFilterMenu={false}
                                                    />
                                                </DataTable>
                                            </div>
                                        }
                                    </>
                                }
                                <div className='col-12'>
                                    <div className='filed-label'>{translatedMessage('generic.description')}</div>
                                    <div className='filed-value'>
                                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(competition.description) }} />
                                    </div>
                                </div>
                                {CompetitionUtils.isJobCompetition(competition?.type) && competition?.bibliography &&
                                    <div className='col-12'>
                                        <div className='filed-label'>{translatedMessage('competition.bibliography')}</div>
                                        <div className='filed-value'>
                                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(competition.bibliography) }} />
                                        </div>
                                    </div>
                                }
                                {!ApiService.getIsProduction() &&
                                    <MoveCompetitionStatusForward competition={competition} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {editDialogVisible &&
                    <CompetitionEditDialog
                        value={competition}
                        visible={editDialogVisible}
                        afterSave={(savedCompetition) => setCompetition(savedCompetition)}
                        visibleSetter={() => setEditDialogVisible(false)}
                        statuses={competitionStatuses}
                        types={competitionTypes}
                    />
                }
            </>
        );
    }
};

export default CompetitionView;
