import React, { useState, useRef, useMemo } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { UserService } from '../../service/UserService';
import { translatedMessage } from '../../service/LanguageService';
import { EvaluationService } from '../../service/EvaluationService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

const ChooseMemberDialog = (props) => {
    const [evaluators, setEvaluators] = useState(false);
    const [visible, setVisible] = useState(false);
    const [filters2] = useState({
        'user.fullName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        institution: { value: null, matchMode: FilterMatchMode.IN },
        rol: { value: null, matchMode: FilterMatchMode.IN }
    });
    const [institutions, setInstitutions] = useState([]);

    const [loadingEvaluators, setLoadingEvaluators] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const evaluationService = useMemo(() => new EvaluationService(), []);
    const userService = useMemo(() => new UserService(), []);

    const setChoosenUser = (rowData) => {
        if (typeof props.onChooseMember === 'function') {
            props.onChooseMember(rowData);
        }
        setVisible(false);
    };

    const getEvaluators = async () => {
        setLoadingEvaluators(true);
        evaluationService
            .getPossibleCommitteeMembers(props.committeeId)
            .then((_users) => {
                setEvaluators(_users);
                setLoadingEvaluators(false);
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const getInstitutions = async () => {
        userService
            .getInstitutions()
            .then((_institutions) => {
                _institutions.unshift({ id: '', name: translatedMessage('generic.other') });
                setInstitutions(_institutions);
            })
            .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
    };

    const showPossibleMembers = () => {
        setVisible(true);
        if (!evaluators || evaluators.length === 0) {
            getEvaluators();
        }
        if (!institutions || institutions.length === 0) {
            getInstitutions();
        }
    };

    const committeeDialogFooter = (
        <>
            <Button
                label={translatedMessage('generic.close')}
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setVisible(false);
                }}
                type="button"
            />
        </>
    );

    const actionBodyTemplate = (evaluator) => {
        return (
            <Button
                icon="pi pi-user-plus"
                className="p-button-rounded p-button-info m-1"
                onClick={() => setChoosenUser(evaluator)}
                tooltip={translatedMessage('generic.add')}
                tooltipOptions={{ showOnDisabled: true, position: 'top' }}
                disabled={!evaluator.canBeMember}
            />
        );
    };

    const userBodyTemplate = (evaluator) => {
        return (
            <>
                <span>
                    <div style={{ display: 'block', marginBottom: 5 }}>
                        {evaluator.user.firstName} {evaluator.user.lastName}
                    </div>
                    <div>{evaluator.user.username}</div>
                </span>
            </>
        );
    };

    const roleBodyTemplate = (evaluator) => {
        return (
            <>
                <span>
                    <div style={{ display: 'block', marginBottom: 5 }}>{evaluator.canBePresident ? translatedMessage('committee.member.isPresident') : ''}</div>
                    <div style={{ display: 'block', marginBottom: 5 }}>{evaluator.canBeSecretary ? translatedMessage('committee.member.isSecretary') : ''}</div>
                    <div>{evaluator.canBeMember ? translatedMessage('committee.member.isSimpleMember') : ''}</div>
                </span>
            </>
        );
    };

    const institutionFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={institutions} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="name" optionValue="name" placeholder="Any" className="p-column-filter" />;
    };

    return (
        <>
            <Toast ref={toast} />
            <Button icon="pi pi-search" onClick={showPossibleMembers} type="button" tooltip={translatedMessage('committee.member.select')} />
            <Dialog
                visible={visible}
                header={translatedMessage('committee.member.select')}
                modal
                className="pcn-dialog p-fluid"
                footer={committeeDialogFooter}
                onHide={() => {
                    setVisible(false);
                }}
            >
                <DataTable
                    loading={loadingEvaluators}
                    ref={dt}
                    value={evaluators}
                    dataKey="id"
                    className="datatable-responsive pcn-datatable"
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    responsiveLayout="stack"
                    // scrollHeight="500px"
                    filters={filters2}
                    filterDisplay="row"
                >
                    <Column 
                        field="user.fullName" 
                        header={translatedMessage('generic.username')} 
                        sortable 
                        body={userBodyTemplate} 
                        filterField="user.fullName" 
                        filter 
                        showFilterMenu={false} 
                        filterMenuStyle={{ width: '15rem' }}
                    />
                    <Column 
                        field="position" 
                        header={translatedMessage('generic.jobTitle')} 
                        sortable 
                        filterField="position" 
                        filter 
                        showFilterMenu={false} 
                        filterMenuStyle={{ width: '15rem' }}
                    />
                    <Column
                        field="institution"
                        header={translatedMessage('generic.institution')}
                        sortable
                        filterField="institution"
                        filter
                        showFilterMenu={false}
                        filterMenuStyle={{ width: '15rem' }}
                        filterElement={institutionFilterTemplate}
                    ></Column>
                    <Column field="role" header={translatedMessage('committee.member.role')} sortable body={roleBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }}></Column>
                </DataTable>
            </Dialog>
        </>
    );
};

export default ChooseMemberDialog;
