import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FilterMatchMode } from 'primereact/api';

import { EvaluationService } from '../../service/EvaluationService';
import { translatedMessage } from '../../service/LanguageService';
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";

import CommitteeEditDialog from './CommitteeEditDialogComponent';
import DataTableUtils from '../../utilities/DataTableUtils';
import DeleteModalComponent from '../DeleteModalComponent';
import EvaluationDataTableUtils from '../../utilities/EvaluationDataTableUtils';
import EnumService from '../../service/EnumService';
import CommitteeUsedRoles from './CommitteeUsedRoles';
import ExportButtonComponent from '../ExportButtonComponent';
import GeneralUtils from '../../utilities/GeneralUtils';

const tableHeader = [
    translatedMessage("generic.nameRo"),
    translatedMessage("competition.competition"),
    translatedMessage("generic.type"),
    translatedMessage("generic.startDate"),
    translatedMessage("generic.endDate"),
    translatedMessage("generic.status"),
    translatedMessage("committee.member.roles")
]

const CommitteeListComponent = (props) => {
    const [committees, setCommittees] = useState([]);
    const [selectedCommittee, setSelectedCommittee] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [competition, setCompetition] = useState(null);
    const [committeeTypes, setCommitteeTypes] = useState([]);

    const [filters] = useState({
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'type': { value: null, matchMode: FilterMatchMode.IN },
        'active': { value: null, matchMode: FilterMatchMode.IN },
    });

    const toast = useRef(null);
    const dt = useRef(null);
    const { auth } = useAuth();
    const navigate = useNavigate();

    const evaluationService = useMemo(() => new EvaluationService(), []);

    const hasPermission = (permissionName) => {
        if (auth && auth.user && auth.user.permissions) {
            return auth.user.permissions.indexOf(permissionName) > -1;
        } else {
            return false;
        }
    };

    useEffect(() => {
        setIsLoading(true);

        const getCommitteeTypes = async () => {
            await EnumService.getEnumByNameAndParam('EvaluationCommitteeType', props?.competition?.id).then((types) => setCommitteeTypes(types));
        };

        const getCommittees = async () => {
            await evaluationService.getCommitteeListByCompetitionId(props?.competition?.id)
                .then((_committees) => {
                    setCommittees(_committees);
                    setCompetition(props?.competition)
                    setCanEdit(props?.canEdit ? true : false)
                    setIsLoading(false);
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getCommitteeTypes();
        getCommittees()
    }, [props, evaluationService]);

    const updateList = async (savedCommittee, isNew) => {
        let updatedCommities = DataTableUtils.updateTableList(committees, savedCommittee, isNew)

        setCommittees(updatedCommities);
    };

    const showNewDialog = () => {
        setEditDialogVisible(true);
    };

    const showEditDialog = (committee) => {
        setSelectedCommittee(committee);
        setEditDialogVisible(true);
    };

    const hideEditDialog = () => {
        setSelectedCommittee(null);
        setEditDialogVisible(false);
    };

    const showCommitteePage = (committee) => {
        navigate(`${props?.viewCommitteeLink}/${committee.id}`);
    };

    const showDeleteDialog = (committee) => {
        setSelectedCommittee(committee);
        setDeleteDialogVisible(true);
    };

    const hideDeleteDialog = () => {
        setDeleteDialogVisible(false);
    };

    const deleteCommittee = () => {
        evaluationService.deleteCommittee(selectedCommittee.id)
            .then(() => {
                let _committees = committees.filter(item => item.id !== selectedCommittee.id)
                setCommittees(_committees)
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.delete.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            })
            .finally(() => {
                setDeleteDialogVisible(false);
                setSelectedCommittee(null);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {canEdit &&
                    <div className="my-2">{hasPermission('COMPETITION_CREATE') && <Button label={translatedMessage('committee.newCommittee')} icon="pi pi-plus" className="p-button-primary mr-2" onClick={showNewDialog} />}</div>
                }
            </React.Fragment>
        );
    };

    const handleExportRoles = (_roles) => {
        let response = ""

        _roles?.forEach((role) => {
            response += response ? "; " : ""
            response += translatedMessage('committee.member.' + role.name) + " (" + role.number + ")"
        })

        return response
    }

    const handleExport = () => {
        let exportData = committees.map(item => ({ ...item }));
        exportData.map(item => {
            let exportItem = item
            exportItem.active = translatedMessage('committee.' + (item.active ? 'ACTIVE' : 'INACTIVE'))
            exportItem.type = translatedMessage("EvaluationCommitteeType." + item.type)
            exportItem.addedRoles = handleExportRoles(item.addedRoles)
            delete exportItem.competitionId
            delete exportItem.competitionType
            delete exportItem.folderId
            delete exportItem.description

            return item;
        })

        return exportData
    }

    const rightToolbarTemplate = () => {
        return (
            <ExportButtonComponent
                getExportData={handleExport}
                header={tableHeader}
                fileName={GeneralUtils.replaceSpaces(translatedMessage("competition.committees"))}
            />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return DataTableUtils.dateIntervalTemplate(rowData.startDate, rowData.endDate)
    }

    const rolesBodyTemplate = (rowData) => {
        return <CommitteeUsedRoles roles={rowData.addedRoles} />;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                {(hasPermission('COMPETITION_EDIT') || hasPermission('COMPETITION_VIEW')) &&
                    <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => showCommitteePage(rowData)}
                        tooltip={translatedMessage("generic.view")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {canEdit && hasPermission('COMPETITION_EDIT') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info m-1" onClick={() => showEditDialog(rowData)}
                        tooltip={translatedMessage("generic.edit")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
                {canEdit && hasPermission('COMPETITION_CREATE') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger m-1" onClick={() => showDeleteDialog(rowData)} disabled={rowData.id < 1000}
                        tooltip={translatedMessage("generic.delete")} tooltipOptions={{ showOnDisabled: true, position: 'top' }} />
                }
            </div>
        );
    };

    const committeeTypeTemplate = (_committeeType) => {
        return <div>{committeeTypes?.find((type) => type.value === _committeeType)?.label}</div>;
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <Toast ref={toast} />
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div>
                    <h5 className='mb-1 font-weight-normal'>{translatedMessage("competition.committees")}</h5>
                </div>
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="px-0 pt-0"></Toolbar>

                <DataTable
                    ref={dt}
                    value={committees}
                    dataKey="id"
                    paginator
                    rows={DataTableUtils.defalRowsPerPage()}
                    rowsPerPageOptions={DataTableUtils.rowsPerPageOptions()}
                    className="datatable-responsive pcn-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                    responsiveLayout="stack"
                    filterDisplay="row"
                    filters={filters}
                    globalFilterFields={['name', 'type', 'active']}
                >
                    <Column
                        field="name"
                        header={translatedMessage('generic.nameRo')}
                        sortable
                        headerStyle={{ width: '20%', minWidth: '10rem' }}
                        filter
                        showFilterMenu={false}
                    />
                    <Column
                        field="type"
                        header={translatedMessage('generic.type')}
                        sortable
                        body={(e) => committeeTypeTemplate(e.type)}
                        headerStyle={{ width: '30%', minWidth: '10rem' }}
                        filter
                        filterElement={(e) => EvaluationDataTableUtils.committeeTypeFilterTemplate(e, committeeTypes)}
                        showFilterMenu={false}
                    />
                    <Column
                        field="active"
                        header={translatedMessage('generic.status')}
                        body={(e) => EvaluationDataTableUtils.committeeStatusBodyTemplate(e.active)}
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                        filter
                        filterElement={(e) => EvaluationDataTableUtils.committeeStatusFilterTemplate(e)}
                        showFilterMenu={false}
                    />
                    <Column
                        field="startDate"
                        header={translatedMessage('generic.interval')}
                        sortable
                        body={dateBodyTemplate}
                        headerStyle={{ width: '5%', minWidth: '8rem' }}
                    />
                    <Column
                        field="addedRoles"
                        header={translatedMessage('committee.member.roles')}
                        body={rolesBodyTemplate}
                        headerStyle={{ width: '15%', minWidth: '8rem' }}
                    />
                    <Column body={actionBodyTemplate} bodyStyle={{ textAlign: 'right' }} />
                </DataTable>


                {editDialogVisible &&
                    <CommitteeEditDialog
                        value={selectedCommittee}
                        visible={editDialogVisible}
                        afterSave={updateList}
                        visibleSetter={hideEditDialog}
                        competition={competition}
                    />
                }

                {deleteDialogVisible &&
                    <DeleteModalComponent
                        visible={deleteDialogVisible}
                        item={selectedCommittee ? selectedCommittee.name : ''}
                        closeDialog={hideDeleteDialog}
                        deleteRecord={() => deleteCommittee()}
                    />
                }

            </>
        );
    }
};

export default CommitteeListComponent;
