import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { translatedMessage } from '../../service/LanguageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompetitionUtils from '../../utilities/CompetitionUtils';

const ProfileToolbar = (props) => {
    const [user, setCompetition] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const _sections = [
        { label: translatedMessage('generic.details'), code: 'DETAIL', icon: 'fa-regular fa-eye', url: "/" },
        { label: translatedMessage('profile.education'), code: 'EDUCATION', icon: 'fa-solid fa-user-graduate', url: "/education" },
        { label: translatedMessage('profile.workExperience'), code: 'WORK', icon: 'fa-solid fa-building-columns', url: `/workExperience` },
        { label: translatedMessage('profile.itKnowledge'), code: 'IT', icon: 'fa-solid fa-computer', url: "/itKnowledge" },
        { label: translatedMessage('profile.language'), code: 'LANGUAGE', icon: 'fa-solid fa-globe', url: "/language" },
        { label: translatedMessage('generic.files'), code: 'FILE', icon: 'fa-solid fa-file', url: "/file" },
        { label: translatedMessage('generic.backToList'), code: 'BACK', icon: 'fa-solid fa-arrow-left' }
    ];
    
    let _section = props ? _sections.filter(item => item.code === props.code)[0] : _sections[0];
    const [section, setSection] = useState(_section);
    const [sections, setSections] = useState(_sections);
    

    const navigate = useNavigate();

    useEffect(() => {
        setCompetition(props?.user)
        if(props?.user && CompetitionUtils.isJobCompetition(props?.user?.type)){
            let enabledSections  = _sections.filter(item => item.code !== 'TEST_CENTER' && item.code !== 'TEST');
            setSections(enabledSections)
        }

        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const navigationTemplate = (option) => {
        return (
            <div className='flex align-items-center'>
                <FontAwesomeIcon icon={option.icon} className="mr-1 " />
                <span>{option.label}</span>
            </div>
        )
    }

    const handleSelectButtonChange = (value) => {
        let url = null
        if (value) {
            if (value.code === 'BACK') {
                navigate(`/administration/users`);
            } else {
                if (value && value.url && value.url !== '#') {
                    url = `/administration/users/${user.id}${value.url}`
                    navigate(url);
                } else {
                    setSection(value)
                }
            }
        }
    }

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
            </div>
        );
    } else {
        return (
            <>
                <h3 className='mb-0'>{user?.fullName}</h3>
                <SelectButton
                    value={section}
                    options={sections}
                    onChange={(e) => handleSelectButtonChange(e.value)}
                    itemTemplate={navigationTemplate}
                    className="pcn-selectbutton-slim mb-3"
                />

            </>
        );
    }
};

export default ProfileToolbar;
