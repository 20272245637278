import React from 'react';
// import { classNames } from 'primereact/utils';
// import { Button } from 'primereact/button';
// import { RTLContext } from './App';
import { translatedMessage } from './service/LanguageService';
import ApiService from './service/ApiService';

const AppFooter = (props) => {
    // const isRTL = useContext(RTLContext);

    return (
        <div className="layout-footer flex align-items-center py-1 px-4 shadow-2">
            <div className='w-full flex flex-row align-items-center justify-content-between'>
                <img id="footer-logo" src={`assets/layout/images/footer-logo${props.colorMode === 'light' ? '' : '-dark'}.png`} alt="logo"
                    style={{ height: '50px' }} />

                <div className='ml-3 flex justify-content-start'>
                    {!ApiService.getIsProduction() && <span style={{ color: "red", fontWeight: "bold" }}>MEDIU DE TEST</span>}
                </div>

                <div className='ml-3 flex flex-column sm:flex-row justify-content-end'>
                    <div className='my-1 sm:my-0'>
                        <a href="https://concurs-national.anfp.gov.ro/termeni-conditii/" target="_blank" rel="noreferrer">{translatedMessage("terms.tc")}</a>
                        <span className='hidden sm:inline mx-1'>|</span>
                    </div>
                    <div className='my-1 sm:my-0'>
                        <a href="https://concurs-national.anfp.gov.ro/politica-confidentialitate/" target="_blank" rel="noreferrer">{translatedMessage("terms.privacy")}</a>
                        <span className='hidden sm:inline mx-1'>|</span>
                    </div>
                    <div className='my-1 sm:my-0'>
                        <a href="https://www.anfp.gov.ro/" target="_blank" rel="noreferrer">anfp.gov.ro</a>
                        <span className='hidden sm:inline mx-1'>|</span>
                    </div>
                    <div className='my-1 sm:my-0'>
                        <a href="https://concurs-national.anfp.gov.ro/" target="_blank" rel="noreferrer">concurs-national.anfp.gov.ro/</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppFooter;
