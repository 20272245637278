import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import DOMPurify from 'dompurify';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { Button } from 'primereact/button';
import SecurityService from '../../service/SecurityService';
import StorageFolderFileList from '../file/StorageFolderFileList';
import ClarificationDialog from './ClarificationDialog';
import GeneralUtils from '../../utilities/GeneralUtils';

const ClarificationComponent = (props) => {
    const [clarification, setClarification] = useState({ application: {} });
    const [canEdit, setCanEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [viewEditDialog, setViewEditDialog] = useState(false);

    const toast = useRef(null);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true);

        const checkCanEdit = async () => {
            setCanEdit(await SecurityService.check('CAN_EDIT_CLARIFICATION', props.clarification.id));
        };


        checkCanEdit()
        setClarification(props.clarification);
        setIsLoading(false);
    }, [props.clarification]);


    const checkCanEdit = async () => {
        setCanEdit(await SecurityService.check('CAN_EDIT_CLARIFICATION', props.clarification.id));
    };

    const afterDialogClarificationSave = (savedClarification) => {
        setClarification(savedClarification);
        checkCanEdit();
    };

    const saveClarification = (data) => {
        applicationService
            .saveClarification(data)
            .then((savedClarification) => {
                setClarification(savedClarification);
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedClarification);
                    checkCanEdit();
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const confirmSendClarification = () => {
        confirmDialog({
            message: translatedMessage('application.send.clarification.response.confirm'),
            header: translatedMessage('generic.confirm'),
            acceptClassName: 'p-button-text',
            acceptIcon: 'pi pi-check',
            rejectClassName: 'p-button-text',
            rejectIcon: 'pi pi-times',
            className: 'pcn-confirm-dialog no-icon',
            accept: () => sendClarification()
        });
    };

    const sendClarification = () => {
        let _clarification = { ...clarification };
        _clarification.sent = true;
        saveClarification(_clarification);
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
                <Toast ref={toast} />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                {canEdit && !clarification.sent && 'RESPONSE' === clarification.type &&
                    <ConfirmDialog />
                }
                <div className='w-full text-align-left mb-0'>
                    <div className="grid mt-1">
                        <div className="col-12 md:col-6">
                            <div className="filed-label">{translatedMessage('generic.status')}</div>
                            <div className="filed-value flex align-items-center">
                                <span className={`mr-2 pill clarification-status-${clarification.sent ? 'sent' : 'not-sent'}`}>
                                    {clarification.sent
                                        ? translatedMessage('application.' + clarification.type.toLowerCase() + '.sent')
                                        : translatedMessage('application.' + clarification.type.toLowerCase() + '.not.sent')}
                                </span>
                                <Button
                                    label={translatedMessage('generic.send')}
                                    icon="pi pi-send"
                                    className="p-button-text pcn-button-slim"
                                    onClick={confirmSendClarification}
                                    visible={canEdit && !clarification.sent && 'RESPONSE' === clarification.type && !props.readOnly}
                                />
                                {!canEdit && !clarification.sent && 'RESPONSE' === clarification.type && !props.readOnly &&
                                    <div className="font-bold">{translatedMessage("application.clarification.response.send.expired")}</div>
                                }
                            </div>
                        </div>
                        {'REQUEST' === clarification.type &&
                            <div className="col-12 md:col-6">
                                <div className="filed-label">{translatedMessage('application.clarification.request.date')}</div>
                                <div className="filed-value">
                                    {GeneralUtils.formatDate(clarification.lastUpdatedOn)}
                                </div>
                            </div>
                        }
                    </div>

                    {clarification && clarification.id && (
                        <div className="w-full grid pcn-data-view-panel mt-2">
                            <div className="col-12">
                                <div className="filed-label flex align-items-center">
                                    <span className='mr-2'>{translatedMessage('application.clarification.detail')}</span>
                                    <Button
                                        label={translatedMessage('generic.edit')}
                                        icon="pi pi-pencil"
                                        className="p-button-text pcn-button-slim"
                                        onClick={() => setViewEditDialog(true)}
                                        visible={canEdit && !props.readOnly}
                                    />
                                </div>
                                <div className="field p-inputgroup mb-2">
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clarification.detail) }} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="filed-label">{translatedMessage('generic.files')}</div>
                                <StorageFolderFileList folderId={clarification.folder.id} folderName={clarification.folder.name} showUploadButton={true} readOnly={!canEdit || props.readOnly} />
                            </div>
                        </div>
                    )}
                    {!(clarification && clarification.id) && <span>{translatedMessage('application.clarification.none')}</span>}
                </div>


                <ClarificationDialog
                    clarification={clarification}
                    applicationId={clarification.application.id}
                    visible={viewEditDialog}
                    onHide={() => setViewEditDialog(false)}
                    afterSave={afterDialogClarificationSave}
                    type={clarification.type}
                />
            </>
        );
    }
};

export default ClarificationComponent;
