import React, { useState, useEffect, useRef, useMemo } from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import { Dropdown } from 'primereact/dropdown';

const quizResultOptions = [
    { label: translatedMessage('Result.ACCEPTED'), code: 'ACCEPTED' },
    { label: translatedMessage('Result.REJECTED'), code: 'REJECTED' },
];

const ApplicationTestResultEditDialog = (props) => {
    const [applicationTest, setApplicationTest] = useState(null);

    const toast = useRef(null);

    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        let _applicationTest = { ...props.applicationTest }
        if(_applicationTest.testType === "QUIZ") {
            if (!_applicationTest.quizResultAfterContestation) {
                _applicationTest.quizResultAfterContestation = _applicationTest.quizResult
            }
        } else {
            if (!_applicationTest.resultAfterContestation) {
                _applicationTest.resultAfterContestation = _applicationTest.result
            }
        }

        setApplicationTest(_applicationTest);
    }, [props]);

    const hideDialog = () => {
        if (typeof props.visibleSetter === 'function') {
            props.visibleSetter(false);
        }
        formik.resetForm();
    };

    const saveApplicationTest = (data) => {
        applicationService.saveApplicationTestResult(data)
            .then((savedTest) => {
                if (typeof props.afterSave === 'function') {
                    props.afterSave(savedTest);
                }
                toast.current.show({ severity: 'success', summary: translatedMessage('generic.save.success') });
                hideDialog();
                setApplicationTest(null);
                formik.resetForm();
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: applicationTest,
        validate: (data) => {
            let errors = {};

            if(data.testType === "QUIZ") {
                if (!data.quizResultAfterContestation) {
                    errors.quizResultAfterContestation = translatedMessage('form.error.result.required');
                }
            } else {
                if (!data.resultAfterContestation && data.resultAfterContestation !== 0) {
                    errors.resultAfterContestation = translatedMessage('form.error.result.required');
                }
            }    

            return errors;
        },
        onSubmit: (data) => {
            saveApplicationTest(data);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error text-align-left">{formik.errors[name]}</small>;
    };

    const testCenterDialogFooter = (
        <>
            <Button label={translatedMessage('generic.cancel')} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label={translatedMessage('generic.save')} icon="pi pi-save" className="p-button-text" form="applicationTest-form" type="submit" />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                visible={props.visible}
                style={{ width: '450px' }}
                header={translatedMessage('test.resultAfterContestation.details')}
                modal
                className="p-fluid"
                footer={testCenterDialogFooter}
                onHide={hideDialog}
            >
                <form id="applicationTest-form" onSubmit={formik.handleSubmit}>
                    <div className="form p-inputgroup mt-3 mb-2">
                        <span className="p-float-label">
                            {applicationTest?.testType === "QUIZ" && 
                                <Dropdown id="quizResultAfterContestation" name="quizResultAfterContestation" optionValue="code" optionLabel="label" 
                                    value={formik.values?.quizResultAfterContestation} options={quizResultOptions} onChange={formik.handleChange} />
                            }
                            {applicationTest?.testType !== "QUIZ" &&
                                <InputNumber id="resultAfterContestation" name="resultAfterContestation" value={formik.values?.resultAfterContestation} onValueChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('resultAfterContestation') })} />
                            }

                            <label htmlFor="resultAfterContestation" className={classNames({ 'p-error': isFormFieldValid('resultAfterContestation') })}>
                                {translatedMessage('test.resultAfterContestation')}*
                            </label>
                        </span>
                    </div>
                    {getFormErrorMessage('resultAfterContestation')}
                </form>
            </Dialog>
        </>
    );
};

export default ApplicationTestResultEditDialog;
