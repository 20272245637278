import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import { translatedMessage } from '../../service/LanguageService';
import { ApplicationService } from '../../service/ApplicationService';
import CompetitionDataTableUtils from '../../utilities/CompetitionDataTableUtils';

const MyApplications = (props) => {
    const [applications, setApplications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const toast = useRef(null);
    const applicationService = useMemo(() => new ApplicationService(), []);

    useEffect(() => {
        setIsLoading(true)

        const getMyApplications = async () => {
            applicationService
                .getMyApplicationsList()
                .then((_applications) => {
                    setApplications(_applications)
                    setIsLoading(false)
                })
                .catch((error) => toast.current.show({ severity: 'error', summary: translatedMessage(error), life: 5000 }));
        };

        getMyApplications()
    }, [applicationService, props.reload])

    const viewMyApplication = (rowData) => {
        navigate(`/application/${rowData.id}`);
    };

    const statusTemplate = (rowData) => {
        return (
            <div className={`inline-block status status-application-${rowData.status.toString().toLowerCase()}`}>{translatedMessage('ApplicationStatus.' + rowData.status)}</div>
        )
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex flex-wrap align-items-center justify-content-end">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-primary m-1" onClick={() => viewMyApplication(rowData)} />
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="w-full flex align-items-center">
                <ProgressSpinner />
                <Toast ref={toast} />
            </div>
        );
    } else {
        return (
            <>
                <Toast ref={toast} />
                <div className="card-header">
                    <h5>{translatedMessage('menu.application')}</h5>
                </div>
                <DataTable
                    value={applications}
                    dataKey="id"
                    paginator
                    rows={5}
                    rowsPerPageOptions={5}
                    className="datatable-responsive"
                    responsiveLayout="stack"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate={"{first} - {last} " + translatedMessage('generic.of') + " {totalRecords}"}
                    emptyMessage={translatedMessage('generic.tableEmptyMessage')}
                >
                    <Column
                        field="competition.name"
                        header={translatedMessage('competition.competition')}
                        sortable
                        headerStyle={{ width: '40%', minWidth: '10rem' }} 
                    />
                    <Column
                        field="competition.positionCategory"
                        header={translatedMessage('competition.positionCategory')}
                        sortable
                        body={(e) => CompetitionDataTableUtils.categoryBodyTemplate(e.competition.positionCategory)}
                        headerStyle={{ width: '25%', minWidth: '8rem' }}
                    />
                    <Column 
                        ield="status" 
                        header={translatedMessage('generic.status')} 
                        body={statusTemplate} 
                        sortable 
                        headerStyle={{ width: '25%', minWidth: '10rem' }} 
                    />
                    <Column body={actionBodyTemplate} />
                </DataTable>
            </>
        );
    }
};

export default MyApplications;
